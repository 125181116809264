import { CustomerMidleLayerApi2 } from '@shared/models';
import { createContext } from 'react';
import { InvoiceHead } from '../components';
import { initialState } from './reducer';
import { State } from './type';

export type ContextValue = State & {
  getInvoiceData$: (
    customerId: CustomerMidleLayerApi2['customerIdApi'],
  ) => Promise<void>;
  updateInvoiceData: (data: InvoiceHead) => void;
};

export const Context = createContext<ContextValue>({
  ...initialState,
  getInvoiceData$: () => Promise.resolve(),
  updateInvoiceData: () => {
    // do nothing.
  },
});
