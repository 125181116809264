import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    bookingForm: {
      marginTop: theme.spacing(2),
    },
    description: {
      marginBottom: theme.spacing(4),
      fontSize: theme.typography.pxToRem(18),
    },
    calendarHelperText: {
      fontSize: theme.typography.pxToRem(18),
    },
  }),
);
