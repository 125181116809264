import { EventApi } from '@fullcalendar/react';
import Box from '@material-ui/core/Box';
import { getDateTime } from '@shared/helpers';
import clsx from 'clsx';
import React, { FC, useMemo } from 'react';
import { useStyles } from './event-content.styles';

type Props = {
  event: EventApi;
  selectedDate: Date | null;
};

export const EventContent: FC<Props> = (props) => {
  const { event, selectedDate } = props;
  const classes = useStyles();
  const formattedDate = useMemo(() => {
    const startHour = event.start && getDateTime(event.start);
    const endHour = event.end && getDateTime(event.end);

    return `${startHour} - ${endHour}`;
  }, [event.end, event.start]);
  const isActive = useMemo(
    () => selectedDate && event.start && +selectedDate === +event.start,
    [selectedDate, event.start],
  );

  if (!formattedDate) {
    return null;
  }

  return (
    <Box className={clsx(classes.brick, { active: isActive })}>
      <strong>{formattedDate}</strong>
    </Box>
  );
};
