import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      marginBottom: theme.spacing(5),
      '&:last-child': {
        marginBottom: 0,
      },
      '& a': {
        color: `${palette.internationalOrange}`,
        [theme.breakpoints.down('xs')]: {
          wordBreak: 'break-all',
        },
      },
    },
    titleWrapper: {
      marginBottom: theme.spacing(2),
      [theme.breakpoints.only('xs')]: {
        textAlign: 'center',
      },
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
      },
    },
    icon: {
      color: palette.internationalOrange,
      stroke: 'currentColor',
      flexShrink: 0,
      [theme.breakpoints.only('xs')]: {
        marginBottom: theme.spacing(1),
      },
      [theme.breakpoints.up('sm')]: {
        marginRight: theme.spacing(2),
      },
    },
    title: {
      fontWeight: theme.typography.fontWeightBold,
      margin: 0,
    },
  }),
);
