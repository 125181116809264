import { getEnvValue } from '@shared/helpers';
import { createInstance, HttpClient } from '@shared/http';

const kundoInstance = createInstance({
  baseURL: getEnvValue('KUNDO_API_URL'),
  headers: {
    'Content-Type': 'application/json',
  },
});

export const kundoClient = new HttpClient(kundoInstance);
