import { TranslationLabels } from '@generated/translation-labels';
import React, { FC } from 'react';
import { ReactComponent as AppWindowError404 } from '@heimstaden/icons-library/img/streamline-regular/programing-apps-websites/apps-window/app-window.svg';
import { useTranslation } from '@shared/translations';
import { ErrorBox } from './ErrorBox/error-box.component';

const NotFoundError: FC = () => {
  const { t } = useTranslation();

  return (
    <ErrorBox
      icon={AppWindowError404}
      title={t(TranslationLabels.notFoundErrorTitle)}
      text={t(TranslationLabels.notFoundErrorText)}
    />
  );
};
export default NotFoundError;
