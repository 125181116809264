import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import MUIBox from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { useStyles } from './item.styles';

type Props = {
  label: string;
  text: string;
};

export const Item: FC<Props> = ({ label, text }) => {
  const classes = useStyles();

  return (
    <MUIBox my={2}>
      <Typography className={classes.label}>{label}</Typography>
      <Typography className={classes.text}>{text}</Typography>
    </MUIBox>
  );
};
Item.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
