import * as Sentry from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';

export const useApi = <P>(
  initialData: P,
  callback: () => Promise<P>,
): [P, boolean, boolean, (data: P) => void] => {
  const [isFetching, setFetching] = useState(true);
  const [data, setData] = useState<P>(initialData);
  const [isError, setError] = useState(false);
  const fetchData = useCallback(async () => {
    setFetching(true);

    try {
      const response = await callback();

      setData(response);
    } catch (e) {
      Sentry.captureException(e);
      setError(true);
    } finally {
      setFetching(false);
    }
  }, [callback]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return [data, isFetching, isError, setData];
};
