import { sanityClient, SanityDocumentType } from '@shared/sanity';
import { SanityRequestTypeIdResponse } from '../../../../+ticketingV2/shared';
import { FormOptions } from './request.type';

export const api = {
  getRequestTypeId$: async (
    country: GenericTypes.Country,
  ): Promise<number | undefined> => {
    const query = `
      *[_type == $type && content.country == $country][0]{
        ...content.ticketing.customerAccountRequest.types.terminateContract{
          ...select(
            typeId > 0 => {
              "requestTypeId": typeId,
            },
          ),
        },
      }
    `;
    const response = await sanityClient.fetch<SanityRequestTypeIdResponse>(
      query,
      {
        country,
        type: SanityDocumentType.SETTINGS,
      },
    );

    return response?.requestTypeId;
  },
  getFormOptions$: (country: GenericTypes.Country): Promise<FormOptions> => {
    const query = `
      *[_type == $type && content.country == $country][0]
        .content
        .ticketing
        .customerAccountRequest
        .types
        .terminateContract{
          reasons[]{
            "label": name->key,
            value,
          },
          newApartment[]{
            "label": name->key,
            value,
          }
        }
    `;

    return sanityClient.fetch(query, {
      country,
      type: SanityDocumentType.SETTINGS,
    });
  },
};
