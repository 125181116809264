import { ReactElement } from 'react';

type Props<Params> = {
  showWrapper: boolean;
  children: (params?: Params) => ReactElement;
  wrapper: (children: (params?: Params) => ReactElement) => JSX.Element;
};

export const ConditionalWrapperWithParams = <Params,>({
  showWrapper,
  children,
  wrapper,
}: Props<Params>): JSX.Element => {
  return showWrapper ? wrapper(children) : children();
};
