import { sanityClient, SanityDocumentType } from '@shared/sanity';
import { itemsPathSuffix, roomsPathSuffix } from '../../shared';
import { Area } from './areas.type';

export const api = {
  getAreas$: async (
    country: GenericTypes.Country,
    path: string,
  ): Promise<Area[]> => {
    const areaCommonQuery = `
      "buttonLabelKey": buttonLabel->key,
      "id": typeId,
      "imageURL": image{asset->{url}}.asset.url,
      "textKey": text->key,
      "titleKey": title->key,
    `;
    const query = `
      * [_type == $type && content.country == $country][0]{
        ...content.ticketing.repairMaintenanceRequest{
          "areas": [
            areas.unit{
              ${areaCommonQuery}
              "redirectURL": "${path}/" + string(typeId) + "${roomsPathSuffix}",
            },
            areas.common{
              ${areaCommonQuery}
              "redirectURL": "${path}/" + string(typeId) + "${itemsPathSuffix}",
            },
            areas.outdoor{
              ${areaCommonQuery}
              "redirectURL": "${path}/" + string(typeId) + "${itemsPathSuffix}",
            },
          ]
        }
      }
    `;
    const response = await sanityClient.fetch<{ areas: Area[] }>(query, {
      country,
      type: SanityDocumentType.SETTINGS,
    });

    return response.areas;
  },
};
