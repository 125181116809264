import { TranslationLabels } from '@generated/translation-labels';
import Hidden from '@material-ui/core/Hidden';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as SingleNeutralIcon } from '@heimstaden/icons-library/img/streamline-regular/users/geometric-full-body-single-user-neutral/single-neutral.svg';

import { Icon, useTranslation } from '../../shared';
import { routes } from '../shared';
import { useStyles } from './widget.styles';

export const Widget: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Hidden only="xs">
      <div className={classes.wrapper}>
        <div className={classes.profile}>
          <Icon icon={SingleNeutralIcon} height={32} width={32} />
          <div className={classes.text}>
            <Link to={routes.PROFILE}>
              {t(TranslationLabels.profileWidgetLink)}
            </Link>
          </div>
        </div>
      </div>
    </Hidden>
  );
};
