import React, { FC, useCallback } from 'react';
import { Box, Spinner } from '@shared/components';
import { useApi, useCountry } from '@shared/hooks';
import { api } from './property.repository';
import { Info } from './info.component';
import { getPropertyId } from './helper';
import { useApartment } from '../../state';

export const Property: FC = () => {
  const { data } = useApartment();
  const country = useCountry();

  const apartmentId = data?.objectIdApi;
  const propertyId = getPropertyId(apartmentId);

  const getPropertyDataCallback = useCallback(
    () => api.getPropertyData(country, propertyId),
    [propertyId, country],
  );

  const getForumUrlCallback = useCallback(
    () => api.getCustomerForumUrl(country),
    [country],
  );

  const requestsCallback = useCallback(
    () => Promise.all([getPropertyDataCallback(), getForumUrlCallback()]),
    [getPropertyDataCallback, getForumUrlCallback],
  );

  const [[property, forumUrl], isFetching, isError] = useApi(
    [undefined, ''],
    requestsCallback,
  );

  if (isFetching) {
    return <Spinner />;
  }

  if (!property || isError) {
    return null;
  }

  return (
    <Box>
      <Info property={property} forumUrl={forumUrl} country={country} />
    </Box>
  );
};
