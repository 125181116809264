import { TranslationLabels } from '@generated/translation-labels';
import Typography from '@material-ui/core/Typography';
import * as Sentry from '@sentry/react';
import { useCountry } from '@shared/hooks';
import { useTranslation } from '@shared/translations';
import { useSnackbar } from 'notistack';
import React, { FC, useMemo, useState } from 'react';
import { useApartment } from '../../../../+apartment';
import { useProfile } from '../../../../+profile';
import { COUNTRY_TICKETING_MODEL } from '../../../ticketing.const';
import { TicketStatus } from '../../../ticketing.enum';
import { api } from '../../../ticketing.repository';
import { ElementField, ServiceRequestDetails } from '../../../ticketing.type';
import { useTicketing } from '../../state';
import { isTicketToApprove } from '../../terminate-request.helper';
import { TerminateConfirmationButton } from '../TerminateConfrmationButton/terminate-confirmation-button.component';
import { useStyles } from './terminate-confirmation-actions.styles';
import { getUpdatedData } from './termninate-confirmation-actions.helper';
import {
  useMarkOneAsReadMutation,
  useUnreadTerminationRequestNotificationQuery,
} from '../../../../+notification/notifications.hook';

type Props = {
  data: ServiceRequestDetails;
};

export const TerminateConfirmationActions: FC<Props> = (props) => {
  const classes = useStyles();
  const { data } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { data: profileData } = useProfile();
  const { data: apartmentData } = useApartment();
  const { setTicketDetails, setTicketList, tickets } = useTicketing();
  const { t } = useTranslation();
  const [isDisabled, setDisabled] = useState(false);
  const country = useCountry();
  const markOneAsReadMutation = useMarkOneAsReadMutation();
  const {
    data: terminationNotification,
  } = useUnreadTerminationRequestNotificationQuery(data.ticketId);
  const { jiraFields } = COUNTRY_TICKETING_MODEL[country];
  const tenantsInApartment = data[jiraFields.TENANTS_IN_APARTMENT];
  const approvedBy = data[jiraFields.APPROVED_TENANTS];
  const isToApprove = useMemo(
    () =>
      isTicketToApprove(
        approvedBy as string[],
        profileData?.customerIdApi,
        data.status?.jiraKey,
        tenantsInApartment as string[],
      ),
    [approvedBy, data, profileData?.customerIdApi, tenantsInApartment],
  );
  const terminationRequestAuthorName = useMemo(() => {
    const firstNameField = data[jiraFields.AUTHOR_FIRST_NAME] as ElementField;
    const lastNameField = data[jiraFields.AUTHOR_LAST_NAME] as ElementField;

    return isToApprove && firstNameField && lastNameField
      ? `${firstNameField.value} ${lastNameField.value}`
      : '';
  }, [data, isToApprove, jiraFields]);

  if (!profileData || !apartmentData || !apartmentData.rentalObjectMyHome) {
    return null;
  }

  const handleAction = async (isAgreed = false): Promise<void> => {
    if (!profileData || !apartmentData.rentalObjectMyHome) {
      return;
    }

    setDisabled(true);
    const rentalDealId = apartmentData.rentalObjectMyHome.id.toString();
    const message = isAgreed
      ? TranslationLabels.terminationAgreedMessage
      : TranslationLabels.terminationDisagreeMessage;

    try {
      if (terminationNotification) {
        markOneAsReadMutation.mutateAsync({
          id: terminationNotification.id,
        });
      }

      await api.handleConfirmationTerminateRequest(
        data.ticketId,
        country,
        profileData,
        rentalDealId,
        isAgreed,
      );

      const updatedData = getUpdatedData(
        data,
        isAgreed,
        TicketStatus.TERMINATION_CANCELLED,
        tickets,
        profileData.customerIdApi,
        approvedBy as string[],
        jiraFields.APPROVED_TENANTS,
      );

      setTicketDetails(updatedData.details);
      setTicketList(updatedData.list);
      enqueueSnackbar(t(message), { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(t(TranslationLabels.formGlobalErrorMessage), {
        variant: 'error',
      });
      Sentry.captureException(e);
    } finally {
      setDisabled(false);
    }
  };

  return isToApprove ? (
    <div className={classes.confirmationWrapper}>
      <Typography>
        {t(TranslationLabels.terminateRequestInfoText, {
          authorName: terminationRequestAuthorName,
        })}
      </Typography>
      <div className={classes.buttonsRow}>
        <TerminateConfirmationButton
          isDisabled={isDisabled}
          onClick={handleAction}
        />
        <TerminateConfirmationButton
          isAgreed
          isDisabled={isDisabled}
          onClick={handleAction}
        />
      </div>
    </div>
  ) : null;
};
