import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import { useTermination } from '../../state';
import { FlowStep } from '../FlowStep/flow-step.component';
import { Stepper } from '../Stepper/stepper.component';

export const FlowSteps: FC = () => {
  const { currentFlowStep } = useTermination();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Stepper />
        <FlowStep flowStep={currentFlowStep} />
      </Grid>
    </Grid>
  );
};

FlowSteps.displayName = 'FlowSteps';
