import React, {
  forwardRef,
  MutableRefObject,
  useCallback,
  useEffect,
  useState,
} from 'react';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { theme } from '@shared/consts';
import { EventContent } from '../EventContent/event-content.component';
import { TimeSlotLabel } from '../TimeSlotLabel/time-slot-label.component';
import { HeaderCell } from '../HeaderCell/header-cell.component';
import { Config } from '../types';
import { useStyles } from './view.styles';

export const View = forwardRef<FullCalendar, Config>((props, ref) => {
  const {
    date,
    mobileView,
    onDateChange,
    view,
    eventType,
    validRange,
    ...restProps
  } = props;
  const classes = useStyles();
  const calendarApi = (ref as MutableRefObject<FullCalendar>)?.current?.getApi();
  const [selectedDate, setSelectedDate] = useState<Date | null>(date);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const onResizeHandler = useCallback(() => {
    if (!calendarApi) {
      return;
    }

    const viewType = isMobile && mobileView ? mobileView : view;

    if (calendarApi.view.type !== viewType) {
      calendarApi.changeView(viewType);
    }
  }, [calendarApi, isMobile, mobileView, view]);

  useEffect(() => onResizeHandler(), [onResizeHandler]);

  const initialDate = eventType === 'MoveOut' ? validRange?.end : date;

  return (
    <Box className={classes.wrapper}>
      <FullCalendar
        {...restProps}
        validRange={validRange}
        initialView={view}
        dayHeaderContent={(props) => <HeaderCell {...props} />}
        headerToolbar={false}
        plugins={[interactionPlugin, timeGridPlugin]}
        ref={ref}
        slotLabelContent={(props) => <TimeSlotLabel {...props} />}
        eventContent={(props) => (
          <EventContent event={props.event} selectedDate={selectedDate} />
        )}
        eventClick={(props) => {
          const { start, end, id } = props.event;

          if (start && end) {
            onDateChange({ id, start, end });
          }

          setSelectedDate(start);
        }}
        initialDate={initialDate || undefined}
      />
    </Box>
  );
});
