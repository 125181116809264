import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    buttonsWrapper: {
      marginTop: theme.spacing(2),
    },
    container: {
      backgroundColor: palette.alabaster,
      border: `1px solid ${palette.altoDD}`,
      borderRadius: theme.spacing(1),
      boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.12)',
      position: 'relative',
      '&:before': {
        backgroundImage: 'url(/assets/img/termination-details.png)',
        backgroundPosition: 'bottom right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        bottom: 0,
        content: "''",
        display: 'block',
        height: '100%',
        maxHeight: 170,
        position: 'absolute',
        right: 0,
        width: '50%',
        zIndex: 1,
      },
    },
    label: {
      color: palette.scorpion,
    },
    content: {
      display: 'inline-flex',
      alignItems: 'center',
    },
    wrapper: {
      minHeight: 400,
      padding: theme.spacing(4, 5),
      zIndex: 2,
    },
    icon: {
      marginRight: theme.spacing(1),
      '& path': {
        strokeWidth: 2,
      },
      cursor: 'pointer',
    },
    suggestedDateIcon: {
      color: palette.orangePeel,
    },
  }),
);
