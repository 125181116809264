import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

const ICON_HEIGHT_PX = 32;

export const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      cursor: 'pointer',
      height: '100%',
      padding: theme.spacing(0, 5),
      position: 'relative',
      zIndex: 5,

      '&.disabled': {
        cursor: 'default',
      },
    },
    buttonIcon: {
      display: 'block',
      position: 'absolute',
      top: `calc(50% - ${ICON_HEIGHT_PX / 2}px)`,
      left: `calc(50% - ${ICON_HEIGHT_PX / 2}px)`,

      '&.disabled': {
        opacity: 0.4,
      },
      width: '32px',
      height: 'auto',

      '& path': {
        stroke: palette.white,
      },
    },
  }),
);
