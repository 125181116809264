import { TranslationLabels } from '@generated/translation-labels';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { Spinner } from '@shared/components';
import { COUNTRY_TRANSLATION_LABELS } from '@shared/consts';
import { useApi } from '@shared/hooks';
import React, { FC, Fragment, useCallback, useMemo } from 'react';
import { ReactComponent as PhoneIcon } from '@heimstaden/icons-library/img/streamline-regular/phones-mobile-devices/phone/phone.svg';
import { ReactComponent as EmailActionUnreadIcon } from '@heimstaden/icons-library/img/streamline-regular/emails/email-actions/email-action-unread.svg';
import { useTranslation } from '@shared/translations';
import { generateUniqId } from '../../helpers';
import { Icon } from '../icons';
import { api } from './contact-info.repository';
import { useStyles } from './contact-info.styles';
import { ContactData } from './contact-info.type';

type Props = {
  country?: GenericTypes.Country;
  isDense?: boolean;
  isTitle?: boolean;
  noAddress?: boolean;
  noEmail?: boolean;
};

export const ContactInfo: FC<Props> = (props) => {
  const { country, isDense, isTitle, noAddress, noEmail } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const callback = useCallback((): Promise<ContactData[]> => api.getData(), []);
  const [data, isFetching, isError] = useApi<ContactData[]>([], callback);
  const filteredData = useMemo(
    () =>
      country
        ? data.filter(
            ({ country: contactCountry }) => contactCountry === country,
          )
        : data,
    [country, data],
  );

  if (isFetching) {
    return <Spinner />;
  }

  if (isError) {
    return null;
  }

  return (
    <div
      {...(isTitle && {
        className: classes.contactInfo,
      })}
    >
      {filteredData.map(({ _id, address, country, email, phone }) => (
        <Fragment key={_id}>
          {(email || phone) && (
            <div className={classes.contactElement}>
              {isTitle && (
                <Typography className={classes.contactInfoTitle} variant="h3">
                  {t(
                    TranslationLabels.contactInfoTitle,
                    data.length > 1
                      ? {
                          country: `${t(TranslationLabels.forConjunction, {
                            variable: t(COUNTRY_TRANSLATION_LABELS[country]),
                          })}`,
                        }
                      : undefined,
                  )}
                </Typography>
              )}
              <div className={isDense ? classes.dense : classes.contact}>
                {phone && (
                  <Typography className={classes.text}>
                    <Icon
                      className={classes.icon}
                      icon={PhoneIcon}
                      height={24}
                      width={24}
                    />
                    <Link href={`tel:${phone}`}>{phone}</Link>
                  </Typography>
                )}
                {email && !noEmail && (
                  <Typography className={classes.text}>
                    <Icon
                      className={classes.icon}
                      icon={EmailActionUnreadIcon}
                      height={24}
                      width={24}
                    />
                    <Link href={`mailto:${email}`}>{email}</Link>
                  </Typography>
                )}
              </div>
            </div>
          )}
          {address && !noAddress && (
            <div>
              <Typography component="strong" className={classes.addressLabel}>
                {t(TranslationLabels.footerAddressLabel)}:
              </Typography>
              {address.split('\n').map((text: string) => (
                <Typography key={`text-${generateUniqId()}`}>{text}</Typography>
              ))}
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
};

ContactInfo.defaultProps = {
  isDense: false,
  noAddress: false,
  noEmail: false,
};
