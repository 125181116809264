import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    disabledText: {
      color: palette.black,
      opacity: 0.38,
    },
    formGroup: {
      flexFlow: 'row nowrap',
      flexGrow: 0,
      userSelect: 'none',
    },
    helperText: {
      marginBottom: theme.spacing(1),
      color: palette.lighterGray,
    },
    readonly: {
      cursor: 'default',
      pointerEvents: 'none',
    },
    stateDescription: {
      margin: theme.spacing(0, 0, 1, 5),
    },
    uncheckedText: {
      color: palette.doveGray,
    },
  }),
);
