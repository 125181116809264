import { Container } from '@shared/components';
import clsx from 'clsx';
import React, { FC, ReactNode } from 'react';
import { IntroProps } from '../Intro';
import { useStyles } from './content.styles';
import { Stepper as StepperConfig } from '../../../types';
import { Stepper } from '../Stepper/Stepper';
import { ContentRows } from './content-rows.component';
import { ContentColumns } from './content-columns.component';
import { ViewProps } from './types';

type ViewType = 'rows' | 'columns';
type Props = {
  children: ReactNode;
  introProps?: IntroProps;
  classes?: { boxClassName?: string; containerClassName?: string };
  isFetching?: boolean;
  stepperConfig?: StepperConfig;
  allStepsFinished?: boolean;
  type?: ViewType;
};

export const Content: FC<Props> = (props) => {
  const {
    children,
    classes,
    introProps,
    isFetching,
    stepperConfig,
    allStepsFinished,
    type,
  } = props;

  const contentStyles = useStyles();

  const viewProps: ViewProps = {
    introProps,
    isFetching,
    children,
  };

  return (
    <Container className={clsx(classes?.containerClassName)}>
      <Stepper
        stepperConfig={stepperConfig}
        allStepsFinished={allStepsFinished}
      />
      <div className={clsx(contentStyles.box, classes?.boxClassName)}>
        {(!type || type === 'rows') && <ContentRows {...viewProps} />}
        {type === 'columns' && <ContentColumns {...viewProps} />}
      </div>
    </Container>
  );
};
Content.defaultProps = {
  classes: undefined,
  introProps: undefined,
  isFetching: false,
};
