import {
  DEFAULT_COUNTRY,
  SUPPORTED_COUNTRIES_DATASOURCE,
} from '@shared/consts';
import { DataSourceEnum } from '@shared/enums';
import { localStorageService } from '@shared/services';

export const getCountry = (
  dataSource?: DataSourceEnum,
): GenericTypes.Country => {
  return (
    localStorageService.getCountryCode() ||
    (dataSource && SUPPORTED_COUNTRIES_DATASOURCE[dataSource]) ||
    DEFAULT_COUNTRY
  );
};
