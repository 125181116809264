import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    closeIcon: {
      color: palette.black,
      padding: theme.spacing(1),
      position: 'absolute',
      top: theme.spacing(2),
      right: theme.spacing(3),
    },
    title: {
      alignItems: 'center',
      display: 'flex',
      flexFlow: 'row wrap',
      justifyContent: 'flex-start',
      paddingLeft: 0,
      paddingRight: 0,
    },
    paper: {
      borderRadius: theme.spacing(2),
      padding: theme.spacing(4),
      minWidth: 300,
    },
  }),
);
