import { Descendant, Element } from 'slate';
import {
  SlateText,
  JiraText,
  SlateParagraph,
  JiraParagraph,
  JiraContent,
  SlateBulletList,
  JiraBulletList,
  SlateQuote,
  JiraQuote,
  JiraValue,
} from './types';

const stylingSlateToJira = (text: SlateText): JiraText['marks'] => {
  const marks: JiraText['marks'] = [];

  if (text.bold) {
    marks.push({ type: 'strong' });
  }

  if (text.italic) {
    marks.push({ type: 'em' });
  }

  if (text.underline) {
    marks.push({ type: 'underline' });
  }

  if (text.code) {
    marks.push({ type: 'code' });
  }

  return marks;
};

const textSlateToJira = (text: SlateText): JiraText => {
  const result: JiraText = {
    type: 'text',
    text: text.text || ' ',
  };

  result.marks = stylingSlateToJira(text);

  if (result.marks?.length === 0) {
    delete result.marks;
  }

  return result;
};

const paragraphSlateToJira = (
  children: SlateParagraph['children'],
): JiraParagraph => {
  return {
    type: 'paragraph',
    content: children.map((c) => textSlateToJira(c)),
  };
};

const bulletListSlateToJira = (
  children: SlateBulletList['children'],
): JiraBulletList => {
  return {
    type: 'bulletList',
    content: children.map((items) => {
      return {
        type: 'listItem',
        content: items.children.map((item) => {
          const result: JiraContent = { type: 'text', text: item.text || ' ' };
          result.marks = stylingSlateToJira(item);

          if (result.marks?.length === 0) {
            delete result.marks;
          }

          return {
            type: 'paragraph',
            content: [result],
          };
        }),
      };
    }),
  };
};

const quoteSlateToJira = (children: SlateQuote['children']): JiraQuote => {
  return {
    type: 'blockquote',
    content: children.map((item) => {
      return {
        type: 'paragraph',
        content: [textSlateToJira(item)],
      };
    }),
  };
};

export const fromSlateToJira = (value: Descendant[]): JiraValue => {
  const jiraValue: JiraValue = {
    type: 'doc', // root node
    version: 1,
    content: [],
  };

  value.forEach((v) => {
    if (Element.isElement(v)) {
      if (v?.type === 'paragraph') {
        const item = paragraphSlateToJira(v.children);
        jiraValue.content.push(item);
        return;
      }

      if (v.type === 'bulleted-list') {
        const item = bulletListSlateToJira(v.children);
        jiraValue.content.push(item);
        return;
      }

      if (v.type === 'block-quote') {
        const item = quoteSlateToJira(v.children);
        jiraValue.content.push(item);
        return;
      }
    }
  });

  return jiraValue;
};
