import { FC, lazy, ComponentType } from 'react';
import { RouteComponentProps } from 'react-router';

export function safeLazyLoad(
  importCallback: () => Promise<{ default: ComponentType }>,
): FC<RouteComponentProps> {
  return lazy(() =>
    importCallback().catch((err) => {
      if (err.name === 'ChunkLoadError') {
        // reload page to load new chunks
        window.location.reload();
        return { default: () => null };
      }

      throw err;
    }),
  );
}
