export const getPropertyId = (
  apartmentId?: string | number,
): string | undefined => {
  if (apartmentId === undefined) {
    return undefined;
  }

  // for NO apartments there is no property ID
  if (typeof apartmentId === 'number') {
    return undefined;
  }

  // for DK apartments get property ID from apartment ID
  const result = /(\d+)-(\d+)-(\d+)-(\d+)/g.exec(apartmentId);
  if (!result) return undefined;
  return `${result[1]}-${result[2]}`;
};
