import { TranslationLabels } from '@generated/translation-labels';
import React, { FC, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from '@shared/translations';
import { ReactComponent as OfficeStampDocument } from '@heimstaden/icons-library/img/streamline-regular/work-office-companies/office/office-stamp-document.svg';
import { ReactComponent as RealEstateSearchBuilding } from '@heimstaden/icons-library/img/streamline-regular/real-estate/search/real-estate-search-building.svg';
import { ReactComponent as ToolsWenchScrewdriver } from '@heimstaden/icons-library/img/streamline-regular/tools-constructions/tools/tools-wench-screwdriver.svg';
import { Tips, Item } from '../Tips';
import { routes } from '../../../../+apartment/shared';
import { useTermination } from '../../state';

export const MoveOutInspectionInstruction: FC = () => {
  const { t } = useTranslation();
  const { documents } = useTermination();

  const pricelistUrl = useMemo(
    () =>
      documents.find(
        ({ attachmentSubType }) => attachmentSubType === 'MoveOutPriceList',
      )?.url || '',
    [documents],
  );

  return (
    <Tips titleKey={TranslationLabels.moveOutInspectionTipsTitle}>
      <Grid container spacing={4}>
        <Grid item md={6} xs={12}>
          <Item
            titleKey={TranslationLabels.moveOutInspectionTipDocumentsTitle}
            icon={OfficeStampDocument}
          >
            <Typography>
              {t(TranslationLabels.moveOutInspectionTipDocumentsText, {
                myApartmentUrl: routes.APARTMENT,
              })}
            </Typography>
          </Item>
          <Item
            titleKey={TranslationLabels.moveOutInspectionTipWhatHappensTitle}
            icon={RealEstateSearchBuilding}
          >
            <Typography>
              {t(TranslationLabels.moveOutInspectionTipWhatHappensText, {
                moveInInspectionProtocolUrl: routes.APARTMENT,
                inspectionChecklistUrl: routes.APARTMENT,
              })}
            </Typography>
          </Item>
        </Grid>
        <Grid item md={6} xs={12}>
          <Item
            titleKey={TranslationLabels.moveOutInspectionTipRepairTitle}
            icon={ToolsWenchScrewdriver}
          >
            <Typography>
              {t(TranslationLabels.moveOutInspectionTipRepairText, {
                pricelistUrl,
              })}
            </Typography>
          </Item>
        </Grid>
      </Grid>
    </Tips>
  );
};

MoveOutInspectionInstruction.displayName = 'MoveOutInspectionInstruction';
