import React, { ReactNode } from 'react';
import { FieldConfig } from '@shared/FormV2';
import { FormField } from '@shared/FormV2/components/form-field.component';
import { Values } from './request.model';

export const getLeftColumnInputs = (
  jiraFields: Record<string, string>,
): string[] => [
  jiraFields.SUMMARY,
  jiraFields.NEW_APARTMENT,
  jiraFields.ALTERNATIVE_CONTACT_EMAIL,
  jiraFields.ALTERNATIVE_CONTACT_PHONE,
];

export const getRightColumnInputs = (
  jiraFields: Record<string, string>,
): string[] => [jiraFields.NOTICE, jiraFields.POTENTIAL_IMPROVEMENT];

export const renderField = (
  field: FieldConfig<Values> | undefined,
  values: Values,
  className: string,
  key: string,
): ReactNode =>
  field ? (
    <div className={className} key={key}>
      <FormField<Values> fieldConfig={field} values={values} />
    </div>
  ) : null;
