import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) => {
  return createStyles({
    editor: {
      width: '100%',
      padding: theme.spacing(0, 2),
    },
  });
});
