import { Typography } from '@material-ui/core';
import React, { FC, SVGProps } from 'react';
import { Icon } from '@shared/components';
import { useStyles } from './card-info.styles';

type Props = {
  label: string;
  value: string;
  icon?: FC<SVGProps<SVGSVGElement>>;
};

export const CardInfo: FC<Props> = ({ label, value, icon }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography className={classes.label}>{label}</Typography>
      <div className={classes.content}>
        {icon && (
          <Icon
            icon={icon}
            width={15}
            height={15}
            className={classes.icon}
            strokeWidth={2}
          />
        )}
        <Typography>{value}</Typography>
      </div>
    </div>
  );
};
