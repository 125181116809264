import React from 'react';
import { TranslationLabels } from '@generated/translation-labels';
import { IntroProps } from '../../../shared/components/Intro';
import { COUNTRY_TICKETING_MODEL } from '../../../../+ticketing/ticketing.const';
import { ReactComponent as AdditionalInfoImage } from '../../../assets/additional-info.svg';

export const getIntroProps = (
  country: GenericTypes.Country,
  subItemId: number,
): IntroProps => {
  const { jiraItems } = COUNTRY_TICKETING_MODEL[country];

  switch (subItemId) {
    case jiraItems.INVOICE_CUSTOMER_ACCOUNT_CONTRACT_AND_TENANCY_CHANGE_INFORMATION: {
      return {
        titleKey:
          TranslationLabels.invoiceAndCustomerRequestChangeOrUpdateCustomerDetailsTitle,
        descriptionKey:
          TranslationLabels.invoiceAndCustomerRequestChangeOrUpdateCustomerDetailsDescription,
        childrenRightColumn: <AdditionalInfoImage />,
      };
    }
    default: {
      return {
        titleKey: TranslationLabels.ticketingGeneralTitle,
        descriptionKey: TranslationLabels.ticketingGeneralText,
        childrenRightColumn: <AdditionalInfoImage />,
      };
    }
  }
};
