import { HeimAttributes } from '@shared/enums';
import { storageService } from '@shared/services';
import { getEnvValue } from '../helpers';
import { HttpClient } from './http.client';
import { createInstance } from './http.instance';

const heimInstance = createInstance({
  baseURL: `${getEnvValue('HEIMAPI_URL')}/Customer`,
  headers: {
    'Content-Type': 'application/json',
  },
});

heimInstance.interceptors.request.use(({ url, ...rest }) => {
  const partnerName = getEnvValue('HEIMAPI_PARTNERNAME');
  const guid = storageService.getGuid();
  const dataSource = storageService.getDataSource();

  return {
    url: url
      ? url
          .replace(HeimAttributes.DATA_SOURCE, dataSource)
          .replace(HeimAttributes.PARTNER_NAME, partnerName)
          .replace(HeimAttributes.GUID, guid)
      : '',
    ...rest,
  };
});

export const heimClient = new HttpClient(heimInstance);
