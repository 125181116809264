import { ReactComponent as ACHeatIcon } from '@heimstaden/icons-library/img/streamline-regular/hotels-spas/ac-heating/ac-heat.svg';
import { ReactComponent as AppliancesFridgeIcon } from '@heimstaden/icons-library/img/streamline-regular/food-drinks/appliances/appliances-fridge.svg';
import { ReactComponent as AppliancesStove } from '@heimstaden/icons-library/img/streamline-regular/food-drinks/appliances/appliances-stove.svg';
import { ReactComponent as BathroomShowerIcon } from '@heimstaden/icons-library/img/streamline-regular/hotels-spas/bathroom/bathroom-shower.svg';
import { ReactComponent as BikingPersonIcon } from '@heimstaden/icons-library/img/streamline-regular/sports/biking/biking-person.svg';
import { ReactComponent as BuildingIcon } from '@heimstaden/icons-library/img/streamline-regular/building-construction/buildings/building.svg';
import { ReactComponent as FamilyOutdoorsPlayhouseIcon } from '@heimstaden/icons-library/img/streamline-regular/family-babies-kids/outdoor-activities/family-outdoors-playhouse.svg';
import { ReactComponent as FamilyOutdoorsTeeterIcon } from '@heimstaden/icons-library/img/streamline-regular/family-babies-kids/outdoor-activities/family-outdoors-teeter.svg';
import { ReactComponent as GarbageBinIcon } from '@heimstaden/icons-library/img/streamline-regular/wayfinding/garbages/garbage-bin.svg';
import { ReactComponent as GardeningWateringPipeIcon } from '@heimstaden/icons-library/img/streamline-regular/nature-farming/gardening/gardening-watering-pipe.svg';
import { ReactComponent as IceRoadIcon } from '@heimstaden/icons-library/img/streamline-regular/weather/ice-snow/ice-road.svg';
import { ReactComponent as RenewableEnergyWindTurbine } from '@heimstaden/icons-library/img/streamline-regular/ecology/renewable-energy/renewable-energy-wind-turbine.svg';
import { ReactComponent as LaundryMachine1Icon } from '@heimstaden/icons-library/img/streamline-regular/wayfinding/laundry/laundry-machine-1.svg';
import { ReactComponent as LaundryMachineIcon } from '@heimstaden/icons-library/img/streamline-regular/wayfinding/laundry/laundry-machine.svg';
import { ReactComponent as LiftIcon } from '@heimstaden/icons-library/img/streamline-regular/wayfinding/lifts/lift.svg';
import { ReactComponent as LightBulbIcon } from '@heimstaden/icons-library/img/streamline-regular/lamps-lights-fire/light-bubbles/light-bulb.svg';
import { ReactComponent as LockerRoomWashHands1Icon } from '@heimstaden/icons-library/img/streamline-regular/wayfinding/cloak-lockers-room/locker-room-wash-hands-1.svg';
import { ReactComponent as MailboxInIcon } from '@heimstaden/icons-library/img/streamline-regular/emails/mailbox/mailbox-in.svg';
import { ReactComponent as MatchesFireIcon } from '@heimstaden/icons-library/img/streamline-regular/lamps-lights-fire/lighter-matches-candles/match-fire.svg';
import { ReactComponent as MaterialStoneIcon } from '@heimstaden/icons-library/img/streamline-regular/tools-constructions/materials/material-stone.svg';
import { ReactComponent as ModernTvRemoteIcon } from '@heimstaden/icons-library/img/streamline-regular/video-movies-tv/modern-tv/modern-tv-remote.svg';
import { ReactComponent as NuclearEnergyMessageIcon } from '@heimstaden/icons-library/img/streamline-regular/ecology/nuclear-fossil-energy/nuclear-energy-message.svg';
import { ReactComponent as ParkingP1Icon } from '@heimstaden/icons-library/img/streamline-regular/transportation/parking/parking-p-1.svg';
import { ReactComponent as RealEstateActionHouseKeyIcon } from '@heimstaden/icons-library/img/streamline-regular/real-estate/actions-houses/real-estate-action-house-key.svg';
import { ReactComponent as SofaDoubleIcon } from '@heimstaden/icons-library/img/streamline-regular/furnitures-decoration/sofas-benches/sofa-double.svg';
import { ReactComponent as StairsAscendIcon } from '@heimstaden/icons-library/img/streamline-regular/wayfinding/stairs/stairs-ascend.svg';
import { ReactComponent as ToiletSeatIcon } from '@heimstaden/icons-library/img/streamline-regular/wayfinding/toilets/toilet-seat.svg';
import { ReactComponent as VegetablesPlateIcon } from '@heimstaden/icons-library/img/streamline-regular/food-drinks/vegetables/vegetables-plate.svg';
import { ReactComponent as WarehouseStorageIcon } from '@heimstaden/icons-library/img/streamline-regular/shipping-delivery/warehouse-fullfiment/warehouse-storage.svg';
import { ReactComponent as WaterFountainJetIcon } from '@heimstaden/icons-library/img/streamline-regular/wayfinding/water-fountain/water-fountain-jet.svg';
import { ReactComponent as WaterFountainSinkIcon } from '@heimstaden/icons-library/img/streamline-regular/wayfinding/water-fountain/water-fountain-sink.svg';
import { ReactComponent as ConstructionPaperHome } from '@heimstaden/icons-library/img/streamline-regular/building-construction/construction/construction-paper-home.svg';
import { ReactComponent as ConversationChat1 } from '@heimstaden/icons-library/img/streamline-regular/messages-chat-smileys/conversation/conversation-chat-1.svg';
import { ReactComponent as HeadphonesCustomerSupportQuestion } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/help/headphones-customer-support-question.svg';
import { ReactComponent as AccountingInvoiceMail } from '@heimstaden/icons-library/img/streamline-regular/money-payments-finance/accounting-billing/accounting-invoice-mail.svg';
import { ReactComponent as SafetyFireAlarm } from '@heimstaden/icons-library/img/streamline-regular/wayfinding/safety/safety-fire-alarm.svg';
import { ReactComponent as BathroomMirrorCabinet } from '@heimstaden/icons-library/img/streamline-regular/hotels-spas/bathroom/bathroom-mirror-cabinet.svg';
import { ReactComponent as HumidityHigh } from '@heimstaden/icons-library/img/streamline-regular/weather/humidity/humidity-high.svg';
import { ReactComponent as WaterProtectionFaucet } from '@heimstaden/icons-library/img/streamline-regular/ecology/water-protection/water-protection-faucet.svg';
import { ReactComponent as HardwareNutsRound } from '@heimstaden/icons-library/img/streamline-regular/tools-constructions/hardware/hardware-nuts-round.svg';
import { ReactComponent as AppliancesSlowCooker } from '@heimstaden/icons-library/img/streamline-regular/food-drinks/appliances/appliances-slow-cooker.svg';
import { ReactComponent as StoveInductionPot } from '@heimstaden/icons-library/img/streamline-regular/food-drinks/stove/stove-induction-pot.svg';
import { ReactComponent as TemperatureSnowflake } from '@heimstaden/icons-library/img/streamline-regular/food-drinks/freezer-cooking-temperatures/temperature-snowflake.svg';
import { ReactComponent as LoginKeys } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/login-logout/login-keys.svg';
import { ReactComponent as AppliancesOven } from '@heimstaden/icons-library/img/streamline-regular/food-drinks/appliances/appliances-oven.svg';
import { ReactComponent as RealEstateDimensionsPlan1 } from '@heimstaden/icons-library/img/streamline-regular/real-estate/maps-dimensions/real-estate-dimensions-plan-1.svg';
import { ReactComponent as StairsPersonDescend } from '@heimstaden/icons-library/img/streamline-regular/wayfinding/stairs/stairs-person-descend.svg';
import { ReactComponent as WarehouseStorage2 } from '@heimstaden/icons-library/img/streamline-regular/shipping-delivery/warehouse-fullfiment/warehouse-storage-2.svg';
import { ReactComponent as GarbageThrow } from '@heimstaden/icons-library/img/streamline-regular/wayfinding/garbages/garbage-throw.svg';
import { ReactComponent as ArchitectureDoor } from '@heimstaden/icons-library/img/streamline-regular/building-construction/architectural-features/architecture-door.svg';
import { ReactComponent as PestBusters } from '@heimstaden/icons-library/img/streamline-regular/building-construction/renovation-improvements/pest-busters.svg';
import { ReactComponent as LockIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/lock-unlock/lock-1.svg';

import { FC, SVGProps } from 'react';
import { COUNTRY_TICKETING_MODEL } from '../../ticketing.const';

import { ReactComponent as SprinklerSystem } from '../../../../assets/icons/building-construction/utilities/sprinkler-system.svg';
import { ReactComponent as OutdoorsOdor } from '../../../../assets/icons/nature-farming/outdoors/outdoors-odor.svg';
import { ReactComponent as EquipmentRenovationBin } from '../../../../assets/icons/tools-construction/equipment/equipment-renovation-bin.svg';
import { ReactComponent as DryerMachine1 } from '../../../../assets/icons/wayfinding/laundry/dryer-machine-1.svg';

// DK
const dkRequestTypes = COUNTRY_TICKETING_MODEL.dk.requestType;
const dkJiraItems = COUNTRY_TICKETING_MODEL.dk.jiraItems;

// NO
const noRequestTypes = COUNTRY_TICKETING_MODEL.no.requestType;
const noJiraItems = COUNTRY_TICKETING_MODEL.no.jiraItems;

// IS
const isRequestTypes = COUNTRY_TICKETING_MODEL.is.requestType;
const isJiraItems = COUNTRY_TICKETING_MODEL.is.jiraItems;

export const icons: {
  [key in GenericTypes.Country]: Record<number, FC<SVGProps<SVGSVGElement>>>;
} = {
  dk: {
    // ------------------------------
    // REQUEST TYPES
    // ------------------------------
    // Customer account types
    [dkRequestTypes.TERMINATE_CONTRACT]: ConstructionPaperHome,
    [dkRequestTypes.INVOICE_CUSTOMER_ACCOUNT]: AccountingInvoiceMail,
    // Customer service types
    [dkRequestTypes.CUSTOMER_SERVICE]: HeadphonesCustomerSupportQuestion,
    [dkRequestTypes.ISSUE_COMPLAINT]: ConversationChat1,
    // ------------------------------
    // JIRA ITEMS
    // ------------------------------
    [dkJiraItems.DRYER_UNIT]: DryerMachine1,
    [dkJiraItems.ELECTRICITY_AND_LIGHTING_UNIT]: LightBulbIcon,
    [dkJiraItems.SMOKE_ALARM_UNIT]: SafetyFireAlarm,
    [dkJiraItems.VENTILATION_AND_HEATING_UNIT]: ACHeatIcon,
    [dkJiraItems.INVENTORY_UNIT]: BathroomMirrorCabinet,
    [dkJiraItems.HUMIDITY_AND_INDOOR_CLIMATE_UNIT]: HumidityHigh,
    [dkJiraItems.SHOWER_BATH_UNIT]: BathroomShowerIcon,
    [dkJiraItems.SURFACES_UNIT]: ArchitectureDoor,
    [dkJiraItems.SINK_UNIT]: WaterFountainSinkIcon,
    [dkJiraItems.WASHING_MACHINE_UNIT]: LaundryMachine1Icon,
    [dkJiraItems.DRAIN_WATER_PIPE_AND_WATER_TAP_UNIT]: WaterProtectionFaucet,
    [dkJiraItems.TOILET_UNIT]: ToiletSeatIcon,
    [dkJiraItems.DISHWASHER_UNIT]: HardwareNutsRound,
    [dkJiraItems.COOKER_HOOD_UNIT]: AppliancesSlowCooker,
    [dkJiraItems.WASH_UNIT]: WaterFountainSinkIcon,
    [dkJiraItems.OVEN_HOB_UNIT]: StoveInductionPot,
    [dkJiraItems.REFRIGERATOR_FREEZER_UNIT]: TemperatureSnowflake,
    [dkJiraItems.KEYS_LOCKS_AND_DOOR_PHONE_UNIT]: LoginKeys,
    [dkJiraItems.PEST_CONTROL_UNIT]: PestBusters,
    // Common areas
    [dkJiraItems.BICYCLE_CELLAR_COMMON]: BikingPersonIcon,
    [dkJiraItems.SHARED_KITCHEN_COMMON]: AppliancesOven,
    [dkJiraItems.COMMON_ROOMS_COMMON]: RealEstateDimensionsPlan1,
    [dkJiraItems.ELEVATOR_COMMON]: LiftIcon,
    [dkJiraItems.SMOKE_ALARM_COMMON]: SafetyFireAlarm,
    [dkJiraItems.PARKING_COMMON]: ParkingP1Icon,
    [dkJiraItems.WASTE_SHAFT_COMMON]: GarbageThrow,
    [dkJiraItems.KEYS_LOCKS_AND_DOOR_PHONE_COMMON]: LoginKeys,
    [dkJiraItems.STAIRS_COMMON]: StairsPersonDescend,
    [dkJiraItems.LAUNDRY_COMMON]: LaundryMachine1Icon,
    [dkJiraItems.MAILBOX_COMMON]: MailboxInIcon,
    [dkJiraItems.ODOR_NUISANCE_COMMON]: OutdoorsOdor,
    [dkJiraItems.SPRINKLER_SYSTEM_COMMON]: SprinklerSystem,
    [dkJiraItems.DEPOTRUM_COMMON]: WarehouseStorage2,
    [dkJiraItems.TRASH_COMMON]: GarbageBinIcon,
    [dkJiraItems.SURFACES_COMMON]: ArchitectureDoor,
    [dkJiraItems.DRAIN_WATER_PIPE_AND_WATER_TAP_COMMON]: WaterProtectionFaucet,
    [dkJiraItems.PEST_CONTROL_COMMON]: PestBusters,
    [dkJiraItems.DOOR]: ArchitectureDoor,
    [dkJiraItems.LOCK]: LockIcon,
    // Outside
    [dkJiraItems.RENOVATION_OUTSIDE]: EquipmentRenovationBin,
    [dkJiraItems.FACADES_OUTSIDE]: BuildingIcon,
    [dkJiraItems.GREEN_AREAS_OUTSIDE]: FamilyOutdoorsPlayhouseIcon,
    [dkJiraItems.PLAYGROUND_OUTSIDE]: FamilyOutdoorsTeeterIcon,
    [dkJiraItems.SNOW_REMOVAL_OUTSIDE]: IceRoadIcon,
    [dkJiraItems.PEST_CONTROL_OUTSIDE]: PestBusters,
  },
  is: {
    // ------------------------------
    // REQUEST TYPES
    // ------------------------------
    // Customer account types
    [isRequestTypes.TERMINATE_CONTRACT]: ConstructionPaperHome,
    [isRequestTypes.INVOICE_CUSTOMER_ACCOUNT]: AccountingInvoiceMail,
    // Customer service types
    [isRequestTypes.CUSTOMER_SERVICE]: HeadphonesCustomerSupportQuestion,
    [isRequestTypes.ISSUE_COMPLAINT]: ConversationChat1,
    // ------------------------------
    // JIRA ITEMS
    // ------------------------------
    // Common
    [isJiraItems.BICYCLE_SHED]: BikingPersonIcon,
    [isJiraItems.COMMON_CORRIDOR_STAIRCASE]: StairsAscendIcon,
    [isJiraItems.COMMUNAL_LAUNDRY]: LaundryMachineIcon,
    [isJiraItems.DRYER]: DryerMachine1,
    [isJiraItems.ELEVATOR]: LiftIcon,
    [isJiraItems.FIRE_ALARM]: MatchesFireIcon,
    [isJiraItems.GARAGE_PARKING]: ParkingP1Icon,
    [isJiraItems.GARBAGE_ROOM]: GarbageBinIcon,
    [isJiraItems.KEYS_LOCKS_INTERCOM]: RealEstateActionHouseKeyIcon,
    [isJiraItems.MAILBOX_NAMEPLATE]: MailboxInIcon,
    [isJiraItems.SMELL]: OutdoorsOdor,
    [isJiraItems.SPRINKLER_SYSTEM]: GardeningWateringPipeIcon,
    [isJiraItems.STORAGE_ROOM]: WarehouseStorageIcon,
    [isJiraItems.SURFACES]: MaterialStoneIcon,
    [isJiraItems.WASTE]: GarbageBinIcon,
    [isJiraItems.WATER_DRAINS_PIPES]: WaterFountainJetIcon,
    // Outdoor
    [isJiraItems.FACADE]: BuildingIcon,
    [isJiraItems.GREEN_AREA]: FamilyOutdoorsPlayhouseIcon,
    [isJiraItems.PLAYGROUND]: FamilyOutdoorsTeeterIcon,
    [isJiraItems.SNOWMAKING]: IceRoadIcon,
    [isJiraItems.CONTAINER_RUBBISH]: GarbageBinIcon,
    // Unit
    [isJiraItems.FIRE_ALARM_UNIT]: MatchesFireIcon,
    [isJiraItems.SHOWER_BATH]: BathroomShowerIcon,
    [isJiraItems.TOILET]: ToiletSeatIcon,
    [isJiraItems.WASHBASIN]: LockerRoomWashHands1Icon,
    [isJiraItems.FRIDGE_FREEZER]: AppliancesFridgeIcon,
    [isJiraItems.DISHWASHER]: VegetablesPlateIcon,
    [isJiraItems.WASHING_MACHINE]: LaundryMachine1Icon,
    [isJiraItems.OVEN_COOKTOP]: AppliancesStove,
    [isJiraItems.KEYS_LOCKS_INTERCOM_UNIT]: RealEstateActionHouseKeyIcon,
    [isJiraItems.ELECTRICITY_LIGHT]: LightBulbIcon,
    [isJiraItems.HEATING_VENTILATION]: ACHeatIcon,
    [isJiraItems.WATER_DRAINS_PIPES_UNIT]: WaterFountainJetIcon,
    [isJiraItems.KITCHEN_FAN]: RenewableEnergyWindTurbine,
    [isJiraItems.SURFACES_UNIT]: MaterialStoneIcon,
    [isJiraItems.TV_INTERNET]: ModernTvRemoteIcon,
    [isJiraItems.MOISTURE_MOLD]: NuclearEnergyMessageIcon,
    [isJiraItems.FIXTURES_FURNITURE]: SofaDoubleIcon,
    [isJiraItems.KITCHEN_SINK]: WaterFountainSinkIcon,
  },
  nl: {},
  no: {
    // ------------------------------
    // REQUEST TYPES
    // ------------------------------
    // Customer account types
    [noRequestTypes.TERMINATE_CONTRACT]: ConstructionPaperHome,
    [noRequestTypes.INVOICE_CUSTOMER_ACCOUNT]: AccountingInvoiceMail,
    // Customer service types
    [noRequestTypes.CUSTOMER_SERVICE]: HeadphonesCustomerSupportQuestion,
    [noRequestTypes.ISSUE_COMPLAINT]: ConversationChat1,
    // ------------------------------
    // JIRA ITEMS
    // ------------------------------
    // Common
    [noJiraItems.FIRE_ALARM]: MatchesFireIcon,
    [noJiraItems.WATER_DRAINS_PIPES]: WaterFountainJetIcon,
    [noJiraItems.SPRINKLER_SYSTEM]: GardeningWateringPipeIcon,
    [noJiraItems.GARAGE_PARKING]: ParkingP1Icon,
    [noJiraItems.ELEVATOR]: LiftIcon,
    [noJiraItems.MAILBOX_NAMEPLATE]: MailboxInIcon,
    [noJiraItems.KEYS_LOCKS_INTERCOM]: RealEstateActionHouseKeyIcon,
    [noJiraItems.SURFACES]: MaterialStoneIcon,
    [noJiraItems.COMMON_CORRIDOR_STAIRCASE]: StairsAscendIcon,
    [noJiraItems.COMMUNAL_LAUNDRY]: LaundryMachineIcon,
    [noJiraItems.BOD]: WarehouseStorageIcon,
    [noJiraItems.GARBAGE_ROOM]: GarbageBinIcon,
    [noJiraItems.BICYCLE_SHED]: BikingPersonIcon,
    [noJiraItems.SMELL]: OutdoorsOdor,
    [noJiraItems.GARBAGE]: GarbageBinIcon,
    // Outdoor
    [noJiraItems.FACADE]: BuildingIcon,
    [noJiraItems.GREEN_AREA]: FamilyOutdoorsPlayhouseIcon,
    [noJiraItems.PLAYGROUND]: FamilyOutdoorsTeeterIcon,
    [noJiraItems.SNOWMAKING]: IceRoadIcon,
    [noJiraItems.CONTAINER_RUBBISH]: GarbageBinIcon,
    // Unit
    [noJiraItems.FIRE_ALARM_UNIT]: MatchesFireIcon,
    [noJiraItems.SHOWER_BATH]: BathroomShowerIcon,
    [noJiraItems.TOILET]: ToiletSeatIcon,
    [noJiraItems.WASHBASIN]: LockerRoomWashHands1Icon,
    [noJiraItems.FRIDGE_FREEZER]: AppliancesFridgeIcon,
    [noJiraItems.DISHWASHER]: VegetablesPlateIcon,
    [noJiraItems.WASHING_MACHINE]: LaundryMachine1Icon,
    [noJiraItems.OVEN_COOKTOP]: AppliancesStove,
    [noJiraItems.KEYS_LOCKS_INTERCOM_UNIT]: RealEstateActionHouseKeyIcon,
    [noJiraItems.ELECTRICITY_LIGHT]: LightBulbIcon,
    [noJiraItems.HEATING_VENTILATION]: ACHeatIcon,
    [noJiraItems.WATER_DRAINS_PIPES_UNIT]: WaterFountainJetIcon,
    [noJiraItems.KITCHEN_FAN]: RenewableEnergyWindTurbine,
    [noJiraItems.SURFACES_UNIT]: MaterialStoneIcon,
    [noJiraItems.TV_INTERNET]: ModernTvRemoteIcon,
    [noJiraItems.MOISTURE_MOLD]: NuclearEnergyMessageIcon,
    [noJiraItems.FIXTURES_FURNITURE]: SofaDoubleIcon,
    [noJiraItems.KITCHEN_SINK]: WaterFountainSinkIcon,
  },
  pl: {},
  de: {},
};
