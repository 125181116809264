import { TranslationLabels } from '@generated/translation-labels';
import { useApi, useCountry } from '@shared/hooks';
import React, { FC, useCallback } from 'react';
import { Content, Elements } from '../../../shared';
import { api } from './items.repository';
import { ReactComponent as InvoicesImage } from '../../../assets/invoices.svg';
import { stepper } from '../../stepper.config';

export const Items: FC = () => {
  const country = useCountry();
  const getItems$ = useCallback(() => api.getItems$(country), [country]);
  const [items, isFetching] = useApi([], getItems$);

  return (
    <Content
      introProps={{
        descriptionKey:
          TranslationLabels.ticketingCustomerAccountRequestItemsText,
        titleKey: TranslationLabels.ticketingCustomerAccountRequestItemsTitle,
        childrenRightColumn: <InvoicesImage />,
      }}
      isFetching={isFetching}
      type="columns"
      stepperConfig={stepper}
    >
      <Elements data={items} />
    </Content>
  );
};
