import { TranslationLabels } from '@generated/translation-labels';
import Button from '@material-ui/core/Button';
import { useAnimatedRoutes } from '@shared/animated-routes';
import { useTranslation } from '@shared/translations';
import React, { FC, useMemo } from 'react';
import { useLocation } from 'react-router';
import { TICKETING_PATHS } from '../../routing';
import { useStyles } from './back-button.styles';

export const BackButton: FC = () => {
  const classes = useStyles();
  const location = useLocation<{ transitionHistory: string[] }>();
  const { state } = location;
  const { t } = useTranslation();
  const { goBack } = useAnimatedRoutes();
  const isTransitionHistoryEmpty = useMemo(
    () => !state?.transitionHistory || state.transitionHistory.length === 0,
    [state?.transitionHistory],
  );
  const isRootIndex = useMemo(
    () => location.pathname === TICKETING_PATHS.INDEX,
    [location.pathname],
  );

  if (isRootIndex || isTransitionHistoryEmpty) {
    return null;
  }

  return (
    <Button
      onClick={() => goBack()}
      variant="outlined"
      color="primary"
      size="large"
      className={classes.button}
    >
      {t(TranslationLabels.ticketingRequestBackButtonLabel)}
    </Button>
  );
};
