import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '../../consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    searchBox: {
      display: 'flex',
      position: 'relative',
      flexGrow: 1,
      maxWidth: '500px',
    },
    inputWrapper: {
      width: '100%',
    },
    input: {
      border: `1px solid ${palette.iron}`,
      borderRadius: theme.spacing(4),
      boxShadow: '0px 4px 24px rgba(245, 245, 247, 1)',
      boxSizing: 'border-box',
      height: 56,
      padding: theme.spacing(1.5, 8, 1.5, 3),
      width: '100%',
      position: 'relative',
      zIndex: 4,
      fontSize: theme.typography.pxToRem(20),
      lineHeight: theme.typography.pxToRem(24),
      [theme.breakpoints.down('sm')]: {
        height: 48,
        fontSize: theme.typography.pxToRem(16),
        lineHeight: theme.typography.pxToRem(20),
      },
    },
    loadingContainer: {
      position: 'absolute',
      right: theme.spacing(2),
      zIndex: 5,
      top: '50%',
      marginTop: -12,
    },
    button: {
      position: 'absolute',
      right: 0,
      zIndex: 5,
      top: '50%',
      marginTop: -28,
    },
    resultsContainer: {
      backgroundColor: palette.white,
      borderRadius: theme.spacing(4),
      boxShadow: '0px 4px 16px rgba(152, 152, 152, 0.5)',
      left: 0,
      position: 'absolute',
      top: 0,
      width: '100%',
      zIndex: 3,
      paddingTop: theme.spacing(8),
    },
    results: {
      maxHeight: 300,
      overflowX: 'auto',
      paddingTop: theme.spacing(1),
    },
    element: {
      borderRadius: 'unset',
      display: 'flex',
      fontWeight: theme.typography.fontWeightRegular,
      justifyContent: 'flex-start',
      padding: theme.spacing(1.5, 3),
      textAlign: 'left',
      width: '100%',

      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
    notFound: {
      '&:hover': {
        backgroundColor: 'unset',
      },
    },
    icon: {
      '& path': {
        stroke: palette.internationalOrange,
      },
    },

    [theme.breakpoints.down('xs')]: {
      searchBox: {
        marginLeft: 'unset',
      },
      input: {
        width: 'auto',
      },
    },
  }),
);
