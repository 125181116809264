import React, { FC } from 'react';
import { ReactComponent as TextBold } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/text-formating/text-bold.svg';
import { ReactComponent as TextItalic } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/text-formating/text-italic.svg';
import { ReactComponent as TextUnderline } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/text-formating/text-underline.svg';
import { ReactComponent as OpenQuote } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/text-formating/open-quote.svg';
import { ReactComponent as AngleBrackets } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/text-formating/angle-brackets.svg';
import { ReactComponent as ListBullets } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/lists/list-bullets.svg';
import { MarkButton } from './components/mark-button.component';
import { BlockButton } from './components/block-button.component';
import { useStyles } from './rich-text-toolbar.styles';

export const RichTextToolbar: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.toolbar}>
      <MarkButton format="bold" icon={TextBold} className={classes.button} />
      <MarkButton
        format="italic"
        icon={TextItalic}
        className={classes.button}
      />
      <MarkButton
        format="underline"
        icon={TextUnderline}
        className={classes.button}
      />
      <MarkButton
        format="code"
        icon={AngleBrackets}
        className={classes.button}
      />
      <BlockButton
        format="bulleted-list"
        icon={ListBullets}
        className={classes.button}
      />
      <BlockButton
        format="block-quote"
        icon={OpenQuote}
        className={classes.button}
      />
    </div>
  );
};
