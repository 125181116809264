import { TFunction } from 'i18next';
import { FormikErrorValues } from '@shared/components/Fields/type';

export const getFirstError = (
  t: TFunction,
  errors?: FormikErrorValues,
): string => {
  if (!errors) {
    return '';
  }

  return t(
    Array.isArray(errors)
      ? (errors[0] as GenericTypes.TranslationLabel)
      : (errors as GenericTypes.TranslationLabel),
  );
};
