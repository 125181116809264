import { FC, ReactElement } from 'react';

type Props = {
  showWrapper: boolean;
  children: ReactElement;
  wrapper: (children: ReactElement) => JSX.Element;
};

export const ConditionalWrapper: FC<Props> = ({
  showWrapper,
  children,
  wrapper,
}) => {
  return showWrapper ? wrapper(children) : children;
};
