import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import React, { FC, useCallback } from 'react';
import { sanityImageBuilder } from '@shared/sanity';
import { AppLogo } from '@shared/components';
import { useApi } from '@shared/hooks';
import { routes } from '../../shared';
import { api } from './public-view.repository';
import { useStyles } from './public-view.styles';
import { ReactComponent as CompanyLogo } from '../../../../assets/logo.svg';

export const PublicView: FC = ({ children }) => {
  const classes = useStyles();

  const callback = useCallback(
    () =>
      api
        .getImage(routes.LOGIN)
        .then((image) => sanityImageBuilder.image(image).width(2000).url()),
    [],
  );
  const [imageUrl] = useApi<string | null>(null, callback);

  return (
    <Grid container className={classes.wrapper}>
      <Hidden only="sm">
        <CompanyLogo className={classes.companyLogo} />
        <Grid
          className={classes.image}
          item
          sm={6}
          {...(imageUrl && { style: { backgroundImage: `url(${imageUrl})` } })}
        />
      </Hidden>
      <Grid item xs={12} sm={12} md={6}>
        <Grid alignItems="center" className={classes.container} container>
          <div>
            <AppLogo />
            {children}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};
