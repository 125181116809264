import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from '@shared/translations';
import { TerminationFlowStep } from '../../../types';
import { getPendingReasonTranslationLabelKey } from '../../helpers';
import { useStyles } from './pending-reasons.styles';

type Props = {
  eventType: TerminationFlowStep['eventType'];
  pendingReasons: TerminationFlowStep['pendingReasons'];
};

export const PendingReasons: FC<Props> = (props) => {
  const { eventType, pendingReasons } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      {pendingReasons.length > 0 && (
        <Grid item xs={12}>
          <List disablePadding>
            {pendingReasons.map((pendingReason) => (
              <ListItem className={classes.listItem} key={pendingReason}>
                <Typography>
                  {t(
                    getPendingReasonTranslationLabelKey(
                      eventType,
                      pendingReason,
                    ),
                  )}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Grid>
      )}
    </>
  );
};

PendingReasons.displayName = 'PendingReasons';
