import { TranslationLabels } from '@generated/translation-labels';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { FC } from 'react';
import {
  formatStandardDate,
  generateUniqId,
  InfoBox,
  useCountry,
  useTranslation,
} from '../../../../shared';
import { Invoice, InvoiceStatus } from '../../../invoice.type';
import { Payment } from '../Payment/payment.component';
import { Status } from '../Status/status.component';
import { Summary } from '../Summary/summary.component';
import { useStyles } from './data.styles';

type Props = {
  invoice: Invoice;
};

export const Data: FC<Props> = ({ invoice }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const country = useCountry();
  const {
    amenities,
    currency,
    bankAccountNumber,
    kidNumber,
    due,
    description,
    invoiceNumber,
    invoiceStatus,
    to,
    amount,
  } = invoice;

  const invoiceNumberInfo = ` #${invoiceNumber}`;

  return (
    <>
      <div className={classes.header}>
        <div className={classes.headerContent}>
          <Status status={invoiceStatus} />
          <Typography className={classes.title} variant="h2">
            {t(TranslationLabels.invoiceDetailsDataTitle)}
            {country !== 'dk' && invoiceNumberInfo}
          </Typography>
        </div>
        {country === 'dk' && (
          <InfoBox
            messageKey={TranslationLabels.invoiceDEComment}
            type="info"
          />
        )}
      </div>
      <Grid
        container
        justify="space-between"
        item
        xs={11}
        className={classes.details}
      >
        <div>
          <Typography className={classes.detailsCaption}>
            {t(TranslationLabels.invoiceDetailsInformationDue)}
          </Typography>
          <Typography className={classes.detailsData}>
            {due && formatStandardDate(due)}
          </Typography>
        </div>
        <div>
          <Typography className={classes.detailsCaption}>
            {t(TranslationLabels.invoiceDetailsInformationDescription)}
          </Typography>
          <Typography className={classes.detailsData}>{description}</Typography>
        </div>
        <div>
          <div>
            <Typography className={classes.detailsCaption}>
              {t(TranslationLabels.invoiceDetailsToLabel)}
            </Typography>
          </div>
          <div>
            {to.split('\n').map((text) => (
              <Typography
                key={generateUniqId()}
                className={classes.detailsData}
              >
                {text}
              </Typography>
            ))}
          </div>
        </div>
      </Grid>
      <Summary amenities={amenities} currency={currency} total={amount} />
      <Payment
        bankAccountNumber={bankAccountNumber}
        isUnpaid={invoiceStatus === InvoiceStatus.Unpaid}
        kidNumber={kidNumber}
      />
    </>
  );
};
