import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import React, { FC, ReactNode } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { getEnvValue } from '@shared/helpers';
import qs from 'query-string';
import { routes, useLogout, adaptUserData } from '../shared';
import { ErrorType } from '../components/Error/types';

type Props = {
  children?: ReactNode;
};

const AuthGuard: FC<Props> = ({ children }) => {
  const { isAuthenticated, isLoading, user: auth0User } = useAuth0();
  const { pathname, search } = useLocation();
  const logout = useLogout();

  if (!isLoading && !isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: routes.LOGIN,
          state: { from: pathname + search },
        }}
      />
    );
  }

  if (
    !isLoading &&
    isAuthenticated &&
    adaptUserData(auth0User)?.isUserInvalid
  ) {
    logout(
      `${getEnvValue('ORIGIN')}${routes.ERROR}?${qs.stringify({
        type: ErrorType.invalidCustomer,
      })}`,
    );
  }

  return <>{children}</>;
};
export default AuthGuard;

AuthGuard.propTypes = {
  children: PropTypes.node,
};
AuthGuard.defaultProps = {
  children: undefined,
};
