import { TranslationLabels } from '@generated/translation-labels';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useCountry } from '@shared/hooks';
import React, { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Box, sanityImageBuilder, useApi, useTranslation } from '../../shared';
import { routes } from '../shared';
import { api } from './widget.repository';
import { useStyles } from './widget.styles';

export const Widget: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const country = useCountry();
  const callback = useCallback(
    () =>
      api
        .getImage(country)
        .then((image) =>
          sanityImageBuilder.image(image).width(582).height(164).url(),
        ),
    [country],
  );
  const [imageUrl, , isError] = useApi<string | null>(null, callback);

  if (isError) {
    return null;
  }

  return (
    <Box>
      <Typography variant="h2">
        {t(TranslationLabels.ticketingWidgetTitle)}
      </Typography>
      <Typography className={classes.text}>
        {t(TranslationLabels.ticketingWidgetText)}
      </Typography>
      {imageUrl && <img src={imageUrl} alt="" className={classes.image} />}

      <Grid
        container
        spacing={2}
        className={classes.buttonsRow}
        justify="flex-end"
      >
        <Grid item xs={12} md={6}>
          <Button
            variant="outlined"
            color="primary"
            component={Link}
            to={routes.LIST}
            className={classes.button}
          >
            {t(TranslationLabels.ticketingSeeMyServiceRequestsLink)}
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={routes.INDEX}
            className={classes.button}
          >
            {t(TranslationLabels.ticketingWidgetLink)}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
