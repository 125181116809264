import React, { FC } from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Container from '@material-ui/core/Container';
import { useTranslation } from '@shared/translations';
import { Typography } from '@material-ui/core';
import { TranslationLabels } from '@generated/translation-labels';
import { Box, Icon } from '@shared/components';
import { ReactComponent as RemoveIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/remove-add/remove.svg';
import { useStyles } from './confirmation-modal.styles';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onProceed: () => void;
};

export const ConfirmationModal: FC<Props> = ({
  isOpen,
  onClose,
  onProceed,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Modal open={isOpen} onClose={onClose} className={classes.modal}>
      <Container maxWidth="md" className={classes.wrapper}>
        <Box className={classes.container}>
          <IconButton className={classes.closeIcon} onClick={onClose}>
            <Icon icon={RemoveIcon} height={16} width={16} />
          </IconButton>
          <Typography variant="h2" className={classes.title}>
            {t(TranslationLabels.terminationFlowStepMoveOutConfirmationTitle)}
          </Typography>
          <Typography>
            {t(TranslationLabels.terminationFlowStepMoveOutConfirmationContent)}
          </Typography>
          <div className={classes.buttonsWrapper}>
            <Button onClick={onClose} variant="outlined">
              {t(
                TranslationLabels.terminationFlowStepMoveOutConfirmationChangeButton,
              )}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.submitButton}
              onClick={onProceed}
            >
              {t(
                TranslationLabels.terminationFlowStepMoveOutConfirmationContinueButton,
              )}
            </Button>
          </div>
        </Box>
      </Container>
    </Modal>
  );
};
