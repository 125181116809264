import { FormLabel } from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from '@shared/translations';
import { useStyles } from './field-label.styles';

type Props = {
  label?: GenericTypes.TranslationLabel;
};

export const FieldLabel: FC<Props> = ({ label }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return label ? (
    <FormLabel className={classes.label} component="legend">
      {t(label)}
    </FormLabel>
  ) : null;
};
