import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    buttonsRow: {
      '& a + a': {
        marginLeft: theme.spacing(3),
      },
    },
  }),
);
