import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles(() =>
  createStyles({
    container: {
      position: 'relative',
    },
    buttonsContainer: {
      height: '100%',
    },
  }),
);
