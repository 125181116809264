import React, { FC, ReactNode } from 'react';
import { PageIntro, PageIntroProps } from '../PageIntro';

type Props = PageIntroProps & {
  children: ReactNode;
};

export const Page: FC<Props> = (props: Props) => {
  const { children, ...pageProps } = props;

  return (
    <>
      <PageIntro {...pageProps} />
      {children}
    </>
  );
};
