import { UserRole } from '@shared/enums';
import { FC, ReactElement, useMemo } from 'react';
// TODO: When the import is shortened it cause the test error.
//  The component should be rewrite according to the comment in the test file
import { useProfile } from '../../../+profile/shared/state';

type Props = {
  children: NonNullable<ReactElement>;
  roles: UserRole[] | undefined | null;
  fallback?: ReactElement;
};

export const AvailableFor: FC<Props> = (props) => {
  const { children, fallback, roles = [] } = props;
  const { isResident, isTenant } = useProfile();
  const isAvailable = useMemo(
    () =>
      !roles ||
      roles.length === 0 ||
      (roles.includes(UserRole.TENANT) && isTenant) ||
      (roles.includes(UserRole.RESIDENT) && isResident),
    [isResident, isTenant, roles],
  );

  return isAvailable ? children : fallback || null;
};
AvailableFor.defaultProps = {
  roles: [],
};
