import { TranslationLabels } from '@generated/translation-labels';
import { ICustomerMidleLayerApi2, ICustomerRentalObject } from '@shared/models';
import { FieldConfig, FormProps } from '@shared/FormV2/types';
import * as Yup from 'yup';
import { ServiceRequestVisibilityEnum } from '@shared/enums';
import { COUNTRY_TICKETING_MODEL } from '../../../+ticketing/ticketing.const';
import { ticketingClient } from '../../ticketing.client';
import { prepareDescription } from './form.helper';
import { AdditionalField, Values } from './form.type';

const fieldsDefinition: Record<AdditionalField, FieldConfig<Values>> = {
  serialNumber: {
    componentType: 'input',
    field: {
      name: 'serialNumber',
      initialValue: '',
      validationSchema: Yup.string().when('typeOfToilet', {
        is: (value: number | null) => value,
        then: Yup.string().required(TranslationLabels.formErrorsRequired),
        otherwise: Yup.string().nullable(),
      }),
    },
    props: {
      type: 'text',
      label: TranslationLabels.ticketingServiceRequestSerialNumberLabel,
    },
  },
  typeOfHeating: {
    componentType: 'input',
    field: {
      name: 'typeOfHeating',
      initialValue: '',
    },
    props: {
      type: 'text',
      label: TranslationLabels.ticketingServiceRequestTypeOfHeatingLabel,
    },
  },
  typeOfKitchen: {
    componentType: 'input',
    field: {
      name: 'typeOfKitchen',
      initialValue: '',
    },
    props: {
      type: 'text',
      label: TranslationLabels.ticketingServiceRequestTypeOfKitchenLabel,
    },
  },
  typeOfLockSystem: {
    componentType: 'input',
    field: {
      name: 'typeOfLockSystem',
      initialValue: '',
    },
    props: {
      type: 'text',
      label: TranslationLabels.ticketingServiceRequestTypeOfLockSystemLabel,
    },
  },
  typeOfOven: {
    componentType: 'input',
    field: {
      name: 'typeOfOven',
      initialValue: '',
    },
    props: {
      type: 'text',
      label: TranslationLabels.ticketingServiceRequestTypeOfOvenLabel,
    },
  },
  typeOfToilet: {
    componentType: 'select',
    field: {
      name: 'typeOfToilet',
      initialValue: '',
    },
    props: {
      required: true,
      options: [
        {
          label: TranslationLabels.ticketingServiceRequestTypeOfToiletWall,
          value: 'wall',
        },
        {
          label: TranslationLabels.ticketingServiceRequestTypeOfToiletFloor,
          value: 'floor',
        },
      ],
      label: TranslationLabels.ticketingServiceRequestTypeOfToiletLabel,
    },
  },
};

const getAdditionalFields = (
  country: GenericTypes.Country,
  itemId: number,
): FieldConfig<Values>[] => {
  const { jiraItems } = COUNTRY_TICKETING_MODEL[country];
  const {
    serialNumber,
    typeOfHeating,
    typeOfKitchen,
    typeOfLockSystem,
    typeOfOven,
    typeOfToilet,
  } = fieldsDefinition;

  switch (itemId) {
    case jiraItems.FRIDGE_FREEZER:
    case jiraItems.DISHWASHER:
    case jiraItems.WASHING_MACHINE: {
      return [serialNumber];
    }
    case jiraItems.OVEN_COOKTOP: {
      return [serialNumber, typeOfOven];
    }
    case jiraItems.KEYS_LOCKS_INTERCOM:
    case jiraItems.KEYS_LOCKS_INTERCOM_UNIT: {
      return [typeOfLockSystem];
    }
    case jiraItems.HEATING_VENTILATION: {
      return [typeOfHeating];
    }
    case jiraItems.KITCHEN_FAN: {
      return [serialNumber, typeOfKitchen];
    }
    case jiraItems.TOILET_TYPE: {
      return [serialNumber, typeOfToilet];
    }
    default: {
      return [];
    }
  }
};

export const getFields = (
  country: GenericTypes.Country,
  itemId: number,
): FieldConfig<Values>[] => {
  const { jiraFields } = COUNTRY_TICKETING_MODEL[country];

  return [
    {
      componentType: 'input',
      field: {
        name: jiraFields.SUMMARY,
        initialValue: '',
      },
      props: {
        type: 'text',
        label: TranslationLabels.ticketingServiceRequestSummaryLabel,
        required: true,
      },
    },
    {
      componentType: 'input',
      field: {
        name: jiraFields.DESCRIPTION,
        initialValue: '',
      },
      props: {
        type: 'textarea',
        label: TranslationLabels.ticketingServiceRequestDescriptionLabel,
        required: true,
      },
    },
    {
      componentType: 'attachment',
      field: {
        name: jiraFields.ATTACHMENT,
        initialValue: [],
      },
      props: {
        accept: ['.png', '.jpg', '.jpeg', '.pdf'],
        helperTextKey:
          TranslationLabels.ticketingServiceRequestAttachmentFileHelperText,
        helperTextOptions: {
          formats: 'JPG, JPEG, PNG, PDF',
          maxSize: 100,
        },
        isMultiple: true,
        label: TranslationLabels.formFieldsAttachmentFileLabel,
        onPreviewClick$: ticketingClient.getAttachment$,
      },
    },
    ...getAdditionalFields(country, itemId),
    {
      componentType: 'switch',
      field: {
        name: jiraFields.VISIBILITY,
        initialValue: ServiceRequestVisibilityEnum.PUBLIC,
      },
      props: {
        label: TranslationLabels.ticketingServiceRequestVisibilityLabel,
        checkedLabelKey:
          TranslationLabels.ticketingServiceRequestVisibilityTypePrivate,
        checkedValue: ServiceRequestVisibilityEnum.PRIVATE,
        uncheckedValue: ServiceRequestVisibilityEnum.PUBLIC,
        checkedValueDescriptionKey:
          TranslationLabels.ticketingServiceRequestVisibilityPrivateDescriptionText,
        helperTextKey:
          TranslationLabels.ticketingServiceRequestVisibilityHelperText,
      },
    },
  ];
};

export const getConfig = (
  country: GenericTypes.Country,
  apartment: ICustomerRentalObject | null,
  user: ICustomerMidleLayerApi2 | null,
  itemId: number,
  failureId?: number,
  requestTypeId?: number,
  roomId?: number,
): FormProps<Values>['config'] => ({
  onSubmit: (values) => {
    const { jiraFields, requestType } = COUNTRY_TICKETING_MODEL[country];
    const {
      [jiraFields.ATTACHMENT]: attachment,
      [jiraFields.SUMMARY]: summary,
      [jiraFields.VISIBILITY]: visibility,
    } = values;
    const commonValues = {
      [jiraFields.ATTACHMENT]: attachment,
      [jiraFields.DESCRIPTION]: prepareDescription(country, values),
      [jiraFields.SUMMARY]: summary,
      [jiraFields.VISIBILITY]:
        visibility || ServiceRequestVisibilityEnum.PUBLIC,
    };
    let additionalValues = {};

    switch (requestTypeId) {
      case requestType.COMMON: {
        additionalValues = {
          [jiraFields.COMMON_FAILURE]: failureId,
          [jiraFields.COMMON_ITEM]: itemId,
        };

        break;
      }
      case requestType.OUTDOOR: {
        additionalValues = {
          [jiraFields.OUTDOOR_ITEM]: itemId,
        };

        break;
      }
      case requestType.UNIT: {
        additionalValues = {
          [jiraFields.UNIT_FAILURE]: failureId,
          [jiraFields.UNIT_ITEM]: itemId,
          [jiraFields.UNIT_ROOM]: roomId,
        };

        break;
      }

      default: {
        break;
      }
    }

    const finalValues = { ...commonValues, ...additionalValues };

    return ticketingClient.createRequest$(
      country,
      finalValues,
      apartment,
      requestTypeId,
      user,
    );
  },
});
