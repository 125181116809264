import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';
import { darken } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    infoText: {
      whiteSpace: 'pre-line',
      marginTop: theme.spacing(3),
    },
    link: {
      color: palette.internationalOrange,
    },
    shortcutsAndRulesContainer: {
      backgroundColor: palette.athensGray,
      padding: theme.spacing(3),
    },
    shortcutsAndRulesTitle: {
      marginBottom: theme.spacing(2),
    },
    shortcutsTitle: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    facebookButton: {
      background: palette.facebook,
      '&:hover': {
        background: darken(palette.facebook, 0.3),
      },
    },
    facebookIcon: {
      marginRight: theme.spacing(1),
    },
  }),
);
