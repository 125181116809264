import React, { FC, SVGProps } from 'react';
import clsx from 'clsx';
import ButtonBase from '@material-ui/core/ButtonBase';
import { Icon, ConditionalWrapper } from '@shared/components';
import { useStyles } from './social-media-circle.styles';

type Props = {
  icon: FC<SVGProps<SVGSVGElement>>;
  colorHex: string;
  href?: GenericTypes.URL;
  className?: string;
};

export const SocialMediaCircle: FC<Props> = ({
  icon,
  colorHex,
  href,
  className,
}: Props) => {
  const classes = useStyles({ colorHex });

  return (
    <ConditionalWrapper
      showWrapper={Boolean(href)}
      wrapper={(children) => (
        <a href={href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      )}
    >
      <ButtonBase
        className={clsx(classes.logoCircle, className)}
        component="div"
      >
        <Icon icon={icon} className={classes.logo} stroke="none" />
      </ButtonBase>
    </ConditionalWrapper>
  );
};
