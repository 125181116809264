import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      '& path': {
        stroke: palette.white,
        strokeWidth: 2,
      },
    },
    buttonGroup: {
      '&:not(:last-child)': {
        border: 0,
      },
    },
  }),
);
