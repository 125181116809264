import { sanityClient, SanityDocumentType } from '@shared/sanity';

export const api = {
  getExternalWebpageURL$: (
    country: GenericTypes.Country,
  ): Promise<string | undefined> => {
    const query = `
      *[_type == $type && content.country == $country][0]
        .content
        .ticketing
        .customerAccountRequest
        .types
        .terminateContract
        .externalWebpageUrl
    `;

    return sanityClient.fetch<string>(query, {
      country,
      type: SanityDocumentType.SETTINGS,
    });
  },
};
