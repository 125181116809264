import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      border: '1px solid #D6D6D6',
      boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.12)',
      borderRadius: theme.spacing(1),
      padding: theme.spacing(5),
    },
    content: {
      marginTop: theme.spacing(3),
    },
    element: {
      display: 'flex',
      marginBottom: theme.spacing(1),
    },
    elementLabel: {
      textAlign: 'right',
      marginRight: theme.spacing(3),
      marginBottom: theme.spacing(1),

      '& span': {
        color: '#706F6F',
      },
    },
    paymentDebitText: {
      marginTop: theme.spacing(2),
      color: '#706F6F',
    },
    [theme.breakpoints.down('xs')]: {
      container: {
        marginTop: theme.spacing(3),
      },
      element: {
        flexDirection: 'column',
      },
    },
  }),
);
