import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    label: {
      lineHeight: theme.typography.pxToRem(19),
      paddingBottom: theme.spacing(0.5),
    },
    text: {
      fontSize: theme.typography.pxToRem(20),
      lineHeight: theme.typography.pxToRem(24),
    },
  }),
);
