import { TranslationLabels } from '@generated/translation-labels';
import React, { FC, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from '@shared/translations';
import { SANITY_PDF_CATEGORY } from '@shared/sanity';
import { ReactComponent as OfficeStampDocument } from '@heimstaden/icons-library/img/streamline-regular/work-office-companies/office/office-stamp-document.svg';
import { ReactComponent as CalendarCash1 } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/date-calendar/calendar-cash-1.svg';
import { ReactComponent as GarbageBin } from '@heimstaden/icons-library/img/streamline-regular/wayfinding/garbages/garbage-bin.svg';
import { ReactComponent as TemperatureSnowflake } from '@heimstaden/icons-library/img/streamline-regular/food-drinks/freezer-cooking-temperatures/temperature-snowflake.svg';
import { ReactComponent as ConnectDeviceCancel } from '@heimstaden/icons-library/img/streamline-regular/phones-mobile-devices/connect-devices/connect-device-cancel.svg';
import { ReactComponent as Certificate } from '@heimstaden/icons-library/img/streamline-regular/social-medias-rewards-rating/certified/certified-certificate.svg';
import { Tips, Item } from '../Tips';
import { routes } from '../../../../+apartment/shared';
import { useTermination } from '../../state';

export const MoveOutInstruction: FC = () => {
  const { t } = useTranslation();
  const { documents, sanityDocuments } = useTermination();

  const pricelistUrl = useMemo(
    () =>
      documents.find(
        ({ attachmentSubType }) => attachmentSubType === 'MoveOutPriceList',
      )?.url || '',
    [documents],
  );

  const moveOutChecklistUrl = useMemo(
    () =>
      sanityDocuments.find(
        ({ id }) => id === SANITY_PDF_CATEGORY.MOVE_OUT_CHECKLIST,
      )?.url || '',
    [sanityDocuments],
  );

  return (
    <Tips titleKey={TranslationLabels.moveOutTipsTitle}>
      <Grid container spacing={4}>
        <Grid item md={6} xs={12}>
          <Item
            titleKey={TranslationLabels.moveOutTipDocumentsTitle}
            icon={OfficeStampDocument}
          >
            <Typography>
              {t(TranslationLabels.moveOutTipDocumentsText, {
                myApartmentUrl: routes.APARTMENT,
              })}
            </Typography>
          </Item>
          <Item
            titleKey={TranslationLabels.moveOutTipSubscriptionsTitle}
            icon={CalendarCash1}
          >
            <Typography>
              {t(TranslationLabels.moveOutTipSubscriptionsText)}
            </Typography>
          </Item>
          <Item
            titleKey={TranslationLabels.moveOutTipWasteTitle}
            icon={GarbageBin}
          >
            <Typography>{t(TranslationLabels.moveOutTipWasteText)}</Typography>
          </Item>
          <Item
            titleKey={TranslationLabels.moveOutTipCleaningTitle}
            icon={GarbageBin}
          >
            <Typography>
              {t(TranslationLabels.moveOutTipCleaningText, {
                pricelistUrl,
              })}
            </Typography>
          </Item>
        </Grid>
        <Grid item md={6} xs={12}>
          <Item
            titleKey={TranslationLabels.moveOutTipDefrostingTitle}
            icon={TemperatureSnowflake}
          >
            <Typography>
              {t(TranslationLabels.moveOutTipDefrostingText)}
            </Typography>
          </Item>
          <Item
            titleKey={TranslationLabels.moveOutTipCancelationTitle}
            icon={ConnectDeviceCancel}
          >
            <Typography>
              {t(TranslationLabels.moveOutTipCancelationText)}
            </Typography>
          </Item>
          <Item
            titleKey={TranslationLabels.moveOutTipReferenceTitle}
            icon={Certificate}
          >
            <Typography>
              {t(TranslationLabels.moveOutTipReferenceText, {
                moveOutChecklistUrl,
              })}
            </Typography>
          </Item>
        </Grid>
      </Grid>
    </Tips>
  );
};

MoveOutInstruction.displayName = 'MoveOutInstruction';
