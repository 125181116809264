import { TranslationLabels } from '@generated/translation-labels';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useAnimatedRoutes } from '@shared/animated-routes';
import { Icon } from '@shared/components';
import { useApi, useCountry } from '@shared/hooks';
import { useTranslation } from '@shared/translations';
import Grid from '@material-ui/core/Grid';
import { GridSize } from '@material-ui/core';
import { ReactComponent as ArrowRight } from '@heimstaden/icons-library/img/streamline-regular/arrows-diagrams/arrows/arrow-right.svg';
import React, { FC, useCallback, useMemo } from 'react';
import { Content } from '../shared';
import { api } from './requests.repository';
import { useStyles } from './requests.styles';
import { ReactComponent as IntroImage } from '../assets/intro.svg';

export const Requests: FC = () => {
  const classes = useStyles();
  const country = useCountry();
  const { t } = useTranslation();
  const getRequests$ = useCallback(() => api.getRequests$(country), [country]);
  const [requests, isFetching] = useApi([], getRequests$);
  const { goTo } = useAnimatedRoutes();
  const filteredRequests = useMemo(
    () =>
      requests.filter(
        ({ externalWebpageURL, redirectURL }) =>
          externalWebpageURL || redirectURL,
      ),
    [requests],
  );

  const getMdGridSize = useCallback((itemsCount: number): GridSize => {
    if (itemsCount === 0) return 'auto';
    if (itemsCount >= 4) return 3;
    return (12 / itemsCount) as GridSize;
  }, []);

  const mdGridSize = useMemo(() => getMdGridSize(filteredRequests.length), [
    filteredRequests.length,
    getMdGridSize,
  ]);

  return (
    <Content
      classes={!isFetching ? { boxClassName: classes.box } : undefined}
      introProps={{
        descriptionKey: TranslationLabels.ticketingRequestText,
        titleKey: TranslationLabels.ticketingRequestTitle,
        childrenRightColumn: <IntroImage />,
      }}
      isFetching={isFetching}
    >
      <Grid container className={classes.list}>
        {filteredRequests.map((request) => {
          const {
            descriptionKey,
            externalWebpageURL,
            iconURL,
            key,
            redirectURL,
            titleKey,
          } = request;
          const props = externalWebpageURL
            ? {
                href: externalWebpageURL,
                rel: 'noopener noreferrer',
                target: '_blank',
              }
            : { onClick: () => goTo(redirectURL) };

          return (
            <Grid
              item
              md={mdGridSize}
              sm={12}
              key={key}
              className={classes.requestWrapper}
            >
              <Button
                {...props}
                classes={{ label: classes.label }}
                className={classes.request}
              >
                {/* TODO: Find a better solution to display icons */}
                {iconURL && (
                  <img
                    src={iconURL}
                    alt={t(titleKey)}
                    className={classes.icon}
                  />
                )}
                <Typography variant="h3" className={classes.title}>
                  <strong>{t(titleKey)}</strong>
                </Typography>
                <Typography className={classes.description}>
                  {t(descriptionKey)}
                </Typography>
                <Icon
                  className={classes.goTo}
                  icon={ArrowRight}
                  height={24}
                  width={24}
                />
              </Button>
            </Grid>
          );
        })}
      </Grid>
    </Content>
  );
};
