import { Environment } from '@shared/enums';

const envPrefix = 'REACT_APP_';

/**
 * @param {string} name
 * @return {string}
 */
export const getEnvValue = (name: string): string =>
  process.env[envPrefix + name] || '';

/**
 * @param {Environment} envName
 * @return {boolean}
 */
export const isEnv = (envName: Environment): boolean => {
  const environment = getEnvValue('ENV_NAME');

  return environment === envName;
};
