import React, { FC, ReactNode } from 'react';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { palette } from '@shared/consts';
import { ReactComponent as ArrowRight1 } from '@heimstaden/icons-library/img/streamline-regular/arrows-diagrams/arrows/arrow-right-1.svg';
import { AccordionSummaryProps } from '@material-ui/core/AccordionSummary/AccordionSummary';
import { useStyles } from './accordion-summary.styles';

type Props = AccordionSummaryProps & {
  children: NonNullable<ReactNode>;
};

export const AccordionSummary: FC<Props> = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <MuiAccordionSummary
      {...props}
      expandIcon={
        <ArrowRight1 width={24} height={24} color={palette.lighterGray} />
      }
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        expandIcon: classes.expandIcon,
      }}
    >
      {children}
    </MuiAccordionSummary>
  );
};

AccordionSummary.displayName = 'AccordionSummary';
