import PropTypes from 'prop-types';
import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';
import { useStyles } from './box.styles';

type Props = {
  children?: ReactNode;
  className?: string;
  color?: 'white' | 'grey';
};

export const Box: FC<Props> = ({ children, className, color = 'white' }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.box,
        {
          [classes.white]: color === 'white',
          [classes.grey]: color === 'grey',
        },
        className,
      )}
    >
      {children}
    </div>
  );
};
Box.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
Box.defaultProps = {
  children: undefined,
  className: undefined,
};
