import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    copyright: {
      fontSize: theme.typography.pxToRem(8),
      lineHeight: theme.typography.pxToRem(10),
      textAlign: 'center',
      textTransform: 'uppercase',
    },
  }),
);
