import { TranslationLabels } from '@generated/translation-labels';
import * as Sentry from '@sentry/react';
import { COUNTRY_TICKETING_MODEL } from '../../../ticketing.const';
import { api } from '../../../ticketing.repository';
import {
  ElementField,
  ServiceRequestDetails,
  Values as TicketValues,
} from '../../../ticketing.type';
import { IntroProps } from '../Intro/intro.component';

const getLabel = async (
  itemId?: number,
  failureId?: number,
  roomId?: number,
): Promise<GenericTypes.TranslationLabel[]> => {
  try {
    const ids = [
      ...(itemId ? [itemId] : []),
      ...(roomId ? [roomId] : []),
      ...(failureId ? [failureId] : []),
    ];
    const response = await api
      .getElementsName(ids)
      .then((elements) =>
        elements.map(({ id, ...rest }) => ({ id: Number(id), ...rest })),
      );
    const roomData = response.find(({ id }) => id === roomId);
    const itemData = response.find(({ id }) => id === itemId);
    const failureData = response.find(({ id }) => id === failureId);

    const labels = [];

    if (roomData) labels.push(roomData.titleKey);
    if (itemData) labels.push(itemData.titleKey);
    if (failureData) labels.push(failureData.titleKey);

    return labels;
  } catch (e) {
    Sentry.captureException(e);

    return [];
  }
};

export const getRequestTypeValue = async (
  data: ServiceRequestDetails,
  country: GenericTypes.Country,
): Promise<GenericTypes.TranslationLabel[]> => {
  const { jiraFields, requestType } = COUNTRY_TICKETING_MODEL[country];
  const { [jiraFields.REQUEST_TYPE]: requestTypeId } = data;

  switch (requestTypeId) {
    case requestType.UNIT: {
      const {
        [jiraFields.UNIT_FAILURE]: failure,
        [jiraFields.UNIT_ITEM]: item,
        [jiraFields.UNIT_ROOM]: room,
      } = data;
      const itemId = (item as ElementField | undefined)?.id;
      const failureId = (failure as ElementField | undefined)?.id;
      const roomId = (room as ElementField | undefined)?.id;

      return getLabel(itemId, failureId, roomId);
    }
    case requestType.COMMON: {
      const {
        [jiraFields.COMMON_FAILURE]: failure,
        [jiraFields.COMMON_ITEM]: item,
      } = data;
      const { id: itemId } = item as ElementField;

      return getLabel(itemId, failure && (failure as ElementField).id);
    }
    case requestType.OUTDOOR: {
      const { [jiraFields.OUTDOOR_ITEM]: item } = data;
      const { id: itemId } = item as ElementField;

      return getLabel(itemId);
    }
    case requestType.CUSTOMER_SERVICE: {
      return [TranslationLabels.customerServiceRequestTitle];
    }
    case requestType.ISSUE_COMPLAINT: {
      return [TranslationLabels.issueComplaintRequestTitle];
    }
    case requestType.TERMINATE_CONTRACT: {
      return [TranslationLabels.terminateContractRequestTitle];
    }
    case requestType.INVOICE_CUSTOMER_ACCOUNT: {
      return [TranslationLabels.invoiceAndCustomerAccountTitle];
    }
    default: {
      return [];
    }
  }
};

export const getUpdateRequestIntroProps = (
  country: GenericTypes.Country,
  ticketValues: TicketValues,
  readonly: boolean,
): IntroProps | null => {
  const {
    requestType: countryRequestType,
    jiraItems,
  } = COUNTRY_TICKETING_MODEL[country];
  const { requestType, failureId } = ticketValues;
  switch (requestType) {
    case countryRequestType.INVOICE_CUSTOMER_ACCOUNT: {
      if (
        failureId ===
        jiraItems.INVOICE_CUSTOMER_ACCOUNT_CONTRACT_AND_TENANCY_CHANGE_INFORMATION
      ) {
        return readonly
          ? {
              titleKey:
                TranslationLabels.invoiceAndCustomerRequestChangeOrUpdateCustomerDetailsJiraSummary,
              descriptionKey: '',
            }
          : {
              titleKey:
                TranslationLabels.invoiceAndCustomerRequestChangeOrUpdateCustomerDetailsTitle,
              descriptionKey:
                TranslationLabels.invoiceAndCustomerRequestChangeOrUpdateCustomerDetailsDescription,
            };
      }

      return null;
    }
    default:
      return null;
  }
};
