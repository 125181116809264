import { TranslationLabels } from '@generated/translation-labels';
import React, { FC, useCallback, useMemo } from 'react';
import { FormV2 } from '@shared/FormV2';
import { useCountry } from '@shared/hooks';
import { HttpStatus } from '@shared/http/http.type';
import Grid from '@material-ui/core/Grid';
import { useProfile } from '../../../../+profile';
import { api } from '../../../ticketing.repository';
import { Comment } from '../../../../+ticketing/ticketing.type';
import {
  getConfig,
  getFields,
  getTextareaField,
  Values,
} from './comment-form.model';
import { CommentSubmitButton } from '../CommentSubmitButton/comment-submit-button.component';
import { FormField } from '../../../../shared/FormV2/components/form-field.component';

type Props = {
  handleSuccess: (comment: Comment) => void;
  ticketId: string;
};

export const CommentForm: FC<Props> = (props) => {
  const { handleSuccess, ticketId } = props;
  const country = useCountry();
  const { data: profileData } = useProfile();

  const handleSubmit = useCallback(
    async (comment: string): Promise<Comment> => {
      try {
        const response = await api.addComment(
          ticketId,
          comment,
          country,
          profileData?.customerIdApi,
        );

        return {
          date: response.created,
          isAuthor: true,
          message: response.renderedBody,
        };
      } catch (e) {
        if (e.status === HttpStatus.METHOD_NOT_ALLOWED) {
          throw new Error(
            TranslationLabels.commentingClosedServiceRequestsNotAllowedErrorMessage,
          );
        }

        throw e;
      }
    },
    [country, profileData?.customerIdApi, ticketId],
  );

  const config = useMemo(() => getConfig(handleSubmit), [handleSubmit]);
  const fields = useMemo(() => getFields(<CommentSubmitButton />), []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <FormV2<Values>
          cancelLabel={
            TranslationLabels.ticketingDetailsEditorCancelButtonLabel
          }
          config={config}
          fieldsConfig={fields}
          handleSuccess={handleSuccess}
          onCancel={() => null}
          showButtons={false}
          submitLabel={TranslationLabels.ticketingDetailsEditorSendButtonLabel}
        >
          {(formProps) => (
            <FormField<Values>
              fieldConfig={getTextareaField(
                <CommentSubmitButton isSubmitting={formProps.isSubmitting} />,
              )}
              values={formProps.values}
            />
          )}
        </FormV2>
      </Grid>
    </Grid>
  );
};
