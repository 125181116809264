import { sanityClient, SanityDocumentType } from '@shared/sanity';
import { SanityRequestTypeIdResponse } from '../../../shared';

export const api = {
  getRequestTypeId$: async (
    country: GenericTypes.Country,
    itemId: number,
    subItemId: number,
  ): Promise<number | undefined> => {
    const query = `
      *[_type == $type && content.country == $country][0]{
        ...content.ticketing.customerAccountRequest.types.invoiceAndCustomerAccount{
          ...select(
            defined(
              items[item->fieldId == string($itemId)][0]
              .failures[failure->fieldId == string($subItemId)][0]
            ) => {
              "requestTypeId": typeId,
            }
          )
        }
      }
    `;
    const response = await sanityClient.fetch<SanityRequestTypeIdResponse>(
      query,
      {
        country,
        itemId,
        subItemId,
        type: SanityDocumentType.SETTINGS,
      },
    );

    return response?.requestTypeId;
  },
};
