import Typography from '@material-ui/core/Typography';
import { PLACEHOLDER } from '@shared/consts';
import React, { FC } from 'react';

type Props<V> = {
  value: V;
  className?: string;
};
export function FieldReadonly<V>(props: Props<V>): ReturnType<FC<Props<V>>> {
  const { className, value } = props;

  return (
    <Typography variant="h3" className={className}>
      {value || PLACEHOLDER}
    </Typography>
  );
}
