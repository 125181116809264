import { Icon } from '@shared/components';
import React, { MouseEvent, KeyboardEvent, FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ReactComponent as ArrowCircleRightIcon } from '@heimstaden/icons-library/img/streamline-regular/arrows-diagrams/arrows/arrow-circle-right.svg';
import { ReactComponent as ArrowCircleLeftIcon } from '@heimstaden/icons-library/img/streamline-regular/arrows-diagrams/arrows/arrow-circle-left.svg';
import { useStyles } from './carousel-button.styles';

export type ButtonDirection = 'left' | 'right';

type Props = {
  direction: ButtonDirection;
  onClick: (
    event: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>,
  ) => void;
  disabled: boolean;
  className?: string;
};

export const CarouselButton: FC<Props> = ({
  direction,
  onClick,
  disabled,
  className,
}) => {
  const classes = useStyles();

  const ArrowIcon =
    direction === 'left' ? ArrowCircleLeftIcon : ArrowCircleRightIcon;

  return (
    <div
      onClick={onClick}
      aria-hidden="true"
      role="button"
      className={clsx(
        classes.button,
        {
          disabled,
        },
        className,
      )}
    >
      <Icon
        className={clsx(classes.buttonIcon, {
          disabled,
        })}
        icon={ArrowIcon}
      />
    </div>
  );
};
CarouselButton.propTypes = {
  direction: PropTypes.oneOf(['left', 'right'] as const).isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};
