import { FieldConfig } from '../types';

export function prepareInitialValues<TFormValues>(
  fieldsConfig: FieldConfig<TFormValues>[],
): TFormValues {
  const initialValues = {} as TFormValues;
  fieldsConfig.forEach(({ field }): void => {
    initialValues[field.name as keyof TFormValues] = field.initialValue;
  });
  return initialValues;
}
