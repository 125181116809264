import { getEnvValue } from '../helpers';
import { HttpClient } from './http.client';
import { createInstance } from './http.instance';

const jiraServiceInstance = createInstance(
  {
    baseURL: getEnvValue('JIRA_SERVICE_API_URL'),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  },
  false,
);

export const jiraServiceClient = new HttpClient(jiraServiceInstance);
