import { ICustomerMidleLayerApi2 } from '@shared/models';
import { createContext } from 'react';
import { NotificationPermissions } from '../../types';
import { initialState } from './reducer';
import { State } from './type';

export type ContextValue = State & {
  getProfileData$: () => Promise<ICustomerMidleLayerApi2['customerIdApi']>;
  updateProfileData: (data: Partial<State>) => void;
  updateNotificationPermissions: (data: NotificationPermissions) => void;
};

export const Context = createContext<ContextValue>({
  ...initialState,
  getProfileData$: () => Promise.resolve(''),
  updateProfileData: () => {
    // do nothing.
  },
  updateNotificationPermissions: () => {
    // do nothing.
  },
});
