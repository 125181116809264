import { TranslationLabels } from '@generated/translation-labels';
import Button from '@material-ui/core/Button';
import { useAnimatedRoutes } from '@shared/animated-routes';
import { useApi, useCountry } from '@shared/hooks';
import { useTranslation } from '@shared/translations';
import React, { FC, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import { RouteParams } from '../service-request.type';
import { api } from './suggestion.repository';
import { useStyles } from './suggestion.styles';
import {
  Content,
  FallbackMessage,
  suggestionPathSuffix,
  TICKETING_PATHS,
} from '../../shared';
import { stepper } from '../stepper.config';

export const Suggestion: FC = () => {
  const classes = useStyles();
  const country = useCountry();
  const { goTo } = useAnimatedRoutes();
  const { failureId } = useParams<RouteParams>();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const getSuggestionKey$ = useCallback(
    () => api.getSuggestion$(country, Number(failureId)),
    [country, failureId],
  );
  const [suggestionKey, isFetching] = useApi('', getSuggestionKey$);
  const redirectToFormPath = useMemo(
    () => pathname.replace(suggestionPathSuffix, ''),
    [pathname],
  );

  return (
    <Content
      introProps={{
        descriptionKey: TranslationLabels.ticketingServiceRequestSuggestionText,
        titleKey: TranslationLabels.ticketingServiceRequestSuggestionTitle,
      }}
      isFetching={isFetching}
      stepperConfig={stepper}
    >
      {suggestionKey ? (
        <div className={classes.box}>
          <div className={classes.content}>{t(suggestionKey)}</div>
          <div className={classes.actionsWrapper}>
            <div className={classes.actions}>
              <Button
                color="primary"
                onClick={() => goTo(redirectToFormPath)}
                variant="outlined"
                size="large"
                className={classes.button}
              >
                {t(
                  TranslationLabels.ticketingServiceRequestSuggestionButtonNextLabel,
                )}
              </Button>
              <Button
                color="primary"
                onClick={() => goTo(TICKETING_PATHS.INDEX)}
                variant="contained"
                size="large"
                className={classes.button}
              >
                {t(
                  TranslationLabels.ticketingServiceRequestSuggestionButtonHomeLabel,
                )}
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <FallbackMessage
          messageKey={TranslationLabels.ticketingRequestFormFallbackMessage}
        />
      )}
    </Content>
  );
};
