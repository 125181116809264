import { FC, MouseEvent, SVGProps } from 'react';

export enum ErrorType {
  invalidCustomer = 'invalidCustomer',
}

type ErrorConfig = {
  icon: FC<SVGProps<SVGSVGElement>>;
  titleKey: GenericTypes.TranslationLabel;
  textKey: GenericTypes.TranslationLabel;
  buttonTextKey: GenericTypes.TranslationLabel;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
};

export type ErrorsConfig = { [key in ErrorType]: ErrorConfig };
