import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Field, getFirstError, SelectOption } from '@shared/components';
import { FieldLabel } from '@shared/components/FieldLabel/field-label.component';
import { FieldReadonly } from '@shared/components/FieldReadonly/field-readonly.component';
import { useStyles } from '@shared/components/Fields/SelectField/select-field.styles';
import React, { ChangeEvent, FC } from 'react';
import { FormikErrorValues } from '@shared/components/Fields/type';
import { FormikHelpers } from 'formik';
import { useTranslation } from '@shared/translations';

export type SelectValue = string | number;

type Props<TFormValues> = Field<TFormValues> & {
  value: SelectValue;
  setFieldValue: FormikHelpers<TFormValues>['setFieldValue'];
  errorMessage?: FormikErrorValues;
  disabled?: boolean;
};

export function SelectField<TFormValues>(
  props: Props<TFormValues>,
): ReturnType<FC<Props<TFormValues>>> {
  const {
    className,
    disabled,
    errorMessage,
    id,
    isMultiple,
    label,
    onBlur,
    options,
    readonly,
    required,
    setFieldValue,
    touched,
    value,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const labelId = `${id}-label`;
  const handleChange = (event: ChangeEvent<{ value: unknown }>): void => {
    setFieldValue(id, event.target.value);
  };
  const error = touched && getFirstError(t, errorMessage);
  const isError = Boolean(error);
  const getOption = (
    selectedValue: SelectOption['value'],
  ): SelectOption | undefined =>
    (options || [])
      .map(({ label, ...rest }) => ({ ...rest, label: t(label) }))
      .find(({ value }) => selectedValue === value);
  const readonlyValue = value ? getOption(value) : undefined;

  return (
    <FormControl
      component="fieldset"
      disabled={disabled}
      error={isError}
      fullWidth
      required={required && !readonly}
    >
      <FieldLabel label={label} />
      {readonly ? (
        <FieldReadonly className={className} value={readonlyValue?.label} />
      ) : (
        <>
          <Select
            {...(isMultiple && {
              multiple: true,
              renderValue: (selected) => (
                <div className={classes.chips}>
                  {(selected as SelectOption['value'][]).map(
                    (selectedValue: SelectOption['value']) => {
                      const option = getOption(selectedValue);

                      return option ? (
                        <Chip
                          className={classes.chip}
                          key={selectedValue}
                          label={option.label}
                        />
                      ) : null;
                    },
                  )}
                </div>
              ),
            })}
            classes={{
              icon: classes.icon,
            }}
            error={isError}
            id={id}
            input={<Input id={id} />}
            labelId={labelId}
            onBlur={onBlur}
            onChange={handleChange}
            value={value}
          >
            {(options || []).map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {t(label)}
              </MenuItem>
            ))}
          </Select>
          {error && <FormHelperText error>{error}</FormHelperText>}
        </>
      )}
    </FormControl>
  );
}
