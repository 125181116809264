import React, { FC } from 'react';
import clsx from 'clsx';
import { useStyles } from './image-modal-preview.styles';

type Props = {
  url: GenericTypes.URL;
  isVisible: boolean;
  className?: string;
};

export const ImageModalPreview: FC<Props> = ({ url, isVisible, className }) => {
  const classes = useStyles();

  return (
    <img
      src={url}
      alt=""
      className={clsx(classes.image, className)}
      style={{ opacity: isVisible ? 1 : 0 }}
    />
  );
};
