import { getEnvValue } from '@shared/helpers';
import { jiraServiceClient } from '@shared/http';
import { storageService } from '@shared/services';
import { HeimAuthParams, UserData } from '@shared/types';

export const api = {
  authorizeUser: (
    email: UserData['email'],
    phone: UserData['mobile'],
  ): Promise<UserData> => {
    const loginAsEmail = getEnvValue('HEIMAPI_SIMULATE_USER');
    const payload = {
      dataSource: Number(getEnvValue('HEIMAPI_DATASOURCE')),
      email: loginAsEmail || email,
      mobile: phone && phone.replace('+', '00'),
      GuidLogin: storageService.getGuid(),
      partnerName: getEnvValue('HEIMAPI_PARTNERNAME'),
    };

    return jiraServiceClient.post<HeimAuthParams, UserData>(
      '/heimapi/authToken',
      payload,
    );
  },
};
