import { heimClient, jiraServiceClient } from '@shared/http';
import { AttachmentFilter, HeimAttributes } from '@shared/enums';
import { Attachment, IRentalObjectMyHome } from '@shared/models';
import {
  SANITY_PDF_CATEGORY,
  sanityClient,
  SanityDocumentType,
} from '@shared/sanity';
import { ProfileData } from '../+profile';
import {
  CreateBookingPayload,
  SuggestBookingPayload,
  CancelBookingPayload,
  SanityDocument,
  TerminationCalendarConfig,
  TerminationFlowStep,
} from './types';

export const api = {
  getCalendarConfig: async (
    country: GenericTypes.Country,
  ): Promise<TerminationCalendarConfig> => {
    return jiraServiceClient.get<TerminationCalendarConfig>(
      `${country}/calendar/termination/config/`,
    );
  },

  // Attachments/:dataSource/:partnerName/:userAndSessionId/:attachmentFilter/:objectIdApi/:rentalDealIdApi
  urlAttachments: (
    attachmentFilter: AttachmentFilter,
    objectIdApi: IRentalObjectMyHome['objectIdApi'],
    rentalDealIdApi: IRentalObjectMyHome['rentalDealIdApi'],
  ): string => {
    return [
      'Attachments',
      HeimAttributes.DATA_SOURCE,
      HeimAttributes.PARTNER_NAME,
      HeimAttributes.GUID,
      attachmentFilter,
      objectIdApi,
      rentalDealIdApi,
    ].join('/');
  },

  getTerminationDocuments: async (
    attachmentFilter: AttachmentFilter,
    objectIdApi: IRentalObjectMyHome['objectIdApi'],
    rentalDealIdApi: IRentalObjectMyHome['rentalDealIdApi'],
  ): Promise<Attachment[]> => {
    const url = api.urlAttachments(
      attachmentFilter,
      objectIdApi,
      rentalDealIdApi,
    );

    return heimClient.get<Attachment[]>(url);
  },

  getSanityDocuments: async (
    country: GenericTypes.Country,
    lang: GenericTypes.Language,
  ): Promise<SanityDocument[]> => {
    return sanityClient.fetch(
      `
        *[_type == $type && category == $category && country == $country]{
          "id": id.current,
          "titleKey": title->key,
          "url": files[language == $lang][0].file.asset->url
        }
      `,
      {
        category: SANITY_PDF_CATEGORY.TERMINATION,
        country,
        lang,
        type: SanityDocumentType.PDF,
      },
    );
  },

  getTerminationFlow: async (
    country: GenericTypes.Country,
    ticketId: string,
    customerId?: ProfileData['customerIdApi'],
  ): Promise<TerminationFlowStep[]> => {
    return jiraServiceClient.get<TerminationFlowStep[]>(
      `${country}/tenant/${customerId}/tickets/${ticketId}/termination-state`,
    );
  },

  createBooking: (
    country: GenericTypes.Country,
    payload: CreateBookingPayload,
  ): Promise<void> => {
    return jiraServiceClient.post(
      `${country}/calendar/termination/create-booking`,
      payload,
    );
  },

  suggestBooking: (
    country: GenericTypes.Country,
    payload: SuggestBookingPayload,
  ): Promise<void> => {
    return jiraServiceClient.post(
      `${country}/calendar/termination/suggest-booking`,
      payload,
    );
  },

  cancelBooking: (
    country: GenericTypes.Country,
    payload: CancelBookingPayload,
  ): Promise<{ isCancellable: boolean; message: string }> => {
    return jiraServiceClient.post(
      `${country}/calendar/termination/cancel-booking`,
      payload,
    );
  },
};
