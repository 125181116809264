import * as Sentry from '@sentry/react';

export const PDF_MEDIA_TYPE = 'application/pdf';

export const fileToBase64 = (file: File): Promise<FileReader['result']> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => {
      Sentry.captureException(error);

      return reject(error);
    };
  });

export const getBlobFromBase64 = (base64: string, type: string): Blob => {
  const byteArray = Uint8Array.from(
    atob(base64)
      .split('')
      .map((char) => char.charCodeAt(0)),
  );

  return new Blob([byteArray], { type });
};
