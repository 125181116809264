import Typography from '@material-ui/core/Typography';
import { useTranslation } from '@shared/translations';
import React, { FC } from 'react';
import { HomeButton } from '../HomeButton/home-button.component';
import { useStyles } from './fallback-message.styles';

type Props = {
  messageKey: GenericTypes.TranslationLabel;
};

export const FallbackMessage: FC<Props> = (props) => {
  const { messageKey } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Typography className={classes.element}>{t(messageKey)}</Typography>
      <div className={classes.buttons}>
        <HomeButton />
      </div>
    </>
  );
};
