import { TranslationLabels } from '@generated/translation-labels';
import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from '@shared/translations';

export const FlowStepDisabled: FC = () => {
  const { t } = useTranslation();

  return (
    <Typography variant="body1">
      {t(TranslationLabels.terminationFlowStepDisabledText)}
    </Typography>
  );
};

FlowStepDisabled.displayName = 'FlowStepDisabled';
