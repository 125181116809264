import React, { FC, ReactNode } from 'react';
import MuiAccordion from '@material-ui/core/Accordion';
import { AccordionProps } from '@material-ui/core/Accordion/Accordion';
import { useStyles } from './accordion.styles';

type Props = AccordionProps & {
  children: NonNullable<ReactNode>;
};

export const Accordion: FC<Props> = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <MuiAccordion
      {...props}
      classes={{
        root: classes.root,
        rounded: classes.rounded,
        expanded: classes.expanded,
      }}
    >
      {children}
    </MuiAccordion>
  );
};

Accordion.displayName = 'Accordion';
