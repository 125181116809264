import React, { FC, MouseEvent, SVGProps } from 'react';
import { useSlate } from 'slate-react';
import { TEXT_ALIGN_TYPES } from '../constants';
import { toggleBlock, isBlockActive } from '../tools';
import { BlockFormat } from '../types';
import { Button } from './button.component';

type Props = {
  format: BlockFormat;
  icon: FC<SVGProps<SVGSVGElement>>;
  className?: string;
};

export const BlockButton: FC<Props> = ({ format, icon, className }) => {
  const editor = useSlate();

  return (
    <Button
      onMouseDown={(event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
      isActive={isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type',
      )}
      icon={icon}
      className={className}
    />
  );
};
