import { TranslationLabels } from '@generated/translation-labels';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import * as Sentry from '@sentry/react';
import { Spinner } from '@shared/components';
import clsx from 'clsx';
import { FormikConfig, useFormik } from 'formik';
import isEqual from 'lodash-es/isEqual';
import React, { FC, MouseEvent, useState } from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import {
  CheckboxValue,
  AttachmentValue,
  InputValue,
  SelectValue,
  DateValue,
  AttachmentField,
  DatePickerField,
  InputField,
  SelectField,
  CheckboxesField,
} from '@shared/components/Fields';
import { useTranslation } from '@shared/translations';
import { SwitchFieldV2, SwitchValue } from '@shared/FormV2/fields';
import { ArrowRightIcon } from '../icons';
import { useStyles } from './form.styles';
import { Field } from './form.type';

type Props<TFormValues> = {
  config: FormikConfig<TFormValues>;
  fields: Field<TFormValues>[];
  cancelLabel?: string;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  handleSuccess?: (response: any) => void;
  onCancel?: () => void;
  showButtons?: boolean;
  submitLabel?: string;
};

type FormValues = {
  [key in string]:
    | InputValue
    | CheckboxValue
    | AttachmentValue[]
    | SelectValue
    | DateValue
    | SwitchValue;
};

export function Form<TFormValues extends FormValues>(
  props: Props<TFormValues>,
): ReturnType<FC<Props<TFormValues>>> {
  const [globalError, setGlobalError] = useState('');
  const {
    cancelLabel,
    className,
    config,
    fields,
    handleSuccess,
    onCancel,
    showButtons,
    submitLabel,
  } = props;
  const formConfig: FormikConfig<TFormValues> = {
    ...config,
    enableReinitialize: true,
    onSubmit: async (values, helpers) => {
      const { resetForm, setSubmitting, setStatus } = helpers;
      setGlobalError('');

      try {
        const response = await config.onSubmit(values, helpers);

        if (handleSuccess) {
          handleSuccess(response);
        }

        resetForm();
        setStatus({ success: true });
      } catch (e) {
        if (e.message) {
          setGlobalError(t(e.message));
        } else if (e.data.message) {
          setGlobalError(e.data.message);
        }

        setStatus({ success: false });
        Sentry.captureException(e);
      } finally {
        setSubmitting(false);
      }
    },
  };
  const form = useFormik(formConfig);
  const {
    errors,
    handleChange,
    handleBlur,
    handleReset,
    handleSubmit,
    initialTouched,
    initialValues,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    values,
    touched,
  } = form;
  const { t } = useTranslation();
  const classes = useStyles();
  const handleCancel = (event: MouseEvent<HTMLButtonElement>): void => {
    handleReset(event);

    if (onCancel) onCancel();
  };

  return (
    <form
      className={clsx(classes.form, className)}
      onSubmit={handleSubmit}
      noValidate
    >
      {globalError && (
        <FormHelperText className={classes.globalError} error>
          {globalError}
        </FormHelperText>
      )}
      {fields.map((field) => {
        const { id, type } = field;
        const error = errors[id];
        const value = values[id];
        const initialValue = initialValues[id];
        const fieldProps = {
          ...field,
          disabled: isSubmitting,
          errorMessage: error,
          onChange: handleChange,
          onBlur: handleBlur,
          touched: Boolean(touched[id]),
        };
        const getField = (): JSX.Element => {
          switch (type) {
            case 'attachment': {
              return (
                <AttachmentField<TFormValues>
                  {...fieldProps}
                  setFieldValue={setFieldValue}
                  value={value as AttachmentValue[]}
                />
              );
            }
            case 'select': {
              return (
                <SelectField<TFormValues>
                  {...fieldProps}
                  value={value as SelectValue}
                  setFieldValue={setFieldValue}
                />
              );
            }
            case 'date': {
              return (
                <DatePickerField<TFormValues>
                  {...fieldProps}
                  value={value as DateValue}
                  setFieldValue={setFieldValue}
                />
              );
            }
            case 'checkboxes': {
              return (
                <CheckboxesField<TFormValues>
                  {...fieldProps}
                  value={value as CheckboxValue}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                />
              );
            }
            // TODO: for now it's used SwitchFieldV2 here,
            //  in the future Form should by replaced by FormV2 in all places
            //  https://fredensborg.atlassian.net/browse/MYH-507
            case 'switch': {
              if (!fieldProps.switchOptions) {
                return <></>;
              }

              const { switchOptions, ...props } = fieldProps;
              const field = {
                name: id,
                initialValue: initialValue as SwitchValue,
                value: value as SwitchValue,
                onBlur: () => {
                  return;
                },
                onChange: () => {
                  return;
                },
              };
              const meta = {
                initialTouched: Boolean(initialTouched),
                touched: Boolean(touched),
                value: value as SwitchValue,
              };

              return (
                <SwitchFieldV2<TFormValues>
                  props={{ ...props, ...switchOptions }}
                  field={field}
                  form={form}
                  meta={meta}
                />
              );
            }
            default: {
              return (
                <InputField<TFormValues>
                  {...fieldProps}
                  value={value as InputValue}
                />
              );
            }
          }
        };

        return (
          <div key={`from-input-${id}`} className={classes.input}>
            {getField()}
          </div>
        );
      })}
      {fields.length === 1 && (
        <IconButton
          className={classes.arrowButton}
          disabled={isSubmitting || isEqual(initialValues, values)}
          type="submit"
        >
          <ArrowRightIcon height={32} width={32} />
        </IconButton>
      )}
      {showButtons && fields.length > 1 && (
        <div className={classes.buttonsRow}>
          {onCancel && (
            <Button
              color="primary"
              disabled={isSubmitting}
              onClick={handleCancel}
              variant="outlined"
            >
              {cancelLabel || t(TranslationLabels.formButtonCancel)}
            </Button>
          )}
          <Button
            color="primary"
            disabled={isSubmitting || isEqual(initialValues, values)}
            type="submit"
            variant="contained"
          >
            {isSubmitting ? (
              <>
                {t(TranslationLabels.formButtonSaving)}
                <div className={classes.spinner}>
                  <Spinner color="inherit" size={16} />
                </div>
              </>
            ) : (
              t(submitLabel || TranslationLabels.formButtonSubmit)
            )}
          </Button>
        </div>
      )}
    </form>
  );
}

Form.defaultProps = {
  cancelLabel: '',
  className: '',
  onCancel: undefined,
  showButtons: true,
  submitLabel: '',
};
