import { TranslationLabels } from '@generated/translation-labels';
import { Environment } from '@shared/enums';
import { isEnv } from '@shared/helpers';
import { ICustomerRentalObject } from '@shared/models';
import { TFunction } from 'i18next';
import { ApartmentState } from '../../../../+apartment';
import {
  ProfileData,
  DetailsRequestValues,
  getDetailsRequestModel,
} from '../../../../+profile';
import { FormOptions as TerminateContractFormOptions } from '../../../../+termination/shared/components/Request/request.type';
import {
  COUNTRY_TICKETING_MODEL,
  TICKETING_DEV_PREFIX,
} from '../../../ticketing.const';
import { api } from '../../../ticketing.repository';
import {
  ServiceRequestDetails,
  Values as TicketValues,
} from '../../../ticketing.type';
import { Model, RequestModel } from './form.type';
import {
  CustomerServiceRequestValues,
  getCustomerServiceRequestModel,
  getInvoiceCustomerAccountRequestModel,
  getIssueComplaintRequestModel,
  getRepairMaintenanceRequestModel,
  getTerminateContractRequestModel,
  InvoiceCustomerAccountRequestValues,
  IssueComplaintRequestValues,
  RepairMaintenanceValues,
  TerminateContractRequestValues,
} from './models';
import { IntroProps } from '../Intro/intro.component';

export const getRequestModel: RequestModel<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | any
  | InvoiceCustomerAccountRequestValues
  | CustomerServiceRequestValues
  | IssueComplaintRequestValues
  | RepairMaintenanceValues
  | TerminateContractRequestValues
  | DetailsRequestValues
> = (
  country: GenericTypes.Country,
  ticketValues: TicketValues,
  initialValues?: ServiceRequestDetails,
  user?: ProfileData | null,
  apartment?: ICustomerRentalObject | null,
  readonly?: boolean,
  t?: TFunction,
  options?: TerminateContractFormOptions,
) => {
  const {
    requestType: countryRequestType,
    jiraItems,
  } = COUNTRY_TICKETING_MODEL[country];
  const { requestType } = ticketValues;

  switch (requestType) {
    case countryRequestType.CUSTOMER_SERVICE: {
      return getCustomerServiceRequestModel(
        country,
        ticketValues,
        initialValues,
      );
    }

    case countryRequestType.COMMON:
    case countryRequestType.OUTDOOR:
    case countryRequestType.UNIT: {
      return getRepairMaintenanceRequestModel(
        country,
        ticketValues,
        initialValues,
      );
    }
    case countryRequestType.ISSUE_COMPLAINT: {
      return getIssueComplaintRequestModel(
        country,
        ticketValues,
        initialValues,
      );
    }
    case countryRequestType.TERMINATE_CONTRACT: {
      return getTerminateContractRequestModel(
        country,
        ticketValues,
        initialValues,
        user,
        apartment,
        readonly,
        t,
        options,
      );
    }
    case countryRequestType.INVOICE_CUSTOMER_ACCOUNT: {
      if (
        ticketValues.failureId ===
        jiraItems.INVOICE_CUSTOMER_ACCOUNT_TERMINATE_REQUEST
      ) {
        return getTerminateContractRequestModel(
          country,
          ticketValues,
          initialValues,
          user,
          apartment,
          readonly,
          t,
        );
      }

      if (
        ticketValues.failureId ===
        jiraItems.INVOICE_CUSTOMER_ACCOUNT_CONTRACT_AND_TENANCY_CHANGE_INFORMATION
      ) {
        return getDetailsRequestModel(
          country,
          ticketValues,
          initialValues,
          user,
          apartment,
          readonly,
          t,
        );
      }

      return getInvoiceCustomerAccountRequestModel(
        country,
        ticketValues,
        initialValues,
      );
    }
    default: {
      return {
        config: {
          initialValues: initialValues || {},
          prepareValues: (values) => values,
        },
        fields: [],
      };
    }
  }
};

export const getFormModel = (
  country: GenericTypes.Country,
  ticketValues: TicketValues,
  user: ProfileData | null,
  apartment: ICustomerRentalObject | null,
  readonly?: boolean,
  residents?: ApartmentState['residents'],
  tenants?: ApartmentState['tenants'],
  t?: TFunction,
): Model<
  | InvoiceCustomerAccountRequestValues
  | CustomerServiceRequestValues
  | IssueComplaintRequestValues
  | RepairMaintenanceValues
  | TerminateContractRequestValues
  | DetailsRequestValues
> => {
  const { jiraFields } = COUNTRY_TICKETING_MODEL[country];
  const { config, fields } = getRequestModel(
    country,
    ticketValues,
    undefined,
    user,
    apartment,
    readonly,
    t,
  );
  const { prepareValues, ...restProps } = config;

  return {
    config: {
      ...restProps,
      onSubmit: (values) => {
        const preparedValues = prepareValues(
          values,
          ticketValues.requestType,
          residents,
          tenants,
        );
        const finalValues = {
          ...preparedValues,
          [jiraFields.SUMMARY]: !isEnv(Environment.PRODUCTION)
            ? `${TICKETING_DEV_PREFIX} ${preparedValues[jiraFields.SUMMARY]}`
            : preparedValues[jiraFields.SUMMARY],
          [jiraFields.REQUEST_TYPE]: ticketValues.requestType,
        };

        return api.createTicket(finalValues, apartment, country, user);
      },
    },
    fields,
  };
};

export const getRequestIntroProps = (
  country: GenericTypes.Country,
  ticketValues: TicketValues,
): IntroProps => {
  const {
    requestType: countryRequestType,
    jiraItems,
  } = COUNTRY_TICKETING_MODEL[country];
  const { requestType, failureId } = ticketValues;
  switch (requestType) {
    case countryRequestType.INVOICE_CUSTOMER_ACCOUNT:
      if (
        failureId ===
        jiraItems.INVOICE_CUSTOMER_ACCOUNT_CONTRACT_AND_TENANCY_CHANGE_INFORMATION
      ) {
        return {
          titleKey:
            TranslationLabels.invoiceAndCustomerRequestChangeOrUpdateCustomerDetailsTitle,
          descriptionKey:
            TranslationLabels.invoiceAndCustomerRequestChangeOrUpdateCustomerDetailsDescription,
        };
      }
      return {
        titleKey: TranslationLabels.ticketingGeneralTitle,
        descriptionKey: TranslationLabels.ticketingGeneralText,
      };
    case countryRequestType.TERMINATE_CONTRACT: {
      return {
        titleKey: TranslationLabels.terminationServiceRequestFormTitle,
        descriptionKey: TranslationLabels.terminationServiceRequestFormText,
      };
    }
    default:
      return {
        titleKey: TranslationLabels.ticketingGeneralTitle,
        descriptionKey: TranslationLabels.ticketingGeneralText,
      };
  }
};
