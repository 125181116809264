import { TranslationLabels } from '@generated/translation-labels';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import * as Sentry from '@sentry/react';
import { Icon } from '@shared/components';
import { useCountry } from '@shared/hooks';
import { useTranslation } from '@shared/translations';
import { ReactComponent as CalendarIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/date-calendar/calendar-3.svg';
import { format, formatISO, differenceInWeeks } from 'date-fns';
import { useSnackbar } from 'notistack';
import React, { FC, useCallback, useMemo, useState, ChangeEvent } from 'react';
import Switch from '@material-ui/core/Switch';
import { useApartment } from '../../../../+apartment';
import { useProfile } from '../../../../+profile';
import { api } from '../../../termination.repository';
import {
  BookingMode,
  CreateBookingPayload,
  JiraTicketId,
  ProjectId,
  SuggestBookingPayload,
  TerminationBookingFlowStep,
} from '../../../types';
import { Calendar } from '../../calendar';
import { JiraProjectId, PendingReason } from '../../enums';
import {
  changeHour,
  getBookingEndDate,
  getBookingOperationErrorLabelKey,
  getBookingTitleKey,
  getBookingWidgetMetaData,
  getCalendarInputLabelKey,
  getSubmitButtonLabelKey,
  getValidRange,
} from '../../helpers';
import { useTermination } from '../../state';
import { useStyles } from './calendar-modal.styles';
import {
  DEFAULT_WORKING_HOUR_END_TIME,
  DEFAULT_WORKING_HOUR_START_TIME,
} from './const';
import { CalendarSlot } from '../../calendar/types';
import { ConfirmationModal } from '../ConfirmationModal/confirmation-modal.component';

type Props = {
  flowStep: TerminationBookingFlowStep;
  jiraIssueTypeId: string;
  jiraProjectId: JiraProjectId;
  jiraTicketId: JiraTicketId;
  projectId: ProjectId;
  withStatement?: boolean;
};

type Event = {
  id?: string;
  start: string;
  end: string;
};

export const CalendarModal: FC<Props> = ({
  flowStep,
  jiraIssueTypeId,
  jiraProjectId,
  jiraTicketId,
  projectId,
  withStatement,
}) => {
  // ---------------------------------------------
  // hooks
  // ---------------------------------------------
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const country = useCountry();
  const { data: userData } = useProfile();
  const { data: apartmentData } = useApartment();
  const { updateFlowStep, reinitialize } = useTermination();

  // ---------------------------------------------
  // local state
  // ---------------------------------------------
  const [isSuggestingDate, setSuggestingDate] = useState(false);
  const [isFetching, setFetching] = useState(false);
  const [
    isMoveOutConfirmationModalOpen,
    setMoveOutConfirmationModalOpen,
  ] = useState(false);
  const [value, setValue] = useState<CalendarSlot | null>(
    flowStep.bookingDate?.value && flowStep.bookingId?.value
      ? {
          id: flowStep.bookingId?.value,
          start: new Date(flowStep.bookingDate?.value),
        }
      : null,
  );
  const [isStatementChecked, changeStatementAcceptance] = useState<boolean>(
    false,
  );

  // ---------------------------------------------
  // aliases
  // ---------------------------------------------
  const address = apartmentData?.rentalObjectMyHome?.addressInclFlatnumber;
  const bookingDate = flowStep.bookingDate?.value;
  const bookingId = flowStep.bookingId?.value;
  const suggestedDate = flowStep.suggestedDate?.value;
  const workingHoursStart = isSuggestingDate
    ? flowStep.workingHoursForSuggestion?.start
    : flowStep.workingHours?.start;
  const workingHoursEnd = isSuggestingDate
    ? flowStep.workingHoursForSuggestion?.end
    : flowStep.workingHours?.end;
  const isStatementRejected = withStatement && !isStatementChecked;
  const {
    eventType,
    mode,
    bookableResource,
    isCancellable,
    pendingReasons,
    bookingDatesRange,
    ticketId,
    isCalendarOpened,
    availability,
    availabilityForSuggestion,
  } = flowStep;

  // ---------------------------------------------
  // variables not affecting the render process or too simple to use useMemo()
  // ---------------------------------------------
  const dateToDisplay =
    value?.start ||
    (bookingDate && new Date(bookingDate)) ||
    (suggestedDate && new Date(suggestedDate));
  const formattedSelectedDate =
    (dateToDisplay && format(dateToDisplay, 'cccc P p')) || ''; // Friday 10/26/2021 11:00 AM

  const isTextFieldDisabled: boolean =
    isFetching || (bookingDate && !isCancellable) || Boolean(suggestedDate);

  const shouldRenderWidget: boolean =
    Boolean(bookableResource) &&
    !pendingReasons.includes(PendingReason.MISSING_BOOKABLE_RESOURCE);

  const isSubmitButtonDisabled: boolean =
    isStatementRejected ||
    isFetching ||
    Boolean(suggestedDate) ||
    !(shouldRenderWidget && value?.start) ||
    Boolean(
      bookingDate && value.start.getTime() === new Date(bookingDate).getTime(),
    );

  const validRange = getValidRange(bookingDatesRange);

  // ---------------------------------------------
  // variables affecting the render process
  // ---------------------------------------------
  const events = useMemo(() => {
    const newAvailability =
      availability?.map((av) => ({
        id: av.resources[0]?.id,
        start: av.start,
        end: av.end,
      })) || [];

    return [
      ...newAvailability,
      ...(bookingDate &&
      bookingId &&
      !newAvailability.some((av) => av.id === bookingId)
        ? [
            {
              id: bookingId,
              start: bookingDate,
              end: getBookingEndDate(bookingDate),
            },
          ]
        : []),
    ];
  }, [bookingDate, bookingId, availability]);

  const eventsToSuggest: Event[] = useMemo(
    () =>
      (availabilityForSuggestion || []).map((av) => ({
        start: av.start,
        end: av.end,
        id: undefined, // because suggestion is not connected to Timekit
      })),
    [availabilityForSuggestion],
  );

  // ---------------------------------------------
  // actions
  // ---------------------------------------------
  const handleDateChange = (calendarSlot: CalendarSlot): void => {
    setValue(calendarSlot);
    toggleModal(false);
  };

  const handleStatementAcceptanceChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      changeStatementAcceptance(event.target.checked),
    [],
  );

  const cancelBooking = useCallback(() => {
    if (!userData?.customerIdApi) {
      return Promise.reject();
    }

    return api.cancelBooking(country, {
      tenantId: userData.customerIdApi,
      ticketId: jiraTicketId,
    });
  }, [country, jiraTicketId, userData?.customerIdApi]);

  const displayConfirmationAndBook = (): void | Promise<void> => {
    const contractEndDate = apartmentData?.rentalDetailsList?.find(
      (details) => details.contractEndDate,
    )?.contractEndDate;

    if (
      eventType === 'MoveOutInspection' ||
      !contractEndDate ||
      !value?.start
    ) {
      return bookDate();
    }

    const weeksBetweenDates = differenceInWeeks(
      new Date(contractEndDate),
      value.start,
    );

    if (weeksBetweenDates < 1) {
      return bookDate();
    }

    return setMoveOutConfirmationModalOpen(true);
  };

  const bookDate = async (): Promise<void> => {
    const meta = getBookingWidgetMetaData({
      apartmentData,
      jiraProjectId,
      jiraTicketId,
      jiraIssueTypeId,
    });

    try {
      setFetching(true);

      if (
        !value?.start ||
        !value?.end ||
        !userData?.customerIdApi ||
        !bookableResource
      ) {
        throw new Error('Missing fields on booking date');
      }

      const bookedStartDate = formatISO(value.start);
      const bookedEndDate = formatISO(value.end);
      const description = (() => {
        const firstNameLabel = t(TranslationLabels.profileFormFirstName);
        const lastNameLabel = t(TranslationLabels.profileFormLastName);
        const emailLabel = t(TranslationLabels.profileFormEmail);
        const mobileNoLabel = t(TranslationLabels.profileFormMobilePhone);
        return `${firstNameLabel}: ${userData?.firstName}\n${lastNameLabel}: ${userData?.lastName}\n${emailLabel}: ${userData?.email}\n${mobileNoLabel}: ${userData?.mobile}\n`;
      })();
      const what = t(getBookingTitleKey(eventType));

      if (mode === BookingMode.RESCHEDULING) {
        await cancelBooking();
      }

      const payload: CreateBookingPayload = {
        start: bookedStartDate,
        end: bookedEndDate,
        description,
        customer: {
          customerId: userData?.customerIdApi,
          email: userData?.email,
          name: userData?.firstName,
          timezone: 'Europe/Oslo',
        },
        project_id: projectId,
        resource_id: bookableResource,
        graph: 'instant',
        what,
        where: address || 'TBD',
        meta,
      };

      await api.createBooking(country, payload);

      await reinitialize();

      enqueueSnackbar(t(TranslationLabels.calendarBookedDateSuccess), {
        variant: 'success',
      });
    } catch (e) {
      Sentry.captureException(e);
      enqueueSnackbar(t(getBookingOperationErrorLabelKey(e?.data?.message)), {
        variant: 'error',
      });
    } finally {
      setFetching(false);
    }
  };

  const suggestDate = async (): Promise<void> => {
    try {
      setFetching(true);

      if (!value?.start || !userData?.customerIdApi) {
        throw new Error('Missing fields on suggesting date');
      }

      const suggestedStartDate = formatISO(value.start);

      if (mode === BookingMode.RESCHEDULING) {
        await cancelBooking();
      }

      const payload: SuggestBookingPayload = {
        date: suggestedStartDate,
        customer: {
          customerId: userData?.customerIdApi,
        },
        meta: {
          jira_ticket_id: ticketId,
        },
      };
      await api.suggestBooking(country, payload);

      await reinitialize();
    } catch (e) {
      Sentry.captureException(e);
      enqueueSnackbar(t(getBookingOperationErrorLabelKey(e?.data?.message)), {
        variant: 'error',
      });
    } finally {
      setFetching(false);
    }
  };

  const toggleModal = useCallback(
    (openState: boolean) => {
      updateFlowStep(eventType, { isCalendarOpened: openState });
    },
    [eventType, updateFlowStep],
  );

  return (
    <>
      <Box className={classes.dateArea}>
        <TextField
          className={classes.inputControl}
          color="primary"
          disabled={isTextFieldDisabled}
          InputLabelProps={{ shrink: true }}
          InputProps={{ classes: { root: classes.input } }}
          label={t(getCalendarInputLabelKey(eventType))}
          onClick={() => {
            if (isTextFieldDisabled) {
              return;
            }

            toggleModal(true);
          }}
          value={formattedSelectedDate}
          variant="outlined"
        />
        <Button
          className={classes.button}
          color="primary"
          disabled={isSubmitButtonDisabled}
          variant="contained"
          onClick={isSuggestingDate ? suggestDate : displayConfirmationAndBook}
        >
          <strong>{t(getSubmitButtonLabelKey(mode, isSuggestingDate))}</strong>
          {isFetching ? (
            <CircularProgress
              classes={{
                root: classes.spinner,
              }}
              size={24}
              color="primary"
            />
          ) : (
            <Icon
              icon={CalendarIcon}
              width={24}
              height={24}
              className={classes.icon}
            />
          )}
        </Button>
        {withStatement && (
          <FormControlLabel
            control={
              <Checkbox
                checked={isStatementChecked}
                onChange={handleStatementAcceptanceChange}
                name="statement"
              />
            }
            label={t(
              TranslationLabels.terminationFlowStepMoveOutInspectionStatement,
            )}
          />
        )}
      </Box>
      <ConfirmationModal
        isOpen={isMoveOutConfirmationModalOpen}
        onClose={() => {
          setMoveOutConfirmationModalOpen(false);
        }}
        onProceed={() => {
          setMoveOutConfirmationModalOpen(false);
          bookDate();
        }}
      />
      <Modal
        open={isCalendarOpened || false}
        onClose={() => toggleModal(false)}
      >
        <Container maxWidth="md" className={classes.wrapper}>
          <Grid container className={classes.container} spacing={2}>
            <Grid item xs={12}>
              <Calendar
                config={{
                  eventType,
                  allDaySlot: false,
                  businessHours: {
                    daysOfWeek: [0, 1, 2, 3, 4, 5, 6], // Monday - Sunday
                    startTime:
                      workingHoursStart || DEFAULT_WORKING_HOUR_START_TIME,
                    endTime: workingHoursEnd || DEFAULT_WORKING_HOUR_END_TIME,
                  },
                  date: value?.start || validRange?.start || null,
                  events: isSuggestingDate ? eventsToSuggest : events,
                  firstDay: 1, // Monday,
                  height: '100%',
                  onDateChange: handleDateChange,
                  mobileView: 'timeGridDay',
                  view: 'timeGridWeek',
                  ...(workingHoursStart && {
                    slotMinTime: changeHour('remove', 1, workingHoursStart),
                  }),
                  ...(workingHoursEnd && {
                    slotMaxTime: changeHour('add', 1, workingHoursEnd),
                  }),
                  ...(validRange && { validRange }),
                }}
              />
            </Grid>
            {eventType === 'MoveOutInspection' && (
              <Grid item xs={12} className={classes.suggestDate}>
                <Grid container>
                  <Grid item xs="auto">
                    <Switch
                      checked={isSuggestingDate}
                      onChange={() => setSuggestingDate(!isSuggestingDate)}
                      inputProps={{
                        role: 'switch',
                      }}
                      className={classes.suggestDateSwitch}
                    />
                  </Grid>
                  <Grid item xs>
                    <Typography className={classes.suggestDateTitle}>
                      {t(
                        TranslationLabels.moveOutInspectionSuggestedDateSwitchTitle,
                      )}
                    </Typography>
                    <Typography className={classes.suggestDateDescription}>
                      {t(
                        TranslationLabels.moveOutInspectionSuggestedDateSwitchDescription,
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {mode === BookingMode.RESCHEDULING && (
              <Grid item xs={12}>
                <Typography variant="body2">
                  {t(TranslationLabels.bookingRescheduleDialogDescription)}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Container>
      </Modal>
    </>
  );
};
