import { wordpressClient } from '@shared/http';
import { sanityClient, SanityDocumentType } from '@shared/sanity';
import { Post } from './blog.type';

type FeaturedMediaItem = {
  mediaDetails: {
    sizes: {
      full: {
        sourceUrl: GenericTypes.URL;
      };
    };
  };
};

type PostResponse = {
  id: number;
  title: {
    rendered: string;
  };
  link: string;
  embedded?: {
    wpFeaturedmedia?: FeaturedMediaItem[];
  };
};

export const api = {
  getPosts: async (country: GenericTypes.Country): Promise<Post[]> => {
    const response = await wordpressClient(country).get<PostResponse[]>(
      'posts',
      {
        per_page: 3,
        order: 'desc',
        _embed: 'wp:featuredmedia',
      },
    );

    return response.map(({ id, title, link, embedded }) => ({
      id,
      title: title.rendered,
      link,
      imageUrl:
        embedded?.wpFeaturedmedia?.[0]?.mediaDetails?.sizes.full.sourceUrl,
    }));
  },
  getBlogUrl: async (
    country: GenericTypes.Country,
  ): Promise<GenericTypes.URL> => {
    const response = await sanityClient.fetch(
      `* [_type == $type && content.country == $country]{
      "url": content.blogUrl,
      }[0]`,
      {
        country,
        type: SanityDocumentType.SETTINGS,
      },
    );
    return response.url;
  },
};
