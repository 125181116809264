import { Container, Logo } from '@shared/components';
import React, { FC } from 'react';
import Hidden from '@material-ui/core/Hidden';
import { useAuth } from '../../../+auth';
import { HeaderSearchBox } from '../HeaderSearchBox/header-search-box.component';
import { LanguageSwitcher } from '../LanguageSwitcher/language-switcher.component';
import { Menu } from '../Menu/menu.component';
import { useStyles } from './header.styles';

export const Header: FC = () => {
  const classes = useStyles();
  const { isUserVerified, isHeimUserExist } = useAuth();

  return (
    <header className={classes.header}>
      <Container>
        <div className={classes.wrapper}>
          <Logo />
          {isHeimUserExist && isUserVerified && (
            <Hidden smDown>
              <HeaderSearchBox />
            </Hidden>
          )}
          <div className={classes.itemsWrapper}>
            <Menu />
            <LanguageSwitcher />
          </div>
        </div>
      </Container>
    </header>
  );
};
