import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { palette } from './pallete.const';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: palette.internationalOrange,
    },
  },
  breakpoints: {},
  typography: {
    allVariants: {
      color: palette.shark,
    },
    fontFamily: 'Helvetica',
    h1: {
      fontSize: '2rem',
      fontWeight: 300,
      lineHeight: '2.25rem',
      marginBottom: 8,
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 500,
      lineHeight: '1.75rem',
      marginBottom: 8,
    },
    h3: {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
      marginBottom: 8,
    },
    h4: {
      fontSize: '1rem',
      lineHeight: '1.25rem',
      marginBottom: 8,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 32,
        fontSize: 16,
        fontWeight: 700,
        lineHeight: '20px',
        padding: '8px 16px',
        textTransform: 'none',
        textAlign: 'center',
        '&:disabled': {
          backgroundColor: palette.white,
          border: `1px solid ${palette.silver}`,
          opacity: '30%',

          '& path': {
            stroke: 'rgba(0, 0, 0, 0.26)',
          },
        },
      },
      containedSizeLarge: {
        fontSize: 18,
        lineHeight: '24px',
        padding: '16px 32px',
      },
      sizeLarge: {
        fontSize: 18,
        lineHeight: '24px',
        padding: '16px 32px',
      },
      contained: {
        boxShadow: 'unset',

        '&:hover': {
          boxShadow: 'unset',
        },

        '&:disabled': {
          backgroundColor: palette.white,
          border: `1px solid ${palette.silver}`,
          opacity: '30%',

          '& path': {
            stroke: 'rgba(0, 0, 0, 0.26)',
          },
        },
      },
      outlined: {
        padding: '8px 16px',
      },
      outlinedPrimary: {
        backgroundColor: palette.white,
        border: 0,
        boxShadow: `inset 0px 0px 0px 2px ${palette.internationalOrange}`,
        '&:hover': {
          border: 0,
          boxShadow: `inset 0px 0px 0px 2px ${palette.internationalOrange}`,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 8,
      },
      notchedOutline: {
        borderColor: palette.silver,
      },
    },
    MuiFormLabel: {
      root: {
        color: palette.lighterGray,
      },
    },
    MuiInput: {
      input: {
        '&::placeholder': {
          color: palette.doveGray,
          opacity: 1,
        },

        fontSize: 16,
        lineHeight: '24px',
      },
    },
    MuiInputBase: {
      input: {
        '&::placeholder': {
          color: palette.doveGray,
          opacity: 1,
        },

        fontSize: 16,
        lineHeight: '24px',
      },
    },
    MuiInputAdornment: {
      root: {
        cursor: 'pointer',
      },
      positionEnd: {
        alignSelf: 'flex-end',
        marginRight: -8,
        marginBottom: 8,
      },
    },
    MuiIcon: {
      root: {
        height: 'auto',
        width: 'auto',
      },
    },
    MuiLink: {
      root: {
        color: palette.shark,
      },
    },
    MuiStepConnector: {
      alternativeLabel: {
        top: 28,
        left: 'calc(-50% + 40px)',
        right: 'calc(50% + 40px)',
      },
      line: {
        borderColor: palette.shark,
      },
    },
    MuiStepLabel: {
      label: {
        textTransform: 'uppercase',
        color: 'rgba(0, 0, 0, 0.2)',
        '&$alternativeLabel': {
          fontSize: '0.75rem',
          fontWeight: 600,
          marginTop: 8,
        },
      },
      root: {
        cursor: 'pointer',
      },
    },
    MuiStepIcon: {
      root: {
        border: '2px solid rgba(0, 0, 0, 0.2)',
        borderRadius: '50%',
        color: palette.white,
        height: 56,
        width: 56,
        '& $text': {
          fill: 'rgba(0, 0, 0, 0.2)',
        },
        '&$active': {
          border: 'none',
          color: palette.internationalOrange,
          '& $text': {
            fill: palette.white,
          },
        },
        '&$completed': {
          border: 'none',
          color: palette.greenHaze,
          '& $text': {
            fill: palette.white,
          },
        },
      },
    },
    MuiSwitch: {
      root: {
        border: `1px solid ${palette.silver}`,
        borderRadius: 16,
        height: 16,
        margin: '8px 8px 8px 11px', // MuiFormControlLabel has margin-left: -11px;
        padding: 0,
        width: 32,
      },
      colorSecondary: {
        '&$checked': {
          color: palette.internationalOrange,
        },
      },
      switchBase: {
        color: palette.silver,
        padding: 2,
        '&$checked': {
          transform: 'translateX(16px)',
        },
      },
      thumb: {
        boxShadow: 'none',
        height: 10,
        width: 10,
      },
      track: {
        display: 'none',
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: palette.internationalOrange,
        },
      },
    },
    MuiRadio: {
      colorSecondary: {
        '&$checked': {
          color: palette.internationalOrange,
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 14,
      },
    },
    MuiTypography: {
      root: {
        '& a': {
          color: palette.darkGray100,
          fontWeight: 'bold',
          textDecoration: 'underline',
        },
        '& a:hover': { color: palette.internationalOrange },
        '& a:active': {
          filter: 'brightness(0.8)',
          textDecoration: 'underline',
        },
        '& a:visited': { color: palette.lighterGray },
        '& a:visited:hover': { color: palette.darkGray100 },
        '& a[href^="tel:"]': {
          color: palette.black,
          fontWeight: 'normal',
        },
        '& a[href^="mailto:"]': {
          color: palette.black,
          fontWeight: 'normal',
        },
      },
    },
    MuiTabs: {
      root: {
        borderBottom: `1px solid ${palette.lightGray}`,
        fontSize: '14px',
        textTransform: 'uppercase',
        opacity: 1,
        color: palette.lighterGray,
      },
      indicator: {
        backgroundColor: palette.internationalOrange,
      },
    },
    MuiTab: {
      root: {
        opacity: 1,
        fontWeight: 'bold',
        '&:hover': {
          opacity: 1,
          color: palette.internationalOrange,
        },
        '&$selected': {
          opacity: 1,
          color: palette.internationalOrange,
          '&:hover': {
            opacity: 1,
            color: palette.internationalOrange,
          },
        },
      },
      textColorInherit: {
        opacity: 1,
      },
    },
  },
});
