import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '../../../../shared';
import { InvoiceStatus } from '../../../invoice.type';

export const useStyles = makeStyles((theme) =>
  createStyles({
    status: {
      borderRadius: theme.spacing(1),
      display: 'inline-block',
      padding: theme.spacing(1, 2),
      textTransform: 'uppercase',
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
    },
    [InvoiceStatus.Paid]: {
      backgroundColor: palette.green20,
      color: palette.darkGray100,
    },
    [InvoiceStatus.Unpaid]: {
      backgroundColor: palette.silver,
      color: palette.shark,
    },
    [InvoiceStatus.Overdue]: {
      backgroundColor: palette.monza,
      color: palette.white,
    },
    [InvoiceStatus.Unknown]: {
      backgroundColor: palette.blueRibbon,
      color: palette.white,
    },
  }),
);
