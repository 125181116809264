import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '../../consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    bar: {
      lineHeight: 0,
      display: 'flex',

      '&:before': {
        backgroundColor: palette.internationalOrange,
        content: `''`,
        height: 4,
        marginRight: theme.spacing(0.25),
        width: 182,
      },

      '& span': {
        backgroundColor: palette.internationalOrange,
        height: 4,
        width: 4,
      },

      '& span + span': {
        marginLeft: theme.spacing(0.25),
      },
    },
  }),
);
