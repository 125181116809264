import { TranslationLabels } from '@generated/translation-labels';
import Link from '@material-ui/core/Link';
import { AvailableFor, MenuElement } from '@shared/components';
import { UserRole } from '@shared/enums';
import { useApi, useCountry } from '@shared/hooks';
import React, { FC, useCallback } from 'react';
import { terminationRoutes } from '../../+termination';
import { api } from './termination-request-link.repository';

type Props = {
  onClick: () => void;
};

export const TerminationRequestLink: FC<Props> = (props) => {
  const { onClick } = props;
  const country = useCountry();
  const callback = useCallback(() => api.getExternalWebpageURL$(country), [
    country,
  ]);
  const [externalWebpageURL] = useApi(undefined, callback);

  return (
    <AvailableFor roles={[UserRole.TENANT]}>
      <MenuElement
        {...(externalWebpageURL
          ? {
              component: Link,
              href: externalWebpageURL,
              rel: 'noopener noreferrer',
              target: '_blank',
            }
          : {
              to: terminationRoutes.INDEX,
            })}
        onClick={onClick}
        shortDescriptionKey={TranslationLabels.terminationPageText}
        titleKey={TranslationLabels.terminationPageMenuTitle}
      />
    </AvailableFor>
  );
};
