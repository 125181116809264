import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      overflow: 'hidden',
      position: 'relative',
    },
  }),
);
