import { TranslationLabels } from '@generated/translation-labels';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useDialog } from '@shared/Dialog';
import { useTranslation } from '@shared/translations';
import React, { FC, useCallback, useMemo } from 'react';
import {
  getTranslationKeys,
  getUniqKeys,
} from './terminate-confirmation-button.helper';

type Props = {
  isDisabled: boolean;
  onClick: (isAgreed: boolean) => void;
  isAgreed?: boolean;
};

export const TerminateConfirmationButton: FC<Props> = (props) => {
  const { isAgreed = false, isDisabled, onClick } = props;
  const { Dialog, closeDialog, openDialog } = useDialog('action');
  const { t } = useTranslation();
  const translationKeys = useMemo(() => getTranslationKeys(isAgreed), [
    isAgreed,
  ]);
  const uniqKeys = useMemo(() => getUniqKeys(isAgreed), [isAgreed]);
  const handleClick = useCallback((): void => {
    onClick(isAgreed);
    closeDialog();
  }, [closeDialog, isAgreed, onClick]);
  const buttons = useMemo<JSX.Element[]>(
    () => [
      <Button
        key={uniqKeys.closeDialogButton}
        color="primary"
        disabled={isDisabled}
        onClick={closeDialog}
        variant="outlined"
      >
        {t(TranslationLabels.confirmationActionDialogCloseButtonLabel)}
      </Button>,
      <Button
        key={uniqKeys.confirmDialogButton}
        color="primary"
        disabled={isDisabled}
        onClick={handleClick}
        variant="contained"
      >
        {t(TranslationLabels.confirmationActionDialogSubmitButtonLabel)}
      </Button>,
    ],
    [
      closeDialog,
      handleClick,
      isDisabled,
      t,
      uniqKeys.closeDialogButton,
      uniqKeys.confirmDialogButton,
    ],
  );

  return (
    <>
      <Button
        color="primary"
        disabled={isDisabled}
        onClick={openDialog}
        variant={isAgreed ? 'contained' : 'outlined'}
      >
        {t(translationKeys.buttonLabelKey)}
      </Button>
      <Dialog buttons={buttons} titleKey={translationKeys.dialogTitleKey}>
        {!isAgreed && (
          <Typography>
            {t(TranslationLabels.disagreeTerminateRequestDialogText)}
          </Typography>
        )}
      </Dialog>
    </>
  );
};
TerminateConfirmationButton.defaultProps = {
  isAgreed: false,
};
