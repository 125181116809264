import { CustomerMidleLayerApi2 } from '@shared/models';
import React, { FC, useCallback, useReducer } from 'react';
import { InvoiceFilter } from '../../invoice.type';
import { api } from '../../invoices.respository';
import { InvoiceHead } from '../components';
import { Context } from './context';
import { initialState, reducer } from './reducer';

export const Provider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const fetchData$ = useCallback(
    async (customerId: CustomerMidleLayerApi2['customerIdApi']) => {
      const payload = await api.getInvoices(
        InvoiceFilter.PendingOrNew,
        customerId,
      );

      dispatch({ type: 'INVOICE_INIT', payload });
    },
    [],
  );
  const updateData = (payload: InvoiceHead): void => {
    dispatch({ type: 'INVOICE_INIT', payload });
  };

  return (
    <Context.Provider
      value={{
        ...state,
        getInvoiceData$: fetchData$,
        updateInvoiceData: updateData,
      }}
    >
      {children}
    </Context.Provider>
  );
};

Provider.displayName = 'InvoiceProvider';
