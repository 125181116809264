import { TranslationLabels } from '@generated/translation-labels';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import { fileToBase64 } from '@shared/helpers';
import clsx from 'clsx';
import { FormikHelpers } from 'formik';
import React, { FC, MouseEvent } from 'react';
import { ReactComponent as ImageFileUploadIcon } from '@heimstaden/icons-library/img/streamline-regular/images-photography/image-files/image-file-upload.svg';
import { FormikErrorValues } from '@shared/components/Fields/type';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from '@shared/translations';
import { AttachmentsThumb } from '@shared/FormV2/components/attachments-thumb.component';
import { Field, getFirstError } from '../../Form';
import { Icon } from '../../icons';
import { useStyles } from './attachment-field.styles';

export type AttachmentValue = {
  name: string;
  id?: number;
  url?: string;
  value?: string;
};

type Props<TFormValues> = Field<TFormValues> & {
  errorMessage?: FormikErrorValues;
  setFieldValue: FormikHelpers<TFormValues>['setFieldValue'];
  value: AttachmentValue[];
  disabled?: boolean;
};

export function AttachmentField<TFormValues>(
  props: Props<TFormValues>,
): ReturnType<FC<Props<TFormValues>>> {
  const classes = useStyles();
  const {
    accept,
    disabled,
    errorMessage,
    helperText,
    id,
    isMultiple,
    label,
    onBlur,
    onPreviewClick,
    readonly,
    setFieldValue,
    touched,
    value,
  } = props;
  const { t } = useTranslation();
  const error = touched && getFirstError(t, errorMessage);
  const onDrop = async (acceptedFiles: File[]): Promise<void> => {
    if (acceptedFiles.length === 0) {
      return;
    }

    const files = await Promise.all(
      acceptedFiles.map(async (file) => ({
        name: file.name,
        value: await fileToBase64(file),
      })),
    );

    setFieldValue(id, [...value, ...files]);
  };
  const handleDeleteFile = (event: MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    const { fileIndex } = event.currentTarget.dataset;

    if (!fileIndex) {
      return;
    }

    const index = Number(fileIndex);

    setFieldValue(id, [...value.slice(0, index), ...value.slice(index + 1)]);
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept,
    disabled,
    multiple: isMultiple,
    onDrop,
  });

  return (
    <FormControl
      error={!!error}
      classes={{
        root: classes.formControlRoot,
      }}
    >
      <label htmlFor={id}>
        {label && <Typography className={classes.label}>{t(label)}</Typography>}
        {helperText && !readonly && (
          <FormHelperText className={classes.helperText}>
            {t(helperText)}
          </FormHelperText>
        )}
        {error && <FormHelperText>{error}</FormHelperText>}
        {!readonly && (
          <>
            <div
              {...getRootProps()}
              className={clsx(classes.dropzone, {
                [classes.disabled]: disabled,
              })}
            >
              <Icon
                className={classes.icon}
                icon={ImageFileUploadIcon}
                height={26}
                width={26}
                strokeWidth={1.5}
              />
              {t(TranslationLabels.addFiles)}
            </div>
            <input {...getInputProps()} onBlur={onBlur} />
          </>
        )}
        <AttachmentsThumb
          {...(!readonly && { handleDelete: handleDeleteFile })}
          {...(onPreviewClick && { onClick: onPreviewClick })}
          disabled={disabled}
          files={value}
        />
      </label>
    </FormControl>
  );
}
