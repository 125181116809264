import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    listItem: {
      boxShadow: 'none',
      padding: theme.spacing(0.5, 0),
    },
  }),
);
