import { TranslationLabels } from '@generated/translation-labels';
import { PendingReason } from '../enums';
import { TerminationFlowStepEventType } from '../../types';

type Value = Partial<
  Record<TerminationFlowStepEventType, GenericTypes.TranslationLabel>
> & {
  defaultKey: GenericTypes.TranslationLabel;
};

export const pendingReasonToTranslationLabelKeyMap: Record<
  PendingReason,
  Value
> = {
  [PendingReason.MISSING_APPROVALS]: {
    defaultKey: TranslationLabels.terminationPendingReasonMissingApprovalsText,
  },
  [PendingReason.MISSING_BOOKING]: {
    defaultKey: TranslationLabels.terminationPendingReasonMissingBookingText,
  },
  [PendingReason.MISSING_BOOKING_DATE]: {
    defaultKey:
      TranslationLabels.terminationPendingReasonMissingBookingDateText,
  },
  [PendingReason.MISSING_BOOKABLE_RESOURCE]: {
    defaultKey:
      TranslationLabels.terminationPendingReasonMissingBookableResourceText,
    MoveOut:
      TranslationLabels.terminationMoveOutPendingReasonMissingBookableResourceText,
    MoveOutInspection:
      TranslationLabels.terminationMoveOutInspectionPendingReasonMissingBookableResourceText,
  },
};

export const getPendingReasonTranslationLabelKey = (
  eventType: TerminationFlowStepEventType,
  pendingReason: PendingReason,
): GenericTypes.TranslationLabel =>
  pendingReasonToTranslationLabelKeyMap[pendingReason]?.[eventType] ||
  pendingReasonToTranslationLabelKeyMap[pendingReason]?.defaultKey ||
  '';
