import { ObjectMainType } from '@shared/enums';
import { IRentalObjectMyHome } from './rental.interface';

export class RentalObject implements IRentalObjectMyHome {
  constructor() {
    this.id = 0;
    this.objectIdApi = '';
    this.objectComment = '';
    this.rentalDealIdApi = '';
    this.customerAccountNumber = '';
    this.landName = '';
    this.areaId = '';
    this.areaName = '';
    this.latitude = 0.0;
    this.longitude = 0.0;
    this.streetAddress = '';
    this.houseNumber = '';
    this.postalCode = '';
    this.city = '';
    this.addressInclFlatnumber = '';
    this.realEstateName = '';
    this.flatNumber = '';
    this.objectShortName = '';
    this.objectTypeText = '';
    this.objectMainType = ObjectMainType.Unknown;
    this.floor = 0;
  }

  objectMainType: ObjectMainType;

  objectTypeText: string;

  refDate?: string;

  id: number;

  flatNumber: string;

  addressInclFlatnumber: string;

  realEstateName: string;

  objectShortName: string;

  objectIdApi: string;

  objectComment: string;

  rentalDealIdApi: string;

  customerAccountNumber: string;

  floor: number;

  streetAddress: string;

  houseNumber: string;

  postalCode: string;

  city: string;

  landName: string;

  areaId: string;

  areaName: string;

  latitude: number;

  longitude: number;
}
