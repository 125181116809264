import { TranslationLabels } from '@generated/translation-labels';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import { getFirstError, Icon } from '@shared/components';
import { FieldLabel } from '@shared/components/FieldLabel/field-label.component';
import { AttachmentsThumb } from '@shared/FormV2/components/attachments-thumb.component';
import { AttachmentProps, AttachmentValue } from '@shared/FormV2/fields/types';
import { fileToBase64 } from '@shared/helpers';
import { useTranslation } from '@shared/translations';
import { ReactComponent as FileUploadIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/remove-add/add-circle.svg';
import clsx from 'clsx';
import { FieldProps as FormikFieldProps } from 'formik/dist/Field';
import React, { FC, MouseEvent } from 'react';
import { useDropzone } from 'react-dropzone';
import { useStyles } from './attachment-field.styles';

type Props<TFormValues> = FormikFieldProps<AttachmentValue, TFormValues> & {
  props: AttachmentProps;
};

export function AttachmentFieldV2<TFormValues>(
  props: Props<TFormValues>,
): ReturnType<FC<Props<TFormValues>>> {
  const classes = useStyles();
  const { meta, form, field, props: fieldProps } = props;
  const { touched, error, value } = meta;
  const { setFieldValue } = form;
  const { name, onBlur } = field;
  const {
    accept,
    disabled,
    helperTextKey,
    helperTextOptions,
    isMultiple,
    label,
    onPreviewClick$,
    readonly,
    required,
  } = fieldProps;
  const { t } = useTranslation();
  const errorMessage = touched && getFirstError(t, error);
  const onDrop = async (acceptedFiles: File[]): Promise<void> => {
    if (acceptedFiles.length === 0) {
      return;
    }

    const files = await Promise.all(
      acceptedFiles.map(async (file) => ({
        name: file.name,
        value: await fileToBase64(file),
      })),
    );

    setFieldValue(name, [...value, ...files]);
  };
  const handleDeleteFile = (event: MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    const { fileIndex } = event.currentTarget.dataset;

    if (!fileIndex) {
      return;
    }

    const index = Number(fileIndex);

    setFieldValue(name, [...value.slice(0, index), ...value.slice(index + 1)]);
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept,
    disabled,
    maxSize: 100000000,
    multiple: isMultiple,
    onDrop,
  });

  return (
    <FormControl
      component="fieldset"
      disabled={disabled}
      error={Boolean(errorMessage)}
      fullWidth
      required={required && !readonly}
    >
      {!readonly && <FieldLabel label={label} />}
      {helperTextKey && !readonly && (
        <FormHelperText className={classes.helperText}>
          {t(helperTextKey, helperTextOptions)}
        </FormHelperText>
      )}
      <AttachmentsThumb
        {...(!readonly && { handleDelete: handleDeleteFile })}
        {...(onPreviewClick$ && { onClick: onPreviewClick$ })}
        disabled={disabled}
        files={value}
      />
      {!readonly && (
        <>
          <div
            {...getRootProps()}
            className={clsx(classes.dropzone, {
              [classes.disabled]: disabled,
            })}
          >
            <Icon
              className={classes.icon}
              icon={FileUploadIcon}
              height={25}
              width={25}
            />
            <Typography>{t(TranslationLabels.addFiles)}</Typography>
          </div>
          <input {...getInputProps()} onBlur={onBlur} />
          {errorMessage && (
            <FormHelperText error>{errorMessage}</FormHelperText>
          )}
        </>
      )}
    </FormControl>
  );
}
