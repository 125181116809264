import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      margin: theme.spacing(7),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(3),
      },
      [theme.breakpoints.down('xs')]: {
        margin: theme.spacing(0),
      },
    },
    [theme.breakpoints.down('sm')]: {
      imageGrid: {
        display: 'none',
      },
    },
  }),
);
