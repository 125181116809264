import { Action, State } from './type';
import { ServiceRequest } from '../../ticketing.type';

export const initialState: State = {
  details: null,
  statusesConfig: [],
  tickets: [],
  ticketsFetching: false,
};

export const reducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'TICKETS_FETCHING': {
      return {
        ...state,
        ticketsFetching: action.payload,
      };
    }
    case 'TICKET_LIST': {
      return {
        ...state,
        tickets: action.payload,
        ticketsFetching: false,
      };
    }
    case 'TICKET_DETAILS': {
      const { payload: details } = action;
      const tickets = state.tickets.map((ticket) => {
        // TODO: this is workaround for https://fredensborg.atlassian.net/browse/MYH-444, it should be refactored
        if (ticket.id === details.ticketId) {
          return {
            ...ticket,
            isAuthor: details.isAuthor,
            ...(details.isTerminationFlowEnabled && {
              isTerminationFlowEnabled: details.isTerminationFlowEnabled,
            }),
          } as ServiceRequest;
        }

        return ticket;
      });

      return {
        ...state,
        details,
        tickets,
      };
    }
    case 'TICKET_STATUSES_CONFIG': {
      return {
        ...state,
        statusesConfig: action.payload,
      };
    }
    default: {
      return { ...state };
    }
  }
};
