import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { FC, ReactNode } from 'react';
import { useTranslation } from '@shared/translations';
import { useStyles } from './intro.styles';

export type IntroProps = {
  descriptionKey: GenericTypes.TranslationLabel;
  titleKey: GenericTypes.TranslationLabel;
  children?: ReactNode;
  titleClassName?: string;
};

export const Intro: FC<IntroProps> = ({
  children,
  descriptionKey,
  titleKey,
  titleClassName,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid
      className={classes.container}
      container
      justify="space-between"
      spacing={2}
    >
      <Grid item xs={12} sm={children ? 5 : 12}>
        <Typography variant="h2" className={titleClassName}>
          {t(titleKey)}
        </Typography>
        <Typography variant="h3">{t(descriptionKey)}</Typography>
      </Grid>
      {children && (
        <Grid item xs={12} sm={6}>
          {children}
        </Grid>
      )}
    </Grid>
  );
};
Intro.propTypes = {
  children: PropTypes.node,
  descriptionKey: PropTypes.string.isRequired,
  titleKey: PropTypes.string.isRequired,
};
Intro.defaultProps = {
  children: undefined,
};
