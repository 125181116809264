import FormControl from '@material-ui/core/FormControl';
import { DatePicker } from '@material-ui/pickers/DatePicker/DatePicker';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Field, getFirstError, Icon } from '@shared/components';
import { FieldLabel } from '@shared/components/FieldLabel/field-label.component';
import { FieldReadonly } from '@shared/components/FieldReadonly/field-readonly.component';
import { useStyles } from '@shared/components/Fields/DatePickerField/date-picker-field.styles';
import { FormikErrorValues } from '@shared/components/Fields/type';
import { ReactComponent as CalendarIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/date-calendar/calendar.svg';
import { format } from 'date-fns';
import { FormikHelpers } from 'formik';
import React, { FC } from 'react';
import { useTranslation } from '@shared/translations';

export type DateValue = string;

type Props<TFormValues> = Field<TFormValues> & {
  value: DateValue;
  setFieldValue: FormikHelpers<TFormValues>['setFieldValue'];
  errorMessage?: FormikErrorValues;
  disabled?: boolean;
};
export function DatePickerField<TFormValues>(
  props: Props<TFormValues>,
): ReturnType<FC<Props<TFormValues>>> {
  const {
    className,
    disabled,
    errorMessage,
    id,
    label,
    maxDate,
    minDate,
    onBlur,
    readonly,
    required,
    setFieldValue,
    touched,
    value,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const error = touched && getFirstError(t, errorMessage);
  const handleChange = (date: MaterialUiPickersDate): void => {
    setFieldValue(id, date?.toISOString());
  };
  const date = value ? new Date(value) : null;
  const isError = Boolean(error);
  const dateFormat = 'd/MM/yyyy';
  const readonlyValue =
    date && dateFormat ? format(date, dateFormat) : date?.toLocaleDateString();

  return (
    <FormControl
      component="fieldset"
      disabled={disabled}
      error={isError}
      fullWidth
      required={required && !readonly}
    >
      <FieldLabel label={label} />
      {readonly ? (
        <FieldReadonly className={className} value={readonlyValue} />
      ) : (
        <DatePicker
          clearable={!required}
          disabled={disabled}
          error={isError}
          format={dateFormat}
          helperText={error}
          id={id}
          maxDate={maxDate}
          minDate={minDate}
          onBlur={onBlur}
          onChange={handleChange}
          value={date}
          InputProps={{
            classes: {
              input: classes.input,
            },
            endAdornment: (
              <Icon
                className={classes.icon}
                height={24}
                icon={CalendarIcon}
                width={24}
              />
            ),
          }}
        />
      )}
    </FormControl>
  );
}
