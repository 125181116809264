import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      color: palette.scorpion,
      fontWeight: theme.typography.fontWeightBold,
      width: '100%',
    },
    content: {
      color: palette.scorpion,
    },
    dialogActions: {
      justifyContent: 'center',
      paddingTop: theme.spacing(3),
    },
  }),
);
