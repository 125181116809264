import { sanityClient, SanityDocumentType } from '@shared/sanity';
import { ContactData } from './contact-info.type';

export const api = {
  getData: (): Promise<ContactData[]> =>
    sanityClient.fetch(
      `*[_type == $type]{
        _id,
        "address": content.address,
        "phone": content.phone,
        "email": content.email,
        "country": content.country
      }`,
      {
        type: SanityDocumentType.SETTINGS,
      },
    ),
};
