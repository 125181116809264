import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      border: `2px solid ${palette.internationalOrange}`,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(5),
    },
    titleWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    icon: {
      color: palette.internationalOrange,
      marginRight: theme.spacing(3),
      stroke: 'currentColor',
      flexShrink: 0,
    },
    title: {
      fontWeight: theme.typography.fontWeightBold,
    },
    divider: {
      height: '2px',
      margin: theme.spacing(4, 0),
    },
  }),
);
