import { TranslationLabels } from '@generated/translation-labels';
import React, { FC, useMemo } from 'react';
import Typography from '@material-ui/core/Typography';
import { useCountry } from '@shared/hooks';
import { Box, Carousel, ImageModalPreview } from '@shared/components';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from '@shared/translations';
import { useApartment } from '../../state';
import { useStyles } from './images.styles';
import { ImagePreview } from './image-preview.component';

export const Images: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const country = useCountry();
  const { data, images } = useApartment();
  const imagesList = useMemo(() => {
    if (!data) return [];
    return images[data.objectIdApi]?.map(({ url }) => url) || [];
  }, [data, images]);

  if (imagesList.length === 0) {
    return null;
  }

  return (
    <Box className={classes.container}>
      <Typography variant="h2" className={classes.title}>
        {country === 'dk'
          ? data?.rentalObjectMyHome?.realEstateName
          : t(TranslationLabels.apartmentImagesTitle)}
      </Typography>
      <Carousel
        length={imagesList.length}
        className={classes.carousel}
        modalChildren={({ activeItemIdx, className }) =>
          imagesList.map((url, idx) => (
            <ImageModalPreview
              key={url}
              url={url}
              isVisible={activeItemIdx === idx}
              className={className}
            />
          ))
        }
      >
        {({ activeItemIdx, LeftButton, RightButton, openModal }) => {
          return (
            <>
              <Grid
                container
                alignItems="stretch"
                justify="space-between"
                className={classes.carouselButtonsContainer}
              >
                {imagesList.length > 1 && (
                  <>
                    <Grid item>
                      <LeftButton />
                    </Grid>
                    <Grid item>
                      <RightButton />
                    </Grid>
                  </>
                )}
              </Grid>
              {imagesList.map((url, idx) => (
                <ImagePreview
                  key={url}
                  url={url}
                  onClick={openModal}
                  isVisible={idx === activeItemIdx}
                />
              ))}
            </>
          );
        }}
      </Carousel>
    </Box>
  );
};
