import { ReactComponent as Megaphone1Icon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/share/megaphone-1.svg';
import { ReactComponent as NetworkQuestion } from '@heimstaden/icons-library/img/streamline-regular/internet-networks-servers/worldwide-web/network-question.svg';
import { ReactComponent as RealEstateSearchHouseIcon } from '@heimstaden/icons-library/img/streamline-regular/real-estate/search/real-estate-search-house.svg';
import { ReactComponent as RealEstatePersonSearchHouseIcon } from '@heimstaden/icons-library/img/streamline-regular/real-estate/search/real-estate-search-house-1.svg';
import { ReactComponent as QuestionCircleIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/alerts/question-circle.svg';
import { ReactComponent as RealEstateActionHouseInformation } from '@heimstaden/icons-library/img/streamline-regular/real-estate/actions-houses/real-estate-action-house-information.svg';
import { ReactComponent as StudyOwl } from '@heimstaden/icons-library/img/streamline-regular/school-learning/studying-learning/study-owl.svg';

import { FC, SVGProps } from 'react';

export const icons: { [key: string]: FC<SVGProps<SVGSVGElement>> } = {
  // NO Kundo
  'aktuelt-na': Megaphone1Icon,
  'leie-bolig': RealEstateSearchHouseIcon,
  beboer: RealEstatePersonSearchHouseIcon,
  questions: QuestionCircleIcon,
  // DK Kundo
  'aktuelt-lige-nu': Megaphone1Icon,
  lejer: RealEstateActionHouseInformation,
  boligsogende: RealEstatePersonSearchHouseIcon,
  studiebolig: StudyOwl,
  ovrigt: QuestionCircleIcon,
  // common
  'in-english': NetworkQuestion,
};
