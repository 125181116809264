import * as Sentry from '@sentry/react';
import { Dispatch, SetStateAction, useState } from 'react';

export const useThrowError = <S = Error>(): Dispatch<
  SetStateAction<S | undefined>
> => {
  const [error, setError] = useState<S | undefined>();

  if (error) {
    Sentry.captureException(error);
    throw error;
  }

  return setError;
};
