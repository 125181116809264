import { TranslationLabels } from '@generated/translation-labels';
import { DataSourceEnum } from '@shared/enums';
import uniq from 'lodash-es/uniq';

export const PLACEHOLDER = '-';
export const DEFAULT_LANGUAGE = 'en';
export const SENTRY_LOCATION_TAG = 'location';
export const DEFAULT_COUNTRY = 'no';
export const SUPPORTED_COUNTRIES_DATASOURCE: {
  [key in DataSourceEnum]: GenericTypes.Country;
} = {
  [DataSourceEnum.HeimNorway]: 'no',
  [DataSourceEnum.Iceland]: 'is',
  [DataSourceEnum.MutipleCandidates]: DEFAULT_COUNTRY,
  [DataSourceEnum.NeLand3100]: 'nl',
  [DataSourceEnum.UnikDenmark]: 'dk',
  [DataSourceEnum.Poland]: 'pl',
  [DataSourceEnum.Germany]: 'de',
  [DataSourceEnum.Unknown]: DEFAULT_COUNTRY,
};
export const SUPPORTED_COUNTRIES: GenericTypes.Country[] = uniq(
  Object.values(SUPPORTED_COUNTRIES_DATASOURCE),
);
export const COUNTRY_TRANSLATION_LABELS: {
  [key in GenericTypes.Country]: GenericTypes.TranslationLabel;
} = {
  dk: TranslationLabels.denmarkLabel,
  no: TranslationLabels.norwayLabel,
  is: TranslationLabels.icelandLabel,
  nl: TranslationLabels.netherlandLabel,
  pl: TranslationLabels.polandLabel,
  de: TranslationLabels.germanyLabel,
};
export const SUPPORTED_LANGS: GenericTypes.Language[] = [
  DEFAULT_LANGUAGE,
  'no',
  'da',
  'is',
];
export const ADDITIONAL_LANGS: GenericTypes.AdditionalLanguage[] = ['nb', 'nn'];
export const SUPPORTED_LANGS_PER_COUNTRY: Record<
  GenericTypes.Country,
  typeof SUPPORTED_LANGS
> = {
  no: [DEFAULT_LANGUAGE, 'no'],
  dk: [DEFAULT_LANGUAGE, 'da'],
  is: [DEFAULT_LANGUAGE, 'is'],
  nl: [DEFAULT_LANGUAGE],
  pl: [DEFAULT_LANGUAGE],
  de: [DEFAULT_LANGUAGE],
};
export const LANGS_TRANSLATION_LABELS: {
  [key in GenericTypes.Language]: GenericTypes.TranslationLabel;
} = {
  en: TranslationLabels.englishLang,
  da: TranslationLabels.danishLang,
  no: TranslationLabels.norwegianLang,
  is: TranslationLabels.icelandicLabel,
  cimode: 'cimode',
};
