import { DayHeaderContentArg } from '@fullcalendar/react';
import Typography from '@material-ui/core/Typography';
import { format } from 'date-fns';
import React, { FC, useMemo } from 'react';
import { useStyles } from './header-cell.styles';

type Props = DayHeaderContentArg;

export const HeaderCell: FC<Props> = (props) => {
  const { date } = props;
  const classes = useStyles();
  const formattedDate = useMemo(() => format(date, 'EEEE, do'), [date]); // Wednesday, 20th

  return (
    <Typography className={classes.text}>
      <strong>{formattedDate}</strong>
    </Typography>
  );
};
