import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
    file: {
      alignItems: 'center',
      borderRadius: theme.spacing(1),
      display: 'inline-flex',
      padding: theme.spacing(1, 0, 1, 2),
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
      border: `1px solid ${palette.lightGray}`,
    },
    fileIcon: {
      marginRight: theme.spacing(1),
    },
    divider: {
      backgroundColor: palette.lightGray,
      display: 'block',
      height: 32,
      width: 1,
    },
    buttonText: {
      fontSize: theme.typography.pxToRem(16),
      margin: theme.spacing(0, 2),
    },
    button: {
      paddingLeft: 0,
      paddingRight: 0,
      width: '48px',
    },
    deleteIcon: {
      color: palette.lighterGray,
    },
  }),
);
