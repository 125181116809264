import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      padding: 0,
      marginRight: theme.spacing(1),
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  }),
);
