import React, { FC, MouseEvent } from 'react';
import { useStyles } from './image-preview.styles';

type Props = {
  url: GenericTypes.URL;
  onClick: (event: MouseEvent<HTMLDivElement>) => void;
  isVisible: boolean;
};

export const ImagePreview: FC<Props> = ({ url, onClick, isVisible }) => {
  const classes = useStyles();

  return (
    <div
      className={classes.image}
      style={{
        backgroundImage: `url(${url})`,
        opacity: isVisible ? 1 : 0,
      }}
      onClick={onClick}
      aria-hidden="true"
      role="button"
      tabIndex={0}
      aria-label="Open image preview"
    />
  );
};
