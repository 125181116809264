import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    text: {
      marginBottom: theme.spacing(3),
    },
    buttonsRows: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    detailsInfoBox: {
      marginBottom: theme.spacing(2),
    },
  }),
);
