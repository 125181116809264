import { Config, Topic } from './faq.type';
import { kundoClient } from './kundo.client';

export const api = {
  getTopics: (
    country: GenericTypes.Country,
    categoriesSlug: string[],
  ): Promise<Topic[]> =>
    kundoClient.get<Topic[]>(
      `/heimstaden-${country}/${categoriesSlug.join(',')}.json`,
    ),
  getConfig: (country: GenericTypes.Country): Promise<Config> =>
    kundoClient
      .get<Config[]>(`/properties/heimstaden-${country}.json?limit=1`)
      .then((configs) => configs[0]),
};
