import React, { FC, SVGProps } from 'react';
import { Icon } from '@shared/components';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from '@shared/translations';
import { useStyles } from './item.styles';

type Props = {
  icon: FC<SVGProps<SVGSVGElement>>;
  titleKey: GenericTypes.TranslationLabel;
};

export const Item: FC<Props> = ({ icon, titleKey, children }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <div className={classes.titleWrapper}>
        <Icon className={classes.icon} icon={icon} height={48} width={48} />
        <Typography variant="h2" className={classes.title}>
          {t(titleKey)}
        </Typography>
      </div>
      <div>{children}</div>
    </div>
  );
};

Item.displayName = 'Item';
