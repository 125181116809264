import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    appLogo: {
      height: 56,
      marginLeft: theme.spacing(-1.375),
    },
  }),
);
