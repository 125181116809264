import React, { FC, ReactNode } from 'react';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import { ReactComponent as Close } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/form-validation/close.svg';
import { Icon } from '@shared/components';
import { useStyles } from './preview-modal.styles';
import { ButtonProps } from '../Carousel/type';

type Props = {
  children: (props: { className: string }) => ReactNode;
  isOpen: boolean;
  onClose: () => void;
  LeftButton: FC<ButtonProps>;
  RightButton: FC<ButtonProps>;
};

export const PreviewModal: FC<Props> = ({
  children,
  isOpen,
  onClose,
  LeftButton,
  RightButton,
}) => {
  const classes = useStyles();

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpen}
        onClose={onClose}
        closeAfterTransition
      >
        <Fade in={isOpen}>
          <Box className={classes.wrapper}>
            <button
              type="button"
              className={classes.closeButton}
              onClick={onClose}
            >
              <Icon icon={Close} width={24} height={24} />
            </button>
            <Box className={classes.contentWrapper}>
              <LeftButton className={classes.arrowButton} />
              <Box className={classes.content}>
                {children({ className: classes.children })}
              </Box>
              <RightButton className={classes.arrowButton} />
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};
