import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minHeight: 64,
      '&$expanded': {
        minHeight: 64,
      },
    },
    content: {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
      fontWeight: theme.typography.fontWeightRegular,
    },
    expanded: {},
    expandIcon: {
      transform: 'rotate(0deg)',
      '&$expanded': {
        transform: 'rotate(90deg)',
      },
    },
  }),
);
