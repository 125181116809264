import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      maxWidth: 1440,
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10),
    },

    [theme.breakpoints.down('sm')]: {
      container: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
    },
  }),
);
