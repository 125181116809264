import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      alignItems: 'center',
      display: 'flex',
      flex: '0 1 auto',
      flexFlow: 'row nowrap',
      justifyContent: 'flex-start',
    },
    label: {
      marginLeft: theme.spacing(1),
      textAlign: 'left',
      textDecoration: 'underline',
    },
  }),
);
