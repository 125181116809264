type LocalStorageKey = 'COUNTRY_CODE';

class LocalStorageService {
  /**
   * @return {GenericTypes.Country}
   */
  public getCountryCode = (): GenericTypes.Country =>
    this.getItem('COUNTRY_CODE') as GenericTypes.Country;

  /**
   * @param {GenericTypes.Country} country
   */
  public setCountryCode = (country: GenericTypes.Country): void => {
    this.setItem('COUNTRY_CODE', country);
  };

  public removeCountryCode = (): void => {
    this.removeItem('COUNTRY_CODE');
  };

  /**
   * @param {LocalStorageKey} item
   */
  public removeItem = (item: LocalStorageKey): void => {
    localStorage.removeItem(item);
  };

  /**
   * @param {LocalStorageKey} key
   * @return {string}
   */
  private getItem = (key: LocalStorageKey): string =>
    localStorage.getItem(key) || '';

  /**
   * @param {LocalStorageKey} key
   * @param {string} value
   */
  private setItem = (key: LocalStorageKey, value: string): void => {
    localStorage.setItem(key, value);
  };
}

export const localStorageService = new LocalStorageService();
