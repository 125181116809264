import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette, theme } from '@shared/consts';

interface Props {
  isRead: boolean;
}

export const useStyles = makeStyles<typeof theme, Props>((theme) =>
  createStyles({
    listItem: {
      // Uncomment this in case we want to use different colors for read/unread
      // backgroundColor: (props) => props.isRead ? palette.white : palette.athensGray,
      backgroundColor: palette.white,
      cursor: 'pointer',
    },
    menuElement: {
      alignItems: 'center',
      display: 'flex',
      letterSpacing: -0.2,
      padding: theme.spacing(1.5, 4),
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
    menuTitle: {
      marginBottom: 0,
    },
    iconContainer: {
      position: 'relative',
    },
    leftIcon: {
      height: 32,
      width: 32,
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(4),
      minWidth: 32,
    },
    isAwaitingActionIcon: {
      position: 'absolute',
      left: 20,
      top: 20,
      '& path': {
        fill: palette.internationalOrange,
      },
    },
    title: {
      wordBreak: 'break-word',
    },
    content: {
      flex: 1,
    },
    icon: {
      backgroundColor: palette.athensGray,
      color: palette.white,
      height: 48,
      width: 48,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: theme.spacing(1),
    },
    date: { fontSize: 12, marginTop: 2 },
    [theme.breakpoints.only('xs')]: {
      leftIcon: {
        display: 'none',
      },
    },
  }),
);
