import { TranslationLabels } from '@generated/translation-labels';
import React, { FC } from 'react';
import { useTranslation } from '@shared/translations';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './details-title.styles';

type Props = {
  ticketId: string;
};

export const DetailsTitle: FC<Props> = (props) => {
  const { ticketId } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid item>
      <Typography className={classes.title} display="inline" variant="h2">
        <strong>{t(TranslationLabels.Termination)}:&nbsp;</strong>
      </Typography>
      <Typography
        className={classes.title}
        color="primary"
        display="inline"
        variant="h2"
      >
        <strong>{ticketId}</strong>
      </Typography>
    </Grid>
  );
};
