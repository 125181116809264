import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    helpfulLinks: {
      display: 'flex',
    },
    helpfulLink: {
      alignItems: 'center',
      display: 'flex',
      marginRight: theme.spacing(4),

      '&:last-child': {
        marginRight: 0,
      },
    },
    helpfulLinkIcon: {
      height: 24,
      marginLeft: theme.spacing(1),
      width: 24,
    },
  }),
);
