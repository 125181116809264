import { TranslationLabels } from '@generated/translation-labels';
import * as Yup from 'yup';
import 'yup-phone';
import { InputValue, AttachmentValue } from '@shared/components/Fields';
import { FieldConfig, FormProps } from '@shared/FormV2';
import { ICustomerMidleLayerApi2, ICustomerRentalObject } from '@shared/models';
import { ServiceRequestVisibilityEnum } from '@shared/enums';
import { api } from '../../../../+ticketing/ticketing.repository';
import { COUNTRY_TICKETING_MODEL } from '../../../../+ticketing/ticketing.const';
import { ticketingClient } from '../../../../+ticketingV2/ticketing.client';

export type Values = {
  firstName: InputValue;
  lastName: InputValue;
  mobile: InputValue;
  email: InputValue;
  attachments: AttachmentValue[];
};

export const getFields = (
  user: ICustomerMidleLayerApi2 | null,
  readonly = false,
): FieldConfig<Values>[] => {
  return [
    {
      componentType: 'input',
      field: {
        name: 'firstName',
        initialValue: user?.firstName || '',
        validationSchema: Yup.string().required(
          TranslationLabels.formErrorsRequired,
        ),
      },
      props: {
        label: TranslationLabels.profileFormFirstName,
        readonly,
        required: true,
        type: 'text',
      },
    },
    {
      componentType: 'input',
      field: {
        name: 'lastName',
        initialValue: user?.lastName || '',
        validationSchema: Yup.string().required(
          TranslationLabels.formErrorsRequired,
        ),
      },
      props: {
        label: TranslationLabels.profileFormLastName,
        readonly,
        required: true,
        type: 'text',
      },
    },
    {
      componentType: 'input',
      field: {
        name: 'mobile',
        initialValue: user?.mobile || '',
        validationSchema: Yup.string()
          .phone('IN', false, TranslationLabels.formErrorsPhone)
          .required(TranslationLabels.formErrorsRequired),
      },
      props: {
        helperTextKey: TranslationLabels.profileFormMobilePhoneHelperText,
        label: TranslationLabels.profileFormMobilePhone,
        readonly,
        required: true,
        type: 'text',
      },
    },
    {
      componentType: 'input',
      field: {
        name: 'email',
        initialValue: user?.email || '',
        validationSchema: Yup.string()
          .email(TranslationLabels.formErrorsEmail)
          .required(TranslationLabels.formErrorsRequired),
      },
      props: {
        label: TranslationLabels.profileFormEmail,
        readonly,
        required: true,
        type: 'text',
      },
    },
    {
      componentType: 'attachment',
      field: {
        name: 'attachments',
        initialValue: [],
        validationSchema: Yup.array().when(['firstName', 'lastName'], {
          is: (firstName: string, lastName: string) => {
            if (!user) {
              return true;
            }

            const {
              firstName: profileFirstName,
              lastName: profileLastName,
            } = user;
            return (
              firstName !== profileFirstName || lastName !== profileLastName
            );
          },
          then: Yup.array()
            .of(
              Yup.object().shape({
                name: Yup.string().required(),
                value: Yup.string().optional(),
              }),
            )
            .min(1, TranslationLabels.formErrorsRequired),
          otherwise: Yup.array().nullable(),
        }),
      },
      props: {
        accept: 'image/*,.pdf',
        isMultiple: true,
        label: TranslationLabels.profileFormCopyOfId,
        onPreviewClick$: api.getAttachment,
        readonly,
      },
    },
  ];
};

export const getConfig = (
  country: GenericTypes.Country,
  apartment: ICustomerRentalObject | null,
  user: ICustomerMidleLayerApi2 | null,
  summaryLabel: GenericTypes.TranslationLabel,
): FormProps<Values>['config'] => ({
  onSubmit: (values) => {
    const { jiraFields, jiraItems, requestType } = COUNTRY_TICKETING_MODEL[
      country
    ];
    const { firstName, lastName, mobile, email, attachments } = values;

    const value = {
      [jiraFields.SUMMARY]: summaryLabel,
      [jiraFields.INVOICE_CUSTOMER_TYPE]:
        jiraItems.INVOICE_CUSTOMER_ACCOUNT_CONTRACT_AND_TENANCY,
      [jiraFields.INVOICE_CUSTOMER_ITEM]:
        jiraItems.INVOICE_CUSTOMER_ACCOUNT_CONTRACT_AND_TENANCY_CHANGE_INFORMATION,
      [jiraFields.INVOICE_CUSTOMER_NEW_FIRST_NAME]: firstName,
      [jiraFields.INVOICE_CUSTOMER_NEW_LAST_NAME]: lastName,
      [jiraFields.INVOICE_CUSTOMER_NEW_MOBILE]: mobile,
      [jiraFields.INVOICE_CUSTOMER_NEW_EMAIL]: email,
      [jiraFields.ATTACHMENT]: attachments,
      [jiraFields.VISIBILITY]: ServiceRequestVisibilityEnum.PRIVATE,
    };

    return ticketingClient.createRequest$(
      country,
      value,
      apartment,
      requestType.INVOICE_CUSTOMER_ACCOUNT,
      user,
    );
  },
});
