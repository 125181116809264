import { TranslationLabels } from '@generated/translation-labels';
import {
  AttachmentValue,
  InputValue,
  SelectValue,
} from '@shared/FormV2/fields';
import { FieldConfig, FormProps } from '@shared/FormV2/types';
import omit from 'lodash-es/omit';
import {
  IContractRole,
  ICustomerMidleLayerApi2,
  ICustomerRentalObject,
} from '@shared/models';
import * as Yup from 'yup';
import { FormOptions } from './request.type';
import { COUNTRY_TICKETING_MODEL } from '../../../../+ticketing/ticketing.const';
import { ticketingClient } from '../../../../+ticketingV2/ticketing.client';

export type Values = Record<string, AttachmentValue | InputValue | SelectValue>;

export const getField = (
  fields: FieldConfig<Values>[],
  fieldName: string,
): FieldConfig<Values> | undefined => {
  return fields.find((fieldConfig) => fieldConfig.field.name === fieldName);
};

export const getFields = (
  country: GenericTypes.Country,
  summaryInitialValue: string,
  { reasons, newApartment }: FormOptions,
  email?: string,
  phone?: string,
): FieldConfig<Values>[] => {
  const { jiraFields, jiraItems } = COUNTRY_TICKETING_MODEL[country];
  return [
    {
      componentType: 'input',
      field: {
        name: jiraFields.SUMMARY,
        initialValue: summaryInitialValue,
      },
      props: {
        type: 'text',
        label: TranslationLabels.ticketingTerminationRequestFormTitle,
        readonly: true,
      },
    },
    ...(reasons?.length
      ? [
          {
            componentType: 'select' as const,
            field: {
              name: jiraFields.NOTICE,
              initialValue: '',
            },
            props: {
              options: reasons,
              label: TranslationLabels.ticketingTerminationRequestNotice,
              required: true,
            },
          },
        ]
      : []),
    ...(newApartment?.length
      ? [
          {
            componentType: 'select' as const,
            field: {
              name: jiraFields.NEW_APARTMENT,
              initialValue: '',
            },
            props: {
              options: newApartment,
              label: TranslationLabels.ticketingTerminationRequestNewApartment,
              required: true,
            },
          },
        ]
      : []),
    {
      componentType: 'input',
      field: {
        name: jiraFields.ALTERNATIVE_CONTACT_EMAIL,
        initialValue: email || '',
        isHidden: (values) =>
          values[jiraFields.NEW_APARTMENT] !== jiraItems.NEW_APARTMENT_YES,
        validationSchema: Yup.string().when(jiraFields.NEW_APARTMENT, {
          is: (newApartmentAnswer: number) =>
            newApartmentAnswer === jiraItems.NEW_APARTMENT_YES,
          then: Yup.string()
            .required(TranslationLabels.formErrorsRequired)
            .email(TranslationLabels.formErrorsEmail),
          otherwise: Yup.string().nullable(),
        }),
      },
      props: {
        label: TranslationLabels.terminationRequestAlternativeEmail,
        type: 'email',
        required: true,
      },
    },
    {
      componentType: 'input',
      field: {
        name: jiraFields.ALTERNATIVE_CONTACT_PHONE,
        initialValue: phone || '',
        isHidden: (values) =>
          values[jiraFields.NEW_APARTMENT] !== jiraItems.NEW_APARTMENT_YES,
        validationSchema: Yup.string().when(jiraFields.NEW_APARTMENT, {
          is: (newApartmentAnswer: number) =>
            newApartmentAnswer === jiraItems.NEW_APARTMENT_YES,
          then: Yup.string()
            .required(TranslationLabels.formErrorsRequired)
            .phone('IN', false, TranslationLabels.formErrorsPhone),
          otherwise: Yup.string().nullable(),
        }),
      },
      props: {
        label: TranslationLabels.terminationRequestAlternativePhone,
        type: 'text',
        required: true,
      },
    },
    {
      componentType: 'input',
      field: {
        name: jiraFields.POTENTIAL_IMPROVEMENT,
        initialValue: '',
        ...(jiraFields.NOTICE && {
          validationSchema: Yup.string().when(jiraFields.NOTICE, {
            is: (notice: number) => notice === jiraItems.NOTICE_OTHER,
            then: Yup.string().required(TranslationLabels.formErrorsRequired),
            otherwise: Yup.string().nullable(),
          }),
        }),
      },
      props: {
        type: 'textarea',
        label: TranslationLabels.ticketingTerminationRequestSuggestion,
      },
    },
    {
      componentType: 'attachment',
      field: {
        name: jiraFields.ATTACHMENT,
        initialValue: [],
      },
      props: {
        accept: ['.png', '.jpg', '.jpeg', '.pdf'],
        isMultiple: true,
        label: TranslationLabels.formFieldsAttachmentFileLabel,
        onPreviewClick$: ticketingClient.getAttachment$,
        readonly: true,
      },
    },
  ];
};

const omitValuesFromHiddenFields = (
  values: Values,
  jiraFields: Record<string, string>,
  jiraItems: Record<string, number>,
): Values => {
  if (values[jiraFields.NEW_APARTMENT] !== jiraItems.NEW_APARTMENT_YES) {
    return omit(values, [
      jiraFields.ALTERNATIVE_CONTACT_EMAIL,
      jiraFields.ALTERNATIVE_CONTACT_PHONE,
    ]);
  }
  return values;
};

export const getConfig = (
  country: GenericTypes.Country,
  apartment: ICustomerRentalObject | null,
  user: ICustomerMidleLayerApi2 | null,
  residents: IContractRole[],
  tenants: IContractRole[],
  requestTypeId: number | undefined,
  contractEndDate: GenericTypes.DateISO | undefined,
): FormProps<Values>['config'] => ({
  onSubmit: (values) => {
    const { jiraFields, jiraItems } = COUNTRY_TICKETING_MODEL[country];
    const arePeopleLiving = Boolean(residents?.length || tenants?.length);
    const newValues = {
      ...omitValuesFromHiddenFields(values, jiraFields, jiraItems),
      ...(jiraFields.PEOPLE_LIVING && {
        [jiraFields.PEOPLE_LIVING]: arePeopleLiving
          ? jiraItems.PEOPLE_LEAVING_YES
          : jiraItems.PEOPLE_LEAVING_NO,
      }),
      ...(jiraFields.TENANTS_IN_APARTMENT && {
        [jiraFields.TENANTS_IN_APARTMENT]:
          tenants?.map(({ customerIdApi }) => +customerIdApi) || [],
      }),
      [jiraFields.CONTRACT_END_DATE]: contractEndDate,
    };

    return ticketingClient.createRequest$(
      country,
      newValues,
      apartment,
      requestTypeId,
      user,
    );
  },
});
