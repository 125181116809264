import React, { FC, SVGProps, MouseEventHandler } from 'react';
import { Icon } from '@shared/components';
import clsx from 'clsx';
import { useStyles } from './button.styles';

type Props = {
  onMouseDown: MouseEventHandler<HTMLElement>;
  isActive: boolean;
  icon: FC<SVGProps<SVGSVGElement>>;
  className?: string;
};

export const Button: FC<Props> = ({
  onMouseDown,
  isActive,
  icon,
  className,
}) => {
  const classes = useStyles();

  return (
    <button
      type="button"
      onMouseDown={onMouseDown}
      className={clsx(
        classes.button,
        {
          active: isActive,
        },
        className,
      )}
    >
      <Icon width={16} height={16} icon={icon} className={classes.icon} />
    </button>
  );
};
