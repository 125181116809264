import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    postImage: {
      width: '100%',
      height: '220px',
      objectFit: 'cover',
      borderRadius: theme.spacing(1),
    },
    postText: {
      margin: theme.spacing(2, 0),
    },
    bottom: {
      textAlign: 'right',
      marginTop: theme.spacing(4),
    },
    bottomText: {
      marginBottom: theme.spacing(2),
    },
    imagePlaceholder: {
      width: '100%',
      height: '220px',
      borderRadius: theme.spacing(1),
    },
  }),
);
