import {
  Layout,
  LayoutFallback,
  LayoutChildrenFallback,
} from '@shared/components';
import { AuthGuard, authRoutes, GuestGuard } from '../../+auth';
import { invoiceRoutes } from '../../+invoice';
import { profileRoutes } from '../../+profile';
import { apartmentRoutes } from '../../+apartment';
import { terminationRoutes } from '../../+termination';
import { ticketingRoutes } from '../../+ticketing';
import { notificationRoutes } from '../../+notification';
import { TICKETING_PATHS } from '../../+ticketingV2';
import { Routes } from './routes.type';
import { safeLazyLoad } from './safeLazyLoad';

export const NOT_FOUND_ERROR_PATH = '/not-found-404';

export const routes: Routes = [
  {
    exact: true,
    guard: GuestGuard,
    path: `${authRoutes.LOGIN}`,
    component: safeLazyLoad(
      () => import('../../+auth/SignIn/sign-in.component'),
    ),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: `${authRoutes.ERROR}`,
    component: safeLazyLoad(
      () => import('../../+auth/components/Error/error.component'),
    ),
  },
  {
    exact: true,
    path: `${authRoutes.SIMULATE}/:guidLogin`,
    component: safeLazyLoad(
      () => import('../../+auth/SimulateUser/simulate-user.component'),
    ),
  },
  {
    path: '*',
    guard: AuthGuard,
    layout: Layout,
    fallback: LayoutFallback,
    routes: [
      {
        exact: true,
        path: '/',
        component: safeLazyLoad(
          () => import('../../+dashboard/dashboard.component'),
        ),
        fallback: LayoutChildrenFallback,
      },
      {
        exact: true,
        path: profileRoutes.PROFILE,
        component: safeLazyLoad(
          () => import('../../+profile/profile.component'),
        ),
        fallback: LayoutChildrenFallback,
      },
      {
        exact: true,
        path: apartmentRoutes.LIST,
        component: safeLazyLoad(
          () => import('../../+apartment/List/list.component'),
        ),
        fallback: LayoutChildrenFallback,
      },
      {
        exact: true,
        path: `${apartmentRoutes.APARTMENT}`,
        component: safeLazyLoad(
          () => import('../../+apartment/apartment.component'),
        ),
        fallback: LayoutChildrenFallback,
      },
      {
        exact: true,
        path: `${apartmentRoutes.CONTRACT}`,
        component: safeLazyLoad(
          () => import('../../+apartment/contract-view.component'),
        ),
        fallback: LayoutChildrenFallback,
      },
      {
        exact: true,
        path: invoiceRoutes.LIST,
        component: safeLazyLoad(
          () => import('../../+invoice/List/list.component'),
        ),
        fallback: LayoutChildrenFallback,
      },
      {
        exact: true,
        path: invoiceRoutes.DETAILS,
        component: safeLazyLoad(
          () => import('../../+invoice/Details/details.component'),
        ),
        fallback: LayoutChildrenFallback,
      },
      {
        exact: true,
        path: `${invoiceRoutes.DETAILS}/:invoiceId`,
        component: safeLazyLoad(
          () => import('../../+invoice/Details/details.component'),
        ),
        fallback: LayoutChildrenFallback,
      },
      {
        exact: true,
        path: `${terminationRoutes.INDEX}/:ticketId?`,
        component: safeLazyLoad(
          () => import('../../+termination/termination.component'),
        ),
        fallback: LayoutChildrenFallback,
      },
      {
        exact: true,
        path: ticketingRoutes.LIST,
        component: safeLazyLoad(
          () => import('../../+ticketing/List/list.component'),
        ),
        fallback: LayoutChildrenFallback,
      },
      {
        exact: true,
        path: ticketingRoutes.DETAILS,
        component: safeLazyLoad(
          () => import('../../+ticketing/Details/details.component'),
        ),
        fallback: LayoutChildrenFallback,
      },
      {
        exact: true,
        path: `${ticketingRoutes.DETAILS}/:ticketId`,
        component: safeLazyLoad(
          () => import('../../+ticketing/Details/details.component'),
        ),
        fallback: LayoutChildrenFallback,
      },
      {
        exact: true,
        path: `${notificationRoutes.LIST}`,
        component: safeLazyLoad(
          () => import('../../+notification/List/list.component'),
        ),
        fallback: LayoutChildrenFallback,
      },
      {
        path: TICKETING_PATHS.INDEX,
        component: safeLazyLoad(
          () => import('../../+ticketingV2/ticketing.component'),
        ),
        fallback: LayoutChildrenFallback,
      },
      // '404 not found' page must be the last item
      {
        path: '*',
        component: safeLazyLoad(
          () =>
            import('../../shared/components/errors/not-found-error.component'),
        ),
        fallback: LayoutChildrenFallback,
      },
    ],
  },
];
