import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { camelizeKeys } from '../helpers';
import { handleError } from './http.helper';

export const createInstance = (
  config: AxiosRequestConfig,
  doCamelize = true,
): AxiosInstance => {
  const instance = axios.create(config);

  instance.interceptors.response.use(
    ({ data }: AxiosResponse) => (doCamelize ? camelizeKeys(data) : data),
    handleError,
  );
  instance.interceptors.request.use(
    (request: AxiosRequestConfig) => request,
    Promise.reject,
  );

  return instance;
};
