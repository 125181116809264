import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    label: {
      display: 'block',
      marginBottom: theme.spacing(1),
      color: palette.doveGray,
    },
  }),
);
