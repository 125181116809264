import { TranslationLabels } from '@generated/translation-labels';
import { useApi, useCountry } from '@shared/hooks';
import React, { FC, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useApartment } from '../../../+apartment';
import { useProfile } from '../../../+profile';
import { Content, RequestCreator } from '../../shared';
import { RouteParams } from '../service-request.type';
import { getConfig, getFields } from './form.model';
import { Values } from './form.type';
import { api } from './form.repository';
import { ReactComponent as AdditionalInfoImage } from '../../assets/additional-info.svg';
import { stepper } from '../stepper.config';

export const Form: FC = () => {
  const { areaId, failureId, itemId, roomId } = useParams<RouteParams>();
  const country = useCountry();
  const { data: apartmentData } = useApartment();
  const { data: profileData } = useProfile();
  const getRequestTypeId$ = useCallback(
    () => api.getRequestTypeId$(+areaId, country),
    [areaId, country],
  );
  const [requestTypeId, isFetching] = useApi(undefined, getRequestTypeId$);
  const config = useMemo(
    () =>
      getConfig(
        country,
        apartmentData,
        profileData,
        Number(itemId),
        Number(failureId),
        requestTypeId,
        Number(roomId),
      ),
    [
      apartmentData,
      country,
      failureId,
      itemId,
      profileData,
      requestTypeId,
      roomId,
    ],
  );
  const fields = useMemo(() => getFields(country, +itemId), [country, itemId]);
  const additionalParamsToCheck = useMemo(
    () => ({
      ...((failureId || itemId) && { subItemTypeId: failureId || itemId }),
      ...(roomId && { roomTypeId: roomId }),
    }),
    [failureId, itemId, roomId],
  );

  return (
    <Content
      introProps={{
        descriptionKey: TranslationLabels.ticketingGeneralText,
        titleKey: TranslationLabels.ticketingGeneralTitle,
        childrenRightColumn: <AdditionalInfoImage />,
      }}
      isFetching={isFetching}
      stepperConfig={stepper}
      type="columns"
    >
      <RequestCreator<Values>
        additionalParamsToCheck={additionalParamsToCheck}
        fallbackMessageKey={
          TranslationLabels.ticketingRequestFormFallbackMessage
        }
        formProps={{ config, fieldsConfig: fields }}
        requestTypeId={requestTypeId}
        fullWidth
      />
    </Content>
  );
};
