import { ICustomerMidleLayerApi2 } from '@shared/models';
import { AttachmentValue } from '@shared/components/Fields';
import {
  ElementField,
  ServiceRequestDetails,
} from '../../../../+ticketing/ticketing.type';
import { COUNTRY_TICKETING_MODEL } from '../../../../+ticketing/ticketing.const';
import { DetailsRequestValues } from './details.type';

export const getDetailsRequestModelInitialValue = (
  initialValueKey: keyof DetailsRequestValues,
  initialValues: ServiceRequestDetails | undefined,
  user: ICustomerMidleLayerApi2 | null | undefined,
  country: GenericTypes.Country,
): string | AttachmentValue[] => {
  const { jiraFields } = COUNTRY_TICKETING_MODEL[country];
  const getInitialValue = (
    jiraField: string,
    profileField: keyof Omit<DetailsRequestValues, 'attachments'>,
  ): string => {
    return (
      (initialValues?.[jiraField] as ElementField)?.value ||
      user?.[profileField] ||
      ''
    );
  };

  switch (initialValueKey) {
    case 'firstName':
      return getInitialValue(
        jiraFields.INVOICE_CUSTOMER_NEW_FIRST_NAME,
        'firstName',
      );
    case 'lastName':
      return getInitialValue(
        jiraFields.INVOICE_CUSTOMER_NEW_LAST_NAME,
        'lastName',
      );
    case 'mobile':
      return getInitialValue(jiraFields.INVOICE_CUSTOMER_NEW_MOBILE, 'mobile');
    case 'email':
      return getInitialValue(jiraFields.INVOICE_CUSTOMER_NEW_EMAIL, 'email');
    case 'attachments':
      return (initialValues?.[jiraFields.ATTACHMENT] ||
        []) as AttachmentValue[];
    default:
      return '';
  }
};
