import { TranslationLabels } from '@generated/translation-labels';

type TranslationKey = 'buttonLabelKey' | 'dialogTitleKey';

type UniqKey = 'closeDialogButton' | 'confirmDialogButton';

export const getTranslationKeys = (
  isAgreed: boolean,
): Record<TranslationKey, GenericTypes.TranslationLabel> => ({
  buttonLabelKey: isAgreed
    ? TranslationLabels.agreeOnTerminationRequestButtonLabel
    : TranslationLabels.disagreeOnTerminationRequestButtonLabel,
  dialogTitleKey: isAgreed
    ? TranslationLabels.terminateRequestDialogAgreeTitle
    : TranslationLabels.terminateRequestDialogDisagreeTitle,
});

export const getUniqKeys = (isAgreed: boolean): Record<UniqKey, string> => ({
  closeDialogButton: isAgreed
    ? 'closeButtonOfTheAgreeDialog'
    : 'closeButtonOfTheDisagreeDialog',
  confirmDialogButton: isAgreed
    ? 'confirmButtonOfTheAgreeDialog'
    : 'confirmButtonOfTheDisagreeDialog',
});
