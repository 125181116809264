import { useCallback, useContext, useState } from 'react';
import * as Sentry from '@sentry/react';
import { AttachmentMainType } from '@shared/enums';
import { HttpStatus } from '@shared/http/http.type';
import { useTranslation } from '@shared/translations';
import { TranslationLabels } from '@generated/translation-labels';
import { useSnackbar } from 'notistack';
import { Context, ContextValue } from './context';
import { api } from '../../apartment.respository';

export const useApartment = (): ContextValue => useContext(Context);

interface UseContractReturnType {
  fetchContract: (args: FetchContractArguments) => Promise<void>;
  isFetching: boolean;
  isContractDisabled: boolean;
}

interface FetchContractArguments {
  openInNewTab: boolean;
}

export const useContract = (): UseContractReturnType => {
  const { t } = useTranslation();
  const { data } = useApartment();
  const [isFetching, setFetching] = useState(false);
  const [isContractDisabled, setContractDisabled] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const fetchContract = useCallback(
    async ({ openInNewTab }: FetchContractArguments): Promise<void> => {
      try {
        if (data?.rentalObjectMyHome?.rentalDealIdApi) {
          setFetching(true);
          const fileUrl = await api.getContractFileUrl(
            data.rentalObjectMyHome.rentalDealIdApi,
          );
          if (openInNewTab) {
            window.open(fileUrl);
          } else {
            window.location.replace(fileUrl);
          }
        }
      } catch (error) {
        setContractDisabled(true);
        if (error.status === HttpStatus.GONE_ERROR) {
          enqueueSnackbar(
            t(TranslationLabels.contractUnavailableErrorMessage),
            {
              variant: 'error',
            },
          );
        } else {
          enqueueSnackbar(t(TranslationLabels.formGlobalErrorMessage), {
            variant: 'error',
          });
          Sentry.captureException(error);
        }
      } finally {
        setFetching(false);
      }
    },
    [data?.rentalObjectMyHome?.rentalDealIdApi, enqueueSnackbar, t],
  );

  return { fetchContract, isFetching, isContractDisabled };
};

interface UseContractAdditionsReturnType {
  fetchContractAdditions: (
    contractAdditionId: string | undefined,
  ) => Promise<void>;
  isFetching: { [key in string]: boolean };
}

export const useContractAdditions = (): UseContractAdditionsReturnType => {
  const { data } = useApartment();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isFetching, setFetching] = useState({});

  const rentalDealIdApi = data?.rentalObjectMyHome?.rentalDealIdApi;

  const fetchContractAdditions = useCallback(
    async (contractAdditionId: string | undefined): Promise<void> => {
      if (!rentalDealIdApi || !contractAdditionId) return;
      const attachmentMainType = AttachmentMainType.ContractRegulation;
      const attachmentSubType = 'CONTRACT-ATTACHMENT';
      try {
        setFetching((prevState) => ({
          ...prevState,
          [contractAdditionId]: true,
        }));
        const fileUrl = await api.getContractAdditionUrl(
          rentalDealIdApi,
          contractAdditionId,
          attachmentMainType,
          attachmentSubType,
        );
        window.open(fileUrl);
      } catch (error) {
        enqueueSnackbar(t(TranslationLabels.formGlobalErrorMessage), {
          variant: 'error',
        });
        Sentry.captureException(error);
      } finally {
        setFetching((prevState) => ({
          ...prevState,
          [contractAdditionId]: false,
        }));
      }
    },
    [rentalDealIdApi, enqueueSnackbar, t],
  );

  return { fetchContractAdditions, isFetching };
};
