import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/index';

export const useStyles = makeStyles((theme) =>
  createStyles({
    label: {
      color: palette.scorpion,
    },
    content: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      marginRight: theme.spacing(1),
      flexShrink: 0,
      color: palette.lighterGray,
    },
    container: {
      marginBottom: theme.spacing(2),
    },
  }),
);
