import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      marginBottom: theme.spacing(3),
    },
    button: {
      borderRadius: theme.spacing(0.5),
    },
    month: {
      marginBottom: 0,
    },
  }),
);
