import { User, UserAuth0 } from '../types';

export const adaptUserData = (userAuth0?: UserAuth0): User | undefined => {
  if (!userAuth0) {
    return undefined;
  }

  return {
    email: userAuth0.email,
    phone: userAuth0.phone_number,
    isUserVerified: Boolean(
      userAuth0.email_verified || userAuth0.phone_number_verified,
    ),
    isUserInvalid: Boolean(userAuth0['http://user.namespace/invalid_user']),
    country: userAuth0['http://user.namespace/country']?.toLowerCase() as
      | GenericTypes.Country
      | undefined,
  };
};
