import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette, theme } from '@shared/consts';

export const useStyles = makeStyles(() => {
  return createStyles({
    button: {
      border: `1px solid ${palette.lightGray}`,
      borderRadius: 4,
      background: palette.white,
      padding: theme.spacing(1),
      cursor: 'pointer',
      color: palette.darkGray100,
      transition: theme.transitions.create(['background', 'border', 'color'], {
        duration: theme.transitions.duration.standard,
        easing: 'linear',
      }),
      '&:hover': {
        borderColor: palette.orange60,
        backgroundColor: palette.orange10,
        color: palette.internationalOrange,
      },
      '&.active': {
        borderColor: palette.internationalOrange,
        backgroundColor: palette.internationalOrange,
        color: palette.white,
      },
    },
    icon: {
      display: 'block',
      '& path': {
        strokeWidth: 2,
      },
    },
  });
});
