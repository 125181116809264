import { sanityClient, SanityDocumentType } from '@shared/sanity';
import { PATHS } from '../shared/routing/paths.const';
import { Request } from './requests.type';

export const api = {
  getRequests$: async (country: GenericTypes.Country): Promise<Request[]> => {
    const query = `
      * [_type == $type && content.country == $country][0]{
        "requests": [
          content.ticketing.repairMaintenanceRequest{
            "descriptionKey": text->key,
            "iconURL": icon{asset->{url}}.asset.url,
            "key": "repairMaintenanceRequest",
            "redirectURL": "${PATHS.SERVICE_REQUEST_AREAS}",
            "titleKey": title->key,
          },
          content.ticketing.customerAccountRequest{
            "descriptionKey": text->key,
            "iconURL": icon{asset->{url}}.asset.url,
            "key": "customerAccountRequest",
            "redirectURL": "${PATHS.MY_ACCOUNT}",
            "titleKey": title->key,
          },
          content.ticketing.customerServiceRequest{
            "descriptionKey": text->key,
            "iconURL": icon{asset->{url}}.asset.url,
            "key": "customerServiceRequest",
            "redirectURL": "${PATHS.CUSTOMER_SERVICE}",
            "titleKey": title->key,
          },
          content.ticketing.immediateAssistanceRequest{
            "descriptionKey": text->key,
            "externalWebpageURL": externalWebpageUrl,
            "iconURL": icon{asset->{url}}.asset.url,
            "key": "immediateAssistanceRequest",
            "titleKey": title->key,
          },
        ]
      }
    `;

    const response = await sanityClient.fetch<{ requests: Request[] }>(query, {
      country,
      type: SanityDocumentType.SETTINGS,
    });

    return response.requests;
  },
};
