import { TranslationLabels } from '@generated/translation-labels';
import React, { FC, MouseEvent, SVGProps } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router';
import { Box, Container, Icon, ContactInfo } from '@shared/components';
import { useTranslation } from '@shared/translations';
import { useStyles } from './error-box.styles';

type Button = {
  text?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  href?: string;
};

type Props = {
  icon: FC<SVGProps<SVGSVGElement>>;
  title: string;
  text: string;
  button?: Button;
  hideButton?: boolean;
  secondaryButton?: Button;
};

export const ErrorBox: FC<Props> = (props) => {
  const { icon, text, title, button, hideButton, secondaryButton } = props;
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const defaultButtonOnClick = (): void => {
    history.goBack();
  };

  return (
    <Container>
      <Box className={classes.box}>
        <div className={classes.iconContainer}>
          <Icon className={classes.icon} icon={icon} />
        </div>
        <Typography variant="h2">{title}</Typography>
        <Typography className={classes.text}>{text}</Typography>
        <ContactInfo isDense isTitle noAddress />
        <div className={classes.buttons}>
          {secondaryButton && (
            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              onClick={secondaryButton.onClick}
              href={secondaryButton.href}
            >
              {secondaryButton.text}
            </Button>
          )}
          {!hideButton && (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={button?.onClick || defaultButtonOnClick}
              href={button?.href}
            >
              {button?.text || t(TranslationLabels.goBackButtonText)}
            </Button>
          )}
        </div>
      </Box>
    </Container>
  );
};
