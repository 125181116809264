import { TranslationLabels } from '@generated/translation-labels';
import {
  AttachmentValue,
  CheckboxesProps,
  CheckboxesValue,
  InputValue,
} from '@shared/FormV2/fields';
import { ICustomerMidleLayerApi2, ICustomerRentalObject } from '@shared/models';
import { ValueOf } from '@shared/types';
import { FieldConfig, FormProps } from '@shared/FormV2/types';
import { ServiceRequestVisibilityEnum } from '@shared/enums';
import { COUNTRY_TICKETING_MODEL } from '../../../+ticketing/ticketing.const';
import { ticketingClient } from '../../ticketing.client';
import { prepareValueOfComplaintCategory } from './issue-complaint-form.helper';

export type Values = Record<
  string,
  AttachmentValue | InputValue | CheckboxesValue
>;

export const getFields = (
  country: GenericTypes.Country,
): FieldConfig<Values>[] => {
  const { jiraFields, jiraItems } = COUNTRY_TICKETING_MODEL[country];
  const getComplaintOption = (
    complaint: ValueOf<typeof jiraItems>,
    label: GenericTypes.TranslationLabel,
  ): CheckboxesProps['options'] =>
    complaint
      ? [
          {
            label,
            name: complaint.toString(),
          },
        ]
      : [];

  return [
    {
      componentType: 'input',
      field: {
        name: jiraFields.SUMMARY,
        initialValue: '',
      },
      props: {
        type: 'text',
        label: TranslationLabels.ticketingCustomerAccountSummaryLabel,
        required: true,
      },
    },
    {
      componentType: 'checkboxes',
      field: {
        name: jiraFields.CUSTOMER_COMPLAINT,
        initialValue: '',
      },
      props: {
        label: TranslationLabels.ticketingCustomerAccountCategoryLabel,
        options: [
          ...getComplaintOption(
            jiraItems.NEIGHBOR_COMPLAINT,
            TranslationLabels.ticketingNeighborComplaintLabel,
          ),
          ...getComplaintOption(
            jiraItems.CUSTOMER_SERVICE_COMPLAINT,
            TranslationLabels.ticketingCustomerServiceComplaintLabel,
          ),
          ...getComplaintOption(
            jiraItems.SMOKE_OR_ODOR_COMPLAINT,
            TranslationLabels.ticketingSmokeOrOdorComplaintLabel,
          ),
          ...getComplaintOption(
            jiraItems.NOISE_FROM_CONSTRUCTION_WORK_COMPLAINT,
            TranslationLabels.ticketingNoiseFromConstructionComplaintLabel,
          ),
          ...getComplaintOption(
            jiraItems.GARBAGE_COMPLAINT,
            TranslationLabels.ticketingGarbageComplaintLabel,
          ),
          ...getComplaintOption(
            jiraItems.SUGGESTIONS_FOR_IMPROVEMENT_COMPLAINT,
            TranslationLabels.ticketingSuggestionsForImprovementComplaintLabel,
          ),
          ...getComplaintOption(
            jiraItems.OTHER_COMPLAINT,
            TranslationLabels.ticketingOtherComplaintLabel,
          ),
        ],
      },
    },
    {
      componentType: 'input',
      field: {
        name: jiraFields.DESCRIPTION,
        initialValue: '',
      },
      props: {
        type: 'textarea',
        label: TranslationLabels.ticketingCustomerAccountDescriptionLabel,
        required: true,
      },
    },

    {
      componentType: 'attachment',
      field: {
        name: jiraFields.ATTACHMENT,
        initialValue: [],
      },
      props: {
        accept: ['.png', '.jpg', '.jpeg', '.pdf'],
        helperTextKey:
          TranslationLabels.ticketingServiceRequestAttachmentFileHelperText,
        helperTextOptions: {
          formats: 'JPG, JPEG, PNG, PDF',
          maxSize: 100,
        },
        isMultiple: true,
        label: TranslationLabels.formFieldsAttachmentFileLabel,
        onPreviewClick$: ticketingClient.getAttachment$,
      },
    },
    {
      componentType: 'switch',
      field: {
        name: jiraFields.VISIBILITY,
        initialValue: ServiceRequestVisibilityEnum.PUBLIC,
      },
      props: {
        label: TranslationLabels.ticketingServiceRequestVisibilityLabel,
        checkedLabelKey:
          TranslationLabels.ticketingServiceRequestVisibilityTypePrivate,
        checkedValue: ServiceRequestVisibilityEnum.PRIVATE,
        uncheckedValue: ServiceRequestVisibilityEnum.PUBLIC,
        checkedValueDescriptionKey:
          TranslationLabels.ticketingServiceRequestVisibilityPrivateDescriptionText,
        helperTextKey:
          TranslationLabels.ticketingServiceRequestVisibilityHelperText,
      },
    },
  ];
};

export const getConfig = (
  country: GenericTypes.Country,
  apartment: ICustomerRentalObject | null,
  user: ICustomerMidleLayerApi2 | null,
  requestTypeId: number | undefined,
): FormProps<Values>['config'] => ({
  onSubmit: (values) => {
    const { jiraFields } = COUNTRY_TICKETING_MODEL[country];
    const newValues = {
      ...values,
      [jiraFields.CUSTOMER_COMPLAINT]: prepareValueOfComplaintCategory(
        values[jiraFields.CUSTOMER_COMPLAINT] as CheckboxesValue,
      ),
    };

    return ticketingClient.createRequest$(
      country,
      newValues,
      apartment,
      requestTypeId,
      user,
    );
  },
});
