import { TranslationLabels } from '@generated/translation-labels';
import Grid from '@material-ui/core/Grid';
import { FormV2 } from '@shared/FormV2';
import { FormProps } from '@shared/FormV2/types';
import { Redirect } from 'react-router-dom';
import { useLocation } from 'react-router';
import React, { FC, useState } from 'react';
import { useTicketing } from '../../../../+ticketing';
import { ServiceRequestApi } from '../../../../+ticketing/ticketing.type';

type Props<T> = FormProps<T> & {
  fullWidth?: boolean;
};

export const RequestForm = <T extends unknown>(
  props: Props<T>,
): ReturnType<FC<Props<T>>> => {
  const location = useLocation();
  const { setTicketList, tickets } = useTicketing();
  const [isSuccess, setSuccess] = useState(false);
  const [currentTicket, setCurrentTicket] = useState('');
  const handleSuccess = (ticket: ServiceRequestApi): void => {
    setCurrentTicket(ticket.ticketId);
    setTicketList([ticket, ...tickets]);
    setSuccess(true);
  };
  const { fullWidth } = props;

  return isSuccess ? (
    <Redirect
      to={{
        pathname: `${location.pathname}/success`,
        state: { ticketNumber: currentTicket },
      }}
    />
  ) : (
    <Grid item xs={12} md={fullWidth ? 12 : 6}>
      <FormV2<T>
        {...props}
        handleSuccess={handleSuccess}
        submitLabel={TranslationLabels.ticketingButtonCreateRequest}
      />
    </Grid>
  );
};
