import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    formControlRoot: {
      display: 'block',
    },
    dropzone: {
      alignItems: 'center',
      backgroundColor: palette.athensGray,
      borderRadius: theme.spacing(1),
      color: theme.typography.body1.color,
      cursor: 'pointer',
      display: 'flex',
      fontSize: theme.typography.pxToRem(16),
      height: 80,
      justifyContent: 'center',
      lineHeight: theme.typography.pxToRem(20),
      padding: theme.spacing(1),
      width: '100%',
      fontWeight: theme.typography.fontWeightBold,
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    disabled: {
      cursor: 'default',
      color: theme.palette.text.disabled,

      '& $icon > path': {
        stroke: theme.palette.text.disabled,
      },
    },
    label: {
      color: palette.lighterGray,
    },
    helperText: {
      color: palette.lighterGray,
    },
  }),
);
