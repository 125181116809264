import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    comment: {
      display: 'inline-block',
      marginBottom: theme.spacing(2),
      marginRight: 'auto',
      maxWidth: '60%',

      '&:last-of-type': {
        marginBottom: theme.spacing(3),
      },
    },
    question: {
      marginLeft: 'auto',
      marginRight: 'unset',
    },
    contentWrapper: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    box: {
      backgroundColor: palette.white,
      borderRadius: theme.spacing(1),
      marginTop: theme.spacing(0.5),
      padding: theme.spacing(2),
    },
    text: {
      whiteSpace: 'pre-line',
    },
    actions: {
      display: 'flex',
      marginLeft: theme.spacing(1),
    },

    [theme.breakpoints.down('xs')]: {
      contentWrapper: {
        display: 'block',
      },
      comment: {
        maxWidth: '100%',
      },
    },
  }),
);
