import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    menuElement: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      letterSpacing: -0.2,
      padding: theme.spacing(1.5, 4),

      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        textDecoration: 'none',
      },
    },
    icon: {
      backgroundColor: palette.athensGray,
      marginLeft: theme.spacing(2),
      height: 48,
      width: 48,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: theme.spacing(1),
    },
  }),
);
