import { TranslationLabels } from '@generated/translation-labels';
import { Stepper } from '../types';

export const stepper: Stepper = [
  {
    step: 'localization',
    labelKey: TranslationLabels.localizationStep,
  },
  {
    step: 'category',
    labelKey: TranslationLabels.categoryStep,
  },
  {
    step: 'additional_info',
    labelKey: TranslationLabels.additionalInfoStep,
  },
];
