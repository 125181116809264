import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    line: {
      backgroundColor: palette.silver,
      height: 2,
      margin: theme.spacing(4, 'auto'),
      maxWidth: 640,
    },
    titleWrapper: {
      margin: theme.spacing(0, 'auto', 4),
      maxWidth: 640,
      textAlign: 'center',
    },
    title: {
      '& > span': {
        color: palette.internationalOrange,
      },
    },
    headerWrapper: {
      position: 'relative',
    },
  }),
);
