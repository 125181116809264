import { CustomerMidleLayerApi2 } from '@shared/models';
import { createContext } from 'react';
import {
  ServiceRequest,
  ServiceRequestApi,
  ServiceRequestDetailsApi,
} from '../../ticketing.type';
import { initialState } from './reducer';
import { State } from './type';

export type ContextValue = State & {
  getTicketingData$: (
    customerId: CustomerMidleLayerApi2['customerIdApi'],
  ) => Promise<State['tickets']>;
  getTicketsIdForRequestType$: (
    requestTypeId: number,
    params?: {
      itemTypeId?: number;
      roomTypeId?: number;
      subItemTypeId?: number;
    },
  ) => Promise<string[]>;
  getUpdateProfileTicketId$: () => Promise<string | undefined>;
  setTicketDetails: (payload: ServiceRequestDetailsApi) => void;
  setTicketList: (payload: (ServiceRequest | ServiceRequestApi)[]) => void;
};

export const Context = createContext<ContextValue>({
  ...initialState,
  getTicketingData$: () => Promise.resolve([]),
  getTicketsIdForRequestType$: () => Promise.resolve([]),
  getUpdateProfileTicketId$: () => Promise.resolve(undefined),
  setTicketDetails: () => null,
  setTicketList: () => null,
});
