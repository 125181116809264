import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  UseMutationResult,
  UseQueryResult,
} from 'react-query/types/react/types';
import { AxiosError } from 'axios';
import { Notification } from '@generated/clients/notifications/data-contracts';
import { api } from './notifications.repository';
import { NotificationWithCustomFields } from './notifications.type';

export const useUnreadTerminationRequestNotificationQuery = (
  taskId: string,
): UseQueryResult<Notification | undefined> => {
  return useQuery<Notification | undefined>(
    ['unreadTerminationRequestNotification', taskId],
    () =>
      api.getUnreadTerminationRequestNotification({
        taskId,
      }),
    {
      keepPreviousData: true,
      staleTime: Infinity,
    },
  );
};

export const useNotificationsQuery = (
  pagination: GenericTypes.Pagination,
): UseQueryResult<GenericTypes.Paginated<NotificationWithCustomFields>> => {
  return useQuery<GenericTypes.Paginated<NotificationWithCustomFields>>(
    ['notifications', pagination.page, pagination.pageSize],
    () =>
      api.getNotificationsPaginated({
        page: pagination.page,
        limit: pagination.pageSize,
      }),
    {
      keepPreviousData: true,
      staleTime: Infinity,
    },
  );
};

export const useUnreadNotificationsCountQuery = (): UseQueryResult<number> => {
  return useQuery<number>(
    ['unreadNotificationsCount'],
    () => api.getUnreadCount(),
    { keepPreviousData: true, staleTime: Infinity },
  );
};

export const useUnreadNotificationsQuery = ({
  limit,
  enabled,
}: {
  limit: number;
  enabled: boolean;
}): UseQueryResult<NotificationWithCustomFields[]> => {
  return useQuery<NotificationWithCustomFields[]>(
    ['unreadNotifications', limit],
    () => api.getUnread(limit),
    {
      keepPreviousData: true,
      enabled,
      staleTime: Infinity,
    },
  );
};

export const useMarkOneAsReadMutation = (): UseMutationResult<
  void,
  AxiosError,
  { id: Notification['id'] }
> => {
  const queryClient = useQueryClient();
  return useMutation(api.markOneAsRead, {
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries(['unreadNotificationsCount']),
        queryClient.invalidateQueries(['unreadNotifications']),
        queryClient.invalidateQueries(['notifications']),
        queryClient.invalidateQueries(['unreadTerminationRequestNotification']),
      ]);
    },
  });
};
