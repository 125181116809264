import React, { FC, useCallback, useMemo } from 'react';
import { ReactComponent as CalendarEdit1Icon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/date-calendar/calendar-edit-1.svg';
import IconButton from '@material-ui/core/IconButton';
import { Icon } from '@shared/components';
import { useStyles } from './reschedule-button.styles';
import {
  BookingMode,
  MoveOutEventType,
  MoveOutInspectionEventType,
  TerminationBookingFlowStep,
} from '../../../types';
import { useTermination } from '../../state';

type Props = {
  eventType: MoveOutInspectionEventType | MoveOutEventType;
};

export const RescheduleButton: FC<Props> = (props) => {
  const { eventType } = props;
  const classes = useStyles();
  const { flowSteps, setCurrentFlowStep, updateFlowStep } = useTermination();
  const isCancellable = useMemo(() => {
    const step: TerminationBookingFlowStep | undefined = flowSteps.find(
      (flowStep) => flowStep.eventType === eventType,
    );

    return step?.isCancellable;
  }, [eventType, flowSteps]);
  const triggerEventReschedule = useCallback(() => {
    updateFlowStep(eventType, {
      isCalendarOpened: true,
      mode: BookingMode.RESCHEDULING,
    });
    setCurrentFlowStep(eventType);
  }, [eventType, setCurrentFlowStep, updateFlowStep]);

  if (!isCancellable) {
    return null;
  }

  return (
    <IconButton
      color="primary"
      className={classes.button}
      onClick={triggerEventReschedule}
    >
      <Icon icon={CalendarEdit1Icon} height={16} width={16} />
    </IconButton>
  );
};
