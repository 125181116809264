import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    input: {
      marginTop: theme.spacing(4),
    },
    description: {
      marginBottom: theme.spacing(8),
      fontSize: theme.typography.pxToRem(18),
    },
    formColumnLeft: {
      [theme.breakpoints.up('md')]: {
        paddingRight: theme.spacing(3),
      },
    },
    formColumnRight: {
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(3),
      },
      display: 'flex',
      flexDirection: 'column',
    },
    image: {
      width: '100%',
    },
    submitButton: {
      marginTop: theme.spacing(4),
      alignSelf: 'center',
      height: '46px',
    },
    content: {
      [theme.breakpoints.up('md')]: {
        paddingRight: theme.spacing(11),
      },
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  }),
);
