import { sanityClient, SanityDocumentType, SanityImage } from '@shared/sanity';

export const api = {
  getImage: (route: string): Promise<SanityImage> =>
    sanityClient
      .fetch<{ image: SanityImage }>(
        `* [_type == "${SanityDocumentType.PAGE}" && $route == route][0]{
      "image": image{asset->{url}},
    }`,
        {
          route,
        },
      )
      .then((response) => response.image),
};
