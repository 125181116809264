import { AxiosError } from 'axios';

export const handleError = (error: AxiosError): Promise<unknown> => {
  if (!error.response) {
    return Promise.reject(error);
  }

  switch (error.response.status) {
    default: {
      return Promise.reject(error.response);
    }
  }
};
