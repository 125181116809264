import { TranslationLabels } from '@generated/translation-labels';
import React, { FC } from 'react';
import { Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { generateUniqId, useTranslation } from '../../../../shared';
import { Invoice } from '../../../invoice.type';
import { formatAmount } from '../../helpers';
import { useStyles } from './summary.styles';

type Props = {
  amenities: Invoice['amenities'];
  currency: Invoice['currency'];
  total: Invoice['amount'];
};

export const Summary: FC<Props> = (props: Props) => {
  const { amenities, currency, total } = props;
  const classes = useStyles();
  const { t, langCode } = useTranslation();

  return (
    <Grid item xs={12} className={classes.table}>
      <Grid className={classes.rowContainer}>
        <Grid
          item
          xs={12}
          container
          justify="space-between"
          className={clsx(classes.tableElement, classes.tableHeader)}
        >
          <Typography className={classes.tableHeaderText}>
            {t(TranslationLabels.invoiceDetailsSummaryDescription)}
          </Typography>
          <Typography className={classes.tableHeaderText}>
            {t(TranslationLabels.invoiceDetailsSummaryAmount)}
          </Typography>
        </Grid>
      </Grid>
      <Grid className={classes.rowContainer}>
        {amenities?.map(({ amount, description }) => (
          <Grid
            item
            xs={12}
            container
            justify="space-between"
            key={generateUniqId()}
            className={clsx(classes.tableElement, classes.tableData)}
          >
            <Typography className={classes.tableDataText}>
              {description}
            </Typography>
            <Typography className={classes.tableDataText}>
              {formatAmount(currency, langCode, amount)}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Grid
        container
        justify="space-between"
        className={clsx(classes.tableElement, classes.tableFooter)}
      >
        <Typography className={classes.tableFooterText}>
          {t(TranslationLabels.invoiceDetailsSummaryTotal)}
        </Typography>
        <Typography className={classes.tableFooterText}>
          {formatAmount(currency, langCode, total)}
        </Typography>
      </Grid>
    </Grid>
  );
};
