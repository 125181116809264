import { TranslationLabels } from '@generated/translation-labels';
import { Main } from '@shared/components';
import React, { FC } from 'react';
import { ReactComponent as AppWindowWarning } from '@heimstaden/icons-library/img/streamline-regular/programing-apps-websites/apps-window/app-window-warning.svg';
import { useTranslation } from '@shared/translations';
import { useLogout } from '../../../+auth';
import { ErrorBox } from './ErrorBox/error-box.component';

export const NotVerifiedEmailError: FC = () => {
  const { t } = useTranslation();
  const logout = useLogout();

  const handleButtonClick = (): void => {
    window.location.reload();
  };

  return (
    <Main disableFetching>
      <ErrorBox
        icon={AppWindowWarning}
        title={t(TranslationLabels.notVerifiedEmailErrorTitle)}
        text={t(TranslationLabels.notVerifiedEmailErrorText)}
        button={{
          text: t(TranslationLabels.tryToLogInButtonText),
          onClick: handleButtonClick,
        }}
        secondaryButton={{
          text: t(TranslationLabels.headerLogOutLabel),
          onClick: () => logout(),
        }}
      />
    </Main>
  );
};
