import React, { FC, useMemo } from 'react';
import { matchPath, useLocation } from 'react-router';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import MuiStepper from '@material-ui/core/Stepper';
import { useTranslation } from '@shared/translations';
import { ticketingRoutes } from '../../routing';
import { Stepper as StepperConfig } from '../../../types';

type Props = {
  stepperConfig?: StepperConfig;
  allStepsFinished?: boolean;
};

export const Stepper: FC<Props> = ({ stepperConfig, allStepsFinished }) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const currentStep = useMemo(() => {
    const route = ticketingRoutes.find(({ path }) => {
      const match = matchPath(pathname, {
        path,
        exact: true,
        strict: true,
      });
      return match && match.isExact;
    });

    return route?.step;
  }, [pathname]);

  const currentStepIdx = useMemo(
    () =>
      (stepperConfig || []).findIndex(
        ({ step }) => step && step === currentStep,
      ),
    [stepperConfig, currentStep],
  );

  if (!stepperConfig || (!allStepsFinished && !currentStep)) return null;

  return (
    <MuiStepper activeStep={0} alternativeLabel>
      {stepperConfig.map(({ step, labelKey }, idx) => {
        const active = allStepsFinished ? false : idx === currentStepIdx;
        const completed = allStepsFinished ? true : idx < currentStepIdx;

        return (
          <Step
            active={active}
            completed={completed}
            disabled
            key={`step-${step}`}
          >
            <StepLabel>{t(labelKey)}</StepLabel>
          </Step>
        );
      })}
    </MuiStepper>
  );
};
