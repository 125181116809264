import React, { FC, MouseEvent, SVGProps } from 'react';
import { useSlate } from 'slate-react';
import { toggleMark, isMarkActive } from '../tools';
import { MarkFormat } from '../types';
import { Button } from './button.component';

type Props = {
  format: MarkFormat;
  icon: FC<SVGProps<SVGSVGElement>>;
  className?: string;
};

export const MarkButton: FC<Props> = ({ format, icon, className }) => {
  const editor = useSlate();

  return (
    <Button
      onMouseDown={(event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
      isActive={isMarkActive(editor, format)}
      icon={icon}
      className={className}
    />
  );
};
