import { TranslationLabels } from '@generated/translation-labels';
import Button from '@material-ui/core/Button';
import { MenuElement } from '@shared/components';
import React, { FC } from 'react';
import { useLogout } from '../shared';

export const SignOut: FC = () => {
  const logout = useLogout();

  return (
    <MenuElement<typeof Button>
      component={Button}
      onClick={() => logout()}
      shortDescriptionKey={TranslationLabels.headerLogOutText}
      titleKey={TranslationLabels.headerLogOutLabel}
    />
  );
};
