import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      paddingRight: theme.spacing(20),
      position: 'relative',
      '&:before': {
        backgroundImage: 'url(/assets/img/termination-completed.png)',
        backgroundPosition: 'bottom right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        bottom: 0,
        content: "''",
        display: 'block',
        height: '100%',
        maxHeight: 160,
        position: 'absolute',
        right: 0,
        width: '50%',
        zIndex: -1,
      },
    },
  }),
);
