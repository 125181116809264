import { Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

type Props = {
  strokeWidth: number;
};

export const useStyles = makeStyles<Theme, Props>(() =>
  createStyles({
    icon: {
      fontSize: 0,

      '& path, & circle': {
        strokeWidth: ({ strokeWidth }) => strokeWidth,
      },
    },
  }),
);
