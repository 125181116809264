import { heimClient, jiraServiceClient } from '@shared/http';
import { IStatusObject, ICustomerMidleLayerApi2 } from '@shared/models';

import {
  CustomerOperationMidlelayer,
  HeimAttributes,
} from '../shared/enums/heimapi.enum';
import { UserMetadata } from './types';

export const api = {
  // GetMyCustomerInfo/:dataSource/:partnerName/:userAndSessionId
  urlProfile: (): string =>
    [
      'GetMyCustomerInfo',
      HeimAttributes.DATA_SOURCE,
      HeimAttributes.PARTNER_NAME,
      HeimAttributes.GUID,
    ].join('/'),
  getMyProfile: (): Promise<ICustomerMidleLayerApi2> => {
    const url = api.urlProfile();

    return heimClient.get<ICustomerMidleLayerApi2>(url);
  },
  urlMyCustomerInfoPost: (command: CustomerOperationMidlelayer): string => {
    // /UpdateMyCustomerInfo/{dataSource}/{partnerName}/{userAndSessionId}/{customerOperation}
    return [
      'UpdateMyCustomerInfo',
      HeimAttributes.DATA_SOURCE,
      HeimAttributes.PARTNER_NAME,
      HeimAttributes.GUID,
      command,
    ].join('/');
  },
  updateMyProfile: async (
    values: ICustomerMidleLayerApi2,
    command: CustomerOperationMidlelayer,
  ): Promise<IStatusObject> => {
    const url = api.urlMyCustomerInfoPost(command);

    return heimClient.post<ICustomerMidleLayerApi2, IStatusObject>(url, values);
  },
  getNotificationPermissions: async (): Promise<UserMetadata> => {
    return jiraServiceClient.get<UserMetadata>('/permissions');
  },
  updateNotificationPermissions: async (
    data: UserMetadata,
  ): Promise<UserMetadata> => {
    return jiraServiceClient.put('/permissions', data);
  },
};
