import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    brick: {
      alignItems: 'center',
      backgroundColor: palette.white,
      border: `1px solid ${palette.internationalOrange}`,
      borderRadius: theme.spacing(0.5),
      color: palette.internationalOrange,
      display: 'flex',
      fontSize: theme.typography.pxToRem(12),
      height: '100%',
      justifyContent: 'center',
      lineHeight: theme.typography.pxToRem(16),
      position: 'relative',
      zIndex: 1,

      '&:hover:not(.active)': {
        cursor: 'pointer',
      },

      '&:hover, &.active': {
        backgroundColor: palette.internationalOrange,
        border: 'unset',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
        color: palette.white,
      },
    },
  }),
);
