import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    container: {
      maxWidth: '420px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      rowGap: '8px',
      padding: theme.spacing(5),
      position: 'relative',
    },
    button: {
      marginTop: theme.spacing(4),
    },
    buttonsWrapper: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(4),
    },
    title: {
      fontWeight: 'bold',
      alignSelf: 'flex-start',
    },
    content: {
      textAlign: 'center',
    },
    submitButton: {
      marginLeft: theme.spacing(2),
    },
    modal: { alignItems: 'center', justifyContent: 'center' },
    closeIcon: {
      color: palette.black,
      padding: theme.spacing(1),
      position: 'absolute',
      top: theme.spacing(2),
      right: theme.spacing(3),
    },
  }),
);
