import React, { FC, useMemo, ReactElement, useEffect } from 'react';
import { Slate, withReact } from 'slate-react';
import { useFormikContext } from 'formik';
import { Descendant, createEditor, Transforms } from 'slate';
import { fromSlateToJira } from './converters';
import { SlateEditor, SlateElement, SlateText } from './types';

declare module 'slate' {
  interface CustomTypes {
    Editor: SlateEditor;
    Element: SlateElement;
    Text: SlateText;
  }
}

const initialValue: Descendant[] = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];

type Props = {
  fieldName: string;
  children: ({ editor }: { editor: SlateEditor }) => ReactElement;
  isFormDirty: boolean;
};

export const RichTextContext: FC<Props> = ({
  children,
  fieldName,
  isFormDirty,
}) => {
  const { setFieldValue } = useFormikContext();
  const editor = useMemo(() => withReact(createEditor()), []);

  // clear text area when form submitted
  useEffect(() => {
    if (!isFormDirty) {
      Transforms.deselect(editor); // reset the cursor position
      editor.children = initialValue;
    }
  }, [isFormDirty, editor]);

  return (
    <Slate
      editor={editor}
      value={initialValue}
      onChange={(value) => {
        const isAstChange = editor.operations.some(
          (op) => op.type !== 'set_selection',
        );
        if (isAstChange) {
          const jiraValue = fromSlateToJira(value);
          setFieldValue(fieldName, JSON.stringify(jiraValue));
        }
      }}
    >
      {children({ editor })}
    </Slate>
  );
};
