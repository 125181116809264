import DialogActions from '@material-ui/core/DialogActions';
import { Dialog } from '@shared/Dialog';
import { ActionDialogProps, CommonProps } from '@shared/Dialog/type';
import React, { FC } from 'react';
import { useStyles } from './action-dialog.styles';

type Props = CommonProps & ActionDialogProps;

export const ActionDialog: FC<Props> = (props) => {
  const { children, buttons } = props;
  const classes = useStyles();

  return (
    <Dialog {...props}>
      {children}
      {buttons.length > 0 && (
        <DialogActions className={classes.actions}>{buttons}</DialogActions>
      )}
    </Dialog>
  );
};
