import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      position: 'relative',
    },
    input: {
      '& + div': {
        marginTop: theme.spacing(4),
      },
    },
    arrowButton: {
      position: 'absolute',
      right: 0,
      top: 26,
    },
    buttonsRow: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(4),

      '& > button + button': {
        marginLeft: theme.spacing(2),
      },
    },
    spinner: {
      marginLeft: theme.spacing(2),
    },
    globalError: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(20),
      marginBottom: theme.spacing(2),
    },

    [theme.breakpoints.down('xs')]: {
      arrowButton: {
        top: 44,
      },
    },
  }),
);
