import { isEmpty } from 'lodash-es';
import { Block } from '../Footer/footer.type';

export const getFilteredOutBlocks = (blocks: Block[]): Block[] =>
  blocks
    .filter((block) => !isEmpty(block))
    .map(
      (block) =>
        block.links.length > 0 && {
          ...block,
          links: block.links.filter(({ route, url }) => route || url),
        },
    )
    .filter((block) => block) as Block[];
