import { TranslationLabels } from '@generated/translation-labels';
import React, { FC, MouseEvent } from 'react';
import Button from '@material-ui/core/Button';
import { useTranslation } from '@shared/translations';

type Props = {
  disabled: boolean;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  label?: GenericTypes.TranslationLabel;
};

export const CancelButton: FC<Props> = ({ disabled, onClick, label }) => {
  const { t } = useTranslation();

  return (
    <Button
      color="primary"
      disabled={disabled}
      onClick={onClick}
      variant="outlined"
    >
      {t(label || TranslationLabels.formButtonCancel)}
    </Button>
  );
};

CancelButton.displayName = 'CancelButton';
