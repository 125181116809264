import Grid from '@material-ui/core/Grid';
import { useApi, useCountry } from '@shared/hooks';
import React, { FC, useCallback } from 'react';
import { ContactInfo, Container, Logo } from '@shared/components';
import { useTranslation } from '@shared/translations';
import { Copyright } from '../Copyright/copyright.component';
import { FooterLinks } from '../FooterLinks/footer-links.component';
import { api } from './footer.repository';
import { useStyles } from './footer.styles';
import { Block } from './footer.type';

export const Footer: FC = () => {
  const classes = useStyles();
  const { langCode } = useTranslation();
  const country = useCountry();
  const callback = useCallback(
    (): Promise<Block[]> => api.getFooterBlocks(country, langCode),
    [country, langCode],
  );
  const [blocks, , isError] = useApi<Block[]>([], callback);

  if (isError) {
    return null;
  }

  return (
    <footer>
      <div className={classes.container}>
        <Container>
          <Grid container spacing={4} className={classes.content}>
            <Grid item xs={12} sm={4} md={3}>
              <Logo />
              <ContactInfo country={country} />
            </Grid>
            {blocks.length > 0 && (
              <Grid item xs={12} sm={8} md={9}>
                <FooterLinks blocks={blocks} />
              </Grid>
            )}
          </Grid>
          <Copyright />
        </Container>
      </div>
    </footer>
  );
};
