import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    flex: {
      display: 'flex',
    },
    warning: {
      alignItems: 'center',
      backgroundColor: palette.silver,
      borderRadius: theme.spacing(1),
      display: 'flex',
      justifyContent: 'center',
      marginLeft: 'auto',
      padding: theme.spacing(2),
      flexWrap: 'wrap',
    },
    icon: {
      flexShrink: 0,
      marginLeft: theme.spacing(1),
    },
  }),
);
