import { TJiraField, TRequestType } from './ticketing.type';

export const TICKETING_DEV_PREFIX = '[Test]';

export const COUNTRY_TICKETING_MODEL: {
  [key in GenericTypes.Country]: {
    readonly jiraFields: Record<TJiraField | string, string>;
    readonly requestType: Record<TRequestType, number>;
    readonly jiraItems: Record<string, number>;
  };
} = {
  dk: {
    jiraFields: {
      // COMMON
      SUMMARY: 'summary',
      RENTAL_ID: 'customfield_10279',
      // REPAIR & MAINTENANCE
      ADDRESS: 'customfield_10294',
      APARTMENT_NUMBER: 'customfield_10275',
      ATTACHMENT: 'attachment',
      COMMON_FAILURE: 'customfield_10262:1',
      COMMON_ITEM: 'customfield_10262',
      DESCRIPTION: 'customfield_10253',
      OUTDOOR_ITEM: 'customfield_10272',
      REQUEST_TYPE: 'requestTypeId',
      UNIT_FAILURE: 'customfield_10259:1',
      UNIT_ITEM: 'customfield_10259',
      UNIT_ROOM: 'customfield_10258',
      // CUSTOMER COMPLAINT
      CUSTOMER_COMPLAINT: 'customfield_10296',
      // INVOICE AND CUSTOMER RELATIONSHIP
      INVOICE_CUSTOMER_TYPE: 'customfield_10264',
      INVOICE_CUSTOMER_ITEM: 'customfield_10264:1',
      INVOICE_CUSTOMER_NEW_FIRST_NAME: 'customfield_10308',
      INVOICE_CUSTOMER_NEW_LAST_NAME: 'customfield_10309',
      INVOICE_CUSTOMER_NEW_MOBILE: 'customfield_10310',
      INVOICE_CUSTOMER_NEW_EMAIL: 'customfield_10311',
      VISIBILITY: 'visibility',
    },
    jiraItems: {
      // CUSTOMER COMPLAINT
      CUSTOMER_SERVICE_COMPLAINT: 11537,
      GARBAGE_COMPLAINT: 11539,
      NEIGHBOR_COMPLAINT: 11536,
      NOISE_FROM_CONSTRUCTION_WORK_COMPLAINT: 11538,
      OTHER_COMPLAINT: 11540,
      SUGGESTIONS_FOR_IMPROVEMENT_COMPLAINT: 11574,
      TOILET_TYPE: 11263,
      // INVOICE CUSTOMER ACCOUNT
      INVOICE_CUSTOMER_ACCOUNT_CONTRACT_AND_TENANCY: 11473,
      INVOICE_CUSTOMER_ACCOUNT_CONTRACT_AND_TENANCY_CHANGE_INFORMATION: 11480,
      // UNITS
      DRYER_UNIT: 11178,
      ELECTRICITY_AND_LIGHTING_UNIT: 11181,
      SMOKE_ALARM_UNIT: 11187,
      VENTILATION_AND_HEATING_UNIT: 11190,
      INVENTORY_UNIT: 11200,
      HUMIDITY_AND_INDOOR_CLIMATE_UNIT: 11216,
      SHOWER_BATH_UNIT: 11234,
      SURFACES_UNIT: 11241,
      SINK_UNIT: 11250,
      WASHING_MACHINE_UNIT: 11253,
      DRAIN_WATER_PIPE_AND_WATER_TAP_UNIT: 11257,
      TOILET_UNIT: 11263,
      OTHER_UNIT: 11268,
      DISHWASHER_UNIT: 11173,
      COOKER_HOOD_UNIT: 11212,
      WASH_UNIT: 11214,
      OVEN_HOB_UNIT: 11221,
      REFRIGERATOR_FREEZER_UNIT: 11229,
      KEYS_LOCKS_AND_DOOR_PHONE_UNIT: 11205,
      PEST_CONTROL_UNIT: 11986,
      // COMMON
      BICYCLE_CELLAR_COMMON: 11275,
      SHARED_KITCHEN_COMMON: 11278,
      COMMON_ROOMS_COMMON: 11281,
      ELEVATOR_COMMON: 11282,
      SMOKE_ALARM_COMMON: 11285,
      PARKING_COMMON: 11288,
      WASTE_SHAFT_COMMON: 11291,
      STAIRS_COMMON: 11295,
      KEYS_LOCKS_AND_DOOR_PHONE_COMMON: 11298,
      LAUNDRY_COMMON: 11304,
      MAILBOX_COMMON: 11311,
      ODOR_NUISANCE_COMMON: 11317,
      SPRINKLER_SYSTEM_COMMON: 11319,
      DEPOTRUM_COMMON: 11321,
      TRASH_COMMON: 11332,
      SURFACES_COMMON: 11325,
      DRAIN_WATER_PIPE_AND_WATER_TAP_COMMON: 11334,
      PEST_CONTROL_COMMON: 11989,
      DOOR: 11323,
      LOCK: 11322,
      // OUTSIDE
      RENOVATION_OUTSIDE: 11270,
      FACADES_OUTSIDE: 11271,
      GREEN_AREAS_OUTSIDE: 11272,
      PLAYGROUND_OUTSIDE: 11273,
      SNOW_REMOVAL_OUTSIDE: 11274,
      PEST_CONTROL_OUTSIDE: 11988,
    },
    requestType: {
      COMMON: 120,
      CUSTOMER_SERVICE: 124,
      INVOICE_CUSTOMER_ACCOUNT: 122,
      ISSUE_COMPLAINT: 123,
      OUTDOOR: 121,
      TERMINATE_CONTRACT: -1,
      UNIT: 119,
    },
  },
  is: {
    jiraFields: {
      // COMMON
      SUMMARY: 'summary',
      RENTAL_ID: 'customfield_10321',
      // REPAIR & MAINTENANCE
      ADDRESS: 'customfield_10328',
      APARTMENT_NUMBER: 'customfield_10125',
      ATTACHMENT: 'attachment',
      COMMON_FAILURE: 'customfield_10143:1',
      COMMON_ITEM: 'customfield_10143',
      DESCRIPTION: 'description',
      OUTDOOR_ITEM: 'customfield_10152',
      REQUEST_TYPE: 'requestTypeId',
      UNIT_FAILURE: 'customfield_10100:1',
      UNIT_ITEM: 'customfield_10100',
      UNIT_ROOM: 'customfield_10102',
      // CUSTOMER COMPLAINT
      CUSTOMER_COMPLAINT: 'customfield_10093',
      // TERMINATE REQUEST
      NEW_APARTMENT: 'customfield_10116',
      NOTICE: 'customfield_10115',
      POTENTIAL_IMPROVEMENT: 'customfield_10117',
      ALTERNATIVE_CONTACT_EMAIL: 'customfield_10352',
      ALTERNATIVE_CONTACT_PHONE: 'customfield_10353',
      // INVOICE AND CUSTOMER RELATIONSHIP
      INVOICE_CUSTOMER_TYPE: 'customfield_10148',
      INVOICE_CUSTOMER_ITEM: 'customfield_10148:1',
      INVOICE_CUSTOMER_NEW_FIRST_NAME: 'customfield_10308',
      INVOICE_CUSTOMER_NEW_LAST_NAME: 'customfield_10309',
      INVOICE_CUSTOMER_NEW_MOBILE: 'customfield_10310',
      INVOICE_CUSTOMER_NEW_EMAIL: 'customfield_10311',
      VISIBILITY: 'visibility',
    },
    jiraItems: {
      // OUTDOOR
      CONTAINER_RUBBISH: 10503,
      FACADE: 10499,
      GREEN_AREA: 10500,
      PLAYGROUND: 10501,
      SNOWMAKING: 10502,
      // COMMON
      BICYCLE_SHED: 10568,
      COMMON_CORRIDOR_STAIRCASE: 10564,
      COMMUNAL_LAUNDRY: 10565,
      ELEVATOR: 10560,
      FIRE_ALARM: 10553,
      GARAGE_PARKING: 10559,
      GARBAGE_ROOM: 10567,
      KEYS_LOCKS_INTERCOM: 10562,
      MAILBOX_NAMEPLATE: 10561,
      SMELL: 10569,
      SPRINKLER_SYSTEM: 10558,
      SURFACES: 10563,
      WATER_DRAINS_PIPES: 10554,
      // UNIT
      DISHWASHER: 10363,
      DRYER: 11804,
      ELECTRICITY_LIGHT: 10367,
      FIRE_ALARM_UNIT: 10400,
      FIXTURES_FURNITURE: 10401,
      FRIDGE_FREEZER: 10362,
      HEATING_VENTILATION: 10368,
      KEYS_LOCKS_INTERCOM_UNIT: 10366,
      KITCHEN_FAN: 10382,
      KITCHEN_SINK: 10541,
      MOISTURE_MOLD: 10389,
      OVEN_COOKTOP: 10365,
      SHOWER_BATH: 10341,
      STORAGE_ROOM: 10566,
      SURFACES_UNIT: 10383,
      TOILET: 10345,
      TV_INTERNET: 10386,
      WASHBASIN: 10361,
      WASHING_MACHINE: 10364,
      WASTE: 10570,
      WATER_DRAINS_PIPES_UNIT: 10369,
      // CUSTOMER COMPLAINT
      NEIGHBOR_COMPLAINT: 10476,
      CUSTOMER_SERVICE_COMPLAINT: 10477,
      SMOKE_OR_ODOR_COMPLAINT: 10478,
      NOISE_FROM_CONSTRUCTION_WORK_COMPLAINT: 10479,
      GARBAGE_COMPLAINT: 11809,
      OTHER_COMPLAINT: 11810,
      // TERMINATE CONTRACT
      NOTICE_OTHER: 10267,
      NEW_APARTMENT_YES: 10268,
      NEW_APARTMENT_NO: 10269,
      // INVOICE CUSTOMER ACCOUNT
      INVOICE_CUSTOMER_ACCOUNT_TERMINATE_REQUEST: 10470,
      INVOICE_CUSTOMER_ACCOUNT_CONTRACT_AND_TENANCY: 10461,
      INVOICE_CUSTOMER_ACCOUNT_CONTRACT_AND_TENANCY_CHANGE_INFORMATION: 10466,
    },
    requestType: {
      COMMON: 253,
      CUSTOMER_SERVICE: 255,
      INVOICE_CUSTOMER_ACCOUNT: 257,
      ISSUE_COMPLAINT: 256,
      OUTDOOR: 254,
      TERMINATE_CONTRACT: 281,
      UNIT: 237,
    },
  },
  nl: {
    jiraFields: {},
    jiraItems: {},
    requestType: {
      COMMON: -1,
      CUSTOMER_SERVICE: -1,
      INVOICE_CUSTOMER_ACCOUNT: -1,
      ISSUE_COMPLAINT: -1,
      OUTDOOR: -1,
      TERMINATE_CONTRACT: -1,
      UNIT: -1,
    },
  },
  pl: {
    jiraFields: {},
    jiraItems: {},
    requestType: {
      COMMON: -1,
      CUSTOMER_SERVICE: -1,
      INVOICE_CUSTOMER_ACCOUNT: -1,
      ISSUE_COMPLAINT: -1,
      OUTDOOR: -1,
      TERMINATE_CONTRACT: -1,
      UNIT: -1,
    },
  },
  de: {
    jiraFields: {},
    jiraItems: {},
    requestType: {
      COMMON: -1,
      CUSTOMER_SERVICE: -1,
      INVOICE_CUSTOMER_ACCOUNT: -1,
      ISSUE_COMPLAINT: -1,
      OUTDOOR: -1,
      TERMINATE_CONTRACT: -1,
      UNIT: -1,
    },
  },
  no: {
    jiraFields: {
      // COMMON
      SUMMARY: 'summary',
      AUTHOR_FIRST_NAME: 'customfield_10207',
      AUTHOR_LAST_NAME: 'customfield_10208',
      RENTAL_ID: 'customfield_10321',
      // REPAIR & MAINTENANCE
      ADDRESS: 'customfield_10165',
      APARTMENT_NUMBER: 'customfield_10209',
      ATTACHMENT: 'attachment',
      COMMON_FAILURE: 'customfield_10171:1',
      COMMON_ITEM: 'customfield_10171',
      DESCRIPTION: 'description',
      CUSTOMER_SERVICE_REQUEST_DESCRIPTION: 'customfield_10232',
      OUTDOOR_ITEM: 'customfield_10173',
      REQUEST_TYPE: 'requestTypeId',
      UNIT_FAILURE: 'customfield_10170:1',
      UNIT_ITEM: 'customfield_10170',
      UNIT_ROOM: 'customfield_10169',
      // CUSTOMER COMPLAINT
      CUSTOMER_COMPLAINT: 'customfield_10167',
      // TERMINATE REQUEST
      APPROVED_TENANTS: 'customfield_10304',
      NEW_APARTMENT: 'customfield_10179',
      NOTICE: 'customfield_10178',
      PEOPLE_LIVING: 'customfield_10176',
      POTENTIAL_IMPROVEMENT: 'customfield_10180',
      TENANTS_IN_APARTMENT: 'customfield_10303',
      ALTERNATIVE_CONTACT_EMAIL: 'customfield_10352',
      ALTERNATIVE_CONTACT_PHONE: 'customfield_10353',
      CONTRACT_END_DATE: 'customfield_10375',
      // INVOICE AND CUSTOMER RELATIONSHIP
      INVOICE_CUSTOMER_TYPE: 'customfield_10168',
      INVOICE_CUSTOMER_ITEM: 'customfield_10168:1',
      INVOICE_CUSTOMER_NEW_FIRST_NAME: 'customfield_10308',
      INVOICE_CUSTOMER_NEW_LAST_NAME: 'customfield_10309',
      INVOICE_CUSTOMER_NEW_MOBILE: 'customfield_10310',
      INVOICE_CUSTOMER_NEW_EMAIL: 'customfield_10311',
      VISIBILITY: 'visibility',
    },
    jiraItems: {
      // OUTDOOR
      CONTAINER_RUBBISH: 10905,
      FACADE: 10901,
      GREEN_AREA: 10902,
      PLAYGROUND: 10903,
      SNOWMAKING: 10904,
      // COMMON
      BICYCLE_SHED: 10845,
      BOD: 10843,
      COMMON_CORRIDOR_STAIRCASE: 10841,
      COMMUNAL_LAUNDRY: 10842,
      ELEVATOR: 10837,
      FIRE_ALARM: 10833,
      GARAGE_PARKING: 10836,
      GARBAGE: 10847,
      GARBAGE_ROOM: 10844,
      KEYS_LOCKS_INTERCOM: 10839,
      MAILBOX_NAMEPLATE: 10838,
      SMELL: 10846,
      SPRINKLER_SYSTEM: 10835,
      SURFACES: 10840,
      WATER_DRAINS_PIPES: 10834,
      // UNIT
      DISHWASHER: 10750,
      ELECTRICITY_LIGHT: 10743,
      FIRE_ALARM_UNIT: 10756,
      FIXTURES_FURNITURE: 10744,
      FRIDGE_FREEZER: 10749,
      HEATING_VENTILATION: 10745,
      KEYS_LOCKS_INTERCOM_UNIT: 10755,
      KITCHEN_FAN: 10752,
      KITCHEN_SINK: 10753,
      MOISTURE_MOLD: 10747,
      WASHBASIN: 10741,
      SHOWER_BATH: 10738,
      OVEN_COOKTOP: 10751,
      SURFACES_UNIT: 10746,
      TOILET: 10739,
      TV_INTERNET: 10754,
      WASHING_MACHINE: 10740,
      WATER_DRAINS_PIPES_UNIT: 10742,
      // CUSTOMER COMPLAINT
      NEIGHBOR_COMPLAINT: 10710,
      CUSTOMER_SERVICE_COMPLAINT: 10711,
      SMOKE_OR_ODOR_COMPLAINT: 10712,
      NOISE_FROM_CONSTRUCTION_WORK_COMPLAINT: 10713,
      GARBAGE_COMPLAINT: 10714,
      OTHER_COMPLAINT: 10715,
      // TERMINATE CONTRACT
      NOTICE_OTHER: 11633,
      PEOPLE_LEAVING_NO: 10908,
      PEOPLE_LEAVING_YES: 10907,
      NEW_APARTMENT_YES: 10920,
      NEW_APARTMENT_NO: 10921,

      // INVOICE CUSTOMER ACCOUNT
      INVOICE_CUSTOMER_ACCOUNT_TERMINATE_REQUEST: 10726,
      INVOICE_CUSTOMER_ACCOUNT_CONTRACT_AND_TENANCY: 10717,
      INVOICE_CUSTOMER_ACCOUNT_CONTRACT_AND_TENANCY_CHANGE_INFORMATION: 10722,
    },
    requestType: {
      COMMON: 94,
      CUSTOMER_SERVICE: 109,
      INVOICE_CUSTOMER_ACCOUNT: 97,
      ISSUE_COMPLAINT: 96,
      OUTDOOR: 95,
      TERMINATE_CONTRACT: 98,
      UNIT: 93,
    },
  },
};
