import { getEnvValue } from '../helpers';
import { HttpClient } from './http.client';
import { createInstance } from './http.instance';

export const instagramListMediaClient = (
  country: GenericTypes.Country,
): HttpClient => {
  const instance = createInstance({
    baseURL: getEnvValue(
      `INSTAGRAM_API_LIST_MEDIA_URL_${country.toUpperCase()}`,
    ),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });

  return new HttpClient(instance);
};
