import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import {
  RichTextEditor,
  RichTextToolbar,
  RichTextContext,
  ConditionalWrapperWithParams,
} from '@shared/components';
import { FieldLabel } from '@shared/components/FieldLabel/field-label.component';
import { FieldReadonly } from '@shared/components/FieldReadonly/field-readonly.component';
import React, { FC, isValidElement, useMemo } from 'react';
import { getFirstError } from '@shared/components/Form';
import { FieldProps as FormikFieldProps } from 'formik';
import { useTranslation } from '@shared/translations';
import { SlateEditor } from '@shared/components/RichText/types';
import { InputProps, InputValue } from './types';
import { useStyles } from './input-field-v2.styles';

type Props<TFormValues> = FormikFieldProps<InputValue, TFormValues> & {
  props: InputProps;
};

export function InputFieldV2<TFormValues>({
  meta,
  field,
  form,
  props,
}: Props<TFormValues>): ReturnType<FC<Props<TFormValues>>> {
  const { t } = useTranslation();
  const classes = useStyles();

  const { error, value, touched } = meta;
  const { name, onChange, onBlur } = field;
  const { submitForm } = form;
  const {
    className,
    disabled,
    fieldClassName,
    helperTextKey,
    label,
    placeholder,
    readonly,
    required,
    submitButton,
    type,
  } = props;

  const errorMessage = touched && getFirstError(t, error);
  const isError = Boolean(errorMessage);
  const helperText = useMemo(() => {
    if (isError) {
      return errorMessage;
    }

    return helperTextKey ? t(helperTextKey) : '';
  }, [errorMessage, helperTextKey, isError, t]);

  return (
    <FormControl
      component="fieldset"
      disabled={disabled}
      error={isError}
      fullWidth
      required={required && !readonly}
    >
      <ConditionalWrapperWithParams<{ editor: SlateEditor }>
        showWrapper={type === 'rich-textarea'}
        wrapper={(children) => (
          <RichTextContext fieldName={name} isFormDirty={form.dirty}>
            {({ editor }) => children({ editor })}
          </RichTextContext>
        )}
      >
        {(params) => (
          <>
            {type === 'rich-textarea' && <RichTextToolbar />}
            <FieldLabel label={label} />
            {readonly ? (
              <FieldReadonly className={className} value={value} />
            ) : (
              <TextField
                InputProps={{
                  classes: {
                    input: fieldClassName,
                  },
                  ...(isValidElement(submitButton) && {
                    endAdornment: (
                      <InputAdornment
                        onClick={submitForm}
                        position="end"
                        classes={{
                          root: classes.button,
                          positionEnd: classes.positionEnd,
                        }}
                      >
                        {submitButton}
                      </InputAdornment>
                    ),
                  }),
                  ...(type === 'rich-textarea' && {
                    inputComponent: RichTextEditor,
                    inputProps: {
                      editor: params?.editor,
                    },
                  }),
                }}
                disabled={disabled}
                error={isError}
                helperText={helperText}
                id={name}
                onChange={onChange}
                onBlur={onBlur}
                {...(placeholder && { placeholder: t(placeholder) })}
                value={value}
                variant="outlined"
                {...(type !== 'textarea'
                  ? { type }
                  : {
                      multiline: true,
                      rows: 4,
                    })}
              />
            )}
          </>
        )}
      </ConditionalWrapperWithParams>
    </FormControl>
  );
}
