import MuiContainer from '@material-ui/core/Container';
import PropTypes from 'prop-types';
import React, { FC, ReactNode } from 'react';
import { useStyles } from './container.styles';

type Props = {
  children: ReactNode;
  className?: string;
};

export const Container: FC<Props> = ({ children, className }) => {
  const classes = useStyles();
  const classNames = [
    classes.container,
    ...(className ? [className] : []),
  ].join(' ');

  return (
    <MuiContainer className={classNames}>
      <>{children}</>
    </MuiContainer>
  );
};
Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
Container.defaultProps = {
  className: undefined,
};
