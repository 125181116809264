import React, { ChangeEvent, FC } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { FieldProps as FormikFieldProps } from 'formik/dist/Field';
import {
  RadioButtonProps,
  RadioButtonValue,
} from '@shared/FormV2/fields/types';
import { FieldLabel } from '@shared/components/FieldLabel/field-label.component';
import { FieldReadonly } from '@shared/components/FieldReadonly/field-readonly.component';
import { useTranslation } from '@shared/translations';
import { getFirstError } from '@shared/components';

type Props<TFormValues> = FormikFieldProps<RadioButtonValue, TFormValues> & {
  props: RadioButtonProps;
};

export function RadioButtonFieldV2<TFormValues>(
  props: Props<TFormValues>,
): ReturnType<FC<Props<TFormValues>>> {
  const { meta, form, field, props: fieldProps } = props;
  const { touched, error, value } = meta;
  const { setFieldValue, setFieldTouched } = form;
  const { name: fieldName } = field;
  const { options, disabled, required, readonly, label } = fieldProps;

  const { t } = useTranslation();
  const errorMessage = touched && getFirstError(t, error);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setFieldTouched(fieldName);
    setFieldValue(fieldName, event.target.value);
  };

  return (
    <FormControl
      component="fieldset"
      disabled={disabled}
      required={required && !readonly}
      error={Boolean(errorMessage)}
    >
      <FieldLabel label={label} />
      {readonly ? (
        <FieldReadonly value={value} />
      ) : (
        <RadioGroup aria-label={t(label)}>
          {(options || []).map(({ name, label }) => (
            <FormControlLabel
              key={`radio-${name}`}
              control={
                <Radio
                  checked={name === value}
                  inputProps={{ 'aria-label': t(label) }}
                  name={`radio-${fieldName}`}
                  onChange={handleChange}
                  value={name}
                />
              }
              label={t(label)}
            />
          ))}
        </RadioGroup>
      )}
      {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
    </FormControl>
  );
}
