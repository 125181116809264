import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    confirmationWrapper: {
      marginTop: theme.spacing(3),
    },
    buttonsRow: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(1),

      '& button + button': {
        marginLeft: theme.spacing(1),
      },
    },
  }),
);
