import { TranslationLabels } from '@generated/translation-labels';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { FC, useCallback } from 'react';
import {
  generateUniqId,
  useApi,
  useCountry,
  useTranslation,
} from '../../../../shared';
import { Invoice } from '../../../invoice.type';
import { api } from '../../../invoices.respository';
import { useStyles } from './payment.styles';

type Props = {
  bankAccountNumber: Invoice['bankAccountNumber'];
  isUnpaid: boolean;
  kidNumber: Invoice['kidNumber'];
};

export const Payment: FC<Props> = (props: Props) => {
  const { bankAccountNumber, isUnpaid, kidNumber } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const country = useCountry();
  const callback = useCallback(
    () =>
      isUnpaid ? api.getContactNumber(country) : Promise.resolve(undefined),
    [isUnpaid, country],
  );
  const [contactNumber, isFetching, isError] = useApi<number | undefined>(
    undefined,
    callback,
  );

  if (isError) {
    return null;
  }

  const elements = [
    {
      label: TranslationLabels.invoiceDetailsPaymentAccount,
      value: bankAccountNumber,
    },
    {
      label: TranslationLabels.invoiceDetailsPaymentKidNumber,
      value: kidNumber,
    },
  ];
  const contactUsElement =
    isUnpaid && !isFetching && contactNumber ? (
      <div className={classes.container}>
        <Grid item xs={12}>
          <Typography>
            {t(TranslationLabels.invoiceDetailsPaymentDefaultText)}{' '}
            <a href={`tel:${contactNumber}`} title="">
              {contactNumber}
            </a>
          </Typography>
        </Grid>
      </div>
    ) : null;

  return bankAccountNumber && kidNumber ? (
    <div className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h2">
            {t(TranslationLabels.invoiceDetailsPaymentDetails)}
          </Typography>
          <Grid className={classes.content}>
            {elements.map(({ label, value }) => (
              <Grid
                justify="space-between"
                key={generateUniqId()}
                className={classes.element}
              >
                <Grid xs={3} item className={classes.elementLabel}>
                  <Typography component="span">{t(label)}</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    component="span"
                    className={classes.value}
                    variant="h3"
                  >
                    <strong>{value}</strong>
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h2">
            {t(TranslationLabels.invoiceDetailsPaymentDebitTitle)}
          </Typography>
          <Typography className={classes.paymentDebitText}>
            {t(TranslationLabels.invoiceDetailsPaymentDebitText)}
          </Typography>
        </Grid>
      </Grid>
    </div>
  ) : (
    contactUsElement
  );
};
