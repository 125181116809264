import { TranslationLabels } from '@generated/translation-labels';
import React, { FC, useMemo } from 'react';
import Typography from '@material-ui/core/Typography';
import DOMPurify from 'dompurify';
import { PLACEHOLDER } from '@shared/consts';
import { Box } from '@shared/components';
import { formatDayMonthYearDate } from '@shared/helpers';
import { useTranslation } from '@shared/translations';
import { useApartment } from '../../state';
import { useStyles } from './details.styles';
import { Item } from './item.component';
import { Apartment } from '../../../apartment.type';

export const Details: FC = () => {
  const { data } = useApartment();
  const { t } = useTranslation();
  const classes = useStyles();
  const apartmentData = useMemo<Omit<Apartment, 'images'>>(() => {
    const initialData = {
      tenantNumber: '',
      address: '',
      city: '',
      postalCode: '',
      leaseStartDate: undefined,
      leaseEndDate: undefined,
      description: '',
    };

    if (!data || !data.rentalDetailsList || !data.rentalObjectMyHome) {
      return initialData;
    }

    const {
      addressInclFlatnumber,
      postalCode,
      city,
      customerAccountNumber,
    } = data.rentalObjectMyHome;
    const rentalDetail = data.rentalDetailsList.find(
      ({ objectIdApi }) => objectIdApi === data.objectIdApi,
    );

    if (!rentalDetail) {
      return initialData;
    }

    const {
      contractEndDate,
      contractStartDate,
      contractSummaryHtml,
    } = rentalDetail;

    return {
      tenantNumber: customerAccountNumber,
      address: addressInclFlatnumber,
      city,
      postalCode,
      leaseStartDate: contractStartDate,
      leaseEndDate: contractEndDate,
      description: contractSummaryHtml,
    };
  }, [data]);
  const {
    tenantNumber,
    address,
    city,
    postalCode,
    leaseStartDate,
    leaseEndDate,
    description,
  } = apartmentData;

  return (
    <Box className={classes.container}>
      <Typography variant="h2">
        {t(TranslationLabels.yourApartmentTitle)}
      </Typography>
      <Typography>{t(TranslationLabels.yourApartmentText)}</Typography>
      <Item
        label={t(TranslationLabels.tenantNumberLabel)}
        text={tenantNumber}
      />
      <Item
        label={t(TranslationLabels.yourApartmentAddressLabel)}
        text={address}
      />
      <Item
        label={t(TranslationLabels.yourApartmentPostalCodeLabel)}
        text={`${postalCode} ${city}`}
      />
      <Item
        label={t(TranslationLabels.yourApartmentLeaseStartDateLabel)}
        text={
          leaseStartDate ? formatDayMonthYearDate(leaseStartDate) : PLACEHOLDER
        }
      />
      {leaseEndDate && (
        <Item
          label={t(TranslationLabels.yourApartmentLeaseEndDateLabel)}
          text={formatDayMonthYearDate(leaseEndDate)}
        />
      )}
      {description && (
        <Typography>
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(description),
            }}
          />
        </Typography>
      )}
    </Box>
  );
};
