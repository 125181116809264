import { TranslationLabels } from '@generated/translation-labels';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useAnimatedRoutes } from '@shared/animated-routes';
import { useApi, useCountry } from '@shared/hooks';
import { sanityImageBuilder } from '@shared/sanity';
import { useTranslation } from '@shared/translations';
import { Box } from '@shared/components';
import React, { FC, useCallback } from 'react';
import { useLocation } from 'react-router';
import { Content } from '../../shared';
import { api } from './areas.repository';
import { useStyles } from './areas.styles';
import { Area } from './areas.type';
import { ReactComponent as AreasImage } from '../../assets/areas.svg';
import { stepper } from '../stepper.config';

export const Areas: FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const { goTo } = useAnimatedRoutes();
  const { t } = useTranslation();
  const country = useCountry();
  const getAreas$ = useCallback(
    () => api.getAreas$(country, location.pathname),
    [country, location.pathname],
  );
  const [areas, isFetching] = useApi<Area[]>([], getAreas$);

  return (
    <Content
      introProps={{
        descriptionKey: TranslationLabels.ticketingServiceRequestAreasText,
        titleKey: TranslationLabels.ticketingServiceRequestAreasTitle,
        childrenRightColumn: <AreasImage />,
      }}
      isFetching={isFetching}
      stepperConfig={stepper}
    >
      <Grid container spacing={2}>
        {areas.map((props) => {
          const {
            buttonLabelKey,
            id,
            imageURL,
            redirectURL,
            textKey,
            titleKey,
          } = props;
          const imageUrl = sanityImageBuilder.image(imageURL).width(510).url();

          return (
            <Grid key={id} item xs={12} sm={4}>
              <Box className={classes.content}>
                {imageUrl && (
                  <img
                    src={imageUrl}
                    alt={t(titleKey)}
                    className={classes.image}
                  />
                )}
                <Typography variant="h3">
                  <strong>{t(titleKey)}</strong>
                </Typography>
                <Typography className={classes.description}>
                  {t(textKey)}
                </Typography>
                <Button
                  className={classes.button}
                  color="primary"
                  onClick={() => goTo(redirectURL)}
                  variant="outlined"
                >
                  {t(buttonLabelKey)}
                </Button>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Content>
  );
};
