import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      height: '60vh',
      maxHeight: '460px',
      '& .fc-license-message': {
        display: 'none',
      },
      '& .fc': {
        '--fc-border-color': palette.altoD3,
        '--fc-today-bg-color': palette.white,
        color: theme.palette.text.primary,
        fontFamily: theme.typography.fontFamily,
        '& col': {
          width: `${theme.spacing(10)}px !important`,
        },
        '& th': {
          padding: 0,
          backgroundColor: palette.wildSand,
          borderColor: palette.altoD3,
        },
        '& .fc-timegrid-slot-minor': {
          borderTopStyle: 'solid',
          borderTopColor: 'rgba(0, 0, 0, 0.08)',
        },
        '& .fc-col-header th.fc-col-header-cell': {
          padding: theme.spacing(0.5),
        },
        '& .fc-timegrid-slot-label': {
          position: 'relative',

          '&.fc-timegrid-slot-minor': {
            borderTop: 'unset',
          },
        },
        '& .fc-timegrid-slot-label-cushion, & .fc-col-header-cell-cushion': {
          padding: 0,
        },
      },
      '& .fc-direction-ltr': {
        '& .fc-timegrid-slot-label-frame': {
          position: 'absolute',
          textAlign: 'center',
          width: '100%',
        },
        '& .fc-timegrid-col-events': {
          margin: 0,
        },
      },
      '& .fc-timegrid-event-harness-inset .fc-timegrid-event, .fc-timegrid-event.fc-event-mirror, .fc-timegrid-more-link': {
        border: 'unset',
        borderRadius: 'unset',
        boxShadow: 'unset',
        margin: 'unset',
        padding: 'unset',
      },
      '& .fc-timegrid-event .fc-event-main': {
        padding: theme.spacing(0.5),
        position: 'relative',

        '&::before': {
          backgroundColor: palette.altoD3,
          content: `''`,
          display: 'block',
          height: 1,
          position: 'absolute',
          top: '50%',
          left: 0,
          right: 0,
        },
      },
      '& .fc-event': {
        backgroundColor: 'unset',

        '&:focus:after': {
          display: 'none',
        },
      },
    },
  }),
);
