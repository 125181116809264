import { ProfileData } from '../../+profile';
import { COUNTRY_TICKETING_MODEL } from '../ticketing.const';
import { TicketStatus } from '../ticketing.enum';
import { isSpecifiedStatus } from '../ticketing.helper';
import { ServiceRequest, StatusConfig } from '../ticketing.type';

export const isTerminateRequestNotConfirmed = (
  ticket: ServiceRequest,
  country: GenericTypes.Country,
  customerId: ProfileData['customerIdApi'],
): boolean => {
  const { jiraFields, requestType } = COUNTRY_TICKETING_MODEL[country];

  const tenantsInApartment = ticket[
    jiraFields.TENANTS_IN_APARTMENT
  ] as string[];

  if (
    ticket.isAuthor ||
    ticket.requestTypeId !== requestType.TERMINATE_CONTRACT ||
    !tenantsInApartment?.includes(customerId)
  ) {
    return false;
  }

  const approvedTenantsFields = ticket[jiraFields.APPROVED_TENANTS] as string[];
  const isApproved = approvedTenantsFields?.some((id) => id === customerId);

  return (
    !isApproved &&
    isSpecifiedStatus(
      ticket.status?.jiraKey,
      TicketStatus.AWAITING_TENANT_ACCEPTANCE,
    )
  );
};

export const getNotConfirmedTerminateRequest = (
  tickets: ServiceRequest[],
  country: GenericTypes.Country,
  customerId: ProfileData['customerIdApi'],
): ServiceRequest | undefined => {
  return tickets.find((ticket) =>
    isTerminateRequestNotConfirmed(ticket, country, customerId),
  );
};

export const isTicketToApprove = (
  approvedBy?: string[],
  customerId?: ProfileData['customerIdApi'],
  status?: StatusConfig['jiraKey'],
  tenantsInApartment?: string[],
): boolean => {
  if (
    !customerId ||
    !isSpecifiedStatus(status, TicketStatus.AWAITING_TENANT_ACCEPTANCE)
  ) {
    return false;
  }

  const tenantInApartment = tenantsInApartment?.includes(customerId);
  const isTenantApproved = approvedBy?.includes(customerId);

  return (tenantInApartment && !isTenantApproved) || false;
};
