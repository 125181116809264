import { TranslationLabels } from '@generated/translation-labels';
import { AttachmentValue } from '@shared/components/Fields';
import * as Yup from 'yup';
import * as Sentry from '@sentry/react';
import { ProfileData } from '../+profile';
import {
  getBlobFromBase64,
  ICustomerRentalObject,
  jiraServiceClient,
  PDF_MEDIA_TYPE,
  sanityClient,
  SanityDocumentType,
} from '../shared';
import {
  ServiceRequestApi,
  ServiceRequestDetailsApi,
  StatusConfig,
} from './ticketing.type';

const errorKey = TranslationLabels.formGlobalErrorMessage;

export const api = {
  // sanity
  getElementsName: (
    ids: number[],
  ): Promise<{ id: string; titleKey: GenericTypes.TranslationLabel }[]> => {
    if (!ids.length) {
      return Promise.resolve([]);
    }

    const mappedIds = ids.map((id) => `fieldId == "${id}"`).join(' || ');

    return sanityClient.fetch<
      { id: string; titleKey: GenericTypes.TranslationLabel }[]
    >(
      `
      *[_type == $type && (${mappedIds})]{
        "id": fieldId,
        "titleKey": title->key
      }
    `,
      { type: SanityDocumentType.TICKET_ELEMENT },
    );
  },
  getTicketStatusesConfig: async (
    country: GenericTypes.Country,
  ): Promise<StatusConfig[]> => {
    const response = await sanityClient.fetch<{
      statuses: {
        jiraKey: string;
        textKey: GenericTypes.TranslationLabel;
        colorHex: string;
        updateEnabled: boolean;
      }[];
    }>(
      `* [_type == $type && content.country == $country]{
        "statuses": content.ticketStatuses[]{
          "jiraKey": jiraKey.current,
          "textKey": name->key,
          "colorHex": color.hex,
          updateEnabled,
        }
      }[0]`,
      {
        country,
        type: SanityDocumentType.SETTINGS,
      },
    );

    return (response.statuses || []).map(({ textKey, ...rest }) => ({
      text: textKey,
      ...rest,
    }));
  },
  // jira
  getTicket: async (
    country: GenericTypes.Country,
    ticketId: string,
    customerId?: ProfileData['customerIdApi'],
  ): Promise<ServiceRequestDetailsApi> => {
    if (!customerId) {
      throw Error(errorKey);
    }

    const response = await jiraServiceClient.get<ServiceRequestDetailsApi>(
      `${country}/tenant/${customerId}/tickets/${ticketId}`,
    );

    try {
      const validationSchema = Yup.object().shape({
        status: Yup.string().required(),
      });
      await validationSchema.validate(response);
    } catch (err) {
      Sentry.captureException(err, {
        extra: {
          validatedData: response,
        },
      });
    }

    return response;
  },
  getTicketList: async (
    country: GenericTypes.Country,
    customerId?: ProfileData['customerIdApi'],
  ): Promise<ServiceRequestApi[]> => {
    const url = `${country}/tenant/${customerId}/tickets`;

    return jiraServiceClient.get<ServiceRequestApi[]>(url);
  },
  createTicket: (
    values: Record<string, number | number[] | string | AttachmentValue[]>,
    apartment: ICustomerRentalObject | null,
    country: GenericTypes.Country,
    user: ProfileData | null,
  ): Promise<ServiceRequestApi> => {
    if (!apartment || !user) {
      throw Error(errorKey);
    }

    const {
      buildingIdApi: buildingId,
      objectIdApi: apartmentId,
      rentalDetailsList,
      rentalObjectMyHome,
    } = apartment;

    if (
      !rentalObjectMyHome ||
      !rentalDetailsList ||
      rentalDetailsList.length === 0
    ) {
      throw Error(errorKey);
    }

    const {
      flatNumber,
      houseNumber,
      objectShortName,
      postalCode,
      streetAddress,
      addressInclFlatnumber,
    } = rentalObjectMyHome;
    const { rentalDealIdApi: rentalDealId } = rentalDetailsList[0];
    const {
      customerIdApi: customerId,
      email,
      firstName,
      mobile,
      lastName,
    } = user;
    const url = `${country}/tenant/${customerId}/tickets`;
    const payload = {
      apartment: {
        apartmentId,
        objectShortName,
        postalCode,
        buildingId,
        flatNumber,
        houseNumber,
        rentalDealId,
        streetAddress,
        addressInclFlatnumber,
      },
      user: { customerId, email, firstName, mobile, lastName },
      ...values,
    };

    return jiraServiceClient.post<unknown, ServiceRequestApi>(url, payload);
  },
  updateTicket: (
    ticketId: string,
    values: Record<string, unknown>,
    country: GenericTypes.Country,
    customerId?: ProfileData['customerIdApi'],
  ): Promise<void> => {
    if (!customerId) {
      throw Error(errorKey);
    }

    const url = `${country}/tenant/${customerId}/tickets/${ticketId}`;

    return jiraServiceClient.patch(url, values);
  },
  addComment: (
    ticketId: string,
    comment: string,
    country: GenericTypes.Country,
    customerId?: string,
  ): Promise<void> => {
    if (!customerId) {
      throw Error(errorKey);
    }

    const url = `${country}/tenant/${customerId}/tickets/${ticketId}/comments`;
    const payload = { comment };

    return jiraServiceClient.post<{ comment: string }, void>(url, payload);
  },
  handleConfirmationTerminateRequest: (
    ticketId: string,
    country: GenericTypes.Country,
    user: ProfileData,
    rentalDealId: string,
    isAgreed = false,
  ): Promise<void> => {
    const urlSuffix = isAgreed ? 'confirm-termination' : 'cancel-termination';

    const {
      customerIdApi: customerId,
      email,
      firstName,
      mobile,
      lastName,
    } = user;

    const url = `${country}/tenant/${customerId}/tickets/${ticketId}/${urlSuffix}`;
    const payload = {
      apartment: { rentalDealId },
      user: { customerId, email, firstName, mobile, lastName },
    };

    return jiraServiceClient.post<
      { apartment: { rentalDealId: string } },
      void
    >(url, payload);
  },
  getAttachment: async (attachment: AttachmentValue): Promise<string> => {
    const { url, value } = attachment;
    const endpoint = url || value || '';

    if (!endpoint) {
      return '';
    }

    const { base64, type } = await jiraServiceClient.get<{
      base64: string;
      type: string;
    }>(endpoint);

    return type === PDF_MEDIA_TYPE
      ? URL.createObjectURL(getBlobFromBase64(base64, type))
      : `data:${type};base64,${base64}`;
  },
};
