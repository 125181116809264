import { TranslationLabels } from '@generated/translation-labels';
import {
  AttachmentValue,
  InputValue,
  SwitchValue,
} from '@shared/FormV2/fields';
import { FieldConfig, FormProps } from '@shared/FormV2/types';
import { ICustomerMidleLayerApi2, ICustomerRentalObject } from '@shared/models';
import { ServiceRequestVisibilityEnum } from '@shared/enums';
import { COUNTRY_TICKETING_MODEL } from '../../../+ticketing/ticketing.const';
import { ticketingClient } from '../../ticketing.client';

export type Values = Record<string, AttachmentValue | InputValue | SwitchValue>;

export const getFields = (
  country: GenericTypes.Country,
): FieldConfig<Values>[] => {
  const { jiraFields } = COUNTRY_TICKETING_MODEL[country];

  return [
    {
      componentType: 'input',
      field: {
        name: jiraFields.SUMMARY,
        initialValue: '',
      },
      props: {
        type: 'text',
        label: TranslationLabels.ticketingGeneralSummaryLabel,
        required: true,
      },
    },
    {
      componentType: 'input',
      field: {
        name: jiraFields.DESCRIPTION,
        initialValue: '',
      },
      props: {
        type: 'textarea',
        label: TranslationLabels.ticketingGeneralDescriptionLabel,
        required: true,
      },
    },
    {
      componentType: 'attachment',
      field: {
        name: jiraFields.ATTACHMENT,
        initialValue: [],
      },
      props: {
        accept: ['.png', '.jpg', '.jpeg', '.pdf'],
        helperTextKey:
          TranslationLabels.ticketingServiceRequestAttachmentFileHelperText,
        helperTextOptions: {
          formats: 'JPG, JPEG, PNG, PDF',
          maxSize: 100,
        },
        isMultiple: true,
        label: TranslationLabels.formFieldsAttachmentFileLabel,
        onPreviewClick$: ticketingClient.getAttachment$,
      },
    },
    {
      componentType: 'switch',
      field: {
        name: jiraFields.VISIBILITY,
        initialValue: ServiceRequestVisibilityEnum.PUBLIC,
      },
      props: {
        label: TranslationLabels.ticketingServiceRequestVisibilityLabel,
        checkedLabelKey:
          TranslationLabels.ticketingServiceRequestVisibilityTypePrivate,
        checkedValue: ServiceRequestVisibilityEnum.PRIVATE,
        uncheckedValue: ServiceRequestVisibilityEnum.PUBLIC,
        checkedValueDescriptionKey:
          TranslationLabels.ticketingServiceRequestVisibilityPrivateDescriptionText,
        helperTextKey:
          TranslationLabels.ticketingServiceRequestVisibilityHelperText,
      },
    },
  ];
};
export const getConfig = (
  country: GenericTypes.Country,
  apartment: ICustomerRentalObject | null,
  user: ICustomerMidleLayerApi2 | null,
  requestTypeId: number | undefined,
): FormProps<Values>['config'] => ({
  onSubmit: (values) =>
    ticketingClient.createRequest$(
      country,
      values,
      apartment,
      requestTypeId,
      user,
    ),
});
