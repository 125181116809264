import { TranslationLabels } from '@generated/translation-labels';
import React, { FC } from 'react';
import * as Sentry from '@sentry/react';
import { ContactInfo } from '@shared/components';
import Button from '@material-ui/core/Button';
import qs from 'query-string';
import { ReactComponent as SingleNeutralActionsWarning } from '@heimstaden/icons-library/img/streamline-regular/users/geomertic-close-up-single-user-actions-neutral/single-neutral-actions-warning.svg';
import { useTranslation } from '@shared/translations';
import { useLocation } from 'react-router';
import Typography from '@material-ui/core/Typography';
import { useLogout } from '../../index';
import { PublicView } from '../index';
import { useStyles } from './error.styles';
import { ErrorsConfig, ErrorType } from './types';

export const InvalidCustomer: FC = () => {
  const { t } = useTranslation();
  const logout = useLogout();
  const { search } = useLocation();
  const classes = useStyles();

  let type = qs.parse(search)?.type;
  if (!type) {
    Sentry.captureMessage(`Error type not provided on the error page`);
    logout();
    return null;
  }
  if (Array.isArray(type)) {
    [type] = type;
  }

  const errorsConfig: ErrorsConfig = {
    invalidCustomer: {
      icon: SingleNeutralActionsWarning,
      titleKey: TranslationLabels.noAccountErrorTitle,
      textKey: TranslationLabels.noAccountErrorText,
      buttonTextKey: TranslationLabels.trySignUpAgainButton,
      onClick: () => {
        logout(); // it will automatically redirect to /login screen
      },
    },
  };

  const config = errorsConfig[type as ErrorType];

  if (!config) {
    Sentry.captureMessage(
      `Probably missing configuration for ${type} error type`,
    );
    logout();
    return null;
  }

  return (
    <PublicView>
      <Typography className={classes.headline}>
        <strong>{t(config.titleKey)}</strong>
      </Typography>
      <Typography className={classes.paragraph}>{t(config.textKey)}</Typography>
      <div className={classes.buttonWrapper}>
        <Button color="primary" variant="contained" onClick={config.onClick}>
          {t(config.buttonTextKey)}
        </Button>
      </div>
      <ContactInfo isDense isTitle noAddress />
    </PublicView>
  );
};

export default InvalidCustomer;
