import { AttachmentMainType } from '@shared/enums';
import { PDF_MEDIA_TYPE } from '@shared/helpers';
import { Action, State } from './type';
import {
  getBookedDates,
  getComments,
  getTenantsResponses,
  getTerminateAddress,
  getTerminateRentalIds,
  isFlowCompleted,
  isFlowStepResolved,
  parseFlowSteps,
} from '../helpers';

export const initialState: State = {
  address: undefined,
  apartmentNo: undefined,
  apartmentId: undefined,
  bookedDates: {},
  calendarWidgetConfig: undefined,
  comments: [],
  currentFlowStep: undefined,
  documents: [],
  flowSteps: [],
  isFlowCompleted: false,
  rentalDealId: undefined,
  sanityDocuments: [],
  tenantsResponses: undefined,
  ticketId: '',
};

export const reducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'CALENDAR_WIDGET_CONFIG_INIT': {
      const { payload: calendarWidgetConfig } = action;

      return {
        ...state,
        calendarWidgetConfig,
      };
    }
    case 'COMMENT_ADD': {
      const { payload: comment } = action;
      const comments = state.comments
        ? [...state.comments, comment]
        : [comment];

      return {
        ...state,
        comments,
      };
    }
    case 'CURRENT_FLOW_STEP_SET': {
      const { payload: eventType } = action;
      const currentFlowStep = state.flowSteps.find(
        (flowStep) => flowStep.eventType === eventType,
      );

      return {
        ...state,
        currentFlowStep,
      };
    }
    case 'FLOW_STEP_PATCH': {
      const { payload } = action;
      const { eventType, flowStepPatch } = payload;
      const flowSteps = state.flowSteps.map((flowStep) => {
        if (flowStep.eventType === eventType) {
          return {
            ...flowStep,
            ...flowStepPatch,
          };
        }

        return flowStep;
      });
      const currentFlowStep = flowSteps.find(
        (flowStep) => flowStep.eventType === state.currentFlowStep?.eventType,
      );

      return {
        ...state,
        currentFlowStep,
        flowSteps,
      };
    }
    case 'TERMINATION_DOCUMENTS_INIT': {
      const { payload: attachments } = action;
      const documents = attachments
        .filter(
          (attachment) =>
            attachment.mimetype === PDF_MEDIA_TYPE &&
            attachment.attachmentMainType ===
              AttachmentMainType.MoveOutAttachment,
        )
        .sort((a, b) => a.seqno - b.seqno);

      return {
        ...state,
        documents,
      };
    }
    case 'TERMINATION_SANITY_DOCUMENTS_INIT': {
      const { payload: attachments } = action;
      const sanityDocuments = attachments.filter(
        (attachment) => attachment.url,
      );

      return {
        ...state,
        sanityDocuments,
      };
    }
    case 'TERMINATION_FLOW_STEPS_INIT': {
      const { payload: flowSteps } = action;
      const { address, apartmentNo } = getTerminateAddress(flowSteps);
      const { apartmentId, rentalDealId } = getTerminateRentalIds(flowSteps);
      const flowCompleted = isFlowCompleted(flowSteps);
      const currentFlowStep = flowCompleted
        ? flowSteps[flowSteps.length - 1]
        : flowSteps.find((flowStep) => !isFlowStepResolved(flowStep));
      const lastAllowedFlowStep = currentFlowStep
        ? { ...currentFlowStep }
        : undefined;
      const tenantsResponses = getTenantsResponses(flowSteps);
      const bookedDates = getBookedDates(flowSteps);
      const comments = getComments(flowSteps);
      const steps = parseFlowSteps(flowSteps);

      return {
        ...state,
        address,
        apartmentNo,
        apartmentId,
        bookedDates,
        comments,
        currentFlowStep,
        flowSteps: steps,
        isFlowCompleted: flowCompleted,
        lastAllowedFlowStep,
        rentalDealId,
        tenantsResponses,
      };
    }
    case 'TICKET_ID_INIT': {
      const { payload: ticketId } = action;

      return {
        ...state,
        ticketId,
      };
    }
    default: {
      return { ...state };
    }
  }
};
