import React, { FC } from 'react';
import { ReactComponent as ArrowCircleRightIcon } from '@heimstaden/icons-library/img/streamline-regular/arrows-diagrams/arrows/arrow-circle-right.svg';
import { Icon } from '@shared/components';
import { useStyles } from './arrow-right-icon.styles';

type Props = {
  width: number;
  height: number;
};

export const ArrowRightIcon: FC<Props> = (props: Props) => {
  const classes = useStyles();

  return (
    <Icon {...props} className={classes.icon} icon={ArrowCircleRightIcon} />
  );
};
