import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '../../shared';

export const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      position: 'relative',
    },
    profile: {
      padding: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      borderTopLeftRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1),
      backgroundColor: palette.white,
      position: 'absolute',
      right: theme.spacing(4),
      top: -96,
      zIndex: 2,
    },
    text: {
      marginLeft: theme.spacing(1),
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightBold,
    },
  }),
);
