import { UserData } from '@shared/types';
import { createContext } from 'react';
import { initialState } from './reducer';
import { State } from './type';
import { User } from '../../types';

export type ContextValue = State & {
  fetchHeimUserData: (
    email: User['email'],
    phone: User['phone'],
    isLogin?: boolean,
  ) => Promise<UserData | void>;
};

export const Context = createContext<ContextValue>({
  ...initialState,
  fetchHeimUserData: () => Promise.resolve(),
});
