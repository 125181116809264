import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    socialMedia: {
      position: 'absolute',
      top: 0,
      right: 0,
      [theme.breakpoints.down('sm')]: {
        position: 'static',
        marginBottom: theme.spacing(4),
        textAlign: 'center',
      },
    },
  }),
);
