import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import background from '../../../../+ticketingV2/assets/maintenance-responsibility.svg';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: 0,
      position: 'relative',
      '&:before': {
        backgroundImage: `url(${background})`,
        backgroundPosition: 'bottom right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        bottom: 0,
        content: "''",
        display: 'block',
        height: '100%',
        maxHeight: 170,
        position: 'absolute',
        right: 0,
        width: '50%',
        zIndex: 1,
      },
    },
    content: {
      padding: theme.spacing(5, 4, 6),
    },
    header: {
      fontWeight: 'bold',
      margin: theme.spacing(1, 0, 2),
    },
    ticketNumber: {
      color: theme.palette.primary.main,
    },
    status: {
      marginBottom: theme.spacing(1),
    },
    comments: {
      marginTop: theme.spacing(4),
    },
  }),
);
