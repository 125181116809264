import React from 'react';
import { GeneralError, Main } from '@shared/components';

export const LayoutFallback = (): JSX.Element => {
  return (
    <Main>
      <GeneralError hideGoBackButton />
    </Main>
  );
};
