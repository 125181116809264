import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    image: {
      borderRadius: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    description: {
      marginBottom: theme.spacing(3),
      color: palette.doveGray,
    },
    button: {
      display: 'flex',
      marginLeft: 'auto',
      marginTop: 'auto',
    },
    icon: {
      marginLeft: theme.spacing(1),

      '& svg': {
        height: 24,
        width: 24,
      },

      '& path': {
        stroke: palette.white,
        strokeWidth: 4,
      },
    },
  }),
);
