import { TranslationLabels } from '@generated/translation-labels';
import { FieldConfig } from '@shared/FormV2/types';
import { FormikConfig } from 'formik';
import * as Yup from 'yup';

export function prepareValidationSchema<TFormValues>(
  fieldsConfig: FieldConfig<TFormValues>[],
): FormikConfig<TFormValues>['validationSchema'] {
  const shape: { [key: string]: Yup.AnySchema } = {};

  fieldsConfig.forEach((config) => {
    const { name, validationSchema } = config.field;
    const { required } = config.props;

    if (validationSchema) {
      shape[name] = required
        ? validationSchema.required(TranslationLabels.formErrorsRequired)
        : validationSchema;
    } else if (required) {
      shape[name] = Yup.mixed().required(TranslationLabels.formErrorsRequired);
    }
  });

  return Yup.object().shape(shape);
}
