import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import React, { FC, Suspense } from 'react';
import { SENTRY_LOCATION_TAG } from '@shared/consts';
import { RouteComponentProps, Switch } from 'react-router';
import { Redirect, Route } from 'react-router-dom';
import {
  MaintenanceProvider,
  renderRoutes,
  routes,
  Spinner,
  TranslationsProvider,
} from './shared';

export const App: FC = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <Sentry.ErrorBoundary
      beforeCapture={(scope) => {
        scope.setTag(SENTRY_LOCATION_TAG, 'App');
      }}
    >
      <QueryClientProvider client={queryClient}>
        <TranslationsProvider>
          <MaintenanceProvider>
            <Suspense fallback={<Spinner />}>
              <Switch>
                {/* Removes trailing slashes */}
                <Route
                  path="/:url*(/+)"
                  exact
                  strict
                  render={({ location }) => (
                    <Redirect to={location.pathname.replace(/\/+$/, '')} />
                  )}
                />
                {/* Removes duplicate slashes in the middle of the URL */}
                <Route
                  path="/:url(.*//+.*)"
                  exact
                  strict
                  render={({
                    match,
                  }: RouteComponentProps<Record<string, string>>) => (
                    <Redirect
                      to={`/${match.params.url.replace(/\/\/+/, '/')}`}
                    />
                  )}
                />
                {renderRoutes(routes)}
              </Switch>
            </Suspense>
          </MaintenanceProvider>
        </TranslationsProvider>
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  );
};
