import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';
import { lighten } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      outline: `3px solid ${palette.internationalOrange}`,
      borderRadius: theme.spacing(1),
    },
    content: {
      padding: theme.spacing(5, 7),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(3),
      },
    },
    actionsWrapper: {
      borderRadius: theme.spacing(0, 0, 1, 1),
      background: lighten(palette.internationalOrange, 0.95),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    actions: {
      margin: theme.spacing(3),
      '& button + button': {
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
          marginLeft: 0,
          marginTop: theme.spacing(2),
        },
      },
    },
    button: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
  }),
);
