import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    headline: {
      marginBottom: theme.spacing(5),
      marginTop: theme.spacing(5),
      fontSize: theme.typography.pxToRem(40),
      lineHeight: 'inherit',
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.pxToRem(32),
      },
    },
    paragraph: {
      marginBottom: theme.spacing(1),
      whiteSpace: 'pre-line',
      fontSize: theme.typography.pxToRem(20),
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.pxToRem(16),
      },
    },
    buttonWrapper: {
      margin: theme.spacing(5, 0),
    },
  }),
);
