import React, { FC, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import { Icon } from '@shared/components';
import { useTranslation } from '@shared/translations';
import { ReactComponent as FolderFileIcon } from '@heimstaden/icons-library/img/streamline-regular/files-folders/folders/folder-file.svg';
import { useApartment } from '../../../../+apartment';
import { useStyles } from './document.styles';
import { useTermination } from '../../state';

type Props = {
  labelKey: GenericTypes.TranslationLabel;
  to: GenericTypes.URL;
};

export const DocumentRedirect: FC<Props> = (props) => {
  const { labelKey, to } = props;
  const classes = useStyles();
  const history = useHistory();
  const { data: apartmentData, changeDefaultApartment } = useApartment();
  const { rentalDealId } = useTermination();
  const { t } = useTranslation();
  const apartment = useMemo(
    () =>
      apartmentData?.allRentalObjects?.find(
        (apartment) => apartment.id.toString() === rentalDealId,
      ),
    [apartmentData?.allRentalObjects, rentalDealId],
  );
  const handleClick = useCallback(async () => {
    if (!apartment) {
      return;
    }

    await changeDefaultApartment(apartment);
    history.push({ pathname: to });
  }, [apartment, changeDefaultApartment, history, to]);

  return apartment ? (
    <ButtonBase className={classes.button} onClick={handleClick}>
      <Icon icon={FolderFileIcon} height={16} width={16} />
      <Typography className={classes.label}>{t(labelKey)}</Typography>
    </ButtonBase>
  ) : null;
};

DocumentRedirect.displayName = 'DocumentRedirect';
