import { instagramListMediaClient } from '@shared/http';
import * as Sentry from '@sentry/react';

export type Media = {
  id: string;
  mediaUrl: GenericTypes.URL;
  permalink: GenericTypes.URL;
  mediaType: 'IMAGE' | 'VIDEO' | 'CAROUSEL_ALBUM';
  thumbnailUrl: GenericTypes.URL | null; // Only available on VIDEO Media
};

export const api = {
  getMediaList: async (country: GenericTypes.Country): Promise<Media[]> => {
    const response = await instagramListMediaClient(country).get<Media[]>('');
    if (!Array.isArray(response)) {
      Sentry.captureException(response);
      return [];
    }
    return response;
  },
};
