import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    dateArea: {
      margin: theme.spacing(3, 0),
    },
    inputControl: {
      height: theme.spacing(7),
      margin: theme.spacing(0, 3, 2, 0),
      maxWidth: theme.spacing(34),
      width: '100%',
    },
    input: {
      borderRadius: theme.spacing(2),
    },
    button: {
      height: theme.spacing(7),
      minWidth: theme.spacing(16),
    },
    icon: {
      marginLeft: theme.spacing(1),
    },
    wrapper: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    container: {
      backgroundColor: palette.white,
      border: `1px solid ${palette.silver}`,
      borderRadius: theme.spacing(2),
      boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.12)',
      padding: theme.spacing(4),
    },
    suggestDate: {
      marginTop: theme.spacing(2),
    },
    suggestDateSwitch: {
      padding: 0,
      margin: theme.spacing('6px', 2, 0, 0),
    },
    suggestDateTitle: {
      color: palette.doveGray,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(18),
    },
    suggestDateDescription: {
      color: palette.dustyGray,
      fontSize: theme.typography.pxToRem(14),
    },
    spinner: {
      marginLeft: theme.spacing(2),
    },
  }),
);
