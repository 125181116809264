import React, { FC, useCallback, useReducer } from 'react';
import { api } from '../../profile.respository';
import { NotificationPermissions } from '../../types';
import { Context } from './context';
import { initialState, reducer } from './reducer';
import { State } from './type';

export const Provider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const updateData = (payload: Partial<State>): void => {
    dispatch({ type: 'PROFILE_UPDATE', payload });
  };
  const updateNotificationPermissions = (
    payload: NotificationPermissions,
  ): void => {
    dispatch({ type: 'PERMISSIONS_UPDATE', payload });
  };
  const fetchData$ = useCallback(async () => {
    const [profileData, permissionsData] = await Promise.all([
      api.getMyProfile(),
      api.getNotificationPermissions(),
    ]);

    dispatch({
      type: 'PROFILE_INIT',
      payload: profileData,
    });

    dispatch({
      type: 'PERMISSIONS_INIT',
      payload: permissionsData,
    });

    return profileData.customerIdApi;
  }, []);

  return (
    <Context.Provider
      value={{
        ...state,
        getProfileData$: fetchData$,
        updateProfileData: updateData,
        updateNotificationPermissions,
      }}
    >
      {children}
    </Context.Provider>
  );
};

Provider.displayName = 'ProfileProvider';
