import Button, { ButtonProps } from '@material-ui/core/Button';
import { Icon, Spinner } from '@shared/components';
import React, { FC, SVGProps } from 'react';
import { useTranslation } from '@shared/translations';
import { useStyles } from './loading-button.styles';

type Props = ButtonProps & {
  isFetching: boolean;
  label: GenericTypes.TranslationLabel;
  loadingLabel?: GenericTypes.TranslationLabel;
  icon?: FC<SVGProps<SVGSVGElement>>;
};

export const LoadingButton: FC<Props> = ({
  isFetching,
  label,
  loadingLabel,
  icon,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Button
      {...props}
      endIcon={icon && <Icon icon={icon} width={22} height={22} />}
    >
      {isFetching ? (
        <>
          {t(loadingLabel || label)}
          <div className={classes.spinner}>
            <Spinner color="inherit" size={16} />
          </div>
        </>
      ) : (
        t(label)
      )}
    </Button>
  );
};

LoadingButton.displayName = 'LoadingButton';
