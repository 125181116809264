import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) => {
  return createStyles({
    quote: {
      borderLeft: `2px solid ${palette.lightGray}`,
      color: palette.doveGray,
      fontStyle: 'italic',
      paddingLeft: theme.spacing(2),
    },
    listBullets: {
      listStyle: 'disc',
      marginLeft: theme.spacing(2),
      margin: 'revert',
    },
  });
});
