import { TranslationLabels } from '@generated/translation-labels';
import { ICustomerRentalObject } from '@shared/models';
import {
  parse,
  format,
  add,
  isValid as isDateValid,
  addHours,
  formatISO,
} from 'date-fns';

import {
  BookingDate,
  BookingMode,
  CalendarDatesRange,
  CreateBookingPayload,
  JiraTicketId,
  TerminationBookingFlowStep,
  TerminationFlowStepEventType,
} from '../../types';
import { JiraProjectId } from '../enums';

interface GetBookingMetadataParams {
  jiraTicketId: JiraTicketId;
  jiraProjectId: JiraProjectId;
  jiraIssueTypeId: string;
  apartmentData: ICustomerRentalObject | null;
}
export const getBookingWidgetMetaData = ({
  jiraProjectId,
  jiraTicketId,
  jiraIssueTypeId,
  apartmentData,
}: GetBookingMetadataParams): CreateBookingPayload['meta'] => {
  return {
    jira_ticket_id: jiraTicketId,
    jira_project_id: jiraProjectId,
    jira_issue_type_id: jiraIssueTypeId,
    rental_deal_id_api:
      apartmentData?.rentalObjectMyHome?.rentalDealIdApi || '',
  };
};

export const getValidRange = (
  bookingDatesRange: TerminationBookingFlowStep['bookingDatesRange'],
): CalendarDatesRange => {
  const start = bookingDatesRange?.start
    ? new Date(bookingDatesRange.start)
    : undefined;
  const end = bookingDatesRange?.end
    ? new Date(bookingDatesRange.end)
    : undefined;

  return {
    ...(isDateValid(start) && { start }),
    ...(isDateValid(end) && { end }),
  };
};

const eventTypeToInputLabelKeyMap: Partial<
  Record<TerminationFlowStepEventType, GenericTypes.TranslationLabel>
> = {
  MoveOutInspection: TranslationLabels.inspectionDateInputLabel,
  MoveOut: TranslationLabels.moveOutDateInputLabel,
};

export const getCalendarInputLabelKey = (
  eventType: TerminationFlowStepEventType,
): GenericTypes.TranslationLabel =>
  eventTypeToInputLabelKeyMap[eventType] || '';

export const getSubmitButtonLabelKey = (
  mode: TerminationBookingFlowStep['mode'],
  isDateSuggestedByUser: boolean,
): GenericTypes.TranslationLabel => {
  if (mode === BookingMode.RESCHEDULING && isDateSuggestedByUser)
    return TranslationLabels.suggestReschedulingButton;

  if (mode === BookingMode.RESCHEDULING && !isDateSuggestedByUser)
    return TranslationLabels.bookingRescheduleActionTitle;

  if (mode !== BookingMode.RESCHEDULING && isDateSuggestedByUser)
    return TranslationLabels.suggestButton;

  return TranslationLabels.inspectionDateButtonLabel;
};

const eventTypeToBookingTitleKeyMap: Partial<
  Record<TerminationFlowStepEventType, GenericTypes.TranslationLabel>
> = {
  MoveOutInspection: TranslationLabels.terminationMoveOutInspectionDateTitle,
  MoveOut: TranslationLabels.terminationMoveOutDateTitle,
};

export const getBookingTitleKey = (
  eventType: TerminationFlowStepEventType,
): GenericTypes.TranslationLabel =>
  eventTypeToBookingTitleKeyMap[eventType] || '';

export const changeHour = (
  action: 'add' | 'remove',
  differenceInHours: number,
  hourToChange?: GenericTypes.Time,
): GenericTypes.Time | undefined => {
  if (!hourToChange) {
    return undefined;
  }

  const hourToChangeAsDate = parse(hourToChange, 'HH:mm:ss', Date.now());

  const sign = action === 'remove' ? '-' : '';
  const newHourAsDate = add(hourToChangeAsDate, {
    hours: Number(`${sign}${differenceInHours}`),
  });

  return format(newHourAsDate, 'HH:mm:ss');
};

export const getBookingEndDate = (bookingDate: BookingDate): BookingDate => {
  const startDate = new Date(bookingDate);
  const endDate = addHours(startDate, 1);

  return formatISO(endDate);
};

export const getBookingOperationErrorLabelKey = (
  errorMessage?: string,
): GenericTypes.TranslationLabel => {
  switch (errorMessage) {
    case 'Event canceled too many times': {
      return TranslationLabels.bookingCanceledTooManyTimesError;
    }
    case 'Too late to cancel booking': {
      return TranslationLabels.bookingCancelTooLateError;
    }
    default: {
      return TranslationLabels.formGlobalErrorMessage;
    }
  }
};
