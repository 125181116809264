import { TranslationLabels } from '@generated/translation-labels';
import React, { FC, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from '@shared/translations';
import { InfoBox } from '@shared/components';
import { format } from 'date-fns';
import {
  JiraTicketId,
  TerminationBookingFlowStep,
  TimeKitProjectId,
} from '../../../types';
import { JiraProjectId } from '../../enums';
import { CalendarModal } from '../CalendarModal/calendar-modal.component';
import { PendingReasons } from '../PendingReasons/pending-reasons';
import { FlowStepSummary } from '../FlowStepSummary/flow-step-summary.component';
import { Details } from '../Details/details.component';
import { getEventTypeTranslationLabelKey } from '../../helpers';
import { MoveOutInspectionInstruction } from '../MoveOutInspectionInstruction/move-out-inspection-instruction.component';
import { MoveOutInstruction } from '../MoveOutInstruction/move-out-instruction.component';
import { useStyles } from './booking.styles';

interface Props {
  flowStep: TerminationBookingFlowStep;
  jiraTicketId: JiraTicketId;
  jiraProjectId: JiraProjectId;
  jiraIssueTypeId: string;
  projectId: TimeKitProjectId;
}

export const Booking: FC<Props> = (props) => {
  const {
    flowStep,
    jiraIssueTypeId,
    jiraProjectId,
    jiraTicketId,
    projectId,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const suggestedDate = flowStep.suggestedDate?.value;

  const isReadyToBook = useMemo(
    () => flowStep.pendingReasons.length === 0 && !suggestedDate,
    [flowStep.pendingReasons.length, suggestedDate],
  );
  const isMoveOutInspection = flowStep.eventType === 'MoveOutInspection';
  const descriptionLabelKey = useMemo(
    () =>
      isMoveOutInspection
        ? TranslationLabels.terminationFlowStepMoveOutInspectionDescriptionText
        : TranslationLabels.terminationFlowStepMoveOutDescription,
    [isMoveOutInspection],
  );

  const calendarBookingHelperText = useMemo(
    () =>
      isMoveOutInspection
        ? TranslationLabels.calendarBookingHelperText
        : TranslationLabels.calendarMoveOutBookingHelperText,
    [isMoveOutInspection],
  );
  const instructionComponent = useMemo(
    () =>
      isMoveOutInspection ? (
        <MoveOutInspectionInstruction />
      ) : (
        <MoveOutInstruction />
      ),
    [isMoveOutInspection],
  );

  return (
    <Grid container spacing={8}>
      <Grid item xs={12}>
        <InfoBox
          type="info"
          messageKey={TranslationLabels.terminationFlowStepMoveOutInfoBox}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FlowStepSummary
          labelKey={getEventTypeTranslationLabelKey(flowStep.eventType)}
        />
        {suggestedDate && (
          <Typography>
            {t(TranslationLabels.suggestedDateMessage, {
              suggestedDate: format(new Date(suggestedDate), 'cccc P p'),
            })}
          </Typography>
        )}
        {isReadyToBook && descriptionLabelKey && (
          <Typography component="div" className={classes.description}>
            {t(descriptionLabelKey)}
          </Typography>
        )}
        {isReadyToBook && (
          <Grid className={classes.bookingForm} item xs={12}>
            <Typography variant="h4" className={classes.calendarHelperText}>
              <strong>{t(calendarBookingHelperText)}</strong>
            </Typography>
            <CalendarModal
              key={`calendar-modal-${flowStep.step}-${flowStep.mode}`}
              flowStep={flowStep}
              projectId={projectId}
              jiraProjectId={jiraProjectId}
              jiraTicketId={jiraTicketId}
              jiraIssueTypeId={jiraIssueTypeId}
              withStatement={isMoveOutInspection}
            />
          </Grid>
        )}
        <PendingReasons
          eventType={flowStep.eventType}
          pendingReasons={flowStep.pendingReasons}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Details />
      </Grid>
      <Grid item xs={12} md={12}>
        {isReadyToBook && instructionComponent}
      </Grid>
    </Grid>
  );
};
