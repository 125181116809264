import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    link: {
      color: palette.internationalOrange,
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    item: {
      paddingBottom: theme.spacing(2),
      '&:last-child': {
        paddingBottom: 0,
      },
    },
  }),
);
