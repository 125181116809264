import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    emptyRow: {
      alignItems: 'center',
      display: 'flex',
      padding: theme.spacing(1.5, 4),
    },
    icon: {
      marginLeft: theme.spacing(1),
    },
  }),
);
