import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    icon: {
      cursor: 'pointer',
      margin: theme.spacing(1),
    },
    input: {
      cursor: 'pointer',
    },
  }),
);
