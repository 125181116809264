import PropTypes from 'prop-types';
import React, { FC } from 'react';
import { useStyles } from './bar.styles';

type Props = {
  className?: string;
};

export const Bar: FC<Props> = ({ className }) => {
  const classes = useStyles();
  const barClassNames = [classes.bar, ...(className ? [className] : [])].join(
    ' ',
  );

  return (
    <div className={barClassNames}>
      <span />
      <span />
      <span />
    </div>
  );
};
Bar.propTypes = {
  className: PropTypes.string,
};
Bar.defaultProps = {
  className: undefined,
};
