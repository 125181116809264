import React, { FC, ReactNode } from 'react';
import { SlateText } from '../types';

type Props = {
  attributes: unknown;
  leaf: SlateText;
  children: ReactNode;
};

export const Leaf: FC<Props> = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    // eslint-disable-next-line no-param-reassign
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    // eslint-disable-next-line no-param-reassign
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    // eslint-disable-next-line no-param-reassign
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    // eslint-disable-next-line no-param-reassign
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};
