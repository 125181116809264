import React, { FC } from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import { palette } from '@shared/consts';
import { ImagePlaceholder } from '@shared/components';
import { ReactComponent as InstagramIcon } from './assets/instagram-icon.svg';
import { useStyles } from './image-link.styles';
import { SocialMediaCircle } from './social-media-circle.component';

type Props = {
  href: GenericTypes.URL;
  imageUrl: GenericTypes.URL;
};

export const ImageLink: FC<Props> = ({ href, imageUrl }: Props) => {
  const classes = useStyles({ imageUrl });

  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={classes.link}
    >
      {imageUrl ? (
        <>
          <ButtonBase className={classes.image} component="div" />
          <SocialMediaCircle
            icon={InstagramIcon}
            colorHex={palette.instagram}
            className={classes.instagramCircle}
          />
        </>
      ) : (
        <ImagePlaceholder className={classes.imagePlaceholder} />
      )}
    </a>
  );
};
