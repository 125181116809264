import * as Sentry from '@sentry/react';
import { SENTRY_LOCATION_TAG } from '@shared/consts';
import React, { Fragment, ReactNode } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Routes } from './routes.type';

export const renderRoutes = (routes: Routes = []): ReactNode =>
  routes.map((props) => {
    const { component, exact, fallback, guard, layout, path, routes } = props;
    const Guard = guard || Fragment;
    const Layout = layout || Fragment;
    const Component = component || Fragment;

    return (
      <Route
        key={path}
        path={path}
        exact={exact}
        render={(props) => (
          <Sentry.ErrorBoundary
            fallback={fallback}
            beforeCapture={(scope) => {
              scope.setTag(SENTRY_LOCATION_TAG, 'routing');
            }}
            showDialog
          >
            <Guard>
              <Layout>
                {routes ? (
                  <Switch>{renderRoutes(routes)}</Switch>
                ) : (
                  <Component {...props} />
                )}
              </Layout>
            </Guard>
          </Sentry.ErrorBoundary>
        )}
      />
    );
  });
