import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
      paddingTop: 0,
      whiteSpace: 'pre-line',
      '& a': {
        color: theme.palette.primary.main,
      },
    },
  }),
);
