import { TranslationLabels } from '@generated/translation-labels';
import {
  AttachmentFilter,
  CustomerApartmentType,
  CustomerOperationMidlelayer,
  DataSourceEnum,
  UpdateCustomerRule,
} from '@shared/enums';
import {
  CustomerMidleLayerApi2,
  DefaultApartment,
  IContractRole,
  IRentalObjectMyHome,
  RentalObjectHead,
} from '@shared/models';
import React, { FC, useCallback, useReducer } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from '@shared/translations';
import { profileApi, useProfile } from '../../../+profile';
import { Context } from './context';
import { initialState, reducer } from './reducer';
import { api } from '../../apartment.respository';
import { invoiceApi, useInvoice } from '../../../+invoice';
import { InvoiceFilter } from '../../../+invoice/invoice.type';
import { useAuth } from '../../../+auth';

export const Provider: FC = ({ children }) => {
  const UNSPECIFIC_RENTAL_DEAL_ID_API = '0';
  const [state, dispatch] = useReducer(reducer, initialState);
  const { dataSource, fetchHeimUserData } = useAuth();
  const { updateInvoiceData } = useInvoice();
  const { data: profileData, updateProfileData } = useProfile();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const getInhabitants = useCallback(
    (
      customerId: CustomerMidleLayerApi2['customerIdApi'],
      rentalObject: RentalObjectHead,
    ) => {
      const { rentalDetailsList, rentalObjectMyHome } = rentalObject;
      const tenants: IContractRole[] = [];
      const residents: IContractRole[] = [];
      const activeRentalObjectDetails = rentalDetailsList?.find(
        ({ rentalDealIdApi }) =>
          rentalDealIdApi.toString() ===
          rentalObjectMyHome.rentalDealIdApi.toString(),
      );

      activeRentalObjectDetails?.contactRoleList?.forEach((contact) => {
        const { customerIdApi, isDepositOwner, isResident, isTenant } = contact;
        const isCurrentUser = customerIdApi === customerId.toString();

        if (isCurrentUser) {
          updateProfileData({
            isDepositOwner: isDepositOwner || false,
            isResident: isResident || false,
            isTenant: isTenant || false,
          });
        }

        if (isResident && !isTenant) {
          residents.push(contact);
        }

        if (isTenant && !isCurrentUser) {
          tenants.push(contact);
        }
      });

      dispatch({
        type: 'APARTMENT_INHABITANTS_INIT',
        payload: { residents, tenants },
      });
    },
    [updateProfileData],
  );
  const fetchAttachments = useCallback(
    async (apartments: IRentalObjectMyHome[]) => {
      Promise.all(
        apartments.map(async (apartment) => {
          const apartmentId = apartment?.objectIdApi;
          const images = await api.getAttachments(
            AttachmentFilter.MarketingPublished,
            apartmentId,
            UNSPECIFIC_RENTAL_DEAL_ID_API,
          );

          dispatch({
            payload: { [apartment.objectIdApi]: images },
            type: 'APARTMENT_IMAGES_INIT',
          });
        }),
      );
    },
    [],
  );
  /* const fetchDocuments = useCallback(
    async (apartments: IRentalObjectMyHome[]) => {
      await Promise.all(
        apartments.map(async (apartment) => {
          const docAttachments = await api.getAttachments(
            apartment.objectIdApi,
            apartment.rentalDealIdApi,
            AttachmentFilter.MyHomeDocuments,
          );
          // eslint-disable-next-line no-console
          console.log(
            'docAttachments',
            apartment.objectIdApi,
            apartment.rentalDealIdApi,
            docAttachments,
          );
          dispatch({
            payload: {
              [(apartment.objectIdApi,
              apartment.rentalDealIdApi)]: docAttachments,
            },
            type: 'APARTMENT_DOCUMENTS_INIT',
          });
        }),
      );
    },
    [],
  ); */
  const fetchData$ = useCallback(
    async (customerId: CustomerMidleLayerApi2['customerIdApi']) => {
      const payload = await api.getRentalObject();
      const { allRentalObjects, rentalObjectMyHome } = payload;
      const apartments =
        allRentalObjects ||
        (rentalObjectMyHome
          ? [{ ...rentalObjectMyHome, objectIdApi: payload.objectIdApi }]
          : []);

      dispatch({ type: 'APARTMENT_INIT', payload });
      dispatch({
        type: 'APARTMENT_IMAGES_SET_FETCHING',
        payload: {
          isFetching: true,
        },
      });
      getInhabitants(customerId, payload);
      fetchAttachments(apartments);
      // fetchDocuments(apartments); // TODO: Documents should be fetched after: https://fredensborg.atlassian.net/browse/MYH-238
    },
    [fetchAttachments, getInhabitants],
  );
  const updateData = useCallback(
    (payload: RentalObjectHead): void => {
      if (!profileData?.customerIdApi) {
        return;
      }

      getInhabitants(profileData?.customerIdApi, payload);
      dispatch({ type: 'APARTMENT_INIT', payload });
    },
    [getInhabitants, profileData?.customerIdApi],
  );
  const handleDefaultApartmentChange = useCallback(
    async ({ rentalDealIdApi }: IRentalObjectMyHome): Promise<void> => {
      const shouldUseObjectIdApi = dataSource !== DataSourceEnum.HeimNorway;
      if (
        !profileData ||
        !rentalDealIdApi ||
        (shouldUseObjectIdApi &&
          rentalDealIdApi === state.data?.rentalObjectMyHome?.objectIdApi) ||
        (!shouldUseObjectIdApi &&
          state.data?.rentalObjectMyHome?.id.toString() === rentalDealIdApi)
      ) {
        return;
      }

      const defaultApartment: DefaultApartment = {
        ref1Type: CustomerApartmentType.RENTAL,
        ref1Key: rentalDealIdApi,
      };

      await profileApi.updateMyProfile(
        {
          ...profileData,
          customerUpdate: defaultApartment,
          updateCustomerRule: UpdateCustomerRule.UpdateDefaultApartment,
        },
        CustomerOperationMidlelayer.UpdateDefaultApartment,
      );
      await fetchHeimUserData(profileData.email, profileData.mobile);

      const [rentalData, invoiceData] = await Promise.all([
        api.getRentalObject(),
        invoiceApi.getInvoices(
          InvoiceFilter.PendingOrNew,
          profileData?.customerIdApi,
        ),
      ]);

      updateData(rentalData);
      updateInvoiceData(invoiceData);
      enqueueSnackbar(t(TranslationLabels.apartmentListSuccessMessage), {
        autoHideDuration: 3000,
        variant: 'success',
      });
    },
    [
      dataSource,
      enqueueSnackbar,
      fetchHeimUserData,
      profileData,
      state.data?.rentalObjectMyHome?.id,
      state.data?.rentalObjectMyHome?.objectIdApi,
      t,
      updateData,
      updateInvoiceData,
    ],
  );

  return (
    <Context.Provider
      value={{
        ...state,
        getApartmentData$: fetchData$,
        changeDefaultApartment: handleDefaultApartmentChange,
        updateApartmentData: updateData,
      }}
    >
      {children}
    </Context.Provider>
  );
};

Provider.displayName = 'ApartmentProvider';
