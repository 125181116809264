import { Main } from '@shared/components';
import React, { FC } from 'react';
import { ApartmentProvider } from '../../../+apartment';
import { AuthProvider } from '../../../+auth';
import { InvoiceProvider } from '../../../+invoice';
import { ProfileProvider } from '../../../+profile';
import { TicketingProvider } from '../../../+ticketing';

export const Layout: FC = ({ children }) => {
  return (
    <AuthProvider>
      <ProfileProvider>
        <ApartmentProvider>
          <InvoiceProvider>
            <TicketingProvider>
              <Main>{children}</Main>
            </TicketingProvider>
          </InvoiceProvider>
        </ApartmentProvider>
      </ProfileProvider>
    </AuthProvider>
  );
};
