import React, { FC, useMemo } from 'react';
import {
  TerminationBookingFlowStep,
  TerminationFlowStep as TerminationFlowStepType,
} from '../../../types';
import { useTermination } from '../../state';
import { FlowStepCompleted } from '../FlowStepCompleted/flow-step-completed.component';
import { FlowStepDisabled } from '../FlowStepDisabled/flow-step-disabled.component';
import { Request } from '../Request/request.component';
import { Booking } from '../Booking/booking.component';
import { JiraProjectId } from '../../enums';

type Props = {
  flowStep?: TerminationFlowStepType;
};

const DEFAULT_STEP: TerminationBookingFlowStep = {
  eventType: 'Termination',
  step: 1,
  status: 'open',
  ticketId: '',
  issueTypeId: '',
  pendingReasons: [],
};

export const FlowStep: FC<Props> = (props) => {
  const { flowStep = DEFAULT_STEP } = props;
  const {
    calendarWidgetConfig,
    currentFlowStep,
    isFlowCompleted,
  } = useTermination();

  const terminationFlowComponent = useMemo(() => {
    if (isFlowCompleted) {
      return <FlowStepCompleted />;
    }

    if (currentFlowStep && flowStep.step > currentFlowStep.step) {
      return <FlowStepDisabled />;
    }

    switch (flowStep.eventType) {
      case 'Termination':
        return <Request flowStep={flowStep} />;
      case 'MoveOutInspection':
        if (
          !calendarWidgetConfig?.timekitProjects.MoveOutInspectionProjectId ||
          !flowStep.ticketId
        ) {
          return <FlowStepDisabled />;
        }

        return (
          <Booking
            flowStep={flowStep}
            jiraProjectId={JiraProjectId.Norway}
            jiraTicketId={flowStep.ticketId}
            jiraIssueTypeId={flowStep.issueTypeId}
            projectId={
              calendarWidgetConfig.timekitProjects.MoveOutInspectionProjectId
            }
          />
        );
      case 'MoveOut':
        if (
          !calendarWidgetConfig?.timekitProjects.MoveOutProjectId ||
          !flowStep.ticketId
        ) {
          return <FlowStepDisabled />;
        }

        return (
          <Booking
            flowStep={flowStep}
            jiraProjectId={JiraProjectId.Norway}
            jiraTicketId={flowStep.ticketId}
            projectId={calendarWidgetConfig.timekitProjects.MoveOutProjectId}
            jiraIssueTypeId={flowStep.issueTypeId}
          />
        );
      default:
        return <FlowStepDisabled />;
    }
  }, [
    calendarWidgetConfig?.timekitProjects.MoveOutInspectionProjectId,
    calendarWidgetConfig?.timekitProjects.MoveOutProjectId,
    currentFlowStep,
    flowStep,
    isFlowCompleted,
  ]);

  return <>{terminationFlowComponent}</>;
};

FlowStep.displayName = 'FlowStep';
