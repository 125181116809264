import { TranslationLabels } from '@generated/translation-labels';
import { AttachmentValue } from '@shared/components/Fields';
import * as Yup from 'yup';
import { COUNTRY_TICKETING_MODEL } from '../../../../ticketing.const';
import { api } from '../../../../ticketing.repository';
import { ElementField } from '../../../../ticketing.type';
import { RequestModel } from '../form.type';

export type TerminateContractRequestValues = Record<
  string,
  number | string | AttachmentValue[]
>;

export const getTerminateContractRequestModel: RequestModel<TerminateContractRequestValues> = (
  country,
  ticketValues,
  initialValues,
  user,
  apartment,
  readonly,
  t,
  options,
) => {
  const { jiraFields, jiraItems } = COUNTRY_TICKETING_MODEL[country];
  const newApartment = (initialValues?.[jiraFields.NEW_APARTMENT] as
    | ElementField
    | undefined)?.id;
  const notice = (initialValues?.[jiraFields.NOTICE] as
    | ElementField
    | undefined)?.id;
  const potentialImprovement = (initialValues?.[
    jiraFields.POTENTIAL_IMPROVEMENT
  ] as ElementField | undefined)?.value;
  const attachment = initialValues?.[jiraFields.ATTACHMENT] as
    | AttachmentValue[]
    | undefined;
  const summaryLabel = t
    ? t(TranslationLabels.terminateRequestSummaryFieldLabel, {
        flatNumber: apartment?.rentalObjectMyHome?.flatNumber,
        address: apartment?.rentalObjectMyHome?.streetAddress,
      })
    : '';

  return {
    config: {
      initialValues: {
        [jiraFields.NEW_APARTMENT]: newApartment || '',
        [jiraFields.NOTICE]: notice || '',
        [jiraFields.POTENTIAL_IMPROVEMENT]: potentialImprovement || '',
        [jiraFields.SUMMARY]: summaryLabel,
        [jiraFields.ATTACHMENT]: attachment || [],
      },
      prepareValues: (values, requestType, residents, tenants) => {
        const arePeopleLiving = !!(residents?.length || tenants?.length);

        return {
          ...values,
          ...(jiraFields.PEOPLE_LIVING && {
            [jiraFields.PEOPLE_LIVING]: arePeopleLiving
              ? jiraItems.PEOPLE_LEAVING_YES
              : jiraItems.PEOPLE_LEAVING_NO,
          }),
          ...(jiraFields.TENANTS_IN_APARTMENT && {
            [jiraFields.TENANTS_IN_APARTMENT]:
              tenants?.map(({ customerIdApi }) => +customerIdApi) || [],
          }),
        };
      },
      validationSchema: Yup.object().shape({
        [jiraFields.NEW_APARTMENT]: Yup.number().required(
          TranslationLabels.formErrorsRequired,
        ),
        [jiraFields.NOTICE]: Yup.number().required(
          TranslationLabels.formErrorsRequired,
        ),
        [jiraFields.POTENTIAL_IMPROVEMENT]: Yup.string().when(
          jiraFields.NOTICE,
          {
            is: (notice: number) => notice === jiraItems.NOTICE_OTHER,
            then: Yup.string().required(TranslationLabels.formErrorsRequired),
            otherwise: Yup.string().nullable(),
          },
        ),
      }),
    },
    fields: [
      {
        id: jiraFields.SUMMARY,
        label: TranslationLabels.ticketingServiceRequestSummaryLabel,
        readonly: true,
        required: true,
        type: 'text',
      },
      ...(options?.reasons?.length
        ? [
            {
              id: jiraFields.NOTICE,
              label: TranslationLabels.ticketingTerminationRequestNotice,
              options: options.reasons,
              required: true,
              type: 'select' as const,
            },
          ]
        : []),
      ...(options?.newApartment?.length
        ? [
            {
              id: jiraFields.NEW_APARTMENT,
              label: TranslationLabels.ticketingTerminationRequestNewApartment,
              options: options?.newApartment,
              required: true,
              type: 'select' as const,
            },
          ]
        : []),
      {
        id: jiraFields.POTENTIAL_IMPROVEMENT,
        label: TranslationLabels.ticketingTerminationRequestSuggestion,
        type: 'textarea',
      },
      {
        accept: 'image/*,.pdf',
        helperText: '',
        id: jiraFields.ATTACHMENT,
        isMultiple: true,
        label: TranslationLabels.formFieldsAttachmentFileLabel,
        onPreviewClick: api.getAttachment,
        type: 'attachment',
        readonly: true,
      },
    ],
  };
};
