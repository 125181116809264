import { createContext } from 'react';
import type { Maintenance } from '@shared/maintenance/types';

export type ContextValue = Maintenance;

export const initialContextValue: ContextValue = {
  descriptionKey: '',
  enabled: false,
  interactionEnabled: true,
  titleKey: '',
};

export const Context = createContext<ContextValue>(initialContextValue);
