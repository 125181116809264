import { TranslationLabels } from '@generated/translation-labels';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import * as Sentry from '@sentry/react';
import { DataSourceEnum, UpdateCustomerRule } from '@shared/enums';
import { useSnackbar } from 'notistack';
import React, { FC, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useAuth } from '../../+auth';
import { invoiceApi, useInvoice } from '../../+invoice';
import { InvoiceFilter } from '../../+invoice/invoice.type';
import { profileApi, useProfile } from '../../+profile';
import {
  Carousel,
  ContainerFluid,
  CustomerApartmentType,
  CustomerOperationMidlelayer,
  DefaultApartment,
  IRentalObjectMyHome,
  LoadingButton,
  useCountry,
  useTranslation,
} from '../../shared';
import { api } from '../apartment.respository';
import outsideDefaultImage from '../assets/apartment-outside.jpg';
import { routes, useApartment } from '../shared';
import { useStyles } from './widget.styles';

export const Widget: FC = () => {
  const { dataSource, fetchHeimUserData } = useAuth();
  const { data: profileData } = useProfile();
  const { data, images, updateApartmentData } = useApartment();
  const { updateInvoiceData } = useInvoice();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { t } = useTranslation();
  const country = useCountry();
  const history = useHistory();
  const [isFetching, setFetching] = useState(false);

  const apartments = useMemo(() => {
    if (!data) {
      return [];
    }

    const { allRentalObjects, rentalObjectMyHome } = data;

    return (
      allRentalObjects ||
      (rentalObjectMyHome
        ? [{ ...rentalObjectMyHome, objectIdApi: data.objectIdApi }]
        : [])
    );
  }, [data]);
  const handleButtonClick = async ({
    rentalDealIdApi,
  }: IRentalObjectMyHome): Promise<void> => {
    setFetching(true);
    const shouldUseObjectIdApi = dataSource !== DataSourceEnum.HeimNorway;
    if (
      !profileData ||
      !rentalDealIdApi ||
      (shouldUseObjectIdApi &&
        rentalDealIdApi === data?.rentalObjectMyHome?.objectIdApi) ||
      (!shouldUseObjectIdApi &&
        data?.rentalObjectMyHome?.id.toString() === rentalDealIdApi)
    ) {
      history.push({ pathname: routes.APARTMENT });
      setFetching(false);

      return;
    }

    const defaultApartment: DefaultApartment = {
      ref1Type: CustomerApartmentType.RENTAL,
      ref1Key: rentalDealIdApi,
    };

    try {
      await profileApi.updateMyProfile(
        {
          ...profileData,
          customerUpdate: defaultApartment,
          updateCustomerRule: UpdateCustomerRule.UpdateDefaultApartment,
        },
        CustomerOperationMidlelayer.UpdateDefaultApartment,
      );

      if (dataSource === DataSourceEnum.UnikDenmark) {
        await fetchHeimUserData(profileData.email, profileData.mobile);
      }

      const [rentalData, invoiceData] = await Promise.all([
        api.getRentalObject(),
        invoiceApi.getInvoices(
          InvoiceFilter.PendingOrNew,
          profileData?.customerIdApi,
        ),
      ]);

      updateApartmentData(rentalData);
      updateInvoiceData(invoiceData);
      enqueueSnackbar(t(TranslationLabels.apartmentListSuccessMessage), {
        autoHideDuration: 3000,
        variant: 'success',
      });
      history.push({ pathname: routes.APARTMENT });
    } catch (e) {
      enqueueSnackbar(t(TranslationLabels.formGlobalErrorMessage), {
        variant: 'error',
      });
      Sentry.captureException(e);
    } finally {
      setFetching(false);
    }
  };

  return (
    <ContainerFluid className={classes.containerFluid}>
      <Typography variant="h2">
        {t(TranslationLabels.apartmentWidgetTitle)}
      </Typography>
      <Carousel length={apartments.length} className={classes.carousel}>
        {({ activeItemIdx, LeftButton, RightButton }) =>
          apartments.map((apartment, idx) => {
            const {
              addressInclFlatnumber,
              city,
              id,
              objectIdApi,
              postalCode,
              realEstateName,
            } = apartment;

            const apartmentImageUrl = images[objectIdApi]?.[0]?.url || '';

            return (
              <div
                key={id}
                className={classes.wrapper}
                style={{
                  opacity: idx === activeItemIdx ? 1 : 0,
                }}
              >
                <div className={classes.information}>
                  <Grid container spacing={2}>
                    {apartments.length > 1 && (
                      <Grid item md={2}>
                        <LeftButton className={classes.carouselButton} />
                      </Grid>
                    )}
                    <Grid item md sm xs>
                      <div className={classes.text}>
                        {country === 'dk' && (
                          <Typography variant="h2">
                            <strong>{realEstateName}</strong>
                          </Typography>
                        )}
                        <Typography variant="h3">
                          <strong>{addressInclFlatnumber}</strong>
                          <br />
                          {postalCode}&nbsp;{city}
                        </Typography>
                        <LoadingButton
                          className={classes.button}
                          color="primary"
                          disabled={isFetching}
                          onClick={() => {
                            handleButtonClick(apartment);
                          }}
                          variant="outlined"
                          isFetching={isFetching}
                          label={TranslationLabels.apartmentWidgetLink}
                        />
                      </div>
                    </Grid>
                    {apartments.length > 1 && (
                      <Grid item md={2}>
                        <RightButton className={classes.carouselButton} />
                      </Grid>
                    )}
                  </Grid>
                </div>
                <div
                  className={classes.coverPhoto}
                  style={{
                    backgroundImage: `url('${
                      apartmentImageUrl || outsideDefaultImage
                    }')`,
                  }}
                />
              </div>
            );
          })
        }
      </Carousel>
    </ContainerFluid>
  );
};
