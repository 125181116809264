import { TranslationLabels } from '@generated/translation-labels';
import { Spinner } from '@shared/components';
import React, { FC, useCallback, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from '@shared/translations';
import { ReactComponent as ArrowRight } from '@heimstaden/icons-library/img/streamline-regular/arrows-diagrams/arrows/arrow-right.svg';
import { useHistory } from 'react-router';
import { FormV2 } from '@shared/FormV2';
import isEqual from 'lodash-es/isEqual';
import { useApi, useCountry } from '@shared/hooks';
import { getConfig, getField, getFields, Values } from './request.model';
import { TerminationRequestFlowStep } from '../../../types';
import { useStyles } from './request-form.styles';
import { FlowStepSummary } from '../FlowStepSummary/flow-step-summary.component';
import { useApartment } from '../../../../+apartment';
import { useProfile } from '../../../../+profile';
import { api } from './request.repository';
import { api as ticketAPI } from '../../../../+ticketing/ticketing.repository';
import TerminationImage from '../../../assets/termination-request.png';
import { COUNTRY_TICKETING_MODEL } from '../../../../+ticketing/ticketing.const';
import { ServiceRequestApi } from '../../../../+ticketing/ticketing.type';
import { useTicketing } from '../../../../+ticketing';
import { terminationRoutes } from '../../..';
import { RequestCreator } from '../../../../+ticketingV2/shared';

import {
  getLeftColumnInputs,
  getRightColumnInputs,
  renderField,
} from './request.helper';
import { SubmitButton } from '../../../../shared/FormV2/components/submit-button.component';

type Props = {
  flowStep: TerminationRequestFlowStep;
};

export const RequestForm: FC<Props> = () => {
  const classes = useStyles();
  const country = useCountry();
  const { data: profileData } = useProfile();
  const [isLoadingGetTicket, setLoadingGetTicket] = useState(false);
  const { t } = useTranslation();
  const { data: apartmentData, residents, tenants } = useApartment();
  const history = useHistory();
  const { tickets, setTicketList } = useTicketing();
  const { data: userData } = useProfile();
  const { jiraFields } = COUNTRY_TICKETING_MODEL[country];

  const getData$ = useCallback(
    () =>
      Promise.all([
        api.getRequestTypeId$(country),
        api.getFormOptions$(country),
      ]),
    [country],
  );

  const handleSuccess = useCallback(
    async (ticket: ServiceRequestApi) => {
      setLoadingGetTicket(true);
      const ticketDetails = await ticketAPI.getTicket(
        country,
        ticket.ticketId,
        profileData?.customerIdApi,
      );
      setTicketList([{ ...ticket, ...ticketDetails }, ...tickets]);
      setLoadingGetTicket(false);
      history.push(`${terminationRoutes.INDEX}/${ticket.ticketId}`);
    },
    [tickets, setTicketList, history, profileData?.customerIdApi, country],
  );

  const [[requestTypeId, options], isFetching] = useApi(
    [undefined, {}],
    getData$,
  );

  const contractEndDate = apartmentData?.rentalDetailsList?.find(
    ({ objectIdApi }) => objectIdApi === apartmentData?.objectIdApi,
  )?.contractEndDate;

  const config = useMemo(
    () =>
      getConfig(
        country,
        apartmentData,
        userData,
        residents,
        tenants,
        requestTypeId,
        contractEndDate,
      ),
    [
      apartmentData,
      country,
      requestTypeId,
      residents,
      tenants,
      userData,
      contractEndDate,
    ],
  );

  const predefinedSummaryValue = useMemo(
    () =>
      t(TranslationLabels.terminateRequestSummaryFieldLabel, {
        flatNumber: apartmentData?.rentalObjectMyHome?.flatNumber,
        address: apartmentData?.rentalObjectMyHome?.streetAddress,
      }),
    [apartmentData, t],
  );

  const fields = useMemo(
    () =>
      getFields(
        country,
        predefinedSummaryValue,
        options,
        userData?.email,
        userData?.mobile,
      ),
    [
      country,
      predefinedSummaryValue,
      options,
      userData?.email,
      userData?.mobile,
    ],
  );

  if (isFetching || isLoadingGetTicket) {
    return <Spinner />;
  }

  return (
    <RequestCreator<Values>
      formProps={{ config, fieldsConfig: fields }}
      fallbackMessageKey={
        TranslationLabels.ticketingNoSupportedServiceRequestMessage
      }
      requestTypeId={requestTypeId}
    >
      {() => (
        <>
          <Grid container spacing={6}>
            <Grid item xs={12} md={5} className={classes.container}>
              <div className={classes.content}>
                <FlowStepSummary
                  labelKey={
                    TranslationLabels.terminationFlowStepTerminationSummaryText
                  }
                />
                <Typography className={classes.description}>
                  {t(
                    TranslationLabels.terminationFlowStepTerminationDescriptionText,
                  )}
                </Typography>
              </div>
            </Grid>
            <Grid item md={1} />
            <Grid item xs={12} md={6}>
              <img
                src={TerminationImage}
                alt={
                  TranslationLabels.terminationFlowStepTerminationSummaryText
                }
                className={classes.image}
              />
            </Grid>
          </Grid>
          <FormV2<Values>
            config={config}
            fieldsConfig={fields}
            handleSuccess={handleSuccess}
            showButtons={false}
          >
            {(formProps) => (
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h2">
                    <strong>
                      {t(
                        TranslationLabels.terminationFlowStepTerminationRequestTitle,
                      )}
                    </strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} className={classes.formColumnLeft}>
                  {getLeftColumnInputs(jiraFields).map((fieldName) =>
                    renderField(
                      getField(fields, fieldName),
                      formProps.values,
                      classes.input,
                      fieldName,
                    ),
                  )}
                </Grid>
                <Grid item xs={12} md={6} className={classes.formColumnRight}>
                  {getRightColumnInputs(jiraFields).map((fieldName) =>
                    renderField(
                      getField(fields, fieldName),
                      formProps.values,
                      classes.input,
                      fieldName,
                    ),
                  )}
                  <SubmitButton
                    isSubmitting={formProps.isSubmitting}
                    label={TranslationLabels.sendRequest}
                    icon={ArrowRight}
                    disabled={
                      formProps.isSubmitting ||
                      isEqual(formProps.initialValues, formProps.values)
                    }
                    className={classes.submitButton}
                  />
                </Grid>
              </Grid>
            )}
          </FormV2>
        </>
      )}
    </RequestCreator>
  );
};
