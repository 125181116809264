import { useRef, useEffect, DependencyList } from 'react';

export const useDidUpdate = (
  callback: () => void,
  dependencies: DependencyList | undefined,
): void => {
  const didMount = useRef<boolean>(false);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
    } else {
      callback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};
