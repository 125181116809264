import { DataSourceEnum } from '@shared/enums';
import { Action, State } from './type';

export const initialState: State = {
  authToken: '',
  dataSource: DataSourceEnum.Unknown,
  heimToken: '',
  isUserVerified: false,
  isHeimUserExist: false,
  user: {},
};

export const reducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'AUTH_INIT': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'AUTH_UPDATE_HEIM_TOKEN': {
      return {
        ...state,
        heimToken: action.payload,
      };
    }
    default: {
      return { ...state };
    }
  }
};
