import { TranslationLabels } from '@generated/translation-labels';
import { Suggestion } from '@shared/components/HeaderSearchBox/header-search-box.type';
import { sanityClient, SanityDocumentType } from '@shared/sanity';
import { flattenDeep } from 'lodash-es';
import { jiraServiceClient } from '@shared/http';
import { profileRoutes } from '../+profile';
import { COUNTRY_TICKETING_MODEL } from '../+ticketing/ticketing.const';
import {
  failuresPathSuffix,
  itemsPathSuffix,
  roomsPathSuffix,
  suggestionPathSuffix,
  TICKETING_PATHS,
} from './shared';
import { TicketElementInternalKey } from './ticketing.enum';
import { terminationRoutes } from '../+termination';
import { Comment } from './types';

export const api = {
  addComment: (
    ticketId: string,
    comment: string,
    country: GenericTypes.Country,
    customerId?: string,
  ): Promise<Comment> => {
    if (!customerId) {
      throw Error(TranslationLabels.formGlobalErrorMessage);
    }

    const url = `${country}/tenant/${customerId}/tickets/${ticketId}/comments`;
    const payload = { comment };

    return jiraServiceClient.post<{ comment: string }, Comment>(url, payload);
  },
  getSuggestions$: async (
    country: GenericTypes.Country,
    isTenant: boolean,
    lang: GenericTypes.SanityLanguageKey,
    query: string,
  ): Promise<Suggestion[]> => {
    const response = await Promise.all([
      api.getCustomerServiceSuggestions$(country, lang, query),
      api.getMyAccountSuggestions$(country, isTenant, lang, query),
      api.getImmediateAssistanceSuggestions$(country, lang, query),
      api.getRepairAndMaintenanceSuggestions$(country, lang, query),
    ]);
    const flattenedSuggestions = flattenDeep(response);

    return flattenedSuggestions.filter((suggestion) => suggestion);
  },
  getRepairAndMaintenanceSuggestions$: async (
    country: GenericTypes.Country,
    lang: GenericTypes.SanityLanguageKey,
    query: string,
  ): Promise<Suggestion[]> => {
    const getAreaQuery = (area: string): string => `
      [
        areas.${area}.buttonTitle->value[$lang],
        areas.${area}.text->value[$lang],
        areas.${area}.title->value[$lang],
      ] match $query => {
        "key": "repairMaintenanceRequest.areas.${area}",
        "route": "${TICKETING_PATHS.SERVICE_REQUEST_AREAS}/" + string(areas.${area}.fieldId),
        "titleKey": [
          title->key,
          areas.${area}.title->key
        ],
      },
    `;
    const getAreaItemsAndFailuresQuery = (area: string): string => {
      const areaItemRoute = `"${TICKETING_PATHS.SERVICE_REQUEST_AREAS}/" + string(^.^.areas.${area}.typeId) + "${itemsPathSuffix}/" + string(fieldId)`;
      const areaItemFailuresRoute = `${areaItemRoute} + "${failuresPathSuffix}"`;
      const areaItemFailure = `"${TICKETING_PATHS.SERVICE_REQUEST_AREAS}/" + string(^.^.^.areas.${area}.typeId) + "${itemsPathSuffix}/" + string(^.^.item->fieldId) + "${failuresPathSuffix}/" + string(fieldId)`;
      const areaItemFailureSuggestion = `${areaItemRoute} + "${suggestionPathSuffix}"`;

      return `
        ...areas.${area}.items[item->title->value[$lang] match $query]{
          "key": _key,
          ...item->{
            "route": select(
              defined(^.failures) => ${areaItemFailuresRoute},
              ${areaItemRoute},
            ),
            "titleKey": [
              ^.^.title->key,
              ^.^.areas.${area}.title->key,
              title->key
            ],
            roles,
          },
        },
        ...areas.${area}.items[]{
          failures[failure->title->value[$lang] match $query]{
            "key": _key,
            ...failure->{
              "route": select(
                defined(suggestion) => ${areaItemFailureSuggestion},
                ${areaItemFailure},
              ),
              "titleKey": [
                ^.^.^.title->key,
                ^.^.^.areas.${area}.title->key,
                ^.^.item->title->key,
                title->key
              ],
              roles,
            },
          }
        }.failures,
      `;
    };
    const roomRoute = `"${TICKETING_PATHS.SERVICE_REQUEST_AREAS}/" + string(^.^.areas.unit.typeId) + "${roomsPathSuffix}/" + string(fieldId)`;
    const roomItemsRoute = `${roomRoute} + "${failuresPathSuffix}"`;
    const roomItemRoute = ` "${TICKETING_PATHS.SERVICE_REQUEST_AREAS}/" + string(^.^.^.areas.unit.typeId) + "${roomsPathSuffix}/" + string(^.^.room->fieldId) + "${itemsPathSuffix}/" + string(fieldId)`;
    const roomItemRouteFailures = `${roomItemRoute} + "${failuresPathSuffix}"`;
    const roomItemFailureRoute = `"${TICKETING_PATHS.SERVICE_REQUEST_AREAS}/" + string(^.^.^.^.areas.unit.typeId) + "${roomsPathSuffix}/" + string(^.^.^.room->fieldId) + "${itemsPathSuffix}/" + string(^.^.item->fieldId) + "${failuresPathSuffix}/" + string(fieldId)`;
    const roomItemFailureSuggestion = `${roomItemFailureRoute} + "${suggestionPathSuffix}"`;
    const response = await sanityClient.fetch<{
      suggestions: Suggestion[] | Suggestion[][] | Suggestion[][][];
    }>(
      `
        *[_type == $type && content.country == $country][0]{
          ...content.ticketing{
            ...repairMaintenanceRequest{
              "suggestions": [
                select(
                  [title->value[$lang], text->value[$lang]] match $query => {
                    "route": "${TICKETING_PATHS.INDEX}",
                    "titleKey": title->key,
                    "key": "repairMaintenanceRequest",
                  },
                ),
                select(
                  ${getAreaQuery('common')}
                  ${getAreaQuery('outdoor')}
                  ${getAreaQuery('unit')}
                ),
                ${getAreaItemsAndFailuresQuery('common')}
                ${getAreaItemsAndFailuresQuery('outdoor')}
                ...areas.unit.rooms[room->title->value[$lang] match $query]{
                  ...room->{
                    "route": select(
                      defined(^.items) => ${roomItemsRoute},
                      ${roomRoute},
                    ),
                    "titleKey": [
                      ^.^.title->key,
                      ^.^.areas.unit.title->key,
                      title->key
                    ],
                    roles,
                  },
                },
                ...areas.unit.rooms[]{
                  items[item->title->value[$lang] match $query]{
                    "key": _key,
                    ...item->{
                      "route": select(
                        defined(^.failures) => ${roomItemRouteFailures},
                        ${roomItemRoute},
                      ),
                      "titleKey": [
                        ^.^.^.title->key,
                        ^.^.^.areas.unit.title->key,
                        ^.^.room->title->key,
                        title->key
                      ],
                      roles,
                    }
                  }
                }.items,
                ...areas.unit.rooms[]{
                  "failures": items[]{
                    failures[failure->title->value[$lang] match $query]{
                      "key": _key,
                      ...failure->{
                        "route": select(
                          defined(suggestion) => ${roomItemFailureSuggestion},
                          ${roomItemFailureRoute},
                        ),
                        "titleKey": [
                          ^.^.^.^.title->key,
                          ^.^.^.^.areas.unit.title->key,
                          ^.^.^.room->title->key,
                          ^.^.item->title->key,
                          title->key
                        ],
                        roles,
                      }
                    }
                  }.failures,
                }.failures,
              ],
            }
          }
        }
      `,
      {
        country,
        lang,
        query: `*${query}*`,
        type: SanityDocumentType.SETTINGS,
      },
    );

    return flattenDeep(response.suggestions);
  },
  getCustomerServiceSuggestions$: async (
    country: GenericTypes.Country,
    lang: GenericTypes.SanityLanguageKey,
    query: string,
  ): Promise<Suggestion[]> => {
    const response = await sanityClient.fetch<{ suggestions: Suggestion[] }>(
      `
        *[_type == $type && content.country == $country][0]{
          ...content.ticketing{
            "suggestions": [
              select(
                [
                  customerServiceRequest.title->value[$lang],
                  customerServiceRequest.text->value[$lang]
                ] match $query => {
                  "key": "customerServiceRequest",
                  "route": "${TICKETING_PATHS.CUSTOMER_SERVICE}",
                  "titleKey": customerServiceRequest.title->key,
                }
              ),
              select(
                customerServiceRequest.types.generalRequest.title->value[$lang] match $query => {
                  "key": "customerServiceRequest.types.generalRequest",
                  "route": "${TICKETING_PATHS.CUSTOMER_SERVICE_GENERAL_REQUEST}",
                  "titleKey": [
                    customerServiceRequest.title->key,
                    customerServiceRequest.types.generalRequest.title->key
                  ],
                }
              ),
              select(
                (
                  customerServiceRequest.types.issueComplaint.title->value[$lang] match $query ||
                  customerServiceRequest.types.issueComplaint.searchEntries[]->{"value": value[$lang]}.value match $query
                ) => {
                  "key": "customerServiceRequest.types.issueComplaint",
                  "route": "${TICKETING_PATHS.CUSTOMER_SERVICE_ISSUE_COMPLAINT}",
                  "titleKey": [
                    customerServiceRequest.title->key,
                    customerServiceRequest.types.issueComplaint.title->key
                  ],
                }
              ),
            ]
          }
        }
      `,
      {
        country,
        lang,
        query: `*${query}*`,
        type: SanityDocumentType.SETTINGS,
      },
    );

    return response.suggestions;
  },
  getMyAccountSuggestions$: async (
    country: GenericTypes.Country,
    isTenant: boolean,
    lang: GenericTypes.SanityLanguageKey,
    query: string,
  ): Promise<Suggestion[]> => {
    const { jiraItems } = COUNTRY_TICKETING_MODEL[country];
    const terminationQuery = isTenant
      ? `
          select(
            (
              customerAccountRequest.types.terminateContract.title->value[$lang] match $query ||
              customerAccountRequest.types.terminateContract.reasons[]{ "value": name->value[$lang] }.value match $query ||
              customerAccountRequest.types.terminateContract.newApartment[]{ "value": name->value[$lang] }.value match $query
            ) => {
              ...select(
                defined(customerAccountRequest.types.terminateContract.externalWebpageUrl) => {
                  "externalWebpageURL": customerAccountRequest.types.terminateContract.externalWebpageUrl,
                },
                { "route": "${terminationRoutes.INDEX}" },
              ),
              "key": "customerAccountRequest.types.terminateContract",
              "titleKey": [
                customerAccountRequest.title->key,
                customerAccountRequest.types.terminateContract.title->key
              ],
            }
          ),
        `
      : '';
    const response = await sanityClient.fetch<{ suggestions: Suggestion[] }>(
      `
        *[_type == $type && content.country == $country][0]{
          ...content.ticketing{
            "suggestions": [
              select(
                [customerAccountRequest.title->value[$lang], customerAccountRequest.text->value[$lang]] match $query => {
                  "key": "customerAccountRequest",
                  "route": "${TICKETING_PATHS.MY_ACCOUNT}",
                  "titleKey": customerAccountRequest.title->key,
                }
              ),
              ${terminationQuery}
              select(
                customerAccountRequest.types.invoiceAndCustomerAccount.title->value[$lang] match $query => {
                  "key": "customerAccountRequest.types.invoiceAndCustomerAccount",
                  "route": "${TICKETING_PATHS.MY_ACCOUNT_INVOICE_AND_CUSTOMER_ACCOUNT_ITEMS}",
                  "titleKey": [
                    customerAccountRequest.title->key,
                    customerAccountRequest.types.invoiceAndCustomerAccount.title->key,
                  ],
                }
              ),
              ...customerAccountRequest.types.invoiceAndCustomerAccount.items[item->title->value[$lang] match $query][]{
                  "key": _key,
                ...item->{
                  "route": "${TICKETING_PATHS.MY_ACCOUNT_INVOICE_AND_CUSTOMER_ACCOUNT_ITEMS}/" + string(fieldId),
                  "titleKey": [
                    ^.^.customerAccountRequest.title->key,
                    ^.^.customerAccountRequest.types.invoiceAndCustomerAccount.title->key,
                    title->key
                  ],
                  roles,
                }
              },
              ...customerAccountRequest.types.invoiceAndCustomerAccount.items[]{
                failures[failure->fieldId != string($terminationRequestId) && failure->title->value[$lang] match $query][]{
                  "key": _key,
                  ...failure->{
                    "externalWebpageURL": externalWebpageUrl,
                    "route": select(
                      fieldId == string($changeProfileRequestId) => "${profileRoutes.PROFILE}",
                      "${TICKETING_PATHS.MY_ACCOUNT_INVOICE_AND_CUSTOMER_ACCOUNT_ITEMS}/" + string(^.^.item->fieldId) + "/" + string(fieldId),
                    ),
                    "titleKey": [
                      ^.^.^.customerAccountRequest.title->key,
                      ^.^.^.customerAccountRequest.types.invoiceAndCustomerAccount.title->key,
                      ^.^.item->title->key,
                      title->key,
                    ],
                    roles,
                  }
                },
              }.failures,
            ]
          }
        }
      `,
      {
        country,
        changeProfileRequestId:
          jiraItems.INVOICE_CUSTOMER_ACCOUNT_CONTRACT_AND_TENANCY_CHANGE_INFORMATION ||
          null,
        lang,
        query: `*${query}*`,
        type: SanityDocumentType.SETTINGS,
        terminationRequestId:
          jiraItems.INVOICE_CUSTOMER_ACCOUNT_TERMINATE_REQUEST || null,
      },
    );

    return flattenDeep(response.suggestions);
  },
  getImmediateAssistanceSuggestions$: async (
    country: GenericTypes.Country,
    lang: GenericTypes.SanityLanguageKey,
    query: string,
  ): Promise<Suggestion[]> => {
    const response = await sanityClient.fetch<{ suggestions: Suggestion[] }>(
      `
        *[_type == $type && content.country == $country][0]{
          ...content.ticketing{
            ...immediateAssistanceRequest{
              "suggestions": [
                select(
                  defined(externalWebpageUrl) && [title->value[$lang], text->value[$lang]] match $query => {
                    "externalWebpageURL": externalWebpageUrl,
                    "key": "immediateAssistanceRequest",
                    "titleKey": title->key,
                  }
                )
              ]
            },
          }
        }
      `,
      {
        country,
        lang,
        query: `*${query}*`,
        type: SanityDocumentType.SETTINGS,
      },
    );

    return response.suggestions;
  },
  getTicketElementsFieldIds$: async (
    country: GenericTypes.Country,
    ticketElementInternalKeys: TicketElementInternalKey[],
  ): Promise<Partial<Record<TicketElementInternalKey, string>>> => {
    const query = ticketElementInternalKeys.reduce((acc, internalKey) => {
      return `
        ${acc}
        "${internalKey}": *[_type == $type && country == $country && internalKey == "${internalKey}"][0].fieldId,
      `;
    }, '');

    return sanityClient.fetch(`{${query}}`, {
      country,
      type: SanityDocumentType.TICKET_ELEMENT,
    });
  },
};
