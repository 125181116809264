import React, { FC } from 'react';
import { Spinner } from '@shared/components';
import { Intro } from '../Intro';
import { BackButton } from '../BackButton/back-button.component';
import { ViewProps } from './types';

export const ContentRows: FC<ViewProps> = ({
  introProps,
  isFetching,
  children,
}) => {
  return (
    <>
      {introProps && (
        <Intro
          descriptionKey={introProps.descriptionKey}
          titleKey={introProps.titleKey}
          childrenRightColumn={introProps.childrenRightColumn}
        />
      )}
      {isFetching ? (
        <Spinner />
      ) : (
        <>
          {children}
          <BackButton />
        </>
      )}
    </>
  );
};
