import * as Sentry from '@sentry/react';
import { TranslationLabels } from '@generated/translation-labels';
import { TicketStatusError } from '@shared/errors';
import { TFunction } from 'i18next';
import { TicketStatus } from './ticketing.enum';
import {
  ServiceRequest,
  ServiceRequestApi,
  StatusConfig,
} from './ticketing.type';

export const transformTickets = (
  tickets: (ServiceRequest | ServiceRequestApi)[],
  statusesConfig: StatusConfig[],
): ServiceRequest[] =>
  tickets.map(({ id, status, ticketId, ...rest }) => ({
    ...rest,
    id: ticketId || id,
    status:
      typeof status === 'string'
        ? transformStatus(status, statusesConfig)
        : status,
  })) as ServiceRequest[];

export const transformStatus = (
  jiraStatus: string,
  statusesConfig: StatusConfig[],
): StatusConfig | undefined => {
  const statusConfig = statusesConfig.find(
    (s) => s.jiraKey.toLowerCase() === jiraStatus.toLowerCase(),
  );

  if (!statusConfig) {
    const error = new TicketStatusError(
      `Missing ticket status configuration for '${jiraStatus}' JIRA status`,
    );
    Sentry.captureException(error, {
      extra: {
        jiraStatus,
        statusesConfig,
      },
    });

    return undefined;
  }

  return {
    jiraKey: jiraStatus,
    text: statusConfig.text,
    colorHex: statusConfig.colorHex,
    updateEnabled: statusConfig.updateEnabled,
  };
};

export const isSpecifiedStatus = (
  jiraKey: string | undefined,
  status: TicketStatus,
): boolean => jiraKey?.toLowerCase() === status.toLowerCase();

export const renderApartmentNo = (
  t: TFunction,
  apartmentNo?: string | null,
): string | null => {
  if (!apartmentNo) {
    return null;
  }
  return ` ${t(TranslationLabels.apartmentNumberPrefix)} ${apartmentNo}`;
};
