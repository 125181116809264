import * as Sentry from '@sentry/react';
import { Dispatch, SetStateAction } from 'react';
import { FormProps } from '../types';

type OnSubmit<TFormValues> = FormProps<TFormValues>['config']['onSubmit'];

export function handleSubmit<TFormValues>(
  setGlobalError: Dispatch<SetStateAction<string | undefined>>,
  handleSuccess: FormProps<TFormValues>['handleSuccess'],
  onSubmit: OnSubmit<TFormValues>,
  resetFormOnSubmit?: boolean,
): OnSubmit<TFormValues> {
  return async (values, helpers): Promise<void> => {
    const { resetForm, setSubmitting, setStatus } = helpers;

    try {
      const response = await onSubmit(values, helpers);

      if (handleSuccess) {
        handleSuccess(response);
      }

      if (resetFormOnSubmit) {
        resetForm();
      }
      setStatus({ success: true });
    } catch (e) {
      if (e.message) {
        setGlobalError(e.message);
      } else if (e.data.message) {
        setGlobalError(e.data.message);
      }

      setStatus({ success: false });
      Sentry.captureException(e);
    } finally {
      setSubmitting(false);
    }
  };
}
