import { sanityClient, SanityDocumentType } from '@shared/sanity';
import { suggestionPathSuffix } from '../../shared';
import { Failures } from './failures.type';

export const api = {
  getFailures$: (
    country: GenericTypes.Country,
    path: string,
    areaId: number,
    itemId: number,
    roomId?: number,
  ): Promise<Failures> => {
    const getAreaQuery = (area?: string): string => {
      const prefix = area ? `${area}.` : '';

      return `
        ...${prefix}items[item->fieldId == string($itemId)][0]{
          "otherElementRedirectURL": "${path}/" + string(otherElementId),
          "failures": failures[]{
            ...failure->{
              "key": fieldId,
              "redirectURL": select(
                defined(suggestion) => "${path}/" + string(fieldId) + "${suggestionPathSuffix}",
                "${path}/" + string(fieldId)
              ),
              "titleKey": title->key,
              externalWebpageUrl,
              roles,
            }
          }
        }
      `;
    };
    const query = `
      * [_type == $type && content.country == $country][0]{
        ...content.ticketing.repairMaintenanceRequest.areas{
          ...select(
            common.typeId == $areaId => {${getAreaQuery('common')}},
            outdoor.typeId == $areaId => {${getAreaQuery('outdoor')}},
            unit.typeId == $areaId => unit.rooms[room->fieldId == string($roomId)][0]{
              ${getAreaQuery()}
            },
          )
        }
      }
    `;

    return sanityClient.fetch(query, {
      areaId,
      country,
      itemId,
      roomId,
      type: SanityDocumentType.SETTINGS,
    });
  },
};
