import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from '@shared/translations';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import MuiStepper from '@material-ui/core/Stepper';
import { useTermination } from '../../state';
import { getEventTypeTranslationLabelKey } from '../../helpers';
import { TerminationFlowStepEventType } from '../../../types';

export const Stepper: FC = () => {
  const { t } = useTranslation();
  const {
    currentFlowStep,
    flowSteps,
    isFlowCompleted,
    lastAllowedFlowStep,
    setCurrentFlowStep,
  } = useTermination();
  const activeStep = useMemo(
    () => (isFlowCompleted ? Number.MAX_SAFE_INTEGER : currentFlowStep?.step),
    [currentFlowStep?.step, isFlowCompleted],
  );
  const steps = useMemo(
    () =>
      flowSteps.map((flowStep) => ({
        completed: flowStep.status === 'resolved',
        disabled:
          lastAllowedFlowStep?.step !== undefined &&
          flowStep.step > lastAllowedFlowStep.step,
        eventType: flowStep.eventType,
        labelKey: getEventTypeTranslationLabelKey(flowStep.eventType),
      })),
    [flowSteps, lastAllowedFlowStep?.step],
  );
  const onStepClick = useCallback(
    (disabled: boolean, eventType: TerminationFlowStepEventType) => {
      if (disabled) {
        return;
      }

      setCurrentFlowStep(eventType);
    },
    [setCurrentFlowStep],
  );

  return (
    <MuiStepper activeStep={activeStep} alternativeLabel>
      {steps.map(({ completed, disabled, eventType, labelKey }) => (
        <Step
          completed={completed}
          disabled={disabled}
          key={`step-${eventType}`}
          onClick={() => onStepClick(disabled, eventType)}
        >
          <StepLabel>{t(labelKey)}</StepLabel>
        </Step>
      ))}
    </MuiStepper>
  );
};

Stepper.displayName = 'Stepper';
