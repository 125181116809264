import { COUNTRY_TICKETING_MODEL } from '../../../+ticketing/ticketing.const';
import { Values } from './form.type';

// TODO: Move them to the custom fields instead of concatenating them to the description field - https://fredensborg.atlassian.net/browse/MYH-431
const additionalInformationPrefix = 'Additional information';
const heatingPrefix = 'Type of heating: ';
const kitchenPrefix = 'Type of kitchen: ';
const lockSystemPrefix = 'Type of lock system: ';
const ovenPrefix = 'Type of oven: ';
const serialNumberPrefix = 'Serial number: ';
const typeOfToiletPrefix = 'Type of toilet: ';

export const prepareDescription = (
  country: GenericTypes.Country,
  values: Values,
): string => {
  const { jiraFields } = COUNTRY_TICKETING_MODEL[country];
  const {
    [jiraFields.DESCRIPTION]: description,
    serialNumber,
    typeOfHeating,
    typeOfKitchen,
    typeOfLockSystem,
    typeOfOven,
    typeOfToilet,
  } = values;
  const typeOfOvenText = typeOfOven ? `${ovenPrefix}${typeOfOven}\n` : '';
  const typeOfKitchenText = typeOfKitchen
    ? `${kitchenPrefix}${typeOfKitchen}\n`
    : '';
  const typeOfHeatingText = typeOfHeating
    ? `${heatingPrefix}${typeOfHeating}\n`
    : '';
  const typeOfLockSystemText = typeOfLockSystem
    ? `${lockSystemPrefix}${typeOfLockSystem}\n`
    : '';
  const typeOfToiletText = typeOfToilet
    ? `${typeOfToiletPrefix}${typeOfToilet}\n`
    : '';
  const serialNumberText = serialNumber
    ? `${serialNumberPrefix}${serialNumber}\n`
    : '';
  const additionalInformation =
    typeOfHeatingText ||
    typeOfKitchenText ||
    typeOfLockSystemText ||
    typeOfOvenText ||
    typeOfToiletText ||
    serialNumberText
      ? `\n\n${additionalInformationPrefix}\n${serialNumberText}${typeOfHeatingText}${typeOfKitchenText}${typeOfLockSystemText}${typeOfOvenText}${typeOfToiletText}`
      : '';

  return `${description}${additionalInformation}`;
};
