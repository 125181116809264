import { Action, State } from './type';

export const initialState: State = {
  data: null,
};

export const reducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'INVOICE_INIT': {
      return {
        ...state,
        data: action.payload,
      };
    }
    default: {
      return { ...state };
    }
  }
};
