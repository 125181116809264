import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    arrowButton: {
      position: 'absolute',
      right: 0,
      top: 26,
    },
    [theme.breakpoints.down('xs')]: {
      arrowButton: {
        top: 44,
      },
    },
  }),
);
