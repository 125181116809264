import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React, { FC, ReactNode, SVGProps, useCallback } from 'react';
import { useHistory } from 'react-router';
import {
  Icon,
  List as GenericList,
  Model,
  Spinner,
  Status,
  useCountry,
} from '../../../../shared';
import { COUNTRY_TICKETING_MODEL } from '../../../ticketing.const';
import { ElementField, ServiceRequest } from '../../../ticketing.type';
import { icons } from '../../consts';
import { routes } from '../../routing';
import { routes as terminationRoutes } from '../../../../+termination/shared/routing';
import { useTicketing } from '../../state';
import { useStyles } from './list-view.styles';

type Props = {
  limit?: number;
};

const property = 'id';

export const ListView: FC<Props> = ({ limit }) => {
  const classes = useStyles();
  const { tickets, ticketsFetching } = useTicketing();
  const country = useCountry();
  const history = useHistory();
  const { jiraFields, requestType } = COUNTRY_TICKETING_MODEL[country];
  const goToCallback = useCallback(
    (element) => {
      const shouldUseTerminationFlow = Boolean(
        element?.isTerminationFlowEnabled && element?.isAuthor,
      );

      if (shouldUseTerminationFlow) {
        history.push({
          pathname: `${terminationRoutes.INDEX}/${element[property] || '/'}`,
        });
        return;
      }

      history.push({
        pathname: `${routes.DETAILS}/${element[property] || '/'}`,
      });
    },
    [history],
  );
  const getIcon = (
    data: ServiceRequest,
  ): FC<SVGProps<SVGSVGElement>> | undefined => {
    const {
      [jiraFields.REQUEST_TYPE]: requestTypeId,
      [jiraFields.COMMON_ITEM]: commonItemId,
      [jiraFields.OUTDOOR_ITEM]: outdoorItemId,
      [jiraFields.UNIT_ITEM]: unitItemId,
    } = data;

    switch (requestTypeId) {
      case requestType.UNIT: {
        return unitItemId
          ? icons[country][(unitItemId as ElementField).id]
          : undefined;
      }
      case requestType.COMMON: {
        return commonItemId
          ? icons[country][(commonItemId as ElementField).id]
          : undefined;
      }
      case requestType.OUTDOOR: {
        return outdoorItemId
          ? icons[country][(outdoorItemId as ElementField).id]
          : undefined;
      }
      default: {
        return undefined;
      }
    }
  };
  const model: Model<ServiceRequest>[] = [
    {
      id: 'id',
      label: '',
      getContent: ({ id }): ReactNode =>
        id && <Typography component="strong">{id}</Typography>,
      width: '20%',
    },
    {
      id: jiraFields.DESCRIPTION,
      label: '',
      getContent: ({ summary }): ReactNode =>
        summary && (
          <Typography
            component="strong"
            className={clsx(classes.elementIcon, classes.ellipsis)}
          >
            {(summary as ElementField).value}
          </Typography>
        ),
      width: '50%',
    },
    {
      id: 'actions',
      getContent: (data): ReactNode => {
        const { status } = data;
        const icon = getIcon(data);

        return (
          <div className={classes.requestElements}>
            {icon && (
              <Icon
                className={classes.elementIcon}
                icon={icon}
                height={24}
                width={24}
              />
            )}
            {status && (
              <div className={classes.statusContainer}>
                <Status className={classes.status} status={status} />
              </div>
            )}
          </div>
        );
      },
      label: '',
    },
  ];
  const elements = tickets ? tickets.slice(0, limit) : [];

  if (ticketsFetching) {
    return <Spinner />;
  }

  return (
    <GenericList<ServiceRequest>
      elements={elements}
      isWidget={!!limit}
      model={model}
      property={property}
      to={goToCallback}
    />
  );
};
