import {
  CustomerMidleLayerApi2,
  IRentalObjectMyHome,
  RentalObjectHead,
} from '@shared/models';
import { createContext } from 'react';
import { initialState } from './reducer';
import { State } from './type';

export type ContextValue = State & {
  changeDefaultApartment: (data: IRentalObjectMyHome) => Promise<void>;
  getApartmentData$: (
    customerId: CustomerMidleLayerApi2['customerIdApi'],
  ) => Promise<void>;
  updateApartmentData: (data: RentalObjectHead) => void;
};

export const Context = createContext<ContextValue>({
  ...initialState,
  changeDefaultApartment: () => Promise.resolve(),
  getApartmentData$: () => Promise.resolve(),
  updateApartmentData: () => {
    // do nothing.
  },
});
