import React, { FC } from 'react';
import { Icon } from '@shared/components';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from '@shared/translations';
import { ReactComponent as StudyBookIcon } from '@heimstaden/icons-library/img/streamline-regular/school-learning/studying-learning/study-book.svg';
import { useStyles } from './tips.styles';

type Props = {
  titleKey: GenericTypes.TranslationLabel;
};

export const Tips: FC<Props> = ({ titleKey, children }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <div>
        <div className={classes.titleWrapper}>
          <Icon
            className={classes.icon}
            icon={StudyBookIcon}
            height={48}
            width={48}
          />
          <Typography variant="h1" color="primary" className={classes.title}>
            {t(titleKey)}
          </Typography>
        </div>
        <Divider classes={{ root: classes.divider }} />
      </div>
      <div>{children}</div>
    </div>
  );
};

Tips.displayName = 'Tips';
