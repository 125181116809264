import React, { FC } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { Icon, Spinner } from '@shared/components';
import { ReactComponent as SendEmailButton } from '@heimstaden/icons-library/img/streamline-regular/emails/send-email/send-email.svg';
import { useStyles } from './comment-submit-button.styles';

type Props = {
  isSubmitting?: boolean;
};

export const CommentSubmitButton: FC<Props> = ({ isSubmitting }) => {
  const classes = useStyles();

  return (
    <IconButton className={classes.iconButton}>
      {isSubmitting ? (
        <Spinner size={24} color="inherit" />
      ) : (
        <Icon icon={SendEmailButton} height={24} width={24} />
      )}
    </IconButton>
  );
};
