import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles(() =>
  createStyles({
    containerFluid: {
      maxWidth: 'unset',
      padding: 0,
    },
  }),
);
