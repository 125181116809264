type Amenity = {
  amount: number;
  description: string;
};

export enum InvoiceStatus {
  Unknown = 0,
  Credited = 2,
  Overdue = 7,
  Unpaid = 8,
  Paid = 10,
  Canceled = 20,
}

export type Invoice = {
  id: number;
  amenities: Amenity[];
  currency: string;
  bankAccountNumber: string;
  kidNumber: string;
  description: string;
  due?: string;
  dueString: string;
  invoiceNumber: string; // Normally the same as id but might be different if invoiceNumber contains letters
  issued?: string;
  issuedString: string; // Temporary
  invoiceStatus: InvoiceStatus;
  statusString: string; // Temporary ?
  to: GenericTypes.URL;
  amountRest: number; // Renamed from total
  amount: number;
  total: number; // TODO: Rename to amountThisFilter
};

export interface IInvoiceHead {
  partnerName: string;
  customerIdApi: string;
  customerAccountNumber: string;
  invoiceFilter: InvoiceFilter;
  invoiceList: Array<Invoice>;
  currency: string;
  saldoAmount: number;
  dueCount: number;
  saldoDate?: string;
  oldestDueDate?: string;
  nextDate?: string;
  nextAmount: number;
}

export enum InvoiceFilter {
  NoAccess = -10, // No access to invoices for this user
  Unknown = 0, // Changed from 0=All
  PendingOnly = 5,
  PendingOrNew = 7,
  NormalHistory = 20,
  ExtendedHistory = 30,
}
