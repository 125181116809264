import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    tips: {
      marginBottom: theme.spacing(2),
    },
    tip: {
      marginBottom: theme.spacing(2),
    },
    text: {
      whiteSpace: 'pre-line',
    },
  }),
);
