import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '../../consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      backgroundColor: palette.athensGray,
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(9),
    },
    content: {
      marginBottom: theme.spacing(6),
    },
  }),
);
