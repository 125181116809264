import FullCalendar from '@fullcalendar/react';
import React, { FC, useRef, useState } from 'react';
import { ActionButtonType, Config } from './types';
import { HeaderToolbar } from './HeaderToolbar/header-toolbar.component';
import { View } from './View/view.component';

type Props = {
  config: Config;
};

export const Calendar: FC<Props> = (props) => {
  const { config } = props;
  const calendarRef = useRef<FullCalendar | null>(null);
  const [selectedDate, setSelectedDate] = useState(config.date || new Date());
  const handleToolbarAction = (type: ActionButtonType): void => {
    const calendarEl = calendarRef.current;

    if (!calendarEl) {
      return;
    }

    const calendarApi = calendarEl.getApi();

    switch (type) {
      case 'next': {
        calendarApi.next();
        break;
      }
      case 'prev': {
        calendarApi.prev();
        break;
      }
      case 'today': {
        calendarApi.today();
        break;
      }
      default: {
        break;
      }
    }

    const date = calendarApi.getDate();
    setSelectedDate(date);
  };

  return (
    <>
      <HeaderToolbar date={selectedDate} onActionClick={handleToolbarAction} />
      <View ref={calendarRef} {...config} />
    </>
  );
};
