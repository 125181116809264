import { createContext } from 'react';
import { State } from './type';
import { initialState } from './reducer';
import { TerminationFlowStep, TerminationFlowStepEventType } from '../../types';
import { Comment } from '../../../+ticketing/ticketing.type';

export type ContextValue = State & {
  addComment: (payload: Comment) => void;
  setCurrentFlowStep: (payload: TerminationFlowStepEventType) => void;
  updateFlowStep: (
    eventType: TerminationFlowStepEventType,
    flowStepPatch: Partial<TerminationFlowStep>,
  ) => void;
  reinitialize: () => void;
};

export const Context = createContext<ContextValue>({
  ...initialState,
  addComment: () => null,
  setCurrentFlowStep: () => null,
  updateFlowStep: () => null,
  reinitialize: () => null,
});
