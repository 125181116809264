import React, { FC } from 'react';
import { List } from '@material-ui/core';
import { TenantResponse } from './tenant-response.component';
import { useTermination } from '../../state';

export const TenantsResponses: FC = () => {
  const { tenantsResponses } = useTermination();

  return (
    <List disablePadding>
      {tenantsResponses?.map((response) => (
        <TenantResponse
          key={`tenant-response-${response.tenantId}`}
          tenantResponse={response}
        />
      ))}
    </List>
  );
};

TenantsResponses.displayName = 'TenantsResponses';
