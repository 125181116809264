import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    label: {
      alignItems: 'unset',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    list: {
      backgroundColor: palette.white,
      border: `1px solid ${palette.athensGray}`,
      display: 'flex',
      flexWrap: 'wrap',
    },
    requestWrapper: {
      borderRight: `1px solid ${palette.athensGray}`,
      '&:last-child': {
        borderRight: 0,
      },
      [theme.breakpoints.down('sm')]: {
        borderBottom: `1px solid ${palette.athensGray}`,
        borderRight: 0,
        '&:last-child': {
          borderBottom: 0,
          borderRight: 0,
        },
      },
    },
    request: {
      height: '100%',
      width: '100%',
      borderRadius: 0,
      padding: theme.spacing(5, 3),
      textAlign: 'left',
      transition: theme.transitions.create(['background-color'], {
        duration: theme.transitions.duration.short,
        easing: 'linear',
      }),

      '&:hover': {
        backgroundColor: palette.athensGray,
      },
    },
    icon: {
      height: 48,
      marginBottom: theme.spacing(1),
      width: 48,
    },
    goTo: {
      display: 'flex',
      marginTop: 'auto',

      '& path': {
        stroke: palette.internationalOrange,
      },
    },
    title: {
      display: 'block',
      marginTop: theme.spacing(2),
      fontSize: theme.typography.pxToRem(18),
    },
    description: {
      marginBottom: theme.spacing(4),
      color: palette.doveGray,
      fontSize: theme.typography.pxToRem(14),
    },

    [theme.breakpoints.up('md')]: {
      box: {
        marginBottom: theme.spacing(6),
      },
      list: {
        border: `1px solid ${palette.athensGray}`,
        borderRadius: theme.spacing(1),
        boxShadow: ' 0px 4px 16px rgba(198, 198, 198, 0.25)',
        left: theme.spacing(3),
        marginLeft: 0,
        marginRight: 0,
        right: theme.spacing(3),
      },
    },
  }),
);
