import { TranslationLabels } from '@generated/translation-labels';
import { useApi, useCountry } from '@shared/hooks';
import React, { FC, useCallback, useMemo } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useApartment } from '../../../../+apartment';
import { profileRoutes, useProfile } from '../../../../+profile';
import { IntroProps } from '../../../shared/components/Intro';
import { terminationRoutes } from '../../../../+termination';
import { COUNTRY_TICKETING_MODEL } from '../../../../+ticketing/ticketing.const';
import { Content, RequestCreator } from '../../../shared';
import { RouteParams } from '../invoice-and-customer-account-type';
import { getIntroProps } from './form.helper';
import { getConfig, getFields, Values } from './form.model';
import { api } from './form.repository';
import { stepper } from '../../stepper.config';

export const Form: FC = () => {
  const { itemId, subItemId } = useParams<RouteParams>();
  const country = useCountry();
  const { data: apartmentData } = useApartment();
  const { data: userData } = useProfile();
  const getRequestTypeId$ = useCallback(
    () => api.getRequestTypeId$(country, +itemId, +subItemId),
    [country, itemId, subItemId],
  );
  const [requestTypeId, isFetching] = useApi(undefined, getRequestTypeId$);
  const config = useMemo(
    () =>
      getConfig(
        country,
        apartmentData,
        userData,
        +itemId,
        +subItemId,
        requestTypeId,
      ),
    [apartmentData, country, itemId, requestTypeId, subItemId, userData],
  );
  const fields = useMemo(() => getFields(country), [country]);
  const introProps: IntroProps = useMemo(
    () => getIntroProps(country, +subItemId),
    [country, subItemId],
  );
  const jiraItems = useMemo(() => COUNTRY_TICKETING_MODEL[country].jiraItems, [
    country,
  ]);
  const isProfileRequest = useMemo(
    () =>
      +subItemId ===
      jiraItems.INVOICE_CUSTOMER_ACCOUNT_CONTRACT_AND_TENANCY_CHANGE_INFORMATION,
    [jiraItems, subItemId],
  );
  const isTerminateRequest = useMemo(
    () => +subItemId === jiraItems.INVOICE_CUSTOMER_ACCOUNT_TERMINATE_REQUEST,
    [jiraItems, subItemId],
  );
  const additionalParamsToCheck = useMemo(
    () => ({ subItemTypeId: subItemId }),
    [subItemId],
  );

  return (
    <Content
      introProps={introProps}
      isFetching={isFetching}
      type="columns"
      stepperConfig={stepper}
    >
      <RequestCreator<Values>
        additionalParamsToCheck={additionalParamsToCheck}
        fallbackMessageKey={
          TranslationLabels.ticketingRequestFormFallbackMessage
        }
        formProps={{ config, fieldsConfig: fields }}
        requestTypeId={requestTypeId}
        fullWidth
      >
        {(form) => {
          if (isProfileRequest) return <Redirect to={profileRoutes.PROFILE} />;
          if (isTerminateRequest)
            return <Redirect to={terminationRoutes.INDEX} />;

          return form;
        }}
      </RequestCreator>
    </Content>
  );
};
