import * as Sentry from '@sentry/react';
import { Notification } from '@generated/clients/notifications/data-contracts';
import { notificationClient } from './notifications.client';
import { NotificationWithCustomFields } from './notifications.type';
import { adaptNotificationsForUI } from './notification.helper';

export const api = {
  getUnreadTerminationRequestNotification: async ({
    taskId,
  }: {
    taskId: string;
  }): Promise<NotificationWithCustomFields | undefined> => {
    const response = await notificationClient.getAll({
      page: 1,
      limit: 1,
      type: 'termination_confirmation',
      taskId,
      unread: true,
    });
    return adaptNotificationsForUI(response.data?.docs)?.[0];
  },
  getNotificationsPaginated: async ({
    page,
    limit,
  }: {
    page: number;
    limit: number;
  }): Promise<GenericTypes.Paginated<NotificationWithCustomFields>> => {
    const response = await notificationClient.getAll({
      page,
      limit,
    });

    return {
      results: adaptNotificationsForUI(response.data.docs),
      count: response.data.totalDocs || 0,
      next: response.data.nextPage,
      previous: response.data.prevPage,
    };
  },
  getUnreadCount: async (): Promise<number> => {
    const response = await notificationClient.getUnreadCount();

    return response.data;
  },
  getUnread: async (limit: number): Promise<NotificationWithCustomFields[]> => {
    const response = await notificationClient.getAll({
      page: 1,
      limit,
      unread: true,
    });

    return adaptNotificationsForUI(response.data.docs);
  },
  markOneAsRead: async ({ id }: { id: Notification['id'] }): Promise<void> => {
    try {
      await notificationClient.markOneAsRead(id);
    } catch (err) {
      Sentry.captureException(err);
    }
  },
};
