import flatten from 'lodash-es/flatten';
import uniqBy from 'lodash-es/uniqBy';
import { ticketingAPI } from '../../../+ticketingV2';
import { sanityClient, SanityDocumentType } from '../../sanity';
import { Suggestion } from './header-search-box.type';

export const api = {
  getSuggestions$: async (
    country: GenericTypes.Country,
    isTenant: boolean,
    lang: GenericTypes.SanityLanguageKey,
    query: string,
  ): Promise<Suggestion[]> => {
    const response = await Promise.all([
      api.getPageSuggestions$(query, lang),
      ticketingAPI.getSuggestions$(country, isTenant, lang, query),
    ]);
    const flattenedResponse = flatten(response);

    return uniqBy(flattenedResponse, 'route');
  },
  getPageSuggestions$: (
    query: string,
    lang: GenericTypes.SanityLanguageKey,
  ): Promise<Suggestion[]> =>
    sanityClient.fetch<Suggestion[]>(
      `
        *[_type == $type && (
          [menuTitle->value[$lang], menuText->value[$lang]] match $query ||
          searchEntries[]->{"value": value[$lang]}.value match $query
         ) && defined(searchEntries[0])]{
          "key": route,
          "titleKey": searchEntries[0]->key,
          route
        }
      `,
      {
        query: `*${query}*`,
        lang,
        type: SanityDocumentType.PAGE,
      },
    ),
};
