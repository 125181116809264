import { sanityClient, SanityDocumentType } from '@shared/sanity';
import { SanityRequestTypeIdResponse } from '../../shared';

export const api = {
  getRequestTypeId$: async (
    country: GenericTypes.Country,
  ): Promise<number | undefined> => {
    const query = `
      *[_type == $type && content.country == $country][0]{
        ...content.ticketing.customerServiceRequest.types.generalRequest{
          ...select(
            typeId > 0 => {
              "requestTypeId": typeId,
            },
          ),
        },
      }
    `;
    const response = await sanityClient.fetch<SanityRequestTypeIdResponse>(
      query,
      {
        country,
        type: SanityDocumentType.SETTINGS,
      },
    );

    return response?.requestTypeId;
  },
};
