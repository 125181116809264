import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      padding: theme.spacing(4, 0),
    },
    wrapper: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    itemsWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      '& > div + div': {
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
          marginLeft: theme.spacing(1),
        },
      },
      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(2),
      },
    },
  }),
);
