import Button, { ButtonProps } from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import { ChevronRightIcon } from '@shared/components';
import React, { ElementType, FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from '@shared/translations';
import { useStyles } from './menu-element.styles';

type Props<C> = {
  component?: C;
  shortDescriptionKey: GenericTypes.TranslationLabel;
  titleKey: GenericTypes.TranslationLabel;
};

export function MenuElement<C extends ElementType = Link>(
  props: ButtonProps<C, Props<C>>,
): ReturnType<FC<ButtonProps<C, Props<C>>>> {
  const { shortDescriptionKey, titleKey, ...rest } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const template = (
    <>
      <div>
        <Typography variant="h3">
          <strong>{t(titleKey)}</strong>
        </Typography>
        <Typography>{t(shortDescriptionKey)}</Typography>
      </div>
      <Icon className={classes.icon}>
        <ChevronRightIcon />
      </Icon>
    </>
  );

  return (
    <li>
      <Button
        {...rest}
        className={classes.menuElement}
        style={{
          borderRadius: 0,
          textAlign: 'left',
          width: '100%',
        }}
      >
        {template}
      </Button>
    </li>
  );
}
MenuElement.defaultProps = {
  component: Link,
  url: '',
};
