import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '../../shared';

export const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      height: '100%',
    },
    container: {
      backgroundColor: palette.athensGray,
      borderRadius: theme.spacing(1),
      color: palette.shark,
      padding: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    title: {
      color: 'inherit',
    },
    description: {
      marginTop: theme.spacing(2),
    },
    row: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(0.5),
    },
    text: {
      marginBottom: theme.spacing(1),
    },
    amountBlock: {
      marginBottom: theme.spacing(2),
    },
    amount: {
      color: 'inherit',
      fontSize: theme.typography.pxToRem(40),
      lineHeight: theme.typography.pxToRem(32),
      marginBottom: theme.spacing(1),
      fontWeight: theme.typography.fontWeightBold,
    },
    paymentDate: {
      color: 'inherit',
    },
    summary: {
      marginBottom: 0,
    },
    link: {
      alignItems: 'center',
      display: 'flex',
      textDecoration: 'underline',
    },
    icon: {
      marginLeft: theme.spacing(1),
      height: 24,

      '& path': {
        stroke: palette.shark,
      },
    },
    buttonsRow: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(2),
    },
  }),
);
