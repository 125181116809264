import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    icon: {
      width: '32px',
      height: '32px',
      [theme.breakpoints.down('sm')]: {
        width: '24px',
        height: '24px',
      },
    },
    synchronizeIcon: {
      width: '16px',
      height: '16px',
    },
  }),
);
