import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { NotVerifiedEmailError, Spinner } from '@shared/components';
import { DataSourceEnum } from '@shared/enums';
import { useThrowError } from '@shared/hooks';
import { heimClient, jiraServiceClient } from '@shared/http';
import { HttpStatus } from '@shared/http/http.type';
import { localStorageService, storageService } from '@shared/services';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { getCountry } from '@shared/helpers';
import { useTranslation } from '@shared/translations';
import { notificationClient } from '../../../+notification/notifications.client';
import { api } from '../../auth.repository';
import { Context } from './context';
import { initialState, reducer } from './reducer';
import { InvalidCustomer } from '../../components/Error/error.component';
import { User } from '../../types';
import { adaptUserData } from '..';

export const Provider: FC = ({ children }) => {
  const {
    getAccessTokenSilently,
    isAuthenticated,
    user: auth0User,
  } = useAuth0();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { authToken, heimToken, isUserVerified, isHeimUserExist } = state;
  const [isFetching, setFetching] = useState(true);
  const user = useMemo(() => adaptUserData(auth0User), [auth0User]);
  const throwError = useThrowError();
  const { setUserCountry } = useTranslation();
  const handleFetchHeimUserData = useCallback(
    async (email: User['email'], phone: User['phone'], isLogin = false) => {
      if (!isLogin) {
        storageService.clear();
      }

      const userData = await api.authorizeUser(email, phone);
      const { dataSource, token, userAndSessionId } = userData;

      Sentry.setUser({ email, phone, userAndSessionId });

      heimClient.instance.defaults.headers.Authorization = `Bearer ${token}`;
      storageService.setDataSource(dataSource);
      storageService.setGuidLogin(userAndSessionId);

      localStorageService.removeCountryCode();
      const country = getCountry(dataSource);

      localStorageService.setCountryCode(country);
      setUserCountry(country);

      // TODO: @michalkowal Find a solution to prevent jumping to the top after update the state
      // if (!isLogin) {
      //   dispatch({
      //     type: 'AUTH_UPDATE_HEIM_TOKEN',
      //     payload: token,
      //   });
      // }

      return userData;
    },
    [setUserCountry],
  );

  const initData = useCallback(
    async (user: User) => {
      try {
        if (!user.isUserVerified) {
          dispatch({
            type: 'AUTH_INIT',
            payload: {
              authToken: '',
              dataSource: DataSourceEnum.Unknown,
              heimToken: '',
              isUserVerified: false,
              isHeimUserExist: false,
              user: {},
            },
          });
          setFetching(false);

          return;
        }

        const authToken = await getAccessTokenSilently();

        notificationClient.instance.defaults.headers.Authorization = `Bearer ${authToken}`;
        jiraServiceClient.instance.defaults.headers.Authorization = `Bearer ${authToken}`;

        const { dataSource, token: heimToken } = await handleFetchHeimUserData(
          user.email,
          user.phone,
          true,
        );

        dispatch({
          type: 'AUTH_INIT',
          payload: {
            authToken,
            dataSource,
            heimToken,
            isHeimUserExist: true,
            isUserVerified: true,
            user,
          },
        });

        // Set fetching is setting only in try block instead of finally block to avoid showing the NoAccountError on HTTP error
        setFetching(false);
      } catch (e) {
        if (e.status === HttpStatus.FORBIDDEN) {
          dispatch({
            type: 'AUTH_INIT',
            payload: {
              authToken: '',
              dataSource: DataSourceEnum.Unknown,
              heimToken: '',
              isUserVerified: user.isUserVerified,
              isHeimUserExist: false,
              user: {},
            },
          });
          setFetching(false);
        } else {
          throwError(e);
        }
      }
    },
    [getAccessTokenSilently, handleFetchHeimUserData, throwError],
  );

  useEffect(() => {
    if (authToken && heimToken) {
      setFetching(false);

      return;
    }

    if (isAuthenticated && user) {
      initData(user);
    }
  }, [authToken, heimToken, initData, isAuthenticated, user]);

  if (isFetching) {
    return <Spinner />;
  }

  const template = isHeimUserExist ? children : <InvalidCustomer />;

  return (
    <Context.Provider
      value={{
        ...state,
        fetchHeimUserData: handleFetchHeimUserData,
      }}
    >
      {!isUserVerified ? <NotVerifiedEmailError /> : template}
    </Context.Provider>
  );
};

Provider.displayName = 'AuthProvider';
