import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    list: {
      marginTop: theme.spacing(3),
      display: 'flex',
      flexWrap: 'wrap',
    },
    icon: {
      display: 'block',
    },
    element: {
      backgroundColor: palette.athensGray,
      borderRadius: theme.spacing(1),
      fontWeight: theme.typography.fontWeightRegular,
      marginBottom: theme.spacing(2),
      marginRight: theme.spacing(2),
      padding: theme.spacing(2),
      textAlign: 'center',
      transition: theme.transitions.create(
        ['background-color', 'color', 'stroke'],
        {
          duration: theme.transitions.duration.short,
          easing: 'linear',
        },
      ),

      '& path, & circle': {
        stroke: palette.shark,
      },

      '&:hover': {
        backgroundColor: palette.internationalOrange,
        color: palette.white,

        '& path, & circle': {
          stroke: palette.white,
        },
      },
    },
    elementIcon: {
      marginBottom: theme.spacing(1),
    },
    buttonLabel: {
      flexDirection: 'column',
    },

    [theme.breakpoints.down('xs')]: {
      element: {
        marginRight: 0,
        width: '100%',
      },
    },
  }),
);
