import { TranslationLabels } from '@generated/translation-labels';
import Button, { ButtonProps } from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from '@shared/translations';
import { format } from 'date-fns';
import React, { FC, Key, useMemo } from 'react';
import { ActionButtonType } from '../types';
import { useStyles } from './header-toolbar.styles';

type Props = {
  date: Date;
  onActionClick: (type: ActionButtonType) => void;
};

type ActionButton = {
  handleClick: ButtonProps['onClick'];
  key: Key;
  labelKey: GenericTypes.TranslationLabel;
};

export const HeaderToolbar: FC<Props> = (props) => {
  const { date, onActionClick } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const buttons = useMemo<ActionButton[]>(
    () => [
      {
        handleClick: () => onActionClick('prev'),
        key: 'prev',
        labelKey: TranslationLabels.calendarActionButtonPrev,
      },
      {
        handleClick: () => onActionClick('today'),
        key: 'today',
        labelKey: TranslationLabels.calendarActionButtonToday,
      },
      {
        handleClick: () => onActionClick('next'),
        key: 'next',
        labelKey: TranslationLabels.calendarActionButtonNext,
      },
    ],
    [onActionClick],
  );
  const formattedMonth = useMemo(() => format(date, 'MMMM y'), [date]); // October 2021

  return (
    <Grid
      alignItems="center"
      className={classes.container}
      container
      justify="space-between"
      spacing={1}
    >
      <Grid item>
        <ButtonGroup size="small">
          {buttons.map((props) => {
            const { labelKey, key, handleClick } = props;

            return (
              <Button
                className={classes.button}
                color="primary"
                key={key}
                onClick={handleClick}
                variant="outlined"
              >
                {t(labelKey)}
              </Button>
            );
          })}
        </ButtonGroup>
      </Grid>
      <Grid item>
        <Typography className={classes.month} variant="h3">
          <strong>{formattedMonth}</strong>
        </Typography>
      </Grid>
    </Grid>
  );
};
