import React, { FC } from 'react';
import { ArrowRightIcon } from '@shared/components';
import IconButton from '@material-ui/core/IconButton';
import { useStyles } from './arrow-right-button.styles';

type Props = {
  disabled: boolean;
};

export const ArrowRightButton: FC<Props> = ({ disabled }) => {
  const classes = useStyles();

  return (
    <IconButton
      className={classes.arrowButton}
      disabled={disabled}
      type="submit"
    >
      <ArrowRightIcon height={32} width={32} />
    </IconButton>
  );
};
