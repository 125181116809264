import Typography from '@material-ui/core/Typography';
import { useApi, useCountry } from '@shared/hooks';
import React, { FC, useCallback } from 'react';
import { ContainerFluid } from '@shared/components';
import { Translation, useTranslation } from '@shared/translations';
import clsx from 'clsx';
import { sanityImageBuilder } from '../../sanity';
import { api } from './page-intro.repository';
import { useStyles } from './page-intro.styles';

export type Props = {
  route: string;
  text: Translation;
  title: Translation;
};

export const PageIntro: FC<Props> = (props: Props) => {
  const { route, text, title } = props;
  const { t } = useTranslation();
  const country = useCountry();
  const imgCallback = useCallback(async () => {
    const img = await api.getImage(route, country);
    return sanityImageBuilder.image(img).url();
  }, [route, country]);

  const [imgUrl] = useApi('', imgCallback);

  const classes = useStyles({ imgUrl });

  return (
    <ContainerFluid>
      <div className={classes.wrapper}>
        <div className={classes.contentItem}>
          <div className={classes.content}>
            <Typography className={classes.text} variant="h1">
              <strong>{t(title.key, title.options)}</strong>
            </Typography>
            <Typography
              className={clsx(classes.description, classes.text)}
              variant="h2"
            >
              {(text.key || text.options) && t(text.key, text.options)}
            </Typography>
          </div>
        </div>
        <div className={classes.imageItem} />
      </div>
    </ContainerFluid>
  );
};
