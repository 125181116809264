import { TranslationLabels } from '@generated/translation-labels';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useCountry } from '@shared/hooks';
import React, { FC, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  ContactInfo,
  generateUniqId,
  useApi,
  useTranslation,
} from '../../../../shared';
import { api } from './tips.repository';
import { useStyles } from './tips.styles';
import { Tip } from './tips.type';
import {
  TicketElementInternalKey,
  TICKETING_PATHS,
  ticketingAPI,
} from '../../../../+ticketingV2';

export const Tips: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const country = useCountry();
  const callback = useCallback(() => api.getTips(country), [country]);
  const [tips, , isError] = useApi<Tip[]>([], callback);
  const fetchTicketElementsFieldIds$ = useCallback(
    () =>
      ticketingAPI.getTicketElementsFieldIds$(country, [
        TicketElementInternalKey.INVOICE,
      ]),
    [country],
  );
  const [ticketElementsFieldIds] = useApi({}, fetchTicketElementsFieldIds$);
  const ticketingPath = useMemo(() => {
    const {
      [TicketElementInternalKey.INVOICE]: invoice,
    } = ticketElementsFieldIds;

    return invoice
      ? `${TICKETING_PATHS.MY_ACCOUNT_INVOICE_AND_CUSTOMER_ACCOUNT_ITEMS}/${invoice}`
      : TICKETING_PATHS.MY_ACCOUNT_INVOICE_AND_CUSTOMER_ACCOUNT_ITEMS;
  }, [ticketElementsFieldIds]);

  if (isError) {
    return null;
  }

  return (
    <Box>
      {tips.length > 0 && (
        <div className={classes.tips}>
          {tips.map(({ textKey, titleKey }) => (
            <div key={generateUniqId()} className={classes.tip}>
              <Typography>
                <strong>{t(titleKey)}</strong>
              </Typography>
              <Typography className={classes.text}>{t(textKey)}</Typography>
            </div>
          ))}
        </div>
      )}
      <Button
        color="primary"
        component={Link}
        to={ticketingPath}
        variant="outlined"
      >
        {t(TranslationLabels.invoiceDetailsTipsButtonLabel)}
      </Button>
      <ContactInfo country={country} isDense noAddress noEmail />
    </Box>
  );
};
