import { sanityClient, SanityDocumentType, SanityImage } from '../../index';

export const api = {
  getImage: (
    route: string,
    country: GenericTypes.Country,
  ): Promise<SanityImage> =>
    sanityClient
      .fetch<{ image: SanityImage }>(
        `* [_type == $type && country == $country && route == $route][0]{
          image
        }`,
        { route, country, type: SanityDocumentType.PAGE },
      )
      .then((result) => result.image),
};
