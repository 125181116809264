import { ReactNode } from 'react';
import { TranslationLabels } from '@generated/translation-labels';
import { InputValue } from '@shared/components/Fields';
import { FieldConfig, FormProps } from '@shared/FormV2/types';
import { Comment } from '../../../../+ticketing/ticketing.type';

export type Values = {
  comment: InputValue;
};

export const getFields = (submitButton: ReactNode): FieldConfig<Values>[] => [
  getTextareaField(submitButton),
];

export const getTextareaField = (
  submitButton: ReactNode,
): FieldConfig<Values> => ({
  componentType: 'input',
  field: {
    initialValue: '',
    name: 'comment',
  },
  props: {
    label: '',
    placeholder: TranslationLabels.ticketingDetailsEditorPlaceholder,
    required: true,
    submitButton,
    type: 'rich-textarea',
  },
});

export const getConfig = (
  handleSubmit: (comment: string) => Promise<Comment>,
): FormProps<Values>['config'] => ({
  validateOnBlur: false,
  validateOnChange: false,
  onSubmit: async ({ comment }): Promise<Comment> => handleSubmit(comment),
});
