import { TranslationLabels } from '@generated/translation-labels';
import { AttachmentValue, InputValue } from '@shared/components/Fields';
import { ValueOf } from '@shared/types';
import * as Yup from 'yup';
import { ServiceRequestVisibilityEnum } from '@shared/enums';
import { SwitchValue } from '@shared/FormV2/fields';
import { Field } from '@shared/components';
import { COUNTRY_TICKETING_MODEL } from '../../../../ticketing.const';
import { api } from '../../../../ticketing.repository';
import { ElementField } from '../../../../ticketing.type';
import { RequestModel } from '../form.type';

type TCustomerComplaintCategory = { [key in number]: boolean };

export type IssueComplaintRequestValues = Record<
  string,
  AttachmentValue[] | InputValue | SwitchValue | TCustomerComplaintCategory
>;

const prepareValueOfComplaintCategory = (
  value: TCustomerComplaintCategory,
): number[] => {
  return Object.entries(value)
    .filter(([, value]) => value)
    .map(([key]) => Number(key));
};

// TODO: @michalkowal Find the solution
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const getIssueComplaintRequestModel: RequestModel<IssueComplaintRequestValues> = (
  country,
  ticketValues,
  initialValues,
) => {
  const { jiraFields, jiraItems } = COUNTRY_TICKETING_MODEL[country];
  const summary = (initialValues?.[jiraFields.SUMMARY] as
    | ElementField
    | undefined)?.value;
  const description = (initialValues?.[jiraFields.DESCRIPTION] as
    | ElementField
    | undefined)?.value;
  const attachment = initialValues?.[jiraFields.ATTACHMENT] as
    | AttachmentValue[]
    | undefined;
  const visibility = initialValues?.[jiraFields.VISIBILITY] as
    | ServiceRequestVisibilityEnum
    | undefined;
  const visibilityFields: Field<IssueComplaintRequestValues>[] = initialValues?.isAuthor
    ? [
        {
          id: jiraFields.VISIBILITY,
          label: TranslationLabels.ticketingServiceRequestVisibilityLabel,
          switchOptions: {
            checkedLabelKey:
              TranslationLabels.ticketingServiceRequestVisibilityTypePrivate,
            checkedValue: ServiceRequestVisibilityEnum.PRIVATE,
            uncheckedValue: ServiceRequestVisibilityEnum.PUBLIC,
            checkedValueDescriptionKey:
              TranslationLabels.ticketingServiceRequestVisibilityPrivateDescriptionText,
            helperTextKey:
              TranslationLabels.ticketingServiceRequestVisibilityHelperText,
          },
          type: 'switch',
        },
      ]
    : [];
  const getFieldInitialValue = (
    complaint: ValueOf<typeof jiraItems>,
  ): boolean => {
    const values = initialValues?.[jiraFields.CUSTOMER_COMPLAINT] as
      | ElementField[]
      | undefined;

    return !!values?.find(({ id }) => id === complaint);
  };
  const getComplaintOption = (
    complaint: ValueOf<typeof jiraItems>,
    label: GenericTypes.TranslationLabel,
  ): { label: GenericTypes.TranslationLabel; name: string }[] =>
    complaint
      ? [
          {
            label,
            name: complaint.toString(),
          },
        ]
      : [];

  return {
    config: {
      initialValues: {
        [jiraFields.SUMMARY]: summary || '',
        [jiraFields.CUSTOMER_COMPLAINT]: {
          [jiraItems.NEIGHBOR_COMPLAINT]: getFieldInitialValue(
            jiraItems.NEIGHBOR_COMPLAINT,
          ),
          [jiraItems.CUSTOMER_SERVICE_COMPLAINT]: getFieldInitialValue(
            jiraItems.CUSTOMER_SERVICE_COMPLAINT,
          ),
          [jiraItems.SMOKE_OR_ODOR_COMPLAINT]: getFieldInitialValue(
            jiraItems.SMOKE_OR_ODOR_COMPLAINT,
          ),
          [jiraItems.NOISE_FROM_CONSTRUCTION_WORK_COMPLAINT]: getFieldInitialValue(
            jiraItems.NOISE_FROM_CONSTRUCTION_WORK_COMPLAINT,
          ),
          [jiraItems.GARBAGE_COMPLAINT]: getFieldInitialValue(
            jiraItems.GARBAGE_COMPLAINT,
          ),
          [jiraItems.OTHER_COMPLAINT]: getFieldInitialValue(
            jiraItems.OTHER_COMPLAINT,
          ),
          [jiraItems.SUGGESTIONS_FOR_IMPROVEMENT_COMPLAINT]: getFieldInitialValue(
            jiraItems.SUGGESTIONS_FOR_IMPROVEMENT_COMPLAINT,
          ),
        },
        [jiraFields.DESCRIPTION]: description || '',
        [jiraFields.ATTACHMENT]: attachment || [],
        [jiraFields.VISIBILITY]:
          visibility || ServiceRequestVisibilityEnum.PUBLIC,
      },
      prepareValues: (values) => ({
        ...values,
        [jiraFields.CUSTOMER_COMPLAINT]: prepareValueOfComplaintCategory(
          values[jiraFields.CUSTOMER_COMPLAINT] as TCustomerComplaintCategory,
        ),
      }),
      validationSchema: Yup.object().shape({
        [jiraFields.SUMMARY]: Yup.string().required(
          TranslationLabels.formErrorsRequired,
        ),
        [jiraFields.DESCRIPTION]: Yup.string().required(
          TranslationLabels.formErrorsRequired,
        ),
      }),
    },
    fields: [
      {
        id: jiraFields.SUMMARY,
        label: TranslationLabels.ticketingCustomerAccountSummaryLabel,
        required: true,
        type: 'text',
      },
      {
        id: jiraFields.CUSTOMER_COMPLAINT,
        label: TranslationLabels.ticketingCustomerAccountCategoryLabel,
        type: 'checkboxes',
        checkboxOptions: [
          ...getComplaintOption(
            jiraItems.NEIGHBOR_COMPLAINT,
            TranslationLabels.ticketingNeighborComplaintLabel,
          ),
          ...getComplaintOption(
            jiraItems.CUSTOMER_SERVICE_COMPLAINT,
            TranslationLabels.ticketingCustomerServiceComplaintLabel,
          ),
          ...getComplaintOption(
            jiraItems.SMOKE_OR_ODOR_COMPLAINT,
            TranslationLabels.ticketingSmokeOrOdorComplaintLabel,
          ),
          ...getComplaintOption(
            jiraItems.NOISE_FROM_CONSTRUCTION_WORK_COMPLAINT,
            TranslationLabels.ticketingNoiseFromConstructionComplaintLabel,
          ),
          ...getComplaintOption(
            jiraItems.GARBAGE_COMPLAINT,
            TranslationLabels.ticketingGarbageComplaintLabel,
          ),
          ...getComplaintOption(
            jiraItems.SUGGESTIONS_FOR_IMPROVEMENT_COMPLAINT,
            TranslationLabels.ticketingSuggestionsForImprovementComplaintLabel,
          ),
          ...getComplaintOption(
            jiraItems.OTHER_COMPLAINT,
            TranslationLabels.ticketingOtherComplaintLabel,
          ),
        ],
      },
      {
        id: jiraFields.DESCRIPTION,
        label: TranslationLabels.ticketingCustomerAccountDescriptionLabel,
        required: true,
        type: 'textarea',
      },
      {
        accept: ['.png', '.jpg', '.jpeg', '.pdf'],
        helperTextKey:
          TranslationLabels.ticketingServiceRequestAttachmentFileHelperText,
        helperTextOptions: {
          formats: 'JPG, JPEG, PNG, PDF',
          maxSize: 100,
        },
        id: jiraFields.ATTACHMENT,
        isMultiple: true,
        label: TranslationLabels.formFieldsAttachmentFileLabel,
        onPreviewClick: api.getAttachment,
        type: 'attachment',
      },
      ...visibilityFields,
    ],
  };
};
