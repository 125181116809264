import { TranslationLabels } from '@generated/translation-labels';
import MuiTooltip, {
  TooltipProps as MuiTooltipProps,
} from '@material-ui/core/Tooltip';
import React, { FC, ReactNode, useMemo } from 'react';
import { useTranslation } from '@shared/translations';

export type TooltipProps = {
  children: ReactNode;
  disabled?: boolean;
  messageKey?: GenericTypes.TranslationLabel;
  placement?: MuiTooltipProps['placement'];
};

export const Tooltip: FC<TooltipProps> = (props) => {
  const { children, disabled, messageKey, placement } = props;
  const { t } = useTranslation();
  const title = t(
    messageKey || TranslationLabels.generalInformationAboutPermission,
  );
  const disabledProps = useMemo(
    () =>
      disabled
        ? {
            disableFocusListener: true,
            disableHoverListener: true,
            disableTouchListener: true,
          }
        : {},
    [disabled],
  );

  return (
    <MuiTooltip title={title} placement={placement} {...disabledProps} arrow>
      <div>{children}</div>
    </MuiTooltip>
  );
};

Tooltip.displayName = 'Tooltip';

Tooltip.defaultProps = {
  placement: 'top',
};
