import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './maintenance-snackbar.styles';

// just strings are used here because of SnackbarProvider is declared before TranslationsProvider,
// that's why TFunction is not available yet
type Props = {
  title: string;
  description: string;
};

export const MaintenanceSnackbar: FC<Props> = (props) => {
  const { title, description } = props;
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.container}>
      <Typography color="inherit" variant="h3">
        {title}
      </Typography>
      <Typography color="inherit" variant="body1">
        {description}
      </Typography>
    </Grid>
  );
};
