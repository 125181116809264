import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import React, { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';

interface Props {
  children?: ReactNode;
}

const GuestGuard: FC<Props> = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (!isLoading && isAuthenticated) {
    return <Redirect to="/" />;
  }

  return <>{children}</>;
};
export default GuestGuard;

GuestGuard.propTypes = {
  children: PropTypes.node,
};
GuestGuard.defaultProps = {
  children: undefined,
};
