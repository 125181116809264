import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from '@shared/translations';
import { useStyles } from './flow-step-summary.styles';

type Props = {
  labelKey: GenericTypes.TranslationLabel;
};

export const FlowStepSummary: FC<Props> = (props) => {
  const { labelKey } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Typography variant="h2" className={classes.headline}>
      <strong>{t(labelKey)}</strong>
    </Typography>
  );
};

FlowStepSummary.displayName = 'FlowStepSummary';
