import { TranslationLabels } from '@generated/translation-labels';
import React, { FC } from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { useTranslation } from '@shared/translations';
import { useStyles } from './update-button.styles';

type Props = {
  disabled: ButtonProps['disabled'];
  setReadonly: (value: boolean) => void;
};

export const UpdateButton: FC<Props> = (props) => {
  const { disabled, setReadonly } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const handleClick = (): void => {
    setReadonly(false);
  };

  return (
    <div className={classes.wrapper}>
      <Button
        color="primary"
        disabled={disabled}
        onClick={handleClick}
        variant="contained"
      >
        {t(TranslationLabels.ticketingDetailsUpdateButtonLabel)}
      </Button>
    </div>
  );
};
