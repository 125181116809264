import { Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '../../../../shared';

type Props = {
  isIndexRoute: boolean;
};

export const useStyles = makeStyles<Theme, Props>((theme) =>
  createStyles({
    wrapper: {
      backgroundColor: palette.athensGray,
      marginTop: ({ isIndexRoute }) => (isIndexRoute ? theme.spacing(-14) : 0),
      padding: ({ isIndexRoute }) => theme.spacing(isIndexRoute ? 14 : 7, 0, 8),
    },
    buttonsRow: {
      display: 'flex',
      marginTop: theme.spacing(3),
    },
    allRequests: {
      marginLeft: 'auto',
    },
    title: {
      fontWeight: theme.typography.fontWeightRegular,
    },
    subtitle: {
      fontWeight: theme.typography.fontWeightRegular,
      marginBottom: theme.spacing(3),
    },
  }),
);
