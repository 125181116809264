import { TranslationLabels } from '@generated/translation-labels';
import {
  Notification,
  TerminationConfirmationNotification,
} from '@generated/clients/notifications/data-contracts';
import { ReactComponent as AccountingDocumentIcon } from '@heimstaden/icons-library/img/streamline-regular/money-payments-finance/accounting-billing/accounting-document.svg';
import { ReactComponent as AlarmBell } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/alert/alarm-bell.svg';
import { ReactComponent as RealStateDealDocumentIcon } from '@heimstaden/icons-library/img/streamline-regular/real-estate/deals/real-estate-deal-document.svg';
import { ReactComponent as ConversationChat2 } from '@heimstaden/icons-library/img/streamline-regular/messages-chat-smileys/conversation/conversation-chat-2.svg';
import { ReactComponent as MessagesBubbleEdit } from '@heimstaden/icons-library/img/streamline-regular/messages-chat-smileys/messages-speech-bubbles/messages-bubble-edit.svg';
import { ReactComponent as ArrowDoubleRight1 } from '@heimstaden/icons-library/img/streamline-regular/arrows-diagrams/arrows/arrow-double-right-1.svg';
import { ReactComponent as IrisScanApproved1 } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/view/view-1.svg';
import { ReactComponent as CommonFileDoubleIcon } from '@heimstaden/icons-library/img/streamline-regular/files-folders/common-files/common-file-double.svg';
import { ICustomerMidleLayerApi2 } from '@shared/models';

import { invoiceRoutes } from '../+invoice';
import {
  isTerminateRequestNotConfirmed,
  ServiceRequest,
  ticketingRoutes,
} from '../+ticketing';
import { terminationRoutes } from '../+termination';
import { NotificationWithCustomFields } from './notifications.type';

type IconType = React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

// todo: remove when MYH-651 ready
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const IconForNotificationType: {
  [key in Notification['metadata']['type']]: IconType;
} = {
  new_invoice: AccountingDocumentIcon,
  new_document: CommonFileDoubleIcon,
  termination_confirmation: RealStateDealDocumentIcon,
  termination_confirmation_awaiting: RealStateDealDocumentIcon,
  service_request_status_changed: ArrowDoubleRight1,
  service_request_employee_comment_added: ConversationChat2,
  service_request_employee_comment_edited: MessagesBubbleEdit,
  move_out_inspection_not_scheduled: IrisScanApproved1,
  move_out_time_not_scheduled: IrisScanApproved1,
};

export const getNotificationLink = (notification: Notification): string => {
  switch (notification.metadata.type) {
    case 'new_invoice':
      return `${invoiceRoutes.DETAILS}/${notification.metadata.invoiceId}`;
    case 'new_document':
      return notification.metadata.documentURL;
    case 'termination_confirmation':
    case 'termination_confirmation_awaiting':
      return `${ticketingRoutes.DETAILS}/${notification.metadata.taskId}`;
    case 'service_request_status_changed':
    case 'service_request_employee_comment_added':
    case 'service_request_employee_comment_edited':
      return `${ticketingRoutes.DETAILS}/${notification.metadata.taskId}`;
    case 'move_out_inspection_not_scheduled':
    case 'move_out_time_not_scheduled':
      return `${terminationRoutes.INDEX}/${notification.metadata.taskId}`;
    default:
      return '/notifications';
  }
};

export const getNotificationTitleTranslationKey = (
  metadata: Notification['metadata'],
): GenericTypes.TranslationLabel => {
  switch (metadata.type) {
    case 'new_invoice':
      return TranslationLabels.notificationItemTitleInvoice;
    case 'new_document':
      return TranslationLabels.notificationItemTitleDocument;
    case 'termination_confirmation':
      return TranslationLabels.notificationItemTitleTerminationConfirmation;
    case 'termination_confirmation_awaiting':
      return TranslationLabels.notificationItemTitleTerminationConfirmationAwaiting;
    case 'service_request_status_changed':
      return TranslationLabels.notificationItemTitleServiceRequestStatusChanged;
    case 'service_request_employee_comment_added':
      return TranslationLabels.notificationItemTitleServiceRequestEmployeeCommentAdded;
    case 'service_request_employee_comment_edited':
      return TranslationLabels.notificationItemTitleServiceRequestEmployeeCommentEdited;
    case 'move_out_inspection_not_scheduled':
      return TranslationLabels.notificationItemTitleMoveOutInspectionNotScheduled;
    case 'move_out_time_not_scheduled':
      return TranslationLabels.notificationItemTitleMoveOutTimeNotScheduled;
    default:
      return 'notificationItemTitleGeneric';
  }
};

export const getNotificationDescriptionTranslationKey = (
  metadata: Notification['metadata'],
): {
  key: GenericTypes.TranslationLabel;
  variables?: { [key: string]: string };
} => {
  switch (metadata.type) {
    case 'new_invoice':
      return { key: TranslationLabels.notificationItemDescriptionInvoice };
    case 'new_document':
      return { key: TranslationLabels.notificationItemDescriptionDocument };
    case 'termination_confirmation':
      return {
        key:
          TranslationLabels.notificationItemDescriptionTerminationConfirmation,
      };
    case 'termination_confirmation_awaiting':
      return {
        key:
          TranslationLabels.notificationItemDescriptionTerminationConfirmationAwaiting,
      };
    case 'service_request_status_changed':
      return {
        key:
          TranslationLabels.notificationItemDescriptionServiceRequestStatusChanged,
        variables: {
          taskId: metadata.taskId,
          newStatus: metadata.newStatus,
        },
      };
    case 'service_request_employee_comment_added':
      return {
        key:
          TranslationLabels.notificationItemDescriptionServiceRequestEmployeeCommentAdded,
        variables: {
          taskId: metadata.taskId,
        },
      };
    case 'service_request_employee_comment_edited':
      return {
        key:
          TranslationLabels.notificationItemDescriptionServiceRequestEmployeeCommentEdited,
        variables: {
          taskId: metadata.taskId,
        },
      };
    case 'move_out_inspection_not_scheduled':
      return {
        key:
          TranslationLabels.notificationItemDescriptionMoveOutInspectionNotScheduled,
      };
    case 'move_out_time_not_scheduled':
      return {
        key:
          TranslationLabels.notificationItemDescriptionMoveOutTimeNotScheduled,
      };

    default:
      return { key: 'notificationItemDescriptionGeneric' };
  }
};

export const getIconForNotificationType = (
  metadata: Notification['metadata'],
): IconType => {
  // todo: remove when MYH-651 ready
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const icon = IconForNotificationType[metadata?.type];
  // Return found icon or fallback a generic notification type
  return icon || AlarmBell;
};

export const isAwaitingAction = (
  notification: Notification,
  customerId: ICustomerMidleLayerApi2['customerIdApi'],
  tickets: ServiceRequest[],
  country: GenericTypes.Country,
): boolean => {
  switch (notification.metadata.type) {
    case 'termination_confirmation': {
      const ticket = tickets.find(
        (t) =>
          t.id ===
          (notification.metadata as TerminationConfirmationNotification).taskId,
      );

      if (!ticket) return false;

      return isTerminateRequestNotConfirmed(ticket, country, customerId);
    }
    default:
      return false;
  }
};

export const adaptNotificationsForUI = (
  notifications?: Notification[],
): NotificationWithCustomFields[] => {
  return (notifications || [])
    .filter((n) => {
      // todo: change when MYH-651 ready
      // As we fetch notifications based on user ID or e-mail,
      // this logic in needed only for the case when an user
      // is our customer and employee at the same time.
      // Probably there is no such case, but it is better to have such logic.
      const supportedNotificationTypes = [
        'new_invoice',
        'new_document',
        'termination_confirmation',
        'termination_confirmation_awaiting',
        'service_request_status_changed',
        'service_request_employee_comment_added',
        'service_request_employee_comment_edited',
        'move_out_inspection_not_scheduled',
        'move_out_time_not_scheduled',
      ];
      return supportedNotificationTypes.includes(n.metadata.type);
    })
    .map((n) => {
      const { key, variables } = getNotificationDescriptionTranslationKey(
        n.metadata,
      );

      return {
        ...n,
        titleKey: getNotificationTitleTranslationKey(n.metadata),
        descriptionKey: key,
        descriptionVariables: variables,
      };
    });
};
