import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles(() =>
  createStyles({
    button: {
      height: 'inherit',
      maxHeight: 'inherit',
    },
    positionEnd: {
      marginRight: '-8px',
      marginBottom: '6px',
    },
  }),
);
