import Typography from '@material-ui/core/Typography';
import React from 'react';
import clsx from 'clsx';
import { useTranslation } from '@shared/translations';
import { useStyles } from './status.styles';

export type Status = {
  text: GenericTypes.TranslationLabel;
  colorHex: string;
};

type Props = {
  status: Status;
  className?: string;
  variant?: 'large' | 'small';
};

export function Status({
  className,
  status: { text, colorHex },
  variant,
}: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Typography
      className={clsx(classes.status, className, {
        [classes.large]: variant === 'large',
      })}
      style={{ backgroundColor: colorHex }}
    >
      <strong>{t(text)}</strong>
    </Typography>
  );
}
