import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      backgroundColor: palette.white,
      borderRadius: theme.spacing(1),
      justifyContent: 'flex-end',
      marginBottom: theme.spacing(1),
      marginLeft: 'auto',
      maxWidth: '55%',
      minHeight: 190,
      padding: theme.spacing(2),
      width: '100%',
    },

    [theme.breakpoints.down('xs')]: {
      container: {
        maxWidth: 'unset',
      },
    },
  }),
);
