import { TranslationLabels } from '@generated/translation-labels';
import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from '@shared/translations';
import { FlowStepSummary } from '../FlowStepSummary/flow-step-summary.component';
import { PendingReasons } from '../PendingReasons/pending-reasons';
import { TerminationRequestFlowStep } from '../../../types';
import { Details } from '../Details/details.component';

type Props = {
  flowStep: TerminationRequestFlowStep;
};

export const RequestSent: FC<Props> = ({ flowStep }) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={4}>
        <FlowStepSummary
          labelKey={
            TranslationLabels.terminationFlowStepTerminationSentSummaryText
          }
        />
        <Typography paragraph>
          {t(
            TranslationLabels.terminationFlowStepTerminationSentDescriptionTextPara1,
          )}
        </Typography>
        <Typography paragraph>
          {t(
            TranslationLabels.terminationFlowStepTerminationSentDescriptionTextPara2,
          )}
        </Typography>
        <Typography paragraph>
          {t(
            TranslationLabels.terminationFlowStepTerminationSentDescriptionTextPara3,
          )}
        </Typography>
        <PendingReasons
          eventType={flowStep.eventType}
          pendingReasons={flowStep.pendingReasons}
        />
      </Grid>
      <Hidden smDown>
        <Grid item md={2} />
      </Hidden>
      <Grid item xs={12} md={6}>
        <Details />
      </Grid>
    </Grid>
  );
};
