import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      display: 'block',

      '& path': {
        stroke: palette.internationalOrange,
      },
    },
  }),
);
