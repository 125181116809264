import { sanityClient, SanityDocumentType } from '@shared/sanity';
import { ElementsData, itemsPathSuffix } from '../../shared';

export const api = {
  getRooms$: async (
    country: GenericTypes.Country,
    areaId: number,
    path: string,
  ): Promise<ElementsData[]> => {
    const query = `
      * [_type == $type && content.country == $country][0]{
        ...content.ticketing.repairMaintenanceRequest.areas{
          "rooms": select(
            unit.typeId == $areaId => unit.rooms[]{
              ...room->{
                "key": fieldId,
                "redirectURL": select(
                  defined(^.items) => "${path}/" + string(fieldId) + "${itemsPathSuffix}",
                  defined(^.otherElementId) => "${path}/" + string(fieldId) + "${itemsPathSuffix}/" + string(^.otherElementId),
                  "${path}/" + string(fieldId)
                ),
                "titleKey": title->key,
                externalWebpageUrl,
                roles,
              },
            }
          ),
        }
      }
    `;
    const response = await sanityClient.fetch<{ rooms: ElementsData[] }>(
      query,
      {
        areaId,
        country,
        type: SanityDocumentType.SETTINGS,
      },
    );

    return response.rooms;
  },
};
