import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

const CIRCLE_SIZE_PX = 64;
const ICON_SIZE_PX = 32;
const CIRCLE_SMALL_SIZE_PX = 32;
const ICON_SMALL_PX = 16;

export const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      background: palette.athensGray,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
    },
    circle: {
      width: CIRCLE_SIZE_PX,
      height: CIRCLE_SIZE_PX,
      borderRadius: '50%',
      background: palette.silver,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: `calc(50% - ${CIRCLE_SIZE_PX / 2}px)`,
      [theme.breakpoints.down('xs')]: {
        width: CIRCLE_SMALL_SIZE_PX,
        height: CIRCLE_SMALL_SIZE_PX,
        top: `calc(50% - ${CIRCLE_SMALL_SIZE_PX / 2}px)`,
      },
    },
    icon: {
      width: ICON_SIZE_PX,
      height: ICON_SIZE_PX,
      '& path': {
        stroke: palette.white,
      },
      [theme.breakpoints.down('xs')]: {
        width: ICON_SMALL_PX,
        height: ICON_SMALL_PX,
      },
    },
  }),
);
