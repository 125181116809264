import { SlotLabelContentArg } from '@fullcalendar/react';
import Typography from '@material-ui/core/Typography';
import { getDateTime } from '@shared/helpers';
import React, { FC, useMemo } from 'react';
import { useStyles } from './time-slot-label.styles';

type Props = SlotLabelContentArg;

export const TimeSlotLabel: FC<Props> = (props) => {
  const { date } = props;
  const classes = useStyles();
  const formattedTime = useMemo(() => getDateTime(date), [date]);

  return (
    <Typography className={classes.text}>
      <strong>{formattedTime}</strong>
    </Typography>
  );
};
