import { Fade, ListItemText } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React, { FC, MouseEvent, useState } from 'react';
import { useTranslation } from '@shared/translations';
import { useStyles } from './language-switcher.styles';

export const LanguageSwitcher: FC = () => {
  const classes = useStyles();
  const { langCode, supportedLanguages, changeLanguage } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClose = (): void => {
    setAnchorEl(null);
  };
  const handleOpen = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick = async (
    event: MouseEvent<HTMLLIElement>,
  ): Promise<void> => {
    const { lang } = event.currentTarget.dataset;

    if (!lang) {
      return;
    }

    await changeLanguage(lang as GenericTypes.Language);
    handleClose();
  };
  const isAnchor = Boolean(anchorEl);
  const getFlag = (lang: GenericTypes.Language): JSX.Element =>
    lang === 'cimode' ? (
      <ListItemText>Keys</ListItemText>
    ) : (
      <img src={`/assets/flags/${lang}.svg`} alt="" className={classes.image} />
    );

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        className={classes.button}
        onClick={handleOpen}
      >
        {getFlag(langCode)}
      </Button>
      <Fade in={isAnchor}>
        <Menu
          anchorEl={anchorEl}
          className={classes.menuListContainer}
          keepMounted
          onClose={handleClose}
          open={isAnchor}
        >
          {supportedLanguages.map((lang) => (
            <MenuItem
              data-lang={lang}
              key={`language-${lang}`}
              onClick={handleClick}
              selected={lang === langCode}
              disabled={lang === langCode}
            >
              {getFlag(lang)}
            </MenuItem>
          ))}
        </Menu>
      </Fade>
    </div>
  );
};
