import { Theme } from '@material-ui/core/styles/createMuiTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

type Props = {
  imageUrl: GenericTypes.URL;
};

export const useStyles = makeStyles<Theme, Props>((theme) =>
  createStyles({
    link: {
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      justifyContent: 'center',
      '& $instagramCircle': {
        opacity: 0,
      },
      '&:hover $instagramCircle': {
        opacity: 1,
      },
      '&:hover $image': {
        opacity: 0.25,
      },
    },
    instagramCircle: {
      position: 'absolute',
      margin: 0,
    },
    image: {
      width: '100%',
      height: 0,
      paddingBottom: '100%',
      borderRadius: theme.spacing(1),
      transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.short,
        easing: theme.transitions.easing.easeInOut,
      }),
      backgroundImage: ({ imageUrl }) => `url(${imageUrl})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
    },
    imagePlaceholder: {
      width: '100%',
      paddingTop: '100%',
      borderRadius: theme.spacing(1),
    },
  }),
);
