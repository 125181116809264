import { TranslationLabels } from '@generated/translation-labels';
import { useApi, useCountry } from '@shared/hooks';
import React, { FC, useCallback } from 'react';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import { Content, Elements } from '../../shared';
import { RouteParams } from '../service-request.type';
import { api } from './failures.repository';
import { stepper } from '../stepper.config';

export const Failures: FC = () => {
  const { areaId, itemId, roomId } = useParams<RouteParams>();
  const { pathname } = useLocation();
  const country = useCountry();
  const getFailures$ = useCallback(
    () =>
      api.getFailures$(
        country,
        pathname,
        Number(areaId),
        Number(itemId),
        Number(roomId),
      ),
    [areaId, country, itemId, pathname, roomId],
  );
  const [response, isFetching] = useApi(undefined, getFailures$);

  return (
    <Content
      introProps={{
        descriptionKey: TranslationLabels.ticketingServiceRequestFailuresText,
        titleKey: TranslationLabels.ticketingServiceRequestFailuresTitle,
      }}
      isFetching={isFetching}
      stepperConfig={stepper}
    >
      <Elements
        data={response?.failures}
        otherElementRedirectURL={response?.otherElementRedirectURL}
      />
    </Content>
  );
};
