import { TranslationLabels } from '@generated/translation-labels';
import Button from '@material-ui/core/Button';
import { useTranslation } from '@shared/translations';
import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ticketingRoutes } from '../../../../+ticketing';
import { HomeButton } from '../HomeButton/home-button.component';
import { useStyles } from './success.styles';
import { Content } from '../Content/content.component';
import { ReactComponent as SuccessImage } from '../../../assets/success.svg';
import { Stepper as StepperConfig } from '../../../types';

type Props = {
  stepperConfig?: StepperConfig;
};

export const Success: FC<Props> = ({ stepperConfig }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    state: { ticketNumber },
  } = useLocation<{ ticketNumber: string }>();

  return (
    <Content
      introProps={{
        titleKey: TranslationLabels.ticketingServiceRequestConfirmationHeader,
        descriptionKey:
          TranslationLabels.ticketingServiceRequestConfirmationFollowText,
        infoKey: TranslationLabels.ticketingServiceRequestConfirmationInfo,
        options: { ticketNumber },
        childrenRightColumn: <SuccessImage />,
      }}
      isFetching={false}
      stepperConfig={stepperConfig}
      allStepsFinished
      type="columns"
    >
      <div className={classes.buttonsRow}>
        <HomeButton />
        <Button
          component={Link}
          to={ticketingRoutes.LIST}
          variant="contained"
          color="primary"
          size="large"
        >
          {t(
            TranslationLabels.ticketingServiceRequestConfirmationButtonListLabel,
          )}
        </Button>
      </div>
    </Content>
  );
};
