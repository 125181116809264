import { TranslationLabels } from '@generated/translation-labels';
import React, { FC, useCallback } from 'react';
import {
  Box,
  Spinner,
  HeaderSection,
  ImagePlaceholder,
} from '@shared/components';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useApi, useCountry } from '@shared/hooks';
import { useTranslation } from '@shared/translations';
import he from 'he';
import { useStyles } from './blog.styles';
import { api } from './blog.repository';
import { Post } from './blog.type';

export const Blog: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const country = useCountry();
  const getPostsCallback = useCallback(() => api.getPosts(country), [country]);
  const [posts, isFetchingPosts, isPostsError] = useApi<Post[]>(
    [],
    getPostsCallback,
  );
  const getBlogUrlCallback = useCallback(() => api.getBlogUrl(country), [
    country,
  ]);
  const [blogUrl, isFetchingBlogUrl, isBlogError] = useApi<GenericTypes.URL>(
    '',
    getBlogUrlCallback,
  );

  if (isFetchingPosts || isFetchingBlogUrl) {
    return <Spinner />;
  }

  if (isBlogError || isPostsError) {
    return null;
  }

  return (
    <div>
      <HeaderSection htmlText={t(TranslationLabels.blogSectionTitle)} />
      <Grid container spacing={2}>
        {posts.map(({ id, title, link, imageUrl }) => (
          <Grid item md={4} sm={12} xs={12} key={id}>
            <Box className={classes.box}>
              <div>
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    alt="post"
                    className={classes.postImage}
                  />
                ) : (
                  <ImagePlaceholder className={classes.imagePlaceholder} />
                )}
                <Typography className={classes.postText} variant="h2">
                  {he.decode(title)}
                </Typography>
              </div>
              <div>
                <Button
                  color="primary"
                  variant="outlined"
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t(TranslationLabels.blogReadStoryButton)}
                </Button>
              </div>
            </Box>
          </Grid>
        ))}
      </Grid>
      <div className={classes.bottom}>
        <Typography variant="h3" className={classes.bottomText}>
          {t(TranslationLabels.visitOurBlogText)}
        </Typography>
        <Button
          color="primary"
          variant="contained"
          href={blogUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t(TranslationLabels.visitOurBlogButton)}
        </Button>
      </div>
    </div>
  );
};
