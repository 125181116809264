import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import MuiLink from '@material-ui/core/Link';
import { Icon } from '@shared/components';
import { ReactComponent as ArrowRight } from '@heimstaden/icons-library/img/streamline-regular/arrows-diagrams/arrows/arrow-right.svg';
import { useTranslation } from '@shared/translations';
import { useStyles } from './shortcuts-item.styles';

type Props = {
  linkLabel: GenericTypes.TranslationLabel;
  href: GenericTypes.URL;
  isInternalLink?: boolean;
};

export const ShortcutsItem: FC<Props> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { href, linkLabel, isInternalLink } = props;

  let additionalProps = {};
  if (!isInternalLink) {
    additionalProps = {
      ...additionalProps,
      target: '_blank',
      rel: 'noopener noreferrer',
    };
  }

  return (
    <li className={classes.item}>
      <Grid container wrap="nowrap">
        <Grid item>
          <Icon
            width={16}
            height={16}
            icon={ArrowRight}
            className={classes.icon}
          />
        </Grid>
        <Grid item>
          <MuiLink href={href} className={classes.link} {...additionalProps}>
            {t(linkLabel)}
          </MuiLink>
        </Grid>
      </Grid>
    </li>
  );
};
