import { TranslationLabels } from '@generated/translation-labels';
import React, { FC, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from '@shared/translations';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useDialog } from '@shared/Dialog';
import { Icon } from '@shared/components';
import { ReactComponent as LoadingCircle2 } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/loading/loading-circle-2.svg';
import { ReactComponent as QuestionCircleIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/alerts/question-circle.svg';
import { format } from 'date-fns';
import { useStyles } from './details.styles';
import { useTermination } from '../../state';
import { TenantsResponses } from '../TenantResponses/tenants-responses.component';
import { Documents } from '../Documents/documents.component';
import { RescheduleButton } from '../RescheduleButton/reschedule-button.component';
import { Comments } from '../../../../+ticketingV2/shared';
import { Comment } from '../../../../+ticketing/ticketing.type';
import { DetailsTitle } from '../DetailsTitle/details-title.component';
import { TerminationBookingFlowStep } from '../../../types';

const DATE_FORMAT = 'cccc dd/MM/y HH:mm';

const formatDate = (date?: string): string | undefined => {
  if (!date) return undefined;
  const newDate = new Date(date);
  return format(newDate, DATE_FORMAT);
};

export const Details: FC = () => {
  const classes = useStyles();
  const {
    addComment,
    address,
    apartmentNo,
    bookedDates,
    comments,
    tenantsResponses,
    ticketId,
    currentFlowStep,
  } = useTermination();
  const { t } = useTranslation();
  const [isSuggestedDateIconHovered, setIsSuggestedDateIconHovered] = useState(
    false,
  );
  const { Dialog, openDialog } = useDialog('tip');

  const suggestedDate = (currentFlowStep as TerminationBookingFlowStep)
    ?.suggestedDate?.value;

  const moveOutInspectionDate = useMemo(
    () => formatDate(bookedDates.MoveOutInspection),
    [bookedDates.MoveOutInspection],
  );

  const moveOutDate = useMemo(() => formatDate(bookedDates.MoveOut), [
    bookedDates.MoveOut,
  ]);

  const moveOutInspectionSuggestedDate = useMemo(
    () => formatDate(suggestedDate),
    [suggestedDate],
  );

  const renderApartmentNo = (apartmentNo?: string | null): string | null => {
    if (!apartmentNo) {
      return null;
    }
    return ` ${t(TranslationLabels.apartmentNumberPrefix)} ${apartmentNo}`;
  };

  const onCommentAdded = (comment: Comment): void => {
    addComment(comment);
  };

  return (
    <>
      <Dialog
        titleKey={TranslationLabels.moveOutInspectionDatePendingTipTitle}
        descriptionKey={
          TranslationLabels.moveOutInspectionDatePendingTipDescription
        }
      />
      <Grid container className={classes.container}>
        <Grid
          container
          spacing={1}
          className={classes.wrapper}
          direction="column"
          justify="space-between"
        >
          <Grid container spacing={1}>
            <DetailsTitle ticketId={ticketId} />
            <Grid item xs={12}>
              <Typography className={classes.label} variant="body2">
                {t(TranslationLabels.terminationDetailsApartmentTitle)}
              </Typography>
              <Typography>
                {address?.value}
                {renderApartmentNo(apartmentNo?.value)}
              </Typography>
            </Grid>
            {moveOutInspectionDate && (
              <Grid item xs={12}>
                <Typography className={classes.label} variant="body2">
                  {t(TranslationLabels.terminationMoveOutInspectionDateTitle)}
                </Typography>
                <Grid container>
                  <RescheduleButton eventType="MoveOutInspection" />
                  <Typography>{moveOutInspectionDate}</Typography>
                </Grid>
              </Grid>
            )}
            {moveOutDate && (
              <Grid item xs={12}>
                <Typography className={classes.label} variant="body2">
                  {t(TranslationLabels.terminationMoveOutDateTitle)}
                </Typography>
                <Typography>{moveOutDate}</Typography>
              </Grid>
            )}
            {tenantsResponses && tenantsResponses.length > 0 && (
              <Grid item xs={12}>
                <Typography className={classes.label} variant="body2">
                  {t(
                    TranslationLabels.terminationFlowStepTenantsAcceptanceOfTerminationTitle,
                  )}
                </Typography>
                <TenantsResponses />
              </Grid>
            )}
            {moveOutInspectionSuggestedDate && (
              <Grid item xs={12}>
                <Typography className={classes.label} variant="body2">
                  {t(TranslationLabels.terminationMoveOutSuggestedDateTitle)}
                </Typography>
                <Typography className={classes.content}>
                  <Icon
                    className={clsx(classes.icon, classes.suggestedDateIcon)}
                    icon={
                      isSuggestedDateIconHovered
                        ? QuestionCircleIcon
                        : LoadingCircle2
                    }
                    height={16}
                    width={16}
                    onMouseEnter={() => setIsSuggestedDateIconHovered(true)}
                    onMouseLeave={() => setIsSuggestedDateIconHovered(false)}
                    onClick={openDialog}
                  />

                  {moveOutInspectionSuggestedDate}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography className={classes.label} variant="body2">
                {t(TranslationLabels.terminationDocumentsTitle)}
              </Typography>
              <Documents />
            </Grid>
          </Grid>
          <Grid container className={classes.buttonsWrapper}>
            <Grid item xs={12}>
              <Comments
                comments={comments || []}
                handleSuccess={onCommentAdded}
                ticketId={ticketId}
                titleComponent={<DetailsTitle ticketId={ticketId} />}
                isCommentingAllowed
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

Details.displayName = 'FlowStepDetails';
