import React, { FC, ReactNode } from 'react';
import { Icon } from '@shared/components';
import Grid, { GridProps } from '@material-ui/core/Grid';
import { ReactComponent as AlertCircleIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/alerts/alert-circle.svg';
import { useStyles } from './alert.styles';

type AlertProps = GridProps & {
  children: ReactNode;
};

export const Alert: FC<AlertProps> = ({ children, ...gridProps }) => {
  const classes = useStyles();

  return (
    <Grid item className={classes.flex} {...gridProps}>
      <div className={classes.warning}>
        {children}
        <Icon
          className={classes.icon}
          height={32}
          icon={AlertCircleIcon}
          width={32}
        />
      </div>
    </Grid>
  );
};
