import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { FC, ReactNode } from 'react';
import { useTranslation } from '@shared/translations';
import { useStyles } from './intro.styles';

export type Props = {
  descriptionKey?: GenericTypes.TranslationLabel;
  titleKey: GenericTypes.TranslationLabel;
  childrenRightColumn?: ReactNode;
  infoKey?: GenericTypes.TranslationLabel;
  options?: { ticketNumber: string };
};

export const Intro: FC<Props> = (props) => {
  const {
    descriptionKey,
    titleKey,
    childrenRightColumn,
    infoKey,
    options,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid
      className={classes.container}
      container
      justify="space-between"
      spacing={2}
    >
      <Grid item sm={12} md={childrenRightColumn ? 6 : 12}>
        <Typography className={classes.title}>{t(titleKey)}</Typography>
        {descriptionKey && (
          <Typography className={classes.description}>
            {t(descriptionKey, options)}
          </Typography>
        )}
        {infoKey && (
          <Typography className={classes.content}>{t(infoKey)}</Typography>
        )}
      </Grid>
      {childrenRightColumn && (
        <Grid item sm={12} md={6} className={classes.imageGrid}>
          {childrenRightColumn}
        </Grid>
      )}
    </Grid>
  );
};
