import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    text: {
      marginTop: theme.spacing(2),
    },
    image: {
      marginTop: theme.spacing(2),
      width: '100%',
      borderRadius: theme.spacing(1),
      objectFit: 'cover',
      objectPosition: '50% 70%',
    },
    buttonsRow: {
      marginTop: theme.spacing(1),
    },
    button: {
      width: '100%',
    },
  }),
);
