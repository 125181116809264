import { Theme } from '@material-ui/core/styles/createMuiTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '../../consts';
import { imageHeight } from './constants';
import background from './assets/background.png';

type Props = {
  imgUrl: GenericTypes.URL | null;
};

export const useStyles = makeStyles<Theme, Props>((theme) =>
  createStyles({
    wrapper: {
      marginBottom: theme.spacing(4),
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column-reverse',
      },
    },
    contentItem: {
      background: `url(${background})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      borderRadius: theme.spacing(1, 0, 0, 1),
      display: 'flex',
      alignItems: 'end',
      width: '40%',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        borderRadius: theme.spacing(0, 0, 1, 1),
      },
    },
    content: {
      padding: theme.spacing(3),
      color: palette.white,
      bottom: 0,
    },
    text: {
      color: 'inherit',
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.pxToRem(28),
        lineHeight: theme.typography.pxToRem(32),
      },
    },
    description: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(22),
      fontWeight: theme.typography.fontWeightRegular,
      marginBottom: 0,
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.pxToRem(16),
        lineHeight: theme.typography.pxToRem(20),
      },
    },
    imageItem: {
      width: '60%',
      borderRadius: theme.spacing(0, 1, 1, 0),
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundImage: ({ imgUrl }) => `url(${imgUrl || ''})`,
      [theme.breakpoints.only('xs')]: {
        minHeight: imageHeight.xs,
        borderRadius: theme.spacing(1, 1, 0, 0),
        width: '100%',
      },
      [theme.breakpoints.only('sm')]: {
        minHeight: imageHeight.sm,
      },
      [theme.breakpoints.only('md')]: {
        minHeight: imageHeight.md,
      },
      [theme.breakpoints.only('lg')]: {
        minHeight: imageHeight.lg,
      },
      [theme.breakpoints.up('xl')]: {
        minHeight: imageHeight.full,
      },
    },
  }),
);
