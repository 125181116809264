import CircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from '@shared/translations';
import clsx from 'clsx';
import React, { FC } from 'react';
import { useStyles } from './spinner.styles';

type Props = {
  className?: string;
  color?: CircularProgressProps['color'];
  labelKey?: GenericTypes.TranslationLabel;
  size?: CircularProgressProps['size'];
};

export const Spinner: FC<Props> = (props) => {
  const { className, color, labelKey, size } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={clsx(classes.container, className)}>
      <CircularProgress color={color} size={size} />
      {labelKey && (
        <Typography className={classes.label} variant="h3">
          {t(labelKey)}
        </Typography>
      )}
    </div>
  );
};
