import { TranslationLabels } from '@generated/translation-labels';
import * as Yup from 'yup';
import { Field } from '@shared/components';
import { AttachmentValue, InputValue } from '@shared/components/Fields';
import { FormikConfig } from 'formik';
import { ServiceRequestVisibilityEnum } from '@shared/enums';
import { COUNTRY_TICKETING_MODEL } from '../../../../ticketing.const';
import { api } from '../../../../ticketing.repository';
import {
  ElementField,
  Values as TicketValues,
} from '../../../../ticketing.type';
import { RequestModel } from '../form.type';

type ObjectProps = { [key: string]: AttachmentValue[] | InputValue };

export type RepairMaintenanceValues = ObjectProps;

type AdditionalConfig = {
  config: Omit<FormikConfig<ObjectProps>, 'onSubmit'>;
  fields: Field<ObjectProps>[];
};

type FieldsDefinition = {
  [key: string]: Field<ObjectProps>;
};

// Duplicated in src/app/+ticketingV2/ServiceRequest/Form/form.helper.ts
const additionalInformationPrefix = 'Additional information';
const heatingPrefix = 'Type of heating: ';
const kitchenPrefix = 'Type of kitchen: ';
const lockSystemPrefix = 'Type of lock system: ';
const ovenPrefix = 'Type of oven: ';
const serialNumberPrefix = 'Serial number: ';
const typeOfToiletPrefix = 'Type of toilet: ';

const fieldsDefinition: FieldsDefinition = {
  serialNumber: {
    id: 'serialNumber',
    label: TranslationLabels.ticketingServiceRequestSerialNumberLabel,
    type: 'text',
  },
  typeOfOven: {
    id: 'typeOfOven',
    label: TranslationLabels.ticketingServiceRequestTypeOfOvenLabel,
    type: 'text',
  },
  typeOfKitchen: {
    id: 'typeOfKitchen',
    label: TranslationLabels.ticketingServiceRequestTypeOfKitchenLabel,
    type: 'text',
  },
  typeOfHeating: {
    id: 'typeOfHeating',
    label: TranslationLabels.ticketingServiceRequestTypeOfHeatingLabel,
    type: 'text',
  },
  typeOfLockSystem: {
    id: 'typeOfLockSystem',
    label: TranslationLabels.ticketingServiceRequestTypeOfLockSystemLabel,
    type: 'text',
  },
  typeOfToilet: {
    id: 'typeOfToilet',
    label: TranslationLabels.ticketingServiceRequestTypeOfToiletLabel,
    options: [
      {
        label: TranslationLabels.ticketingServiceRequestTypeOfToiletWall,
        value: 'wall',
      },
      {
        label: TranslationLabels.ticketingServiceRequestTypeOfToiletFloor,
        value: 'floor',
      },
    ],
    type: 'select',
  },
};

const getFieldInitialValue = (description: string, label: string): string => {
  const regexp = new RegExp(`${label}(.*)`);
  const result = description.match(regexp);

  return result ? result[1] : '';
};

const getAdditionalConfig = (
  country: GenericTypes.Country,
  itemId: TicketValues['itemId'],
  description: string,
): AdditionalConfig => {
  const { jiraItems, jiraFields } = COUNTRY_TICKETING_MODEL[country];
  const {
    serialNumber,
    typeOfOven,
    typeOfKitchen,
    typeOfHeating,
    typeOfLockSystem,
    typeOfToilet,
  } = fieldsDefinition;

  switch (itemId) {
    case jiraItems.FRIDGE_FREEZER:
    case jiraItems.DISHWASHER:
    case jiraItems.WASHING_MACHINE: {
      return {
        config: {
          initialValues: {
            serialNumber: getFieldInitialValue(description, serialNumberPrefix),
          },
        },
        fields: [serialNumber],
      };
    }
    case jiraItems.OVEN_COOKTOP: {
      return {
        config: {
          initialValues: {
            serialNumber: getFieldInitialValue(description, serialNumberPrefix),
            typeOfOven: getFieldInitialValue(description, ovenPrefix),
          },
        },
        fields: [serialNumber, typeOfOven],
      };
    }
    case jiraItems.KEYS_LOCKS_INTERCOM:
    case jiraItems.KEYS_LOCKS_INTERCOM_UNIT: {
      return {
        config: {
          initialValues: {
            typeOfLockSystem: getFieldInitialValue(
              description,
              lockSystemPrefix,
            ),
          },
        },
        fields: [typeOfLockSystem],
      };
    }
    case jiraItems.HEATING_VENTILATION: {
      return {
        config: {
          initialValues: {
            typeOfHeating: getFieldInitialValue(description, heatingPrefix),
          },
        },
        fields: [typeOfHeating],
      };
    }
    case jiraItems.KITCHEN_FAN: {
      return {
        config: {
          initialValues: {
            serialNumber: getFieldInitialValue(description, serialNumberPrefix),
            typeOfKitchen: getFieldInitialValue(description, kitchenPrefix),
          },
        },
        fields: [serialNumber, typeOfKitchen],
      };
    }
    case jiraItems.TOILET_TYPE: {
      return {
        config: {
          initialValues: {
            serialNumber: getFieldInitialValue(description, serialNumberPrefix),
            typeOfToilet: getFieldInitialValue(description, typeOfToiletPrefix),
          },
          validationSchema: Yup.object().shape({
            [jiraFields.SUMMARY]: Yup.string().required(
              TranslationLabels.formErrorsRequired,
            ),
            [jiraFields.DESCRIPTION]: Yup.string().required(
              TranslationLabels.formErrorsRequired,
            ),
            serialNumber: Yup.string().required(
              TranslationLabels.formErrorsRequired,
            ),
            typeOfToilet: Yup.string().required(
              TranslationLabels.formErrorsRequired,
            ),
          }),
        },
        fields: [serialNumber, typeOfToilet],
      };
    }
    default: {
      return {
        config: { initialValues: {} },
        fields: [],
      };
    }
  }
};

const prepareShortDescription = (
  country: GenericTypes.Country,
  values: RepairMaintenanceValues,
): string => {
  const { jiraFields } = COUNTRY_TICKETING_MODEL[country];
  const {
    [jiraFields.DESCRIPTION]: description,
    serialNumber,
    typeOfHeating,
    typeOfKitchen,
    typeOfLockSystem,
    typeOfOven,
    typeOfToilet,
  } = values;
  const typeOfOvenText = typeOfOven ? `${ovenPrefix}${typeOfOven}\n` : '';
  const typeOfKitchenText = typeOfKitchen
    ? `${kitchenPrefix}${typeOfKitchen}\n`
    : '';
  const typeOfHeatingText = typeOfHeating
    ? `${heatingPrefix}${typeOfHeating}\n`
    : '';
  const typeOfLockSystemText = typeOfLockSystem
    ? `${lockSystemPrefix}${typeOfLockSystem}\n`
    : '';
  const typeOfToiletText = typeOfToilet
    ? `${typeOfToiletPrefix}${typeOfToilet}\n`
    : '';
  const serialNumberText = serialNumber
    ? `${serialNumberPrefix}${serialNumber}\n`
    : '';
  const additionalInformation =
    typeOfHeatingText ||
    typeOfKitchenText ||
    typeOfLockSystemText ||
    typeOfOvenText ||
    typeOfToiletText ||
    serialNumberText
      ? `\n\n${additionalInformationPrefix}\n${serialNumberText}${typeOfHeatingText}${typeOfKitchenText}${typeOfLockSystemText}${typeOfOvenText}${typeOfToiletText}`
      : '';

  return `${description}${additionalInformation}`;
};

export const getRepairMaintenanceRequestModel: RequestModel<RepairMaintenanceValues> = (
  country,
  { failureId, itemId, roomId },
  details,
) => {
  const {
    jiraFields,
    requestType: countryRequestType,
  } = COUNTRY_TICKETING_MODEL[country];
  const summary = (details?.[jiraFields.SUMMARY] as ElementField | undefined)
    ?.value;
  const description =
    (details?.[jiraFields.DESCRIPTION] as ElementField | undefined)?.value ||
    (details?.[jiraFields.CUSTOMER_SERVICE_REQUEST_DESCRIPTION] as
      | ElementField
      | undefined)?.value ||
    '';
  const attachment = details?.[jiraFields.ATTACHMENT] as
    | AttachmentValue[]
    | undefined;
  const visibility = details?.[jiraFields.VISIBILITY] as
    | ServiceRequestVisibilityEnum
    | undefined;
  const visibilityFields: Field<RepairMaintenanceValues>[] = details?.isAuthor
    ? [
        {
          id: jiraFields.VISIBILITY,
          label: TranslationLabels.ticketingServiceRequestVisibilityLabel,
          switchOptions: {
            checkedLabelKey:
              TranslationLabels.ticketingServiceRequestVisibilityTypePrivate,
            checkedValue: ServiceRequestVisibilityEnum.PRIVATE,
            uncheckedValue: ServiceRequestVisibilityEnum.PUBLIC,
            checkedValueDescriptionKey:
              TranslationLabels.ticketingServiceRequestVisibilityPrivateDescriptionText,
            helperTextKey:
              TranslationLabels.ticketingServiceRequestVisibilityHelperText,
          },
          type: 'switch',
        },
      ]
    : [];
  const {
    config: { initialValues, ...additionalConfig },
    fields,
  } = getAdditionalConfig(country, itemId, description);

  return {
    config: {
      ...additionalConfig,
      initialValues: {
        [jiraFields.SUMMARY]: summary || '',
        [jiraFields.DESCRIPTION]: description.split(
          additionalInformationPrefix,
        )[0],
        [jiraFields.ATTACHMENT]: attachment || [],
        [jiraFields.VISIBILITY]:
          visibility || ServiceRequestVisibilityEnum.PUBLIC,
        ...initialValues,
      },
      prepareValues: (values, requestType) => {
        const { attachment, summary, visibility } = values;
        const commonValues = {
          [jiraFields.ATTACHMENT]: attachment,
          [jiraFields.DESCRIPTION]: prepareShortDescription(country, values),
          [jiraFields.SUMMARY]: summary,
          [jiraFields.VISIBILITY]: visibility,
        };

        switch (requestType) {
          case countryRequestType.UNIT: {
            return {
              ...commonValues,
              ...(roomId > 0 && {
                [jiraFields.UNIT_ROOM]: roomId.toString(),
              }),
              ...(itemId > 0 && { [jiraFields.UNIT_ITEM]: itemId }),
              ...(failureId > 0 && { [jiraFields.UNIT_FAILURE]: failureId }),
            };
          }
          case countryRequestType.COMMON: {
            return {
              ...commonValues,
              ...(itemId > 0 && { [jiraFields.COMMON_ITEM]: itemId }),
              ...(failureId > 0 && {
                [jiraFields.COMMON_FAILURE]: failureId,
              }),
            };
          }
          case countryRequestType.OUTDOOR: {
            return {
              ...commonValues,
              ...(itemId > 0 && { [jiraFields.OUTDOOR_ITEM]: itemId }),
            };
          }
          default: {
            return {};
          }
        }
      },
    },
    fields: [
      {
        id: jiraFields.SUMMARY,
        label: TranslationLabels.ticketingServiceRequestSummaryLabel,
        type: 'text',
      },
      {
        id: jiraFields.DESCRIPTION,
        label: TranslationLabels.ticketingServiceRequestDescriptionLabel,
        type: 'textarea',
      },
      {
        accept: ['.png', '.jpg', '.jpeg', '.pdf'],
        helperTextKey:
          TranslationLabels.ticketingServiceRequestAttachmentFileHelperText,
        helperTextOptions: {
          formats: 'JPG, JPEG, PNG, PDF',
          maxSize: 100,
        },
        id: jiraFields.ATTACHMENT,
        isMultiple: true,
        label: TranslationLabels.formFieldsAttachmentFileLabel,
        onPreviewClick: api.getAttachment,
        type: 'attachment',
      },
      ...fields,
      ...visibilityFields,
    ],
  };
};
