export enum HeimAttributes {
  DATA_SOURCE = '#DataSource#',
  GUID = '#GUID#',
  PARTNER_NAME = '#PartnerName#',
}

export enum ValidationMainRule {
  Unknown = 0,
  PhoneAny = 10,
  PhoneMobile = 12,
  Email = 20,
  StringAny = 100,
  StringNotNull = 110,
  StringNotEmpty = 120,
  NumericAny = 200,
  NumericNotNull = 210,
  NumericPositiv = 220,
  NumericMotZero = 230,
  DateAny = 300,
  DateNotNull = 310,
  DateTimeAny = 350,
  DateTimeNotNull = 360,
  TimeAny = 400,
  TimeNotNull = 410,
  UrlAny = 500,
  UrlApiMain = 510,
  UrlApiOtherHeim = 520,
  UrlThisApp = 550,
  UrlOtherHeimApp = 560,
  Guid = 700,
  RefRegexPar = 800,
}
export enum AttachmentFilter {
  DefaultMyHome = 0,
  MyHomeDocuments = 100,
  MarketingPublished = 2000, // Images and some links regarding marketing
}
export enum AttachmentMainType {
  Unknown = 0,
  Image = 10,
  PlanningImage = 12,
  MarketingUrl = 20,
  Facts = 310,
  Calculations = 320,
  OtherPDF = 700,
  OtherJPG = 800,
  OtherURL = 900,
  SignedContract = 2100,
  ContractRegulation = 2130,
  CheckListHtmlSummary = 2210,
  CheckListImageAttachment = 2250,
  MoveOutAttachment = 2280,
  OnAccountSettlement = 2400,
  GeneralInfo = 3100,
}
export enum ObjectPropertyTypeApi {
  Furnished = 10,
  Lift = 20,
  Balcony = 100,
  Dishwasher = 210,
  WashingMachine = 220,
  OtherProperty = 0,
}
export enum ObjectMainState {
  Unknown = 0,
  Occupied = 1,
  Locked = 2,
  Reserved = 3,
  Published = 4,
}
export enum ContractType {
  Unknown = 0,
  MainContract = 10,
  SubContract = 20,
}
export enum ContractMainState {
  Canceled = -10,
  Unknown = 0,
  NotReady = 100,
  Normal = 200,
  TerminationInProgress = 500,
  WillBeClosed = 600,
  IsClosed = 710,
}
export enum ContractFinishStatus {
  ResidentOnly = -800,
  WantToTerminateCur = -290,
  WantToTerminateFromAll = -280,
  TerminationCancelled = -275, // New status will be 0 / Default
  TerminationRejectedByTenant = -270,
  TerminationEvaluating = -250,
  TerminationRejectedByUs = -240,
  TerminationAcceptedByUs = -220, // Accepted by Heimstaden
  Default = 0,
  DoNotGiveProposale = 10,
  ProposaleFromUs = 100,
  RenewalAccepted = 400,
  TransferWanted = 500, // Want to switch to an other apartment
}
export enum ObjectMainType {
  Unknown = 0,
  Living = 100,
  Business = 200,
  Storage = 300,
  Parking = 1000,
  Berth = 7000,
  Other = 9000,
}
export enum ObjectSubType {
  DefaultSubtype = 0,
  PrivateObject = 110,
  BusinessOffice = 210,
  BusinessStore = 220,
  Booth = 310,
  OpenArea = 1010,
}
export enum ObjectRestriction {
  Unknown = -1,
  NoRestriction = 0,
  StudentOnly = 1,
  Leisure = 2,
  Business = 200,
}
export enum ArealSqmType {
  Unknown = 0,
  StandardAreaType = 0,
  LivingPrimaryArea = 10,
  LivingMaxArea = 20,
  GrossArea = 30,
}
export enum AreaLevel {
  LevelAreaGroup = 1,
  LevelLowest = 2,
}
export enum RoleType {
  Unknown = -1,
  MainContact = 0,
}
export enum DataSourceEnum {
  MutipleCandidates = -10,
  Unknown = 0,
  UnikDenmark = 4500,
  // VitecSweden = 4600,
  HeimNorway = 4700,
  NeLand3100 = 3100,
  Poland = 4800,
  Iceland = 3540,
  Germany = 4900,
}
export enum NotificationMainType {
  Unknown = 0,
  LeadsNew = 100,
  PersonInfoMissing = 1010,
  ContractNotSigned = 1110,
  WarrantyNotSigned = 1120,
  LegitimationMissing = 1320,
  IncomeDocMissing = 1325,
  PersonNotValidated = 1330,
  PersonRejected = 1340,
  FirstRentNotOk = 1410,
  RentalObjectOccupied = 1810,
  GeneralWarningNewRental = 1900,
}
export enum UrlRule {
  Unknown = 0,
  KeysAndTypeOnly = 10,
  FullLink = 20,
}
export enum ObjectDetailLevel {
  SearchFieldsOnly = -10,
  AllRentalFields = 20,
}
export enum RentalFieldsLevel {
  OnlyKeysAndMainTypes = 100,
  MainCustomer = 200,
  SubCustomer = 300,
}
export enum TestOrProdMode {
  UnKnown = 0,
  TestMode = 1,
  ProdMode = 2,
}
export enum LanguagePrefered {
  Unknown = 0,
  English = 4400,
  Norwegian = 4700,
}
export enum MessageSendType {
  None = -30,
  Unknown = -20,
  ChoseEmailOrSms = -10,
  EmailNew = 0,
  EmailForward = 110,
  EmailReplyOne = 120,
  EmailReplyAll = 130,
  Sms = 200,
  SmsAndEmail = 210,
}
export enum MessageMainType {
  // StoppInvitasjon = -2,
  // AvledesAvUtfylteFelter = -1, Kun Frontend
  None = 0,
  MissingEmailEtc = 10,
  MissingNameEmailEtc = 15,
  MissingSSidEmailEtc = 20,
  MissingSSidNameEmailEtc = 25,
  BookingAndCustomerInfo = 50,
  SurveyViewing = 2100,
}
export enum UpdateCustomerRule {
  Unknown = 0,
  CustomerInfo = 10,
  // MessageFromCustomer = 20,
  // Booking = 30,
  UpdateDefaultApartment = 950,
}

// export enum BookingStatus {
//   Canceled = -1,
//   NoBooking = 0,
//   ProposaleFromUs = 1,
//   ProposaleFromCustomer = 2,
//   TentativeOk = 10,
//   Agree = 20,
// }

export enum MessageSubType {
  Default = 0,
  MoreInfoFromProspect = 90,
  BookingViewing = 100,
  BookingSolveTask = 110,
  BookingMoveIn = 150,
  BookingMoveOutPreCheck = 170,
  BookingMoveOut = 190,
}
export enum MidleLayerState {
  Unknown = 0,
  LogGetResultOk = 50,
  SendBaseSystemOk = 110,
  SendBaseSystemFailed = 120,
  ResponseBaseSystemOk = 210,
  ResponseBaseSystemFailed = 220,
}
export enum CustomerOperationMidlelayer {
  Unknown = 0,
  NewCustomer = 210,
  UpdateCustomer = 220,
  UpdateDefaultApartment = 950,
}
export enum CustomerApartmentType {
  RENTAL = 'L',
}
