import { TranslationLabels } from '@generated/translation-labels';
import Typography from '@material-ui/core/Typography';
import React, { FC } from 'react';
import { useTranslation } from '@shared/translations';
import { useStyles } from './copyright.styles';

export const Copyright: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const year = new Date().getFullYear();

  return (
    <Typography className={classes.copyright}>
      {t(TranslationLabels.footerCopyrightLabel)} © {year} Heimstaden
    </Typography>
  );
};
