import { TranslationLabels } from '@generated/translation-labels';
import Typography from '@material-ui/core/Typography';
import { Alert, Spinner } from '@shared/components';
import { FormProps } from '@shared/FormV2';
import { useApi, useCountry } from '@shared/hooks';
import { useTranslation } from '@shared/translations';
import React, { FC, Fragment, ReactElement, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { getEnvValue } from '@shared/helpers';
import { useApartment } from '../../../../+apartment';
import { useProfile } from '../../../../+profile';
import { ticketingRoutes } from '../../../../+ticketing';
import { ticketingClient } from '../../../ticketing.client';
import { FallbackMessage } from '../FallbackMessage/fallback-message.component';
import { RequestForm } from '../RequestForm/request-form.component';
import { useStyles } from './request-creator.styles';

type Props<T> = {
  fallbackMessageKey: GenericTypes.TranslationLabel;
  formProps: FormProps<T>;
  additionalParamsToCheck?: Record<string, string>;
  children?: (node: ReactElement) => ReactElement;
  requestTypeId?: number;
  fullWidth?: boolean;
};

export const RequestCreator = <T extends unknown>(
  props: Props<T>,
): ReturnType<FC<Props<T>>> => {
  const {
    additionalParamsToCheck,
    children,
    fallbackMessageKey,
    formProps,
    requestTypeId,
    fullWidth,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const country = useCountry();
  const { data: userData } = useProfile();
  const { data: apartmentData } = useApartment();
  const getTickets$ = useCallback(
    () =>
      requestTypeId
        ? ticketingClient.getTicketsIdForRequestType(
            country,
            userData,
            requestTypeId,
            apartmentData?.rentalObjectMyHome?.rentalDealIdApi,
            additionalParamsToCheck,
          )
        : Promise.resolve([]),
    [
      requestTypeId,
      country,
      userData,
      apartmentData?.rentalObjectMyHome?.rentalDealIdApi,
      additionalParamsToCheck,
    ],
  );
  const [tickets, isFetching, isError] = useApi([], getTickets$);
  const form = useMemo(
    () => <RequestForm<T> fullWidth={fullWidth} {...formProps} />,
    [formProps, fullWidth],
  );

  if (isFetching) {
    return <Spinner />;
  }

  if (isError || !requestTypeId) {
    return (
      <FallbackMessage
        messageKey={
          isError
            ? TranslationLabels.formGlobalErrorMessage
            : fallbackMessageKey
        }
      />
    );
  }

  if (tickets.length > 0 && !getEnvValue('ALLOW_DUPLICATED_SERVICE_REQUESTS')) {
    return (
      <Alert xs={12} className={classes.alert}>
        <Typography>
          {t(TranslationLabels.cannotCreateServiceRequest)}
          :&nbsp;
          {tickets.map((ticketId, idx) => (
            <Fragment key={ticketId}>
              <Link
                className={classes.link}
                to={`${ticketingRoutes.DETAILS}/${ticketId}`}
              >
                <strong>{ticketId}</strong>
              </Link>
              {idx !== tickets.length - 1 && <>,&nbsp;</>}
            </Fragment>
          ))}
        </Typography>
      </Alert>
    );
  }

  return children ? children(form) : form;
};
