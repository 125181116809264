import { TranslationLabels } from '@generated/translation-labels';
import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import MuiLink from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import { Icon } from '@shared/components';
import { useTranslation } from '@shared/translations';
import { getEnvValue } from '@shared/helpers';
import { ShortcutsItem } from './shortcuts-item.component';
import { useStyles } from './info.styles';
import { Property } from './types';
import { ReactComponent as FacebookIcon } from '../../../../shared/components/Instagram/assets/facebook-icon.svg';

type Props = {
  property: Property;
  forumUrl: GenericTypes.URL;
  country: GenericTypes.Country;
};

export const Info: FC<Props> = ({
  property: { id, houseRules, residentFolderUrl, facebookGroupUrl, links },
  forumUrl,
  country,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const propertyPageBaseUrl = getEnvValue(
    `WORDPRESS_PROPERTY_PAGE_URL_${country.toUpperCase()}`,
  );
  const propertyPageUrl = propertyPageBaseUrl
    ? `${propertyPageBaseUrl}/${id}`
    : undefined;

  return (
    <Grid container spacing={8}>
      <Grid item md={8} xs={12}>
        <Typography variant="h1">
          {t(TranslationLabels.apartmentInfoTitle)}
        </Typography>
        <Typography className={classes.infoText}>
          {t(TranslationLabels.apartmentInfoText)}{' '}
          <MuiLink
            href={forumUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            {t(TranslationLabels.customerForum)}
          </MuiLink>
          .
        </Typography>
      </Grid>
      <Grid item md={4} xs={12}>
        <div className={classes.shortcutsAndRulesContainer}>
          <Typography variant="h2" className={classes.shortcutsAndRulesTitle}>
            {t(TranslationLabels.houseRulesAndShortcutsTitle)}
          </Typography>
          <Grid container spacing={2}>
            {houseRules.map(({ url, titleKey }) => {
              return (
                <Grid key={titleKey} item>
                  <Button
                    color="primary"
                    variant="contained"
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t(titleKey)}
                  </Button>
                </Grid>
              );
            })}
            {residentFolderUrl && (
              <Grid item>
                <Button
                  color="primary"
                  href={residentFolderUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="contained"
                >
                  {t(TranslationLabels.residentGuidePDFName)}
                </Button>
              </Grid>
            )}
            {propertyPageUrl && (
              <Grid item>
                <Button
                  color="primary"
                  href={propertyPageUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="contained"
                >
                  {t(TranslationLabels.propertyPageButton)}
                </Button>
              </Grid>
            )}
            {facebookGroupUrl && (
              <Grid item>
                <Button
                  className={classes.facebookButton}
                  color="primary"
                  href={facebookGroupUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="contained"
                >
                  <Icon
                    icon={FacebookIcon}
                    width={16}
                    height={16}
                    className={classes.facebookIcon}
                  />
                  {t(TranslationLabels.facebookGroup)}
                </Button>
              </Grid>
            )}
            {links &&
              links.map((link) => (
                <Grid item key={link.url}>
                  <Button
                    color="primary"
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="outlined"
                  >
                    {link.text}
                  </Button>
                </Grid>
              ))}
          </Grid>
          <Typography variant="h4" className={classes.shortcutsTitle}>
            {t(TranslationLabels.shortcutsTitle)}
          </Typography>
          <ul>
            <ShortcutsItem
              href={forumUrl}
              linkLabel={TranslationLabels.customerForum}
            />
          </ul>
        </div>
      </Grid>
    </Grid>
  );
};
