import React, { FC } from 'react';
import { Box } from '@shared/components';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from '@shared/translations';
import { TranslationLabels } from '@generated/translation-labels';
import { useStyles } from './maintenance-box.styles';
import { ReactComponent as Image } from '../../assets/maintenance-responsibility.svg';

export const MaintenanceBox: FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box color="grey">
      <Typography className={classes.title}>
        {t(TranslationLabels.maintenanceResposibilityTitle)}
      </Typography>
      <Typography className={classes.text}>
        {t(TranslationLabels.maintenanceResposibilityText)}
      </Typography>
      <div className={classes.imageWrapper}>
        <Image />
      </div>
    </Box>
  );
};
