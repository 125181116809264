import { Container, Instagram, Spinner } from '@shared/components';
import { Blog } from '@shared/components/Blog/blog.component';
import { Faq } from '@shared/components/Faq/faq.component';
import { useCountry, useThrowError } from '@shared/hooks';
import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useApartment } from '../../../+apartment';
import { useAuth } from '../../../+auth';
import { useInvoice } from '../../../+invoice';
import { useProfile } from '../../../+profile';
import { useTicketing } from '../../../+ticketing';
import background from '../../../../assets/content-boxes_bcg.svg';
import { Footer } from '../Footer/footer.component';
import { Header } from '../Header/header.component';
import { useStyles } from './main.styles';

type Props = {
  children: ReactNode;
  disableFetching?: boolean;
};

export const Main: FC<Props> = ({ children, disableFetching }) => {
  const classes = useStyles();
  const { isHeimUserExist } = useAuth();
  const { getProfileData$ } = useProfile();
  const { getApartmentData$ } = useApartment();
  const { getInvoiceData$ } = useInvoice();
  const { getTicketingData$ } = useTicketing();
  const [isFetching, setFetching] = useState(!disableFetching);
  const throwError = useThrowError();

  const fetchTickets = useCallback(
    async (customerId: string) => {
      await getTicketingData$(customerId);
    },
    [getTicketingData$],
  );

  const fetchData = useCallback(async () => {
    try {
      const customerId = await getProfileData$();

      fetchTickets(customerId);

      await Promise.all([
        getApartmentData$(customerId),
        getInvoiceData$(customerId),
      ]);
    } catch (e) {
      throwError(e);
    } finally {
      setFetching(false);
    }
    // To avoid nested loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [throwError]);
  const country = useCountry();

  useEffect(() => {
    if (disableFetching) {
      return;
    }

    fetchData();
  }, [disableFetching, fetchData]);

  const isFAQAvailable = useMemo(() => isHeimUserExist && country !== 'is', [
    country,
    isHeimUserExist,
  ]);

  return (
    <main className={classes.main}>
      <Header />
      {isFetching ? <Spinner /> : children}
      {isFAQAvailable && (
        <Container>
          <Faq />
        </Container>
      )}
      <Container>
        <Blog />
      </Container>
      <div className={classes.fluidContainer}>
        <Container>
          <Instagram />
        </Container>
        <img src={background} alt="" className={classes.background} />
      </div>
      <Footer />
    </main>
  );
};
Main.defaultProps = {
  disableFetching: false,
};
