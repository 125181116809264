import { UserRole } from '@shared/enums';
import { Types } from './type';

export const transformTypes = (types: Types): Types => ({
  ...types,
  terminateContract: {
    ...types.terminateContract,
    roles: [UserRole.TENANT],
  },
});
