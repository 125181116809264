import { getEnvValue } from '../helpers';
import { HttpClient } from './http.client';
import { createInstance } from './http.instance';

export const wordpressPropertyClient = (
  country: GenericTypes.Country,
): HttpClient => {
  const instance = createInstance({
    baseURL: getEnvValue(`WORDPRESS_PROPERTY_URL_${country.toUpperCase()}`),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return new HttpClient(instance);
};
