import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      position: 'relative',
    },
    categoryTitleWrapper: {
      alignItems: 'center',
      display: 'flex',
    },
    categoryIcon: {
      marginRight: theme.spacing(2),

      '& path': {
        stroke: palette.internationalOrange,
      },
    },
    categoryTitle: {
      marginBottom: 0,
    },
    bar: {
      margin: theme.spacing(2, 0, 3, -3),
    },
    link: {
      marginBottom: theme.spacing(1),
    },
    linkIcon: {
      marginRight: theme.spacing(1),
    },
    buttonsRow: {
      display: 'flex',
      marginTop: theme.spacing(1),
    },
    button: {
      marginLeft: 'auto',
    },
  }),
);
