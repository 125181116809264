import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { FieldLabel } from '@shared/components/FieldLabel/field-label.component';
import { FieldReadonly } from '@shared/components/FieldReadonly/field-readonly.component';
import React, { FC } from 'react';
import { getFirstError, Field } from '@shared/components/Form';
import { FormikErrorValues } from '@shared/components/Fields/type';
import { FormikHandlers } from 'formik';
import { useTranslation } from '@shared/translations';

export type InputValue = string;

type Props<TFormValues> = Field<TFormValues> & {
  onChange: FormikHandlers['handleChange'];
  value: InputValue;
  errorMessage?: FormikErrorValues;
  disabled?: boolean;
};

export function InputField<TFormValues>(
  props: Props<TFormValues>,
): ReturnType<FC<Props<TFormValues>>> {
  const {
    className,
    disabled,
    errorMessage,
    helperText,
    id,
    inputClassName,
    label,
    onBlur,
    onChange,
    readonly,
    required,
    touched,
    type = 'text',
    value,
  } = props;
  const { t } = useTranslation();
  const error = touched && getFirstError(t, errorMessage);
  const isError = Boolean(error);

  return (
    <FormControl
      component="fieldset"
      disabled={disabled}
      error={isError}
      fullWidth
      required={required && !readonly}
    >
      <FieldLabel label={label} />
      {readonly ? (
        <FieldReadonly className={className} value={value} />
      ) : (
        <TextField
          InputProps={{
            classes: {
              input: inputClassName,
            },
          }}
          disabled={disabled}
          error={isError}
          helperText={error || t(helperText || '')}
          id={id}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          variant="outlined"
          {...(type !== 'textarea'
            ? { type }
            : {
                multiline: true,
                rows: 4,
              })}
        />
      )}
    </FormControl>
  );
}

InputField.defaultProps = {
  errorMessage: undefined,
  disabled: undefined,
};
