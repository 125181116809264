import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      fontSize: theme.typography.pxToRem(24),
      lineHeight: theme.typography.pxToRem(28),
      color: palette.internationalOrange,
      fontWeight: theme.typography.fontWeightBold,
      paddingTop: theme.spacing(1),
    },
    text: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(20),
      padding: theme.spacing(2, 0, 6, 0),
      '& a': {
        color: palette.shark,
        textDecoration: 'underline',
        fontWeight: theme.typography.fontWeightBold,
      },
    },
    imageWrapper: {
      textAlign: 'right',
      marginBottom: '-29px',
    },
  }),
);
