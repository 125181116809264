import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) => {
  return createStyles({
    toolbar: {
      margin: theme.spacing(0, 0, 1, 0),
    },
    button: {
      marginRight: theme.spacing(1),
    },
  });
});
