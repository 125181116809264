import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import { FieldLabel } from '@shared/components/FieldLabel/field-label.component';
import { FieldReadonly } from '@shared/components/FieldReadonly/field-readonly.component';
import React, { ChangeEvent, FC } from 'react';
import { FormikHelpers } from 'formik';
import { useTranslation } from '@shared/translations';
import { Field, getFirstError } from '../../Form';
import { FormikErrorValues } from '../type';

export type CheckboxValue = {
  [key in number | string]: boolean;
};

type Props<TFormValues> = Field<TFormValues> & {
  value: CheckboxValue;
  errorMessage?: FormikErrorValues;
  setFieldValue: FormikHelpers<TFormValues>['setFieldValue'];
  setFieldTouched: FormikHelpers<TFormValues>['setFieldTouched'];
  disabled?: boolean;
};

export function CheckboxesField<TFormValues>(
  props: Props<TFormValues>,
): ReturnType<FC<Props<TFormValues>>> {
  const {
    id,
    label,
    checkboxOptions,
    disabled,
    required,
    setFieldValue,
    setFieldTouched,
    value,
    errorMessage,
    touched,
    readonly,
  } = props;

  const { t } = useTranslation();
  const error = touched && getFirstError(t, errorMessage);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setFieldTouched(id);
    setFieldValue(id, {
      ...value,
      [event.target.name]: event.target.checked,
    });
  };

  const readonlyValue =
    value &&
    (checkboxOptions || [])
      .filter(({ name }) => (value as never)[name])
      .map(({ label }) => t(label))
      .join(', ');

  return (
    <FormControl
      component="fieldset"
      disabled={disabled}
      required={required && !readonly}
      error={Boolean(error)}
    >
      <FieldLabel label={label} />
      {readonly ? (
        <FieldReadonly value={readonlyValue} />
      ) : (
        <FormGroup>
          {(checkboxOptions || []).map(({ name, label }) => (
            <FormControlLabel
              key={name}
              control={
                <Checkbox
                  checked={value[name]}
                  onChange={handleChange}
                  name={name}
                />
              }
              label={t(label)}
            />
          ))}
        </FormGroup>
      )}
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
}
