import { TranslationLabels } from '@generated/translation-labels';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import { MenuElement } from '@shared/components/MenuElement/menu-element.component';
import clsx from 'clsx';
import React, { FC, useCallback, useRef, useState } from 'react';
import { ContactInfo, Icon } from '@shared/components';
import { ReactComponent as MenuIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/menu/navigation-menu.svg';
import { ReactComponent as CloseIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/remove-add/remove.svg';
import { useApi, useCountry, useOnClickOutside } from '@shared/hooks';
import { useTranslation } from '@shared/translations';
import { SignOut, useAuth } from '../../../+auth';
import { TerminationRequestLink } from '../../../+ticketingV2';
import { api } from './menu.repository';
import { useStyles } from './menu.styles';
import { Element } from './menu.type';

export const Menu: FC = () => {
  const classes = useStyles();
  const { isHeimUserExist } = useAuth();
  const { t } = useTranslation();
  const country = useCountry();
  const [isOpen, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const callback = useCallback(() => {
    if (!isHeimUserExist) {
      return Promise.resolve([]);
    }

    return api.getElements(country);
  }, [isHeimUserExist, country]);
  const [elements, , isError] = useApi<Element[]>([], callback);

  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  const handleClick = (): void => {
    setOpen(!isOpen);
  };

  if (isError) {
    return null;
  }

  return (
    <div ref={ref} className={classes.menuContainer}>
      <Button className={classes.button} onClick={handleClick} variant="text">
        <Typography variant="h3" className={classes.text}>
          <strong>{t(TranslationLabels.headerMenuLabel)}</strong>
        </Typography>
        <div className={classes.menuIconWrapper}>
          <Icon
            className={clsx(classes.menuIcon, {
              [classes.menuIconHidden]: isOpen,
            })}
            icon={MenuIcon}
          />
          <Icon
            className={clsx(classes.menuIcon, {
              [classes.menuIconHidden]: !isOpen,
            })}
            icon={CloseIcon}
          />
        </div>
      </Button>
      <Fade in={isOpen}>
        <ul className={classes.menuListContainer}>
          {elements.map(({ id, shortDescriptionKey, titleKey, url }) => (
            <MenuElement
              key={id}
              onClick={handleClick}
              shortDescriptionKey={shortDescriptionKey}
              titleKey={titleKey}
              to={url}
            />
          ))}
          {isHeimUserExist && <TerminationRequestLink onClick={handleClick} />}
          <SignOut />
          {isHeimUserExist && (
            <li className={classes.contactElement}>
              <div className={classes.contactInfo}>
                <Typography variant="h3">
                  <strong>{t(TranslationLabels.headerMenuContactTitle)}</strong>
                </Typography>
                <Typography>
                  {t(TranslationLabels.headerMenuContactText)}
                </Typography>
                <ContactInfo country={country} noAddress />
              </div>
            </li>
          )}
        </ul>
      </Fade>
    </div>
  );
};
