import { sanityClient, SanityDocumentType } from '@shared/sanity';

export const api = {
  getAppLogo: (country: GenericTypes.Country): Promise<GenericTypes.URL> =>
    sanityClient
      .fetch<{ appLogoUrl: GenericTypes.URL }>(
        `
      *[_type == $type && content.country == $country]{
        "appLogoUrl": content.appLogo.asset->url,
      }[0]
      `,
        {
          type: SanityDocumentType.SETTINGS,
          country,
        },
      )
      .then((response) => response.appLogoUrl),
};
