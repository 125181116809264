import { sanityClient, SanityDocumentType } from '@shared/sanity';
import { failuresPathSuffix } from '../../shared';
import { Items } from './items.type';

export const api = {
  getItems$: async (
    country: GenericTypes.Country,
    areaId: number,
    path: string,
    roomId?: number,
  ): Promise<Items> => {
    const getAreaQuery = (area?: string): string => {
      const prefix = area ? `${area}.` : '';

      return `
        "otherElementRedirectURL": "${path}/" + string(${prefix}otherElementId),
        "items": ${prefix}items[]{
          ...item->{
            "key": fieldId,
            "redirectURL": select(
              defined(^.failures) => "${path}/" + string(fieldId) + "${failuresPathSuffix}",
              defined(^.otherElementId) => "${path}/" + string(fieldId) + "${failuresPathSuffix}/" + string(^.otherElementId),
              "${path}/" + string(fieldId),
            ),
            "titleKey": title->key,
            externalWebpageUrl,
            roles,
          }
        }
      `;
    };
    const query = `
      * [_type == $type && content.country == $country][0]{
        ...content.ticketing.repairMaintenanceRequest.areas{
          ...select(
            common.typeId == $areaId => {${getAreaQuery('common')}},
            outdoor.typeId == $areaId => {${getAreaQuery('outdoor')}},
            unit.typeId == $areaId => unit.rooms[room->fieldId == string($roomId)][0]{
              ${getAreaQuery()},
            },
          )
        }
      }
    `;

    return sanityClient.fetch<Items>(query, {
      areaId,
      country,
      roomId,
      type: SanityDocumentType.SETTINGS,
    });
  },
};
