import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useReducer,
} from 'react';
import { AttachmentFilter } from '@shared/enums';
import { useApi, useCountry } from '@shared/hooks';
import { Spinner } from '@shared/components';
import { useTranslation } from '@shared/translations';
import { Context } from './context';
import { initialState, reducer } from './reducer';
import { api } from '../../termination.repository';
import { useProfile } from '../../../+profile';
import { TerminationFlowStep, TerminationFlowStepEventType } from '../../types';
import { Comment } from '../../../+ticketing/ticketing.type';

type Props = {
  ticketId: string;
  children: ReactElement;
};

export const Provider: FC<Props> = ({ ticketId, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const country = useCountry();
  const { data: profileData } = useProfile();
  const { langCode } = useTranslation();

  const fetchProcess$ = useCallback(async () => {
    const [calendarWidgetConfig, flowSteps] = await Promise.all([
      api.getCalendarConfig(country),
      api.getTerminationFlow(country, ticketId, profileData?.customerIdApi),
    ]);

    dispatch({
      type: 'CALENDAR_WIDGET_CONFIG_INIT',
      payload: calendarWidgetConfig,
    });
    dispatch({ type: 'TERMINATION_FLOW_STEPS_INIT', payload: flowSteps });
  }, [country, profileData?.customerIdApi, ticketId]);

  const fetchDocuments$ = useCallback(async () => {
    if (!state.apartmentId || !state.rentalDealId) {
      return;
    }

    const documents = await api.getTerminationDocuments(
      AttachmentFilter.MyHomeDocuments,
      state.apartmentId,
      state.rentalDealId,
    );

    dispatch({ type: 'TERMINATION_DOCUMENTS_INIT', payload: documents });
  }, [state.apartmentId, state.rentalDealId]);

  const fetchSanityDocuments$ = useCallback(async () => {
    const documents = await api.getSanityDocuments(country, langCode);

    dispatch({ type: 'TERMINATION_SANITY_DOCUMENTS_INIT', payload: documents });
  }, [country, langCode]);

  const [, isLoading] = useApi(undefined, fetchProcess$);
  useApi(undefined, fetchDocuments$);
  useApi(undefined, fetchSanityDocuments$);

  useEffect(() => {
    dispatch({ type: 'TICKET_ID_INIT', payload: ticketId });
  }, [ticketId]);

  const addComment = (payload: Comment): void => {
    dispatch({ type: 'COMMENT_ADD', payload });
  };

  const setCurrentFlowStep = (
    eventType: TerminationFlowStepEventType,
  ): void => {
    dispatch({ type: 'CURRENT_FLOW_STEP_SET', payload: eventType });
  };

  const updateFlowStep = (
    eventType: TerminationFlowStepEventType,
    flowStepPatch: Partial<TerminationFlowStep>,
  ): void => {
    dispatch({
      type: 'FLOW_STEP_PATCH',
      payload: { eventType, flowStepPatch },
    });
  };

  const reinitialize = async (): Promise<void> => {
    return fetchProcess$();
  };

  if (!ticketId) {
    return children;
  }

  return (
    <Context.Provider
      value={{
        ...state,
        addComment,
        setCurrentFlowStep,
        updateFlowStep,
        reinitialize,
      }}
    >
      {isLoading ? <Spinner /> : children}
    </Context.Provider>
  );
};

Provider.displayName = 'TerminationProvider';
