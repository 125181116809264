import { TranslationLabels } from '@generated/translation-labels';
import { useApi, useCountry } from '@shared/hooks';
import React, { FC, useCallback } from 'react';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import { Content, Elements } from '../../shared';
import { RouteParams } from '../service-request.type';
import { api } from './rooms.repository';
import { stepper } from '../stepper.config';
import { MaintenanceBox } from './maintenance-box.component';

export const Rooms: FC = () => {
  const country = useCountry();
  const { areaId } = useParams<RouteParams>();
  const { pathname } = useLocation();
  const getRooms$ = useCallback(
    () => api.getRooms$(country, +areaId, pathname),
    [areaId, country, pathname],
  );

  const [response, isFetching] = useApi(undefined, getRooms$);

  const showMaintenanceBox = country === 'no' || country === 'dk';

  return (
    <Content
      introProps={{
        descriptionKey: TranslationLabels.ticketingServiceRequestRoomsText,
        titleKey: TranslationLabels.ticketingServiceRequestRoomsTitle,
        childrenRightColumn: showMaintenanceBox ? (
          <MaintenanceBox />
        ) : undefined,
      }}
      isFetching={isFetching}
      stepperConfig={stepper}
      type={showMaintenanceBox ? 'columns' : 'rows'}
    >
      <Elements data={response} />
    </Content>
  );
};
