import { TranslationLabels } from '@generated/translation-labels';
import { palette } from '@shared/consts';
import { Status } from '@shared/components';
import { TenantResponseStatus } from '../../types';

export const getTerminationTenantResponseStatusOptions = (
  terminationApprovalStatus: TenantResponseStatus,
): Status => {
  switch (terminationApprovalStatus) {
    case TenantResponseStatus.ACCEPTED:
      return {
        colorHex: palette.greenHaze,
        text: TranslationLabels.terminationTenantResponseStatusAccepted,
      };
    case TenantResponseStatus.PENDING:
      return {
        colorHex: palette.silver,
        text: TranslationLabels.terminationTenantResponseStatusPending,
      };
    case TenantResponseStatus.REJECTED:
      return {
        colorHex: palette.monza,
        text: TranslationLabels.terminationTenantResponseStatusRejected,
      };
    default:
      return {
        colorHex: palette.blueRibbon,
        text: TranslationLabels.terminationTenantResponseStatusUnknown,
      };
  }
};
