import { Theme } from '@material-ui/core/styles/createMuiTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { darken } from '@material-ui/core/styles';

type Props = {
  colorHex: string;
};

export const useStyles = makeStyles<Theme, Props>((theme) =>
  createStyles({
    logoCircle: {
      width: '40px',
      height: '40px',
      borderRadius: '20px',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      verticalAlign: 'inherit',
      marginLeft: theme.spacing(2),
      transition: theme.transitions.create('all', {
        duration: theme.transitions.duration.short,
        easing: theme.transitions.easing.easeInOut,
      }),
      backgroundColor: ({ colorHex }) => colorHex,
      '&:hover': {
        backgroundColor: ({ colorHex }) => darken(colorHex, 0.2),
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
    },
  }),
);
