import { TranslationLabels } from '@generated/translation-labels';
import { useApi, useCountry } from '@shared/hooks';
import React, { FC, useCallback } from 'react';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import { Content, Elements } from '../../../shared';
import { RouteParams } from '../invoice-and-customer-account-type';
import { api } from './sub-items.repository';
import { stepper } from '../../stepper.config';

export const SubItems: FC = () => {
  const { itemId } = useParams<RouteParams>();
  const { pathname } = useLocation();
  const country = useCountry();
  const getItems$ = useCallback(
    () => api.getItems$(country, pathname, +itemId),
    [country, itemId, pathname],
  );
  const [response, isFetching] = useApi(undefined, getItems$);

  return (
    <Content
      introProps={{
        descriptionKey:
          TranslationLabels.ticketingCustomerAccountRequestItemsText,
        titleKey: TranslationLabels.ticketingCustomerAccountRequestItemsTitle,
      }}
      isFetching={isFetching}
      stepperConfig={stepper}
    >
      <Elements
        data={response?.items}
        otherElementRedirectURL={response?.otherElementRedirectURL}
      />
    </Content>
  );
};
