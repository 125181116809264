import { sanityClient, SanityDocumentType } from '@shared/sanity';
import type { Maintenance } from './types';

export const api = {
  getStatus: (country: GenericTypes.Country): Promise<Maintenance> => {
    return sanityClient.fetch(
      `* [_type == $type && content.country == $country]{
        ...select(
          defined(content.maintenance) => {
            ...content.maintenance{
              "titleKey": select(
                defined(title) => title->key,
                '',
              ),
              "descriptionKey": select(
                defined(description) => description->key,
                '',
              ),
              "enabled": select(
                defined(enabled) => enabled,
                false,
              ),
              "interactionEnabled": select(
                defined(interactionEnabled) => interactionEnabled,
                true,
              ),
            }
          },
          {
            "titleKey": '',
            "descriptionKey": '',
            "enabled": false,
            "interactionEnabled": true,
          }
        )
      }[0]`,
      {
        country,
        type: SanityDocumentType.SETTINGS,
      },
    );
  },
};
