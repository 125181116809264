import { TranslationLabels } from '@generated/translation-labels';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from '@shared/translations';
import logo from '../../../../assets/logo.svg';
import { useStyles } from './logo.styles';

export const Logo: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const siteTitle = t(TranslationLabels.headerSiteTitle);

  return (
    <Link to="/" title={siteTitle} className={classes.logo}>
      <img src={logo} alt={siteTitle} className={classes.logoImg} />
    </Link>
  );
};
