import { ICustomerMidleLayerApi2 } from '@shared/models';
import { TicketStatus } from '../../../ticketing.enum';
import {
  ServiceRequest,
  ServiceRequestApi,
  ServiceRequestDetails,
  ServiceRequestDetailsApi,
} from '../../../ticketing.type';

const getApprovedTenants = (
  isAgreed: boolean,
  customerId: ICustomerMidleLayerApi2['customerIdApi'],
  approvedBy?: string[],
): string[] => [...(approvedBy || []), customerId];

const getPreparedData = (
  approvedBy: string[],
  isAgreed: boolean,
  status: TicketStatus,
  fieldName?: string,
): Record<string, TicketStatus | string[]> => ({
  ...(isAgreed && fieldName && { [fieldName]: approvedBy }),
  ...(!isAgreed && { status }),
});

const getNewTicketDetails = (
  data: ServiceRequestDetails,
  preparedData: ReturnType<typeof getPreparedData>,
): ServiceRequestDetailsApi =>
  ({
    ...data,
    ...preparedData,
    // TODO: Assertions will be removed in https://fredensborg.atlassian.net/browse/MYH-205
  } as never);

const getNewTicketList = (
  data: ServiceRequestDetails,
  preparedData: ReturnType<typeof getPreparedData>,
  tickets: ServiceRequest[],
): ServiceRequestApi[] =>
  tickets.map(
    (ticket) =>
      (ticket.id as string) !== data.ticketId
        ? ticket
        : {
            ...ticket,
            ...preparedData,
          },
    // TODO: Assertions will be removed in https://fredensborg.atlassian.net/browse/MYH-205
  ) as never;

export const getUpdatedData = (
  data: ServiceRequestDetails,
  isAgreed: boolean,
  status: TicketStatus,
  tickets: ServiceRequest[],
  customerId: ICustomerMidleLayerApi2['customerIdApi'],
  approvedBy?: string[],
  fieldName?: string,
): { details: ServiceRequestDetailsApi; list: ServiceRequestApi[] } => {
  const approvedTenants = getApprovedTenants(isAgreed, customerId, approvedBy);
  const preparedData = getPreparedData(
    approvedTenants,
    isAgreed,
    status,
    fieldName,
  );

  return {
    details: getNewTicketDetails(data, preparedData),
    list: getNewTicketList(data, preparedData, tickets),
  };
};
