import React, { FC, ReactNode } from 'react';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { AccordionDetailsProps } from '@material-ui/core/AccordionDetails/AccordionDetails';
import { useStyles } from './accordion-details.styles';

type Props = AccordionDetailsProps & {
  children: NonNullable<ReactNode>;
};

export const AccordionDetails: FC<Props> = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <MuiAccordionDetails {...props} classes={{ root: classes.root }}>
      {children}
    </MuiAccordionDetails>
  );
};

AccordionDetails.displayName = 'AccordionDetails';
