import { sanityClient, SanityDocumentType } from '@shared/sanity';
import { ElementsData, TICKETING_PATHS } from '../../../shared';

export const api = {
  getItems$: async (country: GenericTypes.Country): Promise<ElementsData[]> => {
    const query = `
      * [_type == $type && content.country == $country][0]{
        "items": content.ticketing.customerAccountRequest.types.invoiceAndCustomerAccount.items[]{
          "key": _key,
          ...item->{
            "redirectURL": "${TICKETING_PATHS.MY_ACCOUNT_INVOICE_AND_CUSTOMER_ACCOUNT_ITEMS}/" + string(fieldId),
            "titleKey": title->key,
            externalWebpageUrl,
            roles,
          }
        }
      }
    `;

    const response = await sanityClient.fetch<{ items: ElementsData[] }>(
      query,
      {
        country,
        type: SanityDocumentType.SETTINGS,
      },
    );

    return response.items;
  },
};
