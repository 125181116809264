// TODO: this file needs to be removed during refactor: https://fredensborg.atlassian.net/browse/MYH-522
import { TranslationLabels } from '@generated/translation-labels';
import * as Yup from 'yup';
import 'yup-phone';
import { Field } from '@shared/components';
import { AttachmentValue } from '@shared/components/Fields';
import { ServiceRequestVisibilityEnum } from '@shared/enums';
import { COUNTRY_TICKETING_MODEL } from '../../../../+ticketing/ticketing.const';
import { api } from '../../../../+ticketing/ticketing.repository';
import { DetailsRequestValues } from './details.type';
import { RequestModel } from '../../../../+ticketing/shared/components/AdditionalInformation/form.type';
import { getDetailsRequestModelInitialValue } from './details.helper';

const getFields = (readonly: boolean): Field<DetailsRequestValues>[] => [
  {
    id: 'firstName',
    label: TranslationLabels.profileFormFirstName,
    required: true,
    readonly,
    type: 'text',
  },
  {
    id: 'lastName',
    label: TranslationLabels.profileFormLastName,
    required: true,
    readonly,
    type: 'text',
  },
  {
    id: 'mobile',
    label: TranslationLabels.profileFormMobilePhone,
    helperText: TranslationLabels.profileFormMobilePhoneHelperText,
    required: true,
    readonly,
    type: 'text',
  },
  {
    id: 'email',
    label: TranslationLabels.profileFormEmail,
    required: true,
    readonly,
    type: 'email',
  },
  {
    accept: 'image/*,.pdf',
    id: 'attachments',
    isMultiple: true,
    label: TranslationLabels.profileFormCopyOfId,
    onPreviewClick: api.getAttachment,
    readonly,
    type: 'attachment',
  },
];

export const getDetailsRequestModel: RequestModel<DetailsRequestValues> = (
  country,
  ticketValues,
  initialValues,
  user,
  apartment,
  readonly,
  t,
) => {
  const { jiraFields, jiraItems } = COUNTRY_TICKETING_MODEL[country];
  const formInitialValues = {
    firstName: getDetailsRequestModelInitialValue(
      'firstName',
      initialValues,
      user,
      country,
    ) as string,
    lastName: getDetailsRequestModelInitialValue(
      'lastName',
      initialValues,
      user,
      country,
    ) as string,
    mobile: getDetailsRequestModelInitialValue(
      'mobile',
      initialValues,
      user,
      country,
    ) as string,
    email: getDetailsRequestModelInitialValue(
      'email',
      initialValues,
      user,
      country,
    ) as string,
    attachments: getDetailsRequestModelInitialValue(
      'attachments',
      initialValues,
      user,
      country,
    ) as AttachmentValue[],
  };

  return {
    fields: getFields(readonly || false),
    config: {
      initialValues: formInitialValues,
      prepareValues: ({
        firstName,
        lastName,
        mobile,
        email,
        attachments,
      }: DetailsRequestValues) => {
        const summaryKey =
          TranslationLabels.invoiceAndCustomerRequestChangeOrUpdateCustomerDetailsJiraSummary;
        const summaryLabel = t?.(summaryKey) || '';

        return {
          [jiraFields.SUMMARY]: summaryLabel,
          [jiraFields.INVOICE_CUSTOMER_TYPE]:
            jiraItems.INVOICE_CUSTOMER_ACCOUNT_CONTRACT_AND_TENANCY,
          [jiraFields.INVOICE_CUSTOMER_ITEM]:
            jiraItems.INVOICE_CUSTOMER_ACCOUNT_CONTRACT_AND_TENANCY_CHANGE_INFORMATION,
          [jiraFields.INVOICE_CUSTOMER_NEW_FIRST_NAME]: firstName,
          [jiraFields.INVOICE_CUSTOMER_NEW_LAST_NAME]: lastName,
          [jiraFields.INVOICE_CUSTOMER_NEW_MOBILE]: mobile,
          [jiraFields.INVOICE_CUSTOMER_NEW_EMAIL]: email,
          [jiraFields.ATTACHMENT]: attachments,
          [jiraFields.VISIBILITY]: ServiceRequestVisibilityEnum.PRIVATE,
        };
      },
      validationSchema: Yup.object().shape({
        firstName: Yup.string().required(TranslationLabels.formErrorsRequired),
        lastName: Yup.string().required(TranslationLabels.formErrorsRequired),
        mobile: Yup.string()
          .phone('IN', false, TranslationLabels.formErrorsPhone)
          .required(TranslationLabels.formErrorsRequired),
        email: Yup.string()
          .email(TranslationLabels.formErrorsEmail)
          .required(TranslationLabels.formErrorsRequired),
        attachments: Yup.array().when(['firstName', 'lastName'], {
          is: (firstName: string, lastName: string) => {
            const {
              firstName: formInitialFirstName,
              lastName: formInitialLastName,
            } = formInitialValues;
            const areFormInitialValuesChanged =
              firstName !== formInitialFirstName ||
              lastName !== formInitialLastName;
            let areProfileDetailsChanged = false;

            if (user) {
              const {
                firstName: profileFirstName,
                lastName: profileLastName,
              } = user;
              areProfileDetailsChanged =
                firstName !== profileFirstName || lastName !== profileLastName;
            }

            return areFormInitialValuesChanged || areProfileDetailsChanged;
          },
          then: Yup.array()
            .of(
              Yup.object().shape({
                name: Yup.string().required(),
                value: Yup.string().optional(),
              }),
            )
            .min(1, TranslationLabels.formErrorsRequired),
          otherwise: Yup.array().nullable(),
        }),
      }),
    },
  };
};
