import { useCountry } from '@shared/hooks';
import { CustomerMidleLayerApi2 } from '@shared/models';
import React, { FC, useCallback, useReducer } from 'react';
import { useApartment } from '../../../+apartment';
import { useProfile } from '../../../+profile';
import { ticketingClient } from '../../../+ticketingV2/ticketing.client';
import { COUNTRY_TICKETING_MODEL } from '../../ticketing.const';
import { transformStatus, transformTickets } from '../../ticketing.helper';
import { api } from '../../ticketing.repository';
import {
  ServiceRequest,
  ServiceRequestApi,
  ServiceRequestDetails,
  ServiceRequestDetailsApi,
} from '../../ticketing.type';
import { Context } from './context';
import { initialState, reducer } from './reducer';
import { State } from './type';

export const Provider: FC = ({ children }) => {
  const country = useCountry();
  const { data: userData } = useProfile();
  const { data: apartmentData } = useApartment();
  const [state, dispatch] = useReducer(reducer, initialState);
  const setTicketDetails = useCallback(
    (details: ServiceRequestDetailsApi) => {
      dispatch({
        type: 'TICKET_DETAILS',
        payload: {
          ...details,
          status:
            typeof details.status === 'string'
              ? transformStatus(details.status, state.statusesConfig)
              : details.status,
        } as ServiceRequestDetails,
      });
    },
    [state.statusesConfig],
  );
  const setTicketList = useCallback(
    (tickets: (ServiceRequestApi | ServiceRequest)[]): void => {
      const payload = transformTickets(tickets, state.statusesConfig);

      dispatch({ type: 'TICKET_LIST', payload });
    },
    [state.statusesConfig],
  );
  const setTicketStatusesConfig = (payload: State['statusesConfig']): void => {
    dispatch({ type: 'TICKET_STATUSES_CONFIG', payload });
  };
  const fetchData$ = useCallback(
    async (customerId: CustomerMidleLayerApi2['customerIdApi']) => {
      dispatch({ type: 'TICKETS_FETCHING', payload: true });
      const [tickets, statuses] = await Promise.all([
        api.getTicketList(country, customerId),
        api.getTicketStatusesConfig(country),
      ]);

      const transformedTickets = transformTickets(tickets, statuses);

      dispatch({ type: 'TICKET_LIST', payload: transformedTickets });
      setTicketStatusesConfig(statuses);

      return transformedTickets;
    },
    [country],
  );
  const getTicketsIdForRequestType$ = useCallback(
    (
      requestTypeId: number,
      params?: {
        itemTypeId?: number;
        roomTypeId?: number;
        subItemTypeId?: number;
      },
    ) => {
      return ticketingClient.getTicketsIdForRequestType(
        country,
        userData,
        requestTypeId,
        apartmentData?.rentalObjectMyHome?.rentalDealIdApi,
        params,
      );
    },
    [apartmentData?.rentalObjectMyHome?.rentalDealIdApi, country, userData],
  );
  const getUpdateProfileTicketId$ = useCallback(async () => {
    const {
      jiraItems,
      requestType: countryRequestType,
    } = COUNTRY_TICKETING_MODEL[country];
    const ticketsId = await getTicketsIdForRequestType$(
      countryRequestType.INVOICE_CUSTOMER_ACCOUNT,
      {
        itemTypeId: jiraItems.INVOICE_CUSTOMER_ACCOUNT_CONTRACT_AND_TENANCY,
        subItemTypeId:
          jiraItems.INVOICE_CUSTOMER_ACCOUNT_CONTRACT_AND_TENANCY_CHANGE_INFORMATION,
      },
    );

    return ticketsId[0];
  }, [country, getTicketsIdForRequestType$]);

  return (
    <Context.Provider
      value={{
        ...state,
        getTicketingData$: fetchData$,
        getTicketsIdForRequestType$,
        getUpdateProfileTicketId$,
        setTicketDetails,
        setTicketList,
      }}
    >
      {children}
    </Context.Provider>
  );
};

Provider.displayName = 'TicketingProvider';
