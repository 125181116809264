import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles(() =>
  createStyles({
    logo: {
      display: 'block',
    },
    logoImg: {
      height: 'auto',
      minWidth: 200,
      width: 200,
    },
  }),
);
