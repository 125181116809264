import { sanityClient, SanityDocumentType } from '@shared/sanity';
import { wordpressPropertyClient } from '@shared/http';
import { TranslationLabels } from '@generated/translation-labels';
import { Property, PropertyWordpress } from './types';

export const api = {
  getPropertyData: async (
    country: GenericTypes.Country,
    propertyId?: string,
  ): Promise<Property | undefined> => {
    if (!propertyId) return undefined;
    const response = await wordpressPropertyClient(
      country,
    ).get<PropertyWordpress>(`/property/${propertyId}`);

    const houseRules = [];
    if (response.resources?.houseRules) {
      houseRules.push({
        url: response.resources?.houseRules,
        titleKey: TranslationLabels.houseRulesPDFName,
      });
    }
    if (response.resources?.houseRules2) {
      houseRules.push({
        url: response.resources?.houseRules2,
        titleKey: TranslationLabels.houseRules2PDFName,
      });
    }

    return {
      id: propertyId,
      houseRules,
      residentFolderUrl: response.resources?.residentFolder,
      facebookGroupUrl: response.resources?.facebookGroup,
      links: response.resources?.links,
    };
  },
  getCustomerForumUrl: async (
    country: GenericTypes.Country,
  ): Promise<GenericTypes.URL> => {
    const response = await sanityClient.fetch(
      `* [_type == $type && content.country == $country]{
        "url": content.customerForumUrl,
      }[0]`,
      {
        country,
        type: SanityDocumentType.SETTINGS,
      },
    );
    return response.url;
  },
};
