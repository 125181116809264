import Grid from '@material-ui/core/Grid';
import { TranslationLabels } from '@generated/translation-labels';
import { Hidden, Typography } from '@material-ui/core';
import { useTranslation } from '@shared/translations';
import { Box, Status } from '@shared/components';
import { formatStandardDate } from '@shared/helpers';
import React, { FC } from 'react';
import { ReactComponent as HouseIcon } from '@heimstaden/icons-library/img/streamline-regular/shipping-delivery/delivery/delivery-door.svg';
import { ReactComponent as CalendarIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/date-calendar/calendar-3.svg';
import { useCountry } from '@shared/hooks';
import {
  StatusConfig as StatusType,
  Comment as CommentType,
} from '../../../ticketing.type';
import { useStyles } from './request-details.styles';
import { CardInfo } from './card-info';
import { icons } from '../../consts';
import { Comments } from '../../../../+ticketingV2/shared/components';
import { CommentsTitle } from './comments-title.component';

type Props = {
  ticketId: string;
  status?: StatusType;
  date: string;
  type: string[];
  address?: string;
  requestTypeId: number;
  comments: CommentType[];
  onAddComment: (comment: CommentType) => void;
  areCommentsHidden: boolean;
  isCommentingAllowed: boolean;
};

export const RequestDetails: FC<Props> = ({
  ticketId,
  status,
  date,
  type,
  address,
  requestTypeId,
  comments,
  onAddComment,
  areCommentsHidden,
  isCommentingAllowed,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const country = useCountry();
  const typeText =
    type.length > 0 ? type.map((key) => t(key)).join(' - ') : undefined;

  return (
    <Box className={classes.container} color="grey">
      <Grid
        container
        spacing={1}
        className={classes.content}
        direction="column"
        justify="space-between"
      >
        <div className={classes.status}>
          {status && <Status status={status} variant="large" />}
        </div>
        <Typography className={classes.header} variant="h2">
          {`${t(TranslationLabels.ticketingDetailsRequestTitle)}: `}
          <span className={classes.ticketNumber}>{ticketId}</span>
        </Typography>
        {address && (
          <CardInfo
            label={t(TranslationLabels.ticketingTerminationRequestFormTitle)}
            value={address}
            icon={HouseIcon}
          />
        )}
        {typeText && (
          <CardInfo
            label={t(TranslationLabels.ticketingDetailsRequestTypeLabel)}
            value={typeText}
            icon={icons[country][requestTypeId]}
          />
        )}
        <CardInfo
          label={t(TranslationLabels.ticketingDetailsRequestDateLabel)}
          value={formatStandardDate(date)}
          icon={CalendarIcon}
        />
        <div className={classes.comments}>
          <Hidden smDown>
            {!areCommentsHidden && (
              <Comments
                comments={comments}
                handleSuccess={onAddComment}
                ticketId={ticketId}
                titleComponent={<CommentsTitle ticketId={ticketId} />}
                isCommentingAllowed={isCommentingAllowed}
              />
            )}
          </Hidden>
        </div>
      </Grid>
    </Box>
  );
};
