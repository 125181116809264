import { DataSourceEnum } from '@shared/enums';

type StorageKey = 'DATA_SOURCE' | 'GUID' | 'SIMULATE_GUID';

class StorageService {
  /**
   * @param {string} value
   * @param {boolean} isSimulate
   */
  public setGuidLogin = (value: string, isSimulate = false): void => {
    this.setItem(isSimulate ? 'SIMULATE_GUID' : 'GUID', value);
  };

  /**
   * @return {string}
   */
  public getGuid = (): string => {
    const simulateGuid = this.getItem('SIMULATE_GUID');
    const guid = this.getItem('GUID');

    return simulateGuid || guid;
  };

  /**
   * @param {string} dataSource
   */
  public setDataSource = (dataSource: DataSourceEnum): void => {
    this.setItem('DATA_SOURCE', dataSource.toString());
  };

  /**
   * @return {string}
   */
  public getDataSource = (): string => this.getItem('DATA_SOURCE');

  public clear = (): void => {
    this.removeItem('DATA_SOURCE');
    this.removeItem('GUID');
    this.removeItem('SIMULATE_GUID');
  };

  /**
   * @param {StorageKey} item
   */
  public removeItem = (item: StorageKey): void => {
    sessionStorage.removeItem(item);
  };

  /**
   * @param {StorageKey} key
   * @return {string}
   */
  private getItem = (key: StorageKey): string =>
    sessionStorage.getItem(key) || '';

  /**
   * @param {StorageKey} key
   * @param {string} value
   */
  private setItem = (key: StorageKey, value: string): void => {
    sessionStorage.setItem(key, value);
  };
}
export const storageService = new StorageService();
