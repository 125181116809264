import { PDF_MEDIA_TYPE } from '@shared/helpers';
import { heimClient } from '@shared/http';
import {
  Attachment,
  IRentalObjectMyHome,
  RentalObjectHead,
} from '@shared/models';
import {
  AttachmentFilter,
  HeimAttributes,
  AttachmentMainType,
} from '@shared/enums';
import {
  SANITY_PDF_CATEGORY,
  sanityClient,
  SanityDocumentType,
} from '@shared/sanity';

export const api = {
  // RentalDetails/:dataSource/:partnerName/:userAndSessionId
  urlApartment: (): string =>
    [
      'RentalDetails',
      HeimAttributes.DATA_SOURCE,
      HeimAttributes.PARTNER_NAME,
      HeimAttributes.GUID,
    ].join('/'),
  getRentalObject: (): Promise<RentalObjectHead> => {
    const url = api.urlApartment();

    return heimClient.get<RentalObjectHead>(url);
  },
  // Attachments/:dataSource/:partnerName/:userAndSessionId/:attachmentFilter/:objectIdApi/:rentalDealIdApi
  urlAttachments: (
    attachmentFilter: AttachmentFilter,
    objectIdApi: IRentalObjectMyHome['objectIdApi'],
    rentalDealIdApi: IRentalObjectMyHome['rentalDealIdApi'],
  ): string => {
    return [
      'Attachments',
      HeimAttributes.DATA_SOURCE,
      HeimAttributes.PARTNER_NAME,
      HeimAttributes.GUID,
      attachmentFilter,
      objectIdApi,
      rentalDealIdApi,
    ].join('/');
  },
  getAttachments: async (
    attachmentFilter: AttachmentFilter,
    objectIdApi: IRentalObjectMyHome['objectIdApi'],
    rentalDealIdApi: IRentalObjectMyHome['rentalDealIdApi'],
  ): Promise<Attachment[]> => {
    const url = api.urlAttachments(
      attachmentFilter,
      objectIdApi,
      rentalDealIdApi,
    );
    return heimClient.get<Attachment[]>(url, {}, undefined, 18000);
  },
  // Customer/AttachmentContent/:dataSource/:partnerName/:userAndSessionId/:rentalDealIdApi/:attachmentMainType
  urlContractFile: (
    rentalDealIdApi: IRentalObjectMyHome['rentalDealIdApi'],
  ): string =>
    [
      'AttachmentContent',
      HeimAttributes.DATA_SOURCE,
      HeimAttributes.PARTNER_NAME,
      HeimAttributes.GUID,
      rentalDealIdApi,
      AttachmentMainType.SignedContract,
    ].join('/'),
  getContractFileUrl: async (
    rentalDealIdApi: IRentalObjectMyHome['rentalDealIdApi'],
  ): Promise<GenericTypes.URL> => {
    const url = api.urlContractFile(rentalDealIdApi);
    const contractData = await heimClient.get<Blob>(url, undefined, 'blob');
    const file = new Blob([contractData], { type: PDF_MEDIA_TYPE });
    return URL.createObjectURL(file);
  },
  getContractAdditionUrl: async (
    rentalDealIdApi: IRentalObjectMyHome['rentalDealIdApi'],
    contractAdditionId: string,
    attachmentMainType: AttachmentMainType,
    attachmentSubType: string,
  ): Promise<GenericTypes.URL> => {
    const url = [
      'AttachmentContent',
      HeimAttributes.DATA_SOURCE,
      HeimAttributes.PARTNER_NAME,
      HeimAttributes.GUID,
      rentalDealIdApi,
      attachmentMainType,
      attachmentSubType,
      contractAdditionId,
    ].join('/');
    const contractData = await heimClient.get<Blob>(url, undefined, 'blob');
    const file = new Blob([contractData], { type: PDF_MEDIA_TYPE });
    return URL.createObjectURL(file);
  },
  getMoveOutProtocolUrl: async (
    country: GenericTypes.Country,
    lang: GenericTypes.Language,
  ): Promise<GenericTypes.URL> => {
    const response = await sanityClient.fetch<{ url: GenericTypes.URL }>(
      `
      *[_type == $type && id.current == $category && country == $country ][0]{
        "url": files[language == $lang][0].file.asset->url
      }
      `,
      {
        type: SanityDocumentType.PDF,
        category: SANITY_PDF_CATEGORY.MOVE_OUT_CHECKLIST,
        country,
        lang,
      },
    );

    return response?.url;
  },
  getHtmlDocumentFromHeimApi: async (
    url?: GenericTypes.URL,
  ): Promise<GenericTypes.HtmlString | undefined> => {
    if (!url) return undefined;
    return heimClient.get(url);
  },
};
