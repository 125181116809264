import { TranslationLabels } from '@generated/translation-labels';
import { Hidden } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Form, Spinner, Tooltip } from '@shared/components';
import { Environment } from '@shared/enums';
import { isEnv } from '@shared/helpers';
import { useApi, useCountry } from '@shared/hooks';
import { useTranslation } from '@shared/translations';
import { FormikConfig } from 'formik';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useApartment } from '../../../../+apartment';
import { useProfile } from '../../../../+profile';
import { api as terminateContractAPI } from '../../../../+termination/shared/components/Request/request.repository';
import { Comments } from '../../../../+ticketingV2/shared';
import {
  COUNTRY_TICKETING_MODEL,
  TICKETING_DEV_PREFIX,
} from '../../../ticketing.const';
import { TicketStatus } from '../../../ticketing.enum';
import { isSpecifiedStatus } from '../../../ticketing.helper';
import { api } from '../../../ticketing.repository';
import {
  ElementField,
  ServiceRequest,
  ServiceRequestDetails,
  Comment as CommentType,
} from '../../../ticketing.type';
import { useTicketing } from '../../state';
import { getRequestModel } from '../AdditionalInformation/form.model';
import {
  CustomerServiceRequestValues,
  InvoiceCustomerAccountRequestValues,
  IssueComplaintRequestValues,
  RepairMaintenanceValues,
  TerminateContractRequestValues,
} from '../AdditionalInformation/models';
import { Intro, IntroProps } from '../Intro/intro.component';
import { CommentsTitle } from '../RequestDetails/comments-title.component';
import { RequestDetails } from '../RequestDetails/request-details';
import { TerminateConfirmationActions } from '../TerminateConfirmatonActions/terminate-confirmation-actions.component';
import { UpdateButton } from '../UpdateButton/update-button.component';
import { getRequestTypeValue, getUpdateRequestIntroProps } from './data.helper';
import { useStyles } from './data.styles';

type Props = {
  data: ServiceRequestDetails;
};

// TODO: It needs to be refactored during work on the - https://fredensborg.atlassian.net/browse/MYH-522
export const Data: FC<Props> = ({ data }) => {
  const classes = useStyles();
  const { data: profileData } = useProfile();
  const { data: apartment } = useApartment();
  const { setTicketDetails, setTicketList, tickets, details } = useTicketing();
  const { t } = useTranslation();
  const country = useCountry();
  const [commentsData, setCommentsData] = useState<
    ServiceRequestDetails['comments']
  >(data.comments);
  const onAddComment = (comment: CommentType): void => {
    setCommentsData((prevState) => [...prevState, comment]);
  };
  const {
    jiraFields,
    requestType: countryRequestType,
  } = COUNTRY_TICKETING_MODEL[country];
  const [requestTypeTranslation, setRequestTypeTranslation] = useState<
    GenericTypes.TranslationLabel[]
  >([]);
  const getTerminateFormOptions$ = useCallback(
    () => terminateContractAPI.getFormOptions$(country),
    [country],
  );
  const [formOptions, isFetching] = useApi({}, getTerminateFormOptions$);
  const [isReadonly, setReadonly] = useState(true);
  const {
    [jiraFields.ADDRESS]: addressFromTicketElement,
    [jiraFields.COMMON_FAILURE]: commonFailure,
    [jiraFields.COMMON_ITEM]: commonItem,
    [jiraFields.OUTDOOR_ITEM]: outdoorItem,
    [jiraFields.REQUEST_TYPE]: requestTypeId,
    [jiraFields.UNIT_FAILURE]: unitFailure,
    [jiraFields.UNIT_ITEM]: unitItem,
    [jiraFields.UNIT_ROOM]: room,
    [jiraFields.INVOICE_CUSTOMER_ITEM]: invoiceCustomerItem,
    [jiraFields.RENTAL_ID]: rentailIdElement,
    createdDate,
    isAuthor,
    status,
    ticketId,
  } = data;
  const isUpdateEnabled = status?.updateEnabled;
  const requestType = requestTypeId as number;
  const addressFromTicket = (addressFromTicketElement as ElementField)?.value;
  const rentalId = (rentailIdElement as ElementField)?.value;

  let address = null;
  // Get the most accurate address, first from HeimAPI, then from JIRA ticket
  if (apartment?.allRentalObjects) {
    address = apartment.allRentalObjects?.find(
      (rentalObject) => rentalObject.rentalDealIdApi === rentalId,
    )?.addressInclFlatnumber;
  } else if (
    apartment?.rentalObjectMyHome &&
    apartment?.rentalObjectMyHome.rentalDealIdApi === rentalId
  ) {
    address = apartment.rentalObjectMyHome.addressInclFlatnumber;
  } else {
    address = addressFromTicket;
  }

  const ticketValues = useMemo(() => {
    return {
      requestType,
      roomId: +(room as ElementField)?.id,
      failureId: +(
        (unitFailure as ElementField)?.id ||
        (commonFailure as ElementField)?.id ||
        (invoiceCustomerItem as ElementField)?.id
      ),
      itemId: +(
        (unitItem as ElementField)?.id ||
        (commonItem as ElementField)?.id ||
        (outdoorItem as ElementField)?.id
      ),
      area: undefined,
    };
  }, [
    commonFailure,
    commonItem,
    invoiceCustomerItem,
    outdoorItem,
    requestType,
    room,
    unitFailure,
    unitItem,
  ]);

  const updateRequestIntroProps = useMemo<IntroProps | null>(
    () => getUpdateRequestIntroProps(country, ticketValues, isReadonly),
    [country, ticketValues, isReadonly],
  );

  const { config, fields } = getRequestModel(
    country,
    ticketValues,
    data,
    profileData,
    apartment,
    isReadonly,
    t,
    formOptions,
  );

  const { prepareValues, ...restConfigProps } = config;
  const formConfig: FormikConfig<
    | InvoiceCustomerAccountRequestValues
    | CustomerServiceRequestValues
    | IssueComplaintRequestValues
    | RepairMaintenanceValues
    | TerminateContractRequestValues
  > = {
    ...restConfigProps,
    onSubmit: async (values) => {
      const preparedValues = prepareValues(values, requestType);
      const isSummaryStartedWithTicketingDevPrefix =
        typeof preparedValues[jiraFields.SUMMARY] === 'string' &&
        (preparedValues[jiraFields.SUMMARY] as string).indexOf(
          TICKETING_DEV_PREFIX,
        ) !== 0;
      const shouldAddTicketingDevPrefixToSummary =
        !isEnv(Environment.PRODUCTION) &&
        isSummaryStartedWithTicketingDevPrefix;

      const finalValues = {
        ...preparedValues,
        ...(shouldAddTicketingDevPrefixToSummary && {
          [jiraFields.SUMMARY]: `${TICKETING_DEV_PREFIX} ${
            preparedValues[jiraFields.SUMMARY]
          }`,
        }),
        requestTypeId,
      };

      await api.updateTicket(
        ticketId,
        finalValues,
        country,
        profileData?.customerIdApi,
      );

      const details = await api.getTicket(
        country,
        ticketId,
        profileData?.customerIdApi,
      );

      setTicketDetails(details);
      setReadonly(true);

      const newTickets = tickets.map((ticket) => {
        if (ticket.id !== ticketId) {
          return ticket;
        }

        const {
          [jiraFields.DESCRIPTION]: description,
          [jiraFields.CUSTOMER_SERVICE_REQUEST_DESCRIPTION]: customerServiceRequestDescription,
          [jiraFields.SUMMARY]: summary,
        } = details;

        const updatedTicket: ServiceRequest = {
          ...ticket,
          [jiraFields.DESCRIPTION]:
            (customerServiceRequestDescription as ElementField) ||
            (description as ElementField),
          [jiraFields.SUMMARY]: summary,
        };

        return updatedTicket;
      });

      setTicketList(newTickets);
    },
  };
  const isStatusOpen = isSpecifiedStatus(status?.jiraKey, TicketStatus.OPEN);
  const isStatusClosed = isSpecifiedStatus(
    status?.jiraKey,
    TicketStatus.CLOSED,
  );

  const mappedFields = fields.map((field) => {
    const { id } = field;
    const isFieldEditableInActiveStatuses = [
      jiraFields.ATTACHMENT,
      jiraFields.VISIBILITY,
    ].includes(id);
    const canBeUpdated =
      !isReadonly &&
      !isStatusClosed &&
      (isStatusOpen || isFieldEditableInActiveStatuses);

    const commonProps = { ...field, id: id as never, readonly: !canBeUpdated };

    switch (id) {
      case jiraFields.SUMMARY: {
        return {
          ...commonProps,
          readonly:
            requestTypeId === countryRequestType.TERMINATE_CONTRACT
              ? true
              : commonProps.readonly,
          className: classes.summaryText,
          label: isReadonly
            ? ''
            : TranslationLabels.ticketingCustomerAccountSummaryLabel,
        };
      }
      case jiraFields.DESCRIPTION:
      case jiraFields.CUSTOMER_SERVICE_REQUEST_DESCRIPTION: {
        return {
          ...commonProps,
          className: classes.descriptionText,
          label: TranslationLabels.ticketingServiceRequestDescriptionLabel,
        };
      }
      case jiraFields.ATTACHMENT: {
        return {
          ...commonProps,
          readonly:
            requestTypeId === countryRequestType.TERMINATE_CONTRACT
              ? true
              : commonProps.readonly,
          label: TranslationLabels.formFieldsAttachmentFileLabel,
        };
      }

      case jiraFields.PEOPLE_LIVING: {
        return {
          ...commonProps,
          readonly:
            requestTypeId === countryRequestType.TERMINATE_CONTRACT
              ? true
              : commonProps.readonly,
        };
      }

      default: {
        return commonProps;
      }
    }
  });
  const handleCancel = (): void => {
    setReadonly(true);
  };

  const isCommentingAllowed = Boolean(details?.isCommentingAllowed);
  const areCommentsHidden = commentsData.length === 0 && !isCommentingAllowed;

  useEffect(() => {
    getRequestTypeValue(data, country).then(setRequestTypeTranslation);
  }, [data, country]);

  if (isFetching) {
    return <Spinner />;
  }
  return (
    <Grid container spacing={8}>
      <Grid item xs={12} md={7}>
        <div className={classes.commentsContainer}>
          <Hidden mdUp>
            {!areCommentsHidden && (
              <Comments
                comments={commentsData}
                handleSuccess={onAddComment}
                ticketId={ticketId}
                titleComponent={<CommentsTitle ticketId={ticketId} />}
                isCommentingAllowed={isCommentingAllowed}
              />
            )}
          </Hidden>
        </div>
        <div className={classes.leftColumn}>
          {updateRequestIntroProps && (
            <Intro
              titleClassName={classes.summaryText}
              {...updateRequestIntroProps}
            />
          )}
          <Form
            config={formConfig}
            fields={mappedFields}
            onCancel={handleCancel}
            showButtons={!isReadonly}
          />
          {isReadonly && isUpdateEnabled && (
            <Tooltip
              disabled={isAuthor}
              messageKey={TranslationLabels.commentDisableButtonLabel}
              placement="right"
            >
              <UpdateButton disabled={!isAuthor} setReadonly={setReadonly} />
            </Tooltip>
          )}
        </div>
        {requestTypeId === countryRequestType.TERMINATE_CONTRACT && (
          <TerminateConfirmationActions data={data} />
        )}
      </Grid>
      <Grid item xs={12} md={5}>
        <RequestDetails
          ticketId={ticketId}
          status={status}
          address={address}
          type={requestTypeTranslation}
          requestTypeId={requestType}
          date={createdDate}
          comments={commentsData}
          onAddComment={onAddComment}
          areCommentsHidden={areCommentsHidden}
          isCommentingAllowed={isCommentingAllowed}
        />
      </Grid>
    </Grid>
  );
};
