import { useMemo, FC } from 'react';
import debounce from 'lodash-es/debounce';
import { useFormikContext } from 'formik';
import { useDidUpdate } from '@shared/hooks/did-update.hook';

interface Props {
  debounceMs: number;
}

export const AutoSave: FC<Props> = ({ debounceMs }) => {
  const formik = useFormikContext();
  const debouncedSubmit = useMemo(
    () => debounce(formik.submitForm, debounceMs),
    [debounceMs, formik.submitForm],
  );

  useDidUpdate(() => {
    debouncedSubmit();
  }, [formik.values, debouncedSubmit]);

  return null;
};
