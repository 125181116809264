import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      minHeight: 128,
      overflow: 'hidden',
      position: 'relative',
    },
    emotionsWrapper: {
      display: 'inline-block',
      position: 'relative',
      zIndex: 1,
    },
    emotions: {
      marginTop: theme.spacing(3),
      display: 'flex',
    },
    emotion: {
      marginRight: theme.spacing(2),

      '&:last-child': {
        marginRight: 0,
      },
    },
    icon: {
      backgroundColor: palette.white,
      cursor: 'pointer',
      borderRadius: theme.spacing(1),
      border: `1px solid ${palette.athensGray}`,
      textAlign: 'center',
      padding: theme.spacing(1.5),
      transition: theme.transitions.create(
        ['background-color', 'border-color'],
        {
          duration: theme.transitions.duration.short,
          easing: 'linear',
        },
      ),

      '& path': {
        transition: theme.transitions.create(['stroke'], {
          duration: theme.transitions.duration.short,
          easing: 'linear',
        }),
      },

      '&:hover': {
        background: palette.internationalOrange,
        borderColor: palette.internationalOrange,

        '& path': {
          stroke: palette.white,
        },
      },
    },
    iconSelected: {
      background: palette.internationalOrange,
      borderColor: palette.internationalOrange,

      '& path': {
        stroke: palette.white,
      },
    },
    form: {
      backgroundColor: palette.white,
      borderRadius: theme.spacing(1),
      marginTop: theme.spacing(2.5),
    },
    background: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
    },
    confirmationIcon: {
      position: 'absolute',
      right: theme.spacing(4),
      bottom: theme.spacing(-5),

      '& path': {
        stroke: palette.silver,
      },
    },

    [theme.breakpoints.down('xs')]: {
      emotionsWrapper: {
        display: 'block',
      },
      emotions: {
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
      },
      emotion: {
        marginRight: 'unset',
        marginBottom: theme.spacing(2),

        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
  }),
);
