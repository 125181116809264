import {
  ActionDialogProps,
  TipDialogProps,
  DialogProps,
} from '@shared/Dialog/type';
import React, { FC, useCallback, useState } from 'react';
import { ActionDialog } from './dialogs/action-dialog.component';
import { TipDialog } from './dialogs/tip-dialog.component';

type DialogType = 'action' | 'tip';

type OutcomeProps = {
  closeDialog: () => void;
  Dialog: FC<DialogProps>;
  isOpen: boolean;
  openDialog: () => void;
};

type DialogHook = (type: DialogType) => OutcomeProps;

export const useDialog: DialogHook = (type = 'action') => {
  const [isOpen, setOpen] = useState(false);
  const handleOpen = (): void => {
    setOpen(true);
  };
  const handleClose = (): void => {
    setOpen(false);
  };
  const Dialog = useCallback(
    (props: DialogProps) => {
      switch (type) {
        case 'action':
          return (
            <ActionDialog
              isOpen={isOpen}
              onClose={handleClose}
              {...(props as ActionDialogProps)}
            />
          );
        case 'tip':
          return (
            <TipDialog
              isOpen={isOpen}
              onClose={handleClose}
              {...(props as TipDialogProps)}
            />
          );
        default:
          return null;
      }
    },
    [isOpen, type],
  );

  return {
    closeDialog: handleClose,
    Dialog,
    isOpen,
    openDialog: handleOpen,
  };
};
