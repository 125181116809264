import React, { FC, useCallback, useMemo } from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import { Icon } from '@shared/components';
import { Attachment } from '@shared/models';
import { useTranslation } from '@shared/translations';
import { ReactComponent as OfficeFilePdf1Icon } from '@heimstaden/icons-library/img/streamline-regular/work-office-companies/office-files/office-file-pdf-1.svg';
import { useStyles } from './document.styles';
import { getAttachmentTranslationLabelKey } from '../../helpers';
import { SanityDocument } from '../../../types';
import { isSanityDocument } from './document.helper';

type Props = {
  document: Attachment | SanityDocument;
};

export const Document: FC<Props> = (props) => {
  const { document } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const documentTitle = useMemo(
    () =>
      isSanityDocument(document)
        ? t(document.titleKey)
        : t(getAttachmentTranslationLabelKey(document)) ||
          document.attachmentTitle,
    [document, t],
  );

  const handleClick = useCallback(() => {
    window.open(document.url);
  }, [document.url]);

  return (
    <ButtonBase className={classes.button} onClick={handleClick}>
      <Icon icon={OfficeFilePdf1Icon} height={16} width={16} />
      <Typography className={classes.label}>{documentTitle}</Typography>
    </ButtonBase>
  );
};

Document.displayName = 'Document';
