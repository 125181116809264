import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      position: 'relative',
    },
    input: {
      '& + div': {
        marginTop: theme.spacing(4),
      },
    },
  }),
);
