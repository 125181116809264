import { Attachment } from '@shared/models';
import { AttachmentMainType } from '@shared/enums';
import { TenantResponseStatus, TerminationFlowStep } from '../types';
import { PendingReason } from './enums';

export const flowSteps: TerminationFlowStep[] = [
  {
    apartmentId: {
      id: null,
      fieldId: 'customfield_10325',
      value: '5171',
    },
    eventType: 'Termination',
    issueTypeId: '1',
    pendingReasons: [],
    status: 'resolved',
    step: 0,
    rentalDealId: {
      id: null,
      fieldId: 'customfield_10321',
      value: '56395',
    },
    tenantsResponses: [
      {
        fullName: 'John Doe',
        status: TenantResponseStatus.ACCEPTED,
        tenantId: '1001',
      },
      {
        fullName: 'Mr Simpson',
        status: TenantResponseStatus.PENDING,
        tenantId: '1002',
      },
      {
        fullName: 'Saul Goodman',
        status: TenantResponseStatus.REJECTED,
        tenantId: '1003',
      },
    ],
    ticketId: '1',
  },
  {
    eventType: 'MoveOutInspection',
    issueTypeId: '2',
    pendingReasons: [PendingReason.MISSING_BOOKING_DATE],
    status: 'pending',
    step: 1,
    ticketId: '2',
  },
  {
    eventType: 'MoveOut',
    issueTypeId: '3',
    pendingReasons: [PendingReason.MISSING_BOOKABLE_RESOURCE],
    status: 'open',
    step: 2,
    ticketId: '3',
  },
];

export const documents: Attachment[] = [
  {
    attachmentMainType: AttachmentMainType.CheckListHtmlSummary,
    attachmentSubType: 'MoveIn',
    attachmentTitle: 'Sjekkliste Innflytt',
    seqno: 1,
    mimetype: 'text/html',
    url:
      'https://hs-no-heimapi-web-test.azurewebsites.net/Customer/AttachmentContentHtml/4700/DEV-MYHOME/6f116fd5-592c-4fec-87ee-12c32b95f0e6/56395/2210/MoveIn/1102',
    refDate: '2021-08-10T14:30:00',
  },
  {
    attachmentMainType: AttachmentMainType.MoveOutAttachment,
    attachmentSubType: 'MoveOutInfo',
    attachmentTitle: 'Utflyttsbrev',
    seqno: 4,
    mimetype: 'application/pdf',
    url:
      'https://testapi.fredensborg.no/masterapi/PublicAttachment/PDF/3086116/6f116fd5-592c-4fec-87ee-12c32b95f0e6',
    refDate: '2021-08-10T14:30:00',
  },
  {
    attachmentMainType: AttachmentMainType.MoveOutAttachment,
    attachmentSubType: 'MoveOutPriceList',
    attachmentTitle: 'Prisliste utflytt',
    seqno: 5,
    mimetype: 'application/pdf',
    url:
      'https://testapi.fredensborg.no/masterapi/PublicAttachment/PDF/3086115/6f116fd5-592c-4fec-87ee-12c32b95f0e6',
    refDate: '2022-03-14T11:59:00',
  },
  {
    attachmentMainType: AttachmentMainType.MoveOutAttachment,
    attachmentSubType: 'MoveOutDocument',
    attachmentTitle: 'Bekreftelse på Oppsagt Leieforhold',
    seqno: 6,
    mimetype: 'application/pdf',
    url:
      'https://testapi.fredensborg.no/masterapi/PublicAttachment/PDF/1794868/6f116fd5-592c-4fec-87ee-12c32b95f0e6',
    refDate: '2022-03-22T10:48:00',
  },
];
