import { createContext } from 'react';

export type ContextValue = {
  goBack: () => void;
  goTo: (pathname: string) => void;
};

export const Context = createContext<ContextValue>({
  goBack: () => null,
  goTo: () => null,
});
