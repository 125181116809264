import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    alert: {
      marginBottom: theme.spacing(3),
    },
    link: {
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  }),
);
