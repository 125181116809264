import { sanityClient, SanityDocumentType } from '@shared/sanity';
import { TICKETING_PATHS } from '../../shared';
import { terminationRoutes } from '../../../+termination';
import { Types } from './type';

export const api = {
  getTypes$: (country: GenericTypes.Country): Promise<Types> => {
    const query = `
      * [_type == $type && content.country == $country][0]{
        ...content.ticketing.customerAccountRequest.types{
          invoiceAndCustomerAccount{
            "redirectURL": "${TICKETING_PATHS.MY_ACCOUNT_INVOICE_AND_CUSTOMER_ACCOUNT_ITEMS}",
            "titleKey": title->key,
          },
          terminateContract{
            "externalWebpageURL": externalWebpageUrl,
            "redirectURL": "${terminationRoutes.INDEX}",
            "titleKey": title->key,
          },
        }
      }
    `;

    return sanityClient.fetch<Types>(query, {
      country,
      type: SanityDocumentType.SETTINGS,
    });
  },
};
