import { TranslationLabels } from '@generated/translation-labels';
import Typography from '@material-ui/core/Typography';
import React, { FC } from 'react';
import { useTranslation } from '@shared/translations';
import { InvoiceStatus } from '../../../invoice.type';
import { useStyles } from './status.styles';

type Props = {
  status: InvoiceStatus;
};

const getTranslationKey = (status: Props['status']): string => {
  switch (status) {
    case InvoiceStatus.Overdue: {
      return TranslationLabels.invoiceDetailsStatusOverdue;
    }
    case InvoiceStatus.Paid: {
      return TranslationLabels.invoiceDetailsStatusPaid;
    }
    case InvoiceStatus.Unpaid: {
      return TranslationLabels.invoiceDetailsStatusUnpaid;
    }
    case InvoiceStatus.Credited: {
      return TranslationLabels.invoiceDetailsStatusCredited;
    }
    case InvoiceStatus.Canceled: {
      return TranslationLabels.invoiceDetailsStatusCanceled;
    }
    default: {
      return '';
    }
  }
};

export const Status: FC<Props> = (props) => {
  const classes = useStyles();
  const { status } = props;
  const { t } = useTranslation();
  const statusClassName = [classes.status, classes[status]].join(' ');
  const translationKey = getTranslationKey(status);

  return (
    <Typography className={statusClassName}>
      <strong>{t(translationKey)}</strong>
    </Typography>
  );
};
