import MUIDialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { SharedProps } from '@shared/Dialog/type';
import React, { FC, isValidElement } from 'react';
import { useTranslation } from '@shared/translations';
import { Icon } from '@shared/components';
import { ReactComponent as RemoveIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/remove-add/remove.svg';
import { useStyles } from './dialog.styles';

export const Dialog: FC<SharedProps> = (props) => {
  const {
    children,
    fullWidth,
    isOpen,
    onClose,
    onEnter,
    showCloseIcon,
    titleComponent,
    titleIcon,
    titleKey,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <MUIDialog
      classes={{
        paper: classes.paper,
      }}
      disableEscapeKeyDown={false}
      fullWidth={fullWidth || false}
      maxWidth="sm"
      onClose={onClose}
      onEnter={onEnter}
      open={isOpen}
    >
      <DialogTitle className={classes.title} disableTypography>
        {isValidElement(titleIcon) && titleIcon}
        {isValidElement(titleComponent) && titleComponent}
        {!isValidElement(titleComponent) && titleKey && (
          <Typography variant="h2">
            <strong>{t(titleKey)}</strong>
          </Typography>
        )}
        {showCloseIcon && (
          <IconButton className={classes.closeIcon} onClick={onClose}>
            <Icon icon={RemoveIcon} height={16} width={16} />
          </IconButton>
        )}
      </DialogTitle>
      {children}
    </MUIDialog>
  );
};
