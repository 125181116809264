import { sanityClient, SanityDocumentType } from '@shared/sanity';

export const api = {
  getSuggestion$: async (
    country: GenericTypes.Country,
    failureId: number,
  ): Promise<GenericTypes.TranslationLabel> => {
    const query = `
      *[_type == $type && country == $country && fieldId == string($failureId)][0].suggestion->{
        key
      }.key
    `;

    return sanityClient.fetch<GenericTypes.TranslationLabel>(query, {
      country,
      failureId,
      type: SanityDocumentType.TICKET_ELEMENT,
    });
  },
};
