export enum HttpMethod {
  DELETE = 'DELETE',
  GET = 'GET',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
}

export type Params = {
  [key: string]: string | number;
};

export enum HttpStatus {
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  GONE_ERROR = 410,
  INTERNAL_ERROR = 500,
}

export type HttpClientOptions = {
  headers?: HttpHeaders;
};

export type HttpHeaders = Record<string, string>;
