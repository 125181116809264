import { sanityClient, SanityDocumentType } from '../../../../shared';
import { Tip } from './tips.type';

export const api = {
  getTips: (country: GenericTypes.Country): Promise<Tip[]> => {
    const query = `
      *[_type == $type && content.country == $country][0]{
        "tips": [
          ...content.invoiceWidget.tips[]{
            "titleKey": title->key,
            "textKey": text->key,
          },
        ],
      }
    `;
    return sanityClient
      .fetch<{ tips: Tip[] }>(query, {
        country,
        type: SanityDocumentType.SETTINGS,
      })
      .then(({ tips }) => tips);
  },
};
