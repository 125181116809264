// TODO: Decision which Jira project should be used could be handled by jira-service.
//  For dev purposes TEST project is used but later it will be moved to production projects.
//  Then this file can be removed.
export enum JiraProjectId {
  // https://fredensborg.atlassian.net/secure/project/EditProject!default.jspa?pid=10061
  Test = '10061',

  // https://fredensborg.atlassian.net/secure/project/EditProject!default.jspa?pid=10076
  Norway = '10076',

  // https://fredensborg.atlassian.net/secure/project/EditProject!default.jspa?pid=10099
  Iceland = '10099',
}
