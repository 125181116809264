import { TranslationLabels } from '@generated/translation-labels';
import React, { FC } from 'react';
import { ReactComponent as AlertTriangle } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/alerts/alert-triangle.svg';
import { useTranslation } from '@shared/translations';
import { ErrorBox } from './ErrorBox/error-box.component';

type Props = {
  hideGoBackButton?: boolean;
};

export const GeneralError: FC<Props> = ({ hideGoBackButton }) => {
  const { t } = useTranslation();

  return (
    <ErrorBox
      icon={AlertTriangle}
      title={t(TranslationLabels.generalErrorTitle)}
      text={t(TranslationLabels.generalErrorText)}
      hideButton={hideGoBackButton}
    />
  );
};
