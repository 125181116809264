import { TranslationLabels } from '@generated/translation-labels';
import React, { FC } from 'react';
import { useTermination } from '../../state';
import { Document } from '../Document/document.component';
import { DocumentRedirect } from '../Document/document-redirect.component';
import { routes } from '../../../../+apartment/shared';

export const Documents: FC = () => {
  const { documents, sanityDocuments } = useTermination();

  return (
    <>
      {sanityDocuments.map((document) => (
        <Document key={`sanity-document-${document.id}`} document={document} />
      ))}
      {documents.map((document) => (
        <Document
          key={`document-${document.attachmentMainType}-${document.attachmentSubType}-${document.seqno}`}
          document={document}
        />
      ))}
      <DocumentRedirect
        labelKey={TranslationLabels.terminationDocumentsShowAllText}
        to={routes.APARTMENT}
      />
    </>
  );
};

Documents.displayName = 'Documents';
