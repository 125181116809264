import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '../../consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      backgroundColor: palette.white,
      border: `1px solid ${palette.iron}`,
      borderRadius: theme.spacing(4),
      boxShadow: '0px 4px 24px rgba(245, 245, 247, 1)',
      height: 56,
      [theme.breakpoints.down('sm')]: {
        height: 48,
      },
    },
    image: {
      width: 42,
      borderRadius: theme.spacing(0.5),
      margin: theme.spacing(0, 4),
      [theme.breakpoints.down('sm')]: {
        height: 48,
        width: 32,
        margin: theme.spacing(0, 1),
      },
    },
    menuListContainer: {
      borderRadius: theme.spacing(4),
      boxShadow: '4px 4px 16px rgba(201, 201, 204, 0.25)',
    },
  }),
);
