import { TranslationLabels } from '@generated/translation-labels';
import { useApi, useCountry } from '@shared/hooks';
import React, { FC, useCallback, useMemo } from 'react';
import { useApartment } from '../../../+apartment';
import { useProfile } from '../../../+profile';
import { Content, RequestCreator } from '../../shared';
import { getConfig, getFields, Values } from './issue-complaint-form.model';
import { api } from './issue-complaint-form.repository';
import { ReactComponent as AdditionalInfoImage } from '../../assets/additional-info.svg';
import { stepper } from '../stepper.config';

export const IssueComplaintForm: FC = () => {
  const country = useCountry();
  const getRequestTypeId$ = useCallback(() => api.getRequestTypeId$(country), [
    country,
  ]);
  const { data: apartmentData } = useApartment();
  const { data: userData } = useProfile();
  const [requestTypeId, isFetching] = useApi(undefined, getRequestTypeId$);
  const config = useMemo(
    () => getConfig(country, apartmentData, userData, requestTypeId),
    [apartmentData, country, requestTypeId, userData],
  );
  const fields = useMemo(() => getFields(country), [country]);

  return (
    <Content
      introProps={{
        descriptionKey: TranslationLabels.ticketingGeneralText,
        titleKey: TranslationLabels.ticketingGeneralTitle,
        childrenRightColumn: <AdditionalInfoImage />,
      }}
      isFetching={isFetching}
      type="columns"
      stepperConfig={stepper}
    >
      <RequestCreator<Values>
        fallbackMessageKey={
          TranslationLabels.ticketingNoSupportedServiceRequestMessage
        }
        formProps={{ config, fieldsConfig: fields }}
        requestTypeId={requestTypeId}
        fullWidth
      />
    </Content>
  );
};
