import { sanityClient, SanityDocumentType } from '@shared/sanity';
import { COUNTRY_TICKETING_MODEL } from '../../../../+ticketing/ticketing.const';
import { SubItems } from './sub-items.type';

export const api = {
  getItems$: (
    country: GenericTypes.Country,
    path: string,
    itemId: number,
  ): Promise<SubItems> => {
    const { jiraItems } = COUNTRY_TICKETING_MODEL[country];
    const query = `
      * [_type == $type && content.country == $country][0]{
        ...content.ticketing.customerAccountRequest.types.invoiceAndCustomerAccount.items[item->fieldId == string($itemId)][0]{
          "otherElementRedirectURL": "${path}/" + string(otherElementId),
          "items": failures[]{
            "key": _key,
            ...failure->{
              "key": fieldId,
              "redirectURL": "${path}/" + string(fieldId),
              "titleKey": title->key,
              externalWebpageUrl,
              roles,
            }
          }
        }
      }
    `;

    return sanityClient.fetch<SubItems>(query, {
      country,
      itemId,
      type: SanityDocumentType.SETTINGS,
      terminationRequestId:
        jiraItems.INVOICE_CUSTOMER_ACCOUNT_TERMINATE_REQUEST || null,
    });
  },
};
