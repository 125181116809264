import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    typography: {
      padding: `${theme.spacing(1)}px 0`,
    },

    iframeContainer: {
      overflow: 'hidden',
      paddingTop: '56.25%',
      position: 'relative',

      '& iframe': {
        border: 0,
        height: '100%',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100%',
      },
    },
  }),
);
