import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { TerminationRequestFlowStep } from '../../../types';
import { RequestSent } from './request-sent.component';
import { RequestForm } from './request-form.component';
import { RouteParams } from '../../routing';

type Props = {
  flowStep: TerminationRequestFlowStep;
};

export const Request: FC<Props> = ({ flowStep }) => {
  const { ticketId } = useParams<RouteParams>();
  return ticketId ? (
    <RequestSent flowStep={flowStep} />
  ) : (
    <RequestForm flowStep={flowStep} />
  );
};
