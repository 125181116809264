import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      backgroundColor: palette.athensGray,
      borderRadius: theme.spacing(0, 0, 1, 1),
      margin: theme.spacing(0, 2),
    },
    button: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(2, 0),
      width: '100%',
      borderRadius: theme.spacing(0, 0, 1, 1),
      transition: theme.transitions.create(['background'], {
        duration: theme.transitions.duration.standard,
        easing: 'linear',
      }),
    },
    buttonOpened: {
      backgroundColor: palette.silver,

      '&:hover': {
        backgroundColor: palette.silver,
      },
    },
    icon: {
      marginRight: theme.spacing(2),
      lineHeight: 0,
      transition: theme.transitions.create(['transform'], {
        duration: theme.transitions.duration.standard,
        easing: 'linear',
      }),
    },
    iconOpened: {
      transform: 'rotate(180deg)',
    },
    comments: {
      maxHeight: 0,
      overflow: 'hidden',
      transition: theme.transitions.create('max-height', {
        duration: theme.transitions.duration.short,
        easing: theme.transitions.easing.easeInOut,
      }),
    },
    commentsOpened: {
      maxHeight: 999999,
    },
    commentContent: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
    },
  }),
);
