import { TranslationLabels } from '@generated/translation-labels';
import React, { FC, SVGProps } from 'react';
import { LoadingButton } from '@shared/components';

type Props = {
  disabled: boolean;
  isSubmitting: boolean;
  label?: GenericTypes.TranslationLabel;
  loadingLabel?: GenericTypes.TranslationLabel;
  icon?: FC<SVGProps<SVGSVGElement>>;
  className?: string;
};

export const SubmitButton: FC<Props> = ({
  disabled,
  isSubmitting,
  label,
  loadingLabel,
  icon,
  className,
}) => (
  <LoadingButton
    color="primary"
    disabled={disabled}
    type="submit"
    variant="contained"
    size="large"
    isFetching={isSubmitting}
    label={label || TranslationLabels.formButtonSubmit}
    loadingLabel={loadingLabel || TranslationLabels.formButtonSaving}
    icon={icon}
    className={className}
  />
);

SubmitButton.displayName = 'SubmitButton';
