import {
  DEFAULT_LANGUAGE,
  sanityClient,
  SanityDocumentType,
} from '../../index';
import { Block } from './footer.type';

export const api = {
  getFooterBlocks: (
    country: GenericTypes.Country,
    lang: GenericTypes.Language,
  ): Promise<Block[]> =>
    sanityClient
      .fetch<{ blocks: Block[] }>(
        `*[_type == $settingsType && content.country == $country][0]{
        "blocks": [
          ...content.footer.blocks[]{
            "key": _key,
            "titleKey": title->key,
            "links": [
              ...links[]{
                ...select(
                  _type == 'reference' => {
                    ...*[_type == $pageType && _id == ^._ref][0]{
                      "titleKey": menuTitle->key,
                      route
                    },
                  },
                  {
                    "titleKey": title->key,
                    "url": url[$lang],
                  }
                ),
                "key": _key,
              },
            ]
          }
        ]
      }`,
        {
          country,
          lang: lang !== DEFAULT_LANGUAGE ? 'native' : lang,
          pageType: SanityDocumentType.PAGE,
          settingsType: SanityDocumentType.SETTINGS,
        },
      )
      .then((response) => response.blocks),
};
