import { TranslationLabels } from '@generated/translation-labels';
import * as Yup from 'yup';
import { InputValue } from '@shared/components/Fields';
import { FieldConfig, FormProps } from '@shared/FormV2/types';
import { getEnvValue } from '@shared/helpers';
import { feedbackClient } from './feedback.client';

export type Values = {
  comment: InputValue;
};

export const getFields = (label: string): FieldConfig<Values>[] => [
  {
    componentType: 'input',
    field: {
      name: 'comment',
      initialValue: '',
      validationSchema: Yup.string().required(
        TranslationLabels.formErrorsRequired,
      ),
    },
    props: {
      type: 'text',
      label,
      required: true,
    },
  },
];
export const getConfig = ({
  customerId,
  emotion,
}: {
  customerId: string;
  emotion: number;
}): FormProps<Values>['config'] => ({
  onSubmit: async (values) => {
    try {
      const payload = {
        customerId,
        response: emotion,
        ...values,
      };

      await feedbackClient.post(
        `/${getEnvValue('FEEDBACK_PROJECT_ID')}`,
        payload,
      );
    } catch (e) {
      throw new Error(
        e.data?.response || TranslationLabels.formGlobalErrorMessage,
      );
    }
  },
});
