import { TranslationLabels } from '@generated/translation-labels';
import { AttachmentValue, InputValue } from '@shared/components/Fields';
import * as Yup from 'yup';
import { ServiceRequestVisibilityEnum } from '@shared/enums';
import { Field } from '@shared/components';
import { COUNTRY_TICKETING_MODEL } from '../../../../ticketing.const';
import { api } from '../../../../ticketing.repository';
import { ElementField } from '../../../../ticketing.type';
import { RequestModel } from '../form.type';

export type InvoiceCustomerAccountRequestValues = {
  [key: string]: InputValue | AttachmentValue[];
};

export const getInvoiceCustomerAccountRequestModel: RequestModel<InvoiceCustomerAccountRequestValues> = (
  country,
  ticketValues,
  initialValues,
) => {
  const { jiraFields } = COUNTRY_TICKETING_MODEL[country];
  const summary = (initialValues?.[jiraFields.SUMMARY] as
    | ElementField
    | undefined)?.value;
  const description =
    (initialValues?.[jiraFields.GENERAL_REQUEST_DESCRIPTION] as
      | ElementField
      | undefined)?.value ||
    (initialValues?.[jiraFields.DESCRIPTION] as ElementField | undefined)
      ?.value;
  const attachment = initialValues?.[jiraFields.ATTACHMENT] as
    | AttachmentValue[]
    | undefined;
  const visibility = initialValues?.[jiraFields.VISIBILITY] as
    | ServiceRequestVisibilityEnum
    | undefined;
  const visibilityFields: Field<InvoiceCustomerAccountRequestValues>[] = initialValues?.isAuthor
    ? [
        {
          id: jiraFields.VISIBILITY,
          label: TranslationLabels.ticketingServiceRequestVisibilityLabel,
          switchOptions: {
            checkedLabelKey:
              TranslationLabels.ticketingServiceRequestVisibilityTypePrivate,
            checkedValue: ServiceRequestVisibilityEnum.PRIVATE,
            uncheckedValue: ServiceRequestVisibilityEnum.PUBLIC,
            checkedValueDescriptionKey:
              TranslationLabels.ticketingServiceRequestVisibilityPrivateDescriptionText,
            helperTextKey:
              TranslationLabels.ticketingServiceRequestVisibilityHelperText,
          },
          type: 'switch',
        },
      ]
    : [];

  return {
    config: {
      initialValues: {
        [jiraFields.SUMMARY]: summary || '',
        [jiraFields.DESCRIPTION]: description || '',
        [jiraFields.ATTACHMENT]: attachment || [],
        [jiraFields.VISIBILITY]:
          visibility || ServiceRequestVisibilityEnum.PUBLIC,
      },
      prepareValues: (values) => {
        const { jiraFields } = COUNTRY_TICKETING_MODEL[country];
        const { itemId, failureId } = ticketValues;

        return {
          ...values,
          [jiraFields.INVOICE_CUSTOMER_TYPE]: itemId,
          [jiraFields.INVOICE_CUSTOMER_ITEM]: failureId,
        };
      },
      validationSchema: Yup.object().shape({
        [jiraFields.DESCRIPTION]: Yup.string().required(
          TranslationLabels.formErrorsRequired,
        ),
        [jiraFields.SUMMARY]: Yup.string().required(
          TranslationLabels.formErrorsRequired,
        ),
      }),
    },
    fields: [
      {
        id: jiraFields.SUMMARY,
        label: TranslationLabels.ticketingGeneralSummaryLabel,
        required: true,
        type: 'text',
      },
      {
        id: jiraFields.DESCRIPTION,
        label: TranslationLabels.ticketingGeneralDescriptionLabel,
        required: true,
        type: 'textarea',
      },
      {
        accept: 'image/*,.pdf',
        helperText: '',
        id: jiraFields.ATTACHMENT,
        isMultiple: true,
        label: TranslationLabels.formFieldsAttachmentFileLabel,
        onPreviewClick: api.getAttachment,
        type: 'attachment',
      },
      ...visibilityFields,
    ],
  };
};
