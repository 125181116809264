import React, { FC, useCallback } from 'react';
import { sanityImageBuilder } from '@shared/sanity';
import { useApi, useCountry } from '@shared/hooks';
import { useStyles } from './app-logo.styles';
import { api } from './app-logo.repository';

export const AppLogo: FC = () => {
  const classes = useStyles();
  const country = useCountry();
  const callbackAppLogoUrl = useCallback(
    () =>
      api
        .getAppLogo(country)
        .then((appLogoUrl) => sanityImageBuilder.image(appLogoUrl).url()),
    [country],
  );
  const [appLogoUrl] = useApi<GenericTypes.URL>('', callbackAppLogoUrl);

  return (
    <img
      src={appLogoUrl}
      alt="MyHome application logo"
      className={classes.appLogo}
    />
  );
};
