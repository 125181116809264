export const failuresPathSuffix = '/failures';
export const itemsPathSuffix = '/items';
export const roomsPathSuffix = '/rooms';
export const suggestionPathSuffix = '/suggestion';

const prefix = '/ticketing';
const customerServicePrefix = `${prefix}/customer-service`;
const myAccountPrefix = `${prefix}/my-account`;
const serviceRequestAreasPrefix = `${prefix}/service-request/areas`;
const serviceRequestAreaRoomsPrefix = `${serviceRequestAreasPrefix}/:areaId${roomsPathSuffix}`;
const serviceRequestAreaRoomItemsPrefix = `${serviceRequestAreaRoomsPrefix}/:roomId${itemsPathSuffix}`;
const serviceRequestAreaRoomItemFailuresPrefix = `${serviceRequestAreaRoomItemsPrefix}/:itemId${failuresPathSuffix}`;
const serviceRequestAreaItemsPrefix = `${serviceRequestAreasPrefix}/:areaId${itemsPathSuffix}`;
const serviceRequestAreaItemFailuresPrefix = `${serviceRequestAreaItemsPrefix}/:itemId${failuresPathSuffix}`;

export const PATHS = {
  INDEX: prefix,
  CUSTOMER_SERVICE: customerServicePrefix,
  CUSTOMER_SERVICE_GENERAL_REQUEST: `${customerServicePrefix}/general-request`,
  CUSTOMER_SERVICE_GENERAL_REQUEST_SUCCESS: `${customerServicePrefix}/general-request/success`,
  CUSTOMER_SERVICE_ISSUE_COMPLAINT: `${customerServicePrefix}/issue-complaint`,
  CUSTOMER_SERVICE_ISSUE_COMPLAINT_SUCCESS: `${customerServicePrefix}/issue-complaint/success`,
  MY_ACCOUNT: myAccountPrefix,
  MY_ACCOUNT_INVOICE_AND_CUSTOMER_ACCOUNT_ITEMS: `${myAccountPrefix}/invoice-and-customer-account`,
  MY_ACCOUNT_INVOICE_AND_CUSTOMER_ACCOUNT_SUB_ITEMS: `${myAccountPrefix}/invoice-and-customer-account/:itemId`,
  MY_ACCOUNT_INVOICE_AND_CUSTOMER_ACCOUNT_FORM: `${myAccountPrefix}/invoice-and-customer-account/:itemId/:subItemId`,
  MY_ACCOUNT_INVOICE_AND_CUSTOMER_ACCOUNT_SUCCESS: `${myAccountPrefix}/invoice-and-customer-account/:itemId/:subItemId/success`,
  SERVICE_REQUEST_AREAS: serviceRequestAreasPrefix,
  SERVICE_REQUEST_AREA_ROOMS: serviceRequestAreaRoomsPrefix,
  SERVICE_REQUEST_AREA_ROOM_FORM: `${serviceRequestAreaRoomsPrefix}/:roomId`,
  SERVICE_REQUEST_AREA_ROOM_SUCCESS: `${serviceRequestAreaRoomsPrefix}/:roomId/success`,
  SERVICE_REQUEST_AREA_ROOM_FORM_SUMMARY: `${serviceRequestAreaRoomsPrefix}/:roomId/summary`,
  SERVICE_REQUEST_AREA_ROOM_ITEMS: serviceRequestAreaRoomItemsPrefix,
  SERVICE_REQUEST_AREA_ROOM_ITEM_FORM: `${serviceRequestAreaRoomItemsPrefix}/:itemId`,
  SERVICE_REQUEST_AREA_ROOM_ITEM_SUCCESS: `${serviceRequestAreaRoomItemsPrefix}/:itemId/success`,
  SERVICE_REQUEST_AREA_ROOM_ITEM_FAILURES: serviceRequestAreaRoomItemFailuresPrefix,
  SERVICE_REQUEST_AREA_ROOM_ITEM_FAILURE_SUGGESTION: `${serviceRequestAreaRoomItemFailuresPrefix}/:failureId${suggestionPathSuffix}`,
  SERVICE_REQUEST_AREA_ROOM_ITEM_FAILURE_FORM: `${serviceRequestAreaRoomItemFailuresPrefix}/:failureId`,
  SERVICE_REQUEST_AREA_ROOM_ITEM_FAILURE_SUCCESS: `${serviceRequestAreaRoomItemFailuresPrefix}/:failureId/success`,
  SERVICE_REQUEST_AREA_ITEMS: serviceRequestAreaItemsPrefix,
  SERVICE_REQUEST_AREA_ITEM_FORM: `${serviceRequestAreaItemsPrefix}/:itemId`,
  SERVICE_REQUEST_AREA_ITEM_SUCCESS: `${serviceRequestAreaItemsPrefix}/:itemId/success`,
  SERVICE_REQUEST_AREA_ITEM_FAILURES: serviceRequestAreaItemFailuresPrefix,
  SERVICE_REQUEST_AREA_ITEM_FAILURE_SUGGESTION: `${serviceRequestAreaItemFailuresPrefix}/:failureId${suggestionPathSuffix}`,
  SERVICE_REQUEST_AREA_ITEM_FAILURE_FORM: `${serviceRequestAreaItemFailuresPrefix}/:failureId`,
  SERVICE_REQUEST_AREA_ITEM_FAILURE_SUCCESS: `${serviceRequestAreaItemFailuresPrefix}/:failureId/success`,
};
