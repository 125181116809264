import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) => {
  const BUTTON_HEIGHT_PX = 24;
  const BUTTON_SPACING_PX = theme.spacing(3);

  return createStyles({
    modal: {
      userSelect: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },
    wrapper: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    closeButton: {
      position: 'absolute',
      top: BUTTON_SPACING_PX,
      right: BUTTON_SPACING_PX,
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      height: `${BUTTON_HEIGHT_PX}px`,
      '& path': {
        stroke: palette.white,
      },
    },
    contentWrapper: {
      width: '100%',
      height: `calc(100% - ${2 * BUTTON_HEIGHT_PX}px - ${
        4 * BUTTON_SPACING_PX
      }px)`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    arrowButton: {
      width: '10%',
    },
    content: {
      width: '80%',
      maxWidth: '1440px',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
    },
    children: {
      maxWidth: '100%',
      maxHeight: '100%',
      position: 'absolute',
    },
  });
});
