import React, { FC, useCallback } from 'react';
import { Editable, ReactEditor } from 'slate-react';
import { BaseEditor } from 'slate';
import isHotkey from 'is-hotkey';

import { HOTKEYS } from '@shared/components/RichText/constants';
import { toggleMark } from '@shared/components/RichText/tools';
import { Element } from '@shared/components/RichText/components/element.component';
import { Leaf } from '@shared/components/RichText/components/leaf.component';
import { useStyles } from './rich-text-editor.styles';

type Props = {
  editor?: BaseEditor & ReactEditor;
};

export const RichTextEditor: FC<Props> = ({ editor }) => {
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const classes = useStyles();

  if (!editor) return null;

  return (
    <Editable
      renderElement={renderElement}
      renderLeaf={renderLeaf}
      autoFocus
      className={classes.editor}
      onKeyDown={(event) => {
        Object.keys(HOTKEYS).forEach((hotkey) => {
          if (isHotkey(hotkey, event)) {
            event.preventDefault();
            const mark = HOTKEYS[hotkey];
            toggleMark(editor, mark);
          }
        });
      }}
    />
  );
};
