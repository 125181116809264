import { useAuth0 } from '@auth0/auth0-react';
import { heimClient, jiraServiceClient } from '@shared/http';
import { localStorageService, storageService } from '@shared/services';
import { useTranslation } from '@shared/translations';

export const useLogout = (): ((returnTo?: GenericTypes.URL) => void) => {
  const { logout } = useAuth0();
  const { setUserCountry } = useTranslation();

  return (returnTo?: GenericTypes.URL): void => {
    delete jiraServiceClient.instance.defaults.headers.Authorization;
    delete heimClient.instance.defaults.headers.Authorization;
    storageService.clear();
    setUserCountry(localStorageService.getCountryCode());
    logout({ returnTo });
  };
};
