import { TranslationLabels } from '@generated/translation-labels';
import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from '@shared/translations';
import { useCountry } from '@shared/hooks';
import { ContactInfo } from '@shared/components';
import { FlowStepSummary } from '../FlowStepSummary/flow-step-summary.component';
import { Details } from '../Details/details.component';
import { useStyles } from './flow-step-completed.styles';

export const FlowStepCompleted: FC = () => {
  const classes = useStyles();
  const country = useCountry();
  const { t } = useTranslation();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <FlowStepSummary
          labelKey={TranslationLabels.terminationFlowStepCompletedTitle}
        />
        <Grid className={classes.container} item xs={12}>
          <Typography component="div">
            {t(TranslationLabels.terminationFlowStepCompletedDescriptionText)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ContactInfo country={country} />
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Details />
      </Grid>
    </Grid>
  );
};

FlowStepCompleted.displayName = 'FlowStepCompleted';
