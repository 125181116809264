import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '../../index';

export const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      borderSize: `1px`,
      borderStyle: `solid`,
      borderRadius: theme.spacing(1),
      boxShadow: ' 0px 4px 16px rgba(198, 198, 198, 0.25)',
      padding: theme.spacing(3),
    },
    white: {
      backgroundColor: palette.white,
      borderColor: palette.athensGray,
    },
    grey: {
      backgroundColor: palette.athensGray,
      borderColor: palette.altoDD,
    },
  }),
);
