import { createContext } from 'react';
import { TFunction, TFunctionKeys } from 'i18next';
import { DEFAULT_LANGUAGE, SUPPORTED_LANGS } from '@shared/consts';

export type ContextValue = {
  langCode: GenericTypes.Language;
  supportedLanguages: GenericTypes.Language[];
  t: TFunction;
  changeLanguage: (language: GenericTypes.Language) => Promise<void>;
  setUserCountry: (country: GenericTypes.Country) => void;
};

export const Context = createContext<ContextValue>({
  langCode: DEFAULT_LANGUAGE,
  supportedLanguages: SUPPORTED_LANGS,
  t: (key: TFunctionKeys) => key,
  changeLanguage: () => Promise.resolve(),
  setUserCountry: () => null,
});
