import { TranslationLabels } from '@generated/translation-labels';
import { AttachmentValue } from '@shared/components/Fields';
import { Environment } from '@shared/enums';
import { getBlobFromBase64, isEnv, PDF_MEDIA_TYPE } from '@shared/helpers';
import { jiraServiceClient } from '@shared/http';
import {
  ICustomerRentalObject,
  IRentalDealListItem,
  IRentalObjectMyHome,
} from '@shared/models';
import qs from 'query-string';
import { ProfileData } from '../+profile';
import {
  COUNTRY_TICKETING_MODEL,
  TICKETING_DEV_PREFIX,
} from '../+ticketing/ticketing.const';
import { ServiceRequestApi } from '../+ticketing/ticketing.type';

type AdditionalPayloadData = {
  apartment: Pick<
    IRentalObjectMyHome,
    | 'addressInclFlatnumber'
    | 'flatNumber'
    | 'houseNumber'
    | 'objectShortName'
    | 'streetAddress'
    | 'postalCode'
  > & {
    apartmentId: ICustomerRentalObject['objectIdApi'];
    buildingId: ICustomerRentalObject['buildingIdApi'];
    rentalDealId: IRentalDealListItem['rentalDealIdApi'] | undefined;
  };
  user: Pick<ProfileData, 'email' | 'firstName' | 'lastName' | 'mobile'> & {
    customerId: ProfileData['customerIdApi'];
  };
};

class TicketingClient {
  public createRequest$ = <T extends Record<string, unknown>>(
    country: GenericTypes.Country,
    values: T,
    apartment: ICustomerRentalObject | null,
    requestTypeId: number | undefined,
    user: ProfileData | null,
  ): Promise<ServiceRequestApi> => {
    if (!apartment || !user || !requestTypeId) {
      return Promise.reject(TranslationLabels.formGlobalErrorMessage);
    }

    const userPayload = this.prepareUserPayloadData(user);
    const url = `${country}/tenant/${userPayload.customerId}/tickets`;
    const { jiraFields } = COUNTRY_TICKETING_MODEL[country];
    const finalValues = {
      ...values,
      [jiraFields.SUMMARY]: this.getSummaryValue(country, values),
      [jiraFields.REQUEST_TYPE]: requestTypeId,
    };
    const payload = {
      ...finalValues,
      apartment: this.prepareApartmentPayloadData(apartment),
      user: userPayload,
    };

    return jiraServiceClient.post<typeof payload, ServiceRequestApi>(
      url,
      payload,
    );
  };

  public getAttachment$ = async (
    attachment: AttachmentValue,
  ): Promise<string> => {
    const { url, value } = attachment;
    const endpoint = url || value || '';

    if (!endpoint) {
      return '';
    }

    const { base64, type } = await jiraServiceClient.get<{
      base64: string;
      type: string;
    }>(endpoint);

    return type === PDF_MEDIA_TYPE
      ? URL.createObjectURL(getBlobFromBase64(base64, type))
      : `data:${type};base64,${base64}`;
  };

  public getTicketsIdForRequestType<T = string[]>(
    country: GenericTypes.Country,
    user: ProfileData | null,
    requestTypeId: number,
    rentalDealId?: IRentalObjectMyHome['rentalDealIdApi'],
    additionalParams?: Record<string, unknown>,
  ): Promise<T> {
    if (!user || !rentalDealId)
      throw Error(TranslationLabels.formGlobalErrorMessage);

    const { customerId } = this.prepareUserPayloadData(user);
    const params = additionalParams ? qs.stringify(additionalParams) : {};
    const queryParams = additionalParams ? `?${params}` : '';
    const url = `${country}/tenant/${customerId}/tickets/${rentalDealId}/request-type/${requestTypeId}${queryParams}`;

    return jiraServiceClient.get<T>(url);
  }

  private prepareUserPayloadData = (
    user: ProfileData,
  ): AdditionalPayloadData['user'] => {
    const {
      customerIdApi: customerId,
      email,
      firstName,
      lastName,
      mobile,
    } = user;

    return { customerId, email, firstName, lastName, mobile };
  };

  private prepareApartmentPayloadData = (
    apartment: ICustomerRentalObject,
  ): AdditionalPayloadData['apartment'] => {
    const {
      buildingIdApi: buildingId,
      objectIdApi: apartmentId,
      rentalDetailsList,
      rentalObjectMyHome,
    } = apartment;

    return {
      addressInclFlatnumber: rentalObjectMyHome?.addressInclFlatnumber || '',
      apartmentId,
      buildingId,
      flatNumber: rentalObjectMyHome?.flatNumber || '',
      houseNumber: rentalObjectMyHome?.houseNumber || '',
      objectShortName: rentalObjectMyHome?.objectShortName || '',
      rentalDealId: rentalDetailsList?.[0].rentalDealIdApi,
      streetAddress: rentalObjectMyHome?.streetAddress || '',
      postalCode: rentalObjectMyHome?.postalCode || '',
    };
  };

  private getSummaryValue = <T extends Record<string, unknown>>(
    country: GenericTypes.Country,
    values: T,
  ): string => {
    const { jiraFields } = COUNTRY_TICKETING_MODEL[country];
    const summary = values[jiraFields.SUMMARY] as string;

    return !isEnv(Environment.PRODUCTION)
      ? `${TICKETING_DEV_PREFIX} ${summary}`
      : summary;
  };
}

export const ticketingClient = new TicketingClient();
