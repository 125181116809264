import { TranslationLabels } from '@generated/translation-labels';
import Hidden from '@material-ui/core/Hidden';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell, { TableCellProps } from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableContainer from '@material-ui/core/TableContainer';
import { ClassNameMap } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import * as Sentry from '@sentry/react';
import { Box } from '@shared/components';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, FC, MouseEvent, ReactNode, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from '@shared/translations';
import { generateUniqId } from '../../helpers';
import { ArrowRightIcon } from '../icons';
import { applyPagination, applySort } from './list.helper';
import { useStyles } from './list.styles';

type Order = 'asc' | 'desc';

export type Model<T> = {
  getContent: (element: T) => string | ReactNode;
  id: keyof T | 'actions';
  label: string;
  align?: TableCellProps['align'];
  disableSorting?: boolean;
  width?: TableCellProps['width'];
};

export type Props<T> = {
  elements: T[];
  model: Model<T>[];
  to: GenericTypes.URL | ((element: T) => void);
  className?: string;
  isWidget?: boolean;
  onBeforeClick?: (element: T) => void;
  property?: keyof T;
  classes?: ClassNameMap<string>;
};

export function List<T>(props: Props<T>): ReturnType<FC<Props<T>>> {
  const history = useHistory();
  const ownClasses = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    className,
    classes: overridenClasses,
    elements,
    isWidget = false,
    model,
    onBeforeClick,
    property,
    to,
  } = props;
  const classes = overridenClasses || ownClasses;
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState('');
  const [isFetching, setFetching] = useState(false);
  const tableClassNames = [
    classes.table,
    ...(className ? [className] : []),
  ].join(' ');
  const isHead = model.filter(({ label }) => label).length > 0;
  const handlePageChange = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ): void => {
    setPage(newPage);
  };
  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(Number(event.target.value));
  };
  const handleSort = (event: MouseEvent<HTMLHeadElement>): void => {
    const { property } = event.currentTarget.dataset;

    if (!property) {
      return;
    }

    const isAsc = orderBy === property && order === 'asc';

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const sortedElements = applySort<T>(elements, orderBy, order);
  const paginatedElements = applyPagination<T>(sortedElements, page, limit);
  const handleRowClick = async (element: T): Promise<void> => {
    setFetching(true);

    try {
      if (onBeforeClick) {
        await onBeforeClick(element);
      }

      if (typeof to === 'string') {
        history.push({
          pathname: `${to}${property ? `/${element[property]}` : ''}`,
        });
      } else if (typeof to === 'function') {
        // if 'to' is a function, handle on the parent
        to(element);
      }
    } catch (e) {
      enqueueSnackbar(t(TranslationLabels.formGlobalErrorMessage), {
        variant: 'error',
      });
      Sentry.captureException(e);
    } finally {
      setFetching(false);
    }
  };

  return (
    <TableContainer className={classes.container}>
      <Table className={tableClassNames}>
        {isHead && (
          <TableHead>
            <Hidden xsDown>
              <TableRow className={classes.headerRow}>
                <td className={classes.paddingCell} />
                {model.map(({ align, disableSorting, id, label, width }) => (
                  <TableCell
                    align={align}
                    className={classes.headCell}
                    key={generateUniqId()}
                    sortDirection={orderBy === id ? order : false}
                    width={width}
                  >
                    {disableSorting ? (
                      t(label)
                    ) : (
                      <TableSortLabel
                        active={orderBy === id}
                        data-property={id}
                        direction={orderBy === id ? order : 'asc'}
                        onClick={handleSort}
                      >
                        {t(label)}
                      </TableSortLabel>
                    )}
                  </TableCell>
                ))}
                <TableCell
                  className={clsx(classes.headCell, classes.actionCell)}
                  align="right"
                  width="60"
                />
                <td className={classes.paddingCell} />
              </TableRow>
            </Hidden>
          </TableHead>
        )}
        <TableBody>
          {paginatedElements.length > 0 ? (
            paginatedElements.map((element) => (
              <TableRow
                className={classes.row}
                key={generateUniqId()}
                {...(!isFetching && {
                  hover: true,
                  onClick: () => {
                    handleRowClick(element);
                  },
                })}
              >
                <td className={classes.paddingCell} />
                {model.map(({ align, getContent, label, width }) => (
                  <TableCell
                    align={align}
                    className={classes.cell}
                    key={generateUniqId()}
                    style={{
                      width: `${width}px`,
                    }}
                  >
                    {label && (
                      <Hidden smUp>
                        <Typography className={classes.cellLabel}>
                          <strong>{t(label)}:</strong>
                        </Typography>
                      </Hidden>
                    )}
                    {getContent(element)}
                  </TableCell>
                ))}
                <Hidden xsDown>
                  <TableCell align="right" className={classes.cell} width={60}>
                    <ArrowRightIcon width={24} height={24} />
                  </TableCell>
                </Hidden>
                <td className={classes.paddingCell} />
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                className={classes.emptyCell}
                colSpan={model.length + 2}
              >
                <Typography className={classes.emptyCellText}>
                  <strong>{t(TranslationLabels.listEmptyText)}</strong>
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {!isWidget && (
        <Box className={classes.pagination}>
          <TablePagination
            component="div"
            count={elements.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25]}
            labelRowsPerPage={t(TranslationLabels.rowsPerPageLabel)}
            classes={{
              actions: classes.paginationActions,
              root: classes.paginationRoot,
              selectRoot: classes.paginationActions,
              toolbar: classes.paginationToolbar,
            }}
          />
        </Box>
      )}
    </TableContainer>
  );
}
