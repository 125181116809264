/* eslint camelcase: 0 */

import { IContractRole } from '@shared/models';
import { JiraProjectId, PendingReason } from './shared';
import { Comment } from '../+ticketing/ticketing.type';

export enum TenantResponseStatus {
  ACCEPTED = 'accepted',
  PENDING = 'pending',
  REJECTED = 'rejected',
}

export enum BookingMode {
  BOOKING = 'booking',
  RESCHEDULING = 'rescheduling',
}

export type TenantResponse = {
  fullName: string;
  status: TenantResponseStatus;
  tenantId: IContractRole['customerIdApi'];
};

type TerminationRequestJiraField<T = string | null> = {
  fieldId: string | null;
  id: string | null;
  value: T;
};

export type MoveOutInspectionEventType = 'MoveOutInspection';
export type MoveOutEventType = 'MoveOut';

export type TerminationFlowStepEventType =
  | 'Termination'
  | MoveOutInspectionEventType
  | MoveOutEventType;

export type TerminationBasicFlowStep = {
  eventType: TerminationFlowStepEventType;
  issueTypeId: string;
  pendingReasons: PendingReason[];
  status: 'open' | 'pending' | 'resolved';
  step: number;
  ticketId: string;
};

export type BookingDate = GenericTypes.DateISO;

export type BookingId = string | null;

export type BookableResource = string | null;

export type SuggestedDate = GenericTypes.DateISO;

export type CalendarDatesRange = {
  start?: Date;
  end?: Date;
};

export type TerminationBookingFlowStep = TerminationBasicFlowStep & {
  availability?: Availability[];
  availabilityForSuggestion?: Availability[];
  bookingDate?: TerminationRequestJiraField<BookingDate>;
  bookingDatesRange?: {
    start: BookingDate;
    end: BookingDate;
  };
  bookingId?: TerminationRequestJiraField<BookingId>;
  bookableResource?: BookableResource;
  mode?: BookingMode;
  isCalendarOpened?: boolean;
  isCancellable?: boolean;
  workingHours?: WorkingHours;
  workingHoursForSuggestion?: WorkingHours;
  suggestedDate?: TerminationRequestJiraField<SuggestedDate>;
};

export type TerminationRequestFlowStep = TerminationBasicFlowStep & {
  address?: TerminationRequestJiraField;
  apartmentNo?: TerminationRequestJiraField;
  apartmentId?: TerminationRequestJiraField;
  comments?: TerminationRequestJiraField<Comment[]>;
  customerFeedback?: TerminationRequestJiraField;
  needHelpFindingNewHome?: TerminationRequestJiraField;
  rentalDealId?: TerminationRequestJiraField;
  terminationReason?: TerminationRequestJiraField;
  tenantsResponses?: TenantResponse[];
};

export type TerminationFlowStep =
  | TerminationBasicFlowStep
  | TerminationBookingFlowStep
  | TerminationRequestFlowStep;

export type JiraTicketId = string;

export type TimeKitProjectId = string;

export type TerminationCalendarConfig = {
  jiraProjectId: JiraProjectId;
  timekitProjects: Record<string, TimeKitProjectId>;
};

export type SanityDocument = {
  id: string;
  titleKey: GenericTypes.TranslationLabel;
  url?: GenericTypes.URL;
};

type Resource = {
  id: string;
  name: string;
  timezone: string;
};

export type Availability = {
  end: BookingDate;
  resources: Resource[];
  start: BookingDate;
};

type WorkingHours = {
  start: string; // string like "08:00:00"
  end: string; // string like "16:00:00"
};

export type ProjectId = string;

export type CreateBookingPayload = {
  project_id: string;
  resource_id: string;
  graph: string;
  start: string;
  end: string;
  what?: string;
  where: string;
  description: string;
  customer: {
    customerId: IContractRole['customerIdApi'];
    email: IContractRole['email'];
    name: IContractRole['name'];
    timezone: string;
  };
  meta: {
    jira_issue_type_id: string;
    jira_project_id: string;
    jira_ticket_id: string;
    rental_deal_id_api: string;
  };
};

export type SuggestBookingPayload = {
  date: SuggestedDate;
  customer: {
    customerId: IContractRole['customerIdApi'];
  };
  meta: {
    jira_ticket_id: string;
  };
};

export type CancelBookingPayload = {
  tenantId: IContractRole['customerIdApi'];
  ticketId: string;
};
