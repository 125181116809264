import { Routes } from '@shared/routing';
import React, { cloneElement, FC, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useStyles } from './styles';
import { Context } from './context';

enum SlideDirection {
  BACKWARD = 'backward',
  FORWARD = 'forward',
}

type Props = {
  routes: Routes;
};

// If you change the value remember to adjust a value in animated-routes.css as well
const SLIDE_TIMEOUT = 500;

export const Provider: FC<Props> = (props) => {
  const { routes } = props;
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<{
    transitionHistory: string[];
  }>();
  const [direction, setDirection] = useState<SlideDirection>();
  const pathNames = useMemo(() => routes.map((route) => route.path), [routes]);
  const animationClassNames = useMemo(() => {
    switch (direction) {
      case SlideDirection.BACKWARD:
        return 'slide-backward';
      case SlideDirection.FORWARD:
        return 'slide-forward';
      default:
        return '';
    }
  }, [direction]);
  const timeout = useMemo(() => (direction ? SLIDE_TIMEOUT : 0), [direction]);
  const goTo = (pathname: string): void => {
    setDirection(SlideDirection.FORWARD);
    history.push(pathname, {
      transitionHistory: [
        ...(location.state?.transitionHistory || []),
        location.pathname,
      ],
    });
  };
  const goBack = (): void => {
    const { transitionHistory } = location.state;
    const redirectTo = transitionHistory.pop() || pathNames[0];

    setDirection(SlideDirection.BACKWARD);
    history.push(redirectTo, {
      transitionHistory,
    });
  };

  return (
    <Context.Provider value={{ goBack, goTo }}>
      <div className={classes.wrapper}>
        <TransitionGroup
          childFactory={(child) =>
            cloneElement(child, {
              classNames: animationClassNames,
            })
          }
        >
          <CSSTransition
            classNames={animationClassNames}
            key={location.key}
            onExited={() => setDirection(undefined)}
            timeout={timeout}
            unmountOnExit
          >
            <Switch location={location}>
              {routes.map((route) => {
                const { component: Component, exact, path } = route;

                return (
                  <Route
                    component={Component}
                    exact={exact}
                    key={path}
                    path={path}
                  />
                );
              })}
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </Context.Provider>
  );
};
