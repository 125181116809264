import FormControl from '@material-ui/core/FormControl';
import { DatePicker } from '@material-ui/pickers/DatePicker/DatePicker';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { getFirstError, Icon } from '@shared/components';
import { FieldLabel } from '@shared/components/FieldLabel/field-label.component';
import { FieldReadonly } from '@shared/components/FieldReadonly/field-readonly.component';
import { useStyles } from '@shared/components/Fields/DatePickerField/date-picker-field.styles';
import { ReactComponent as CalendarIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/date-calendar/calendar.svg';
import { format } from 'date-fns';
import React, { FC } from 'react';
import { FieldProps as FormikFieldProps } from 'formik/dist/Field';
import { DateProps, DateValue } from '@shared/FormV2/fields/types';
import { useTranslation } from '@shared/translations';

type Props<TFormValues> = FormikFieldProps<DateValue, TFormValues> & {
  props: DateProps;
};

export function DatePickerFieldV2<TFormValues>({
  meta,
  form,
  field,
  props,
}: Props<TFormValues>): ReturnType<FC<Props<TFormValues>>> {
  const { error, value, touched } = meta;
  const { setFieldValue } = form;
  const { name, onBlur } = field;
  const {
    className,
    disabled,
    label,
    maxDate,
    minDate,
    readonly,
    required,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const errorMessage = touched && getFirstError(t, error);
  const handleChange = (date: MaterialUiPickersDate): void => {
    setFieldValue(name, date?.toISOString());
  };
  const date = value ? new Date(value) : null;
  const isError = Boolean(errorMessage);
  const dateFormat = 'd/MM/yyyy';
  const readonlyValue =
    date && dateFormat ? format(date, dateFormat) : date?.toLocaleDateString();

  return (
    <FormControl
      component="fieldset"
      disabled={disabled}
      error={isError}
      fullWidth
      required={required && !readonly}
    >
      <FieldLabel label={label} />
      {readonly ? (
        <FieldReadonly className={className} value={readonlyValue} />
      ) : (
        <DatePicker
          clearable={!required}
          disabled={disabled}
          error={isError}
          format={dateFormat}
          helperText={errorMessage}
          id={name}
          maxDate={maxDate}
          minDate={minDate}
          onBlur={onBlur}
          onChange={handleChange}
          value={date}
          InputProps={{
            classes: {
              input: classes.input,
            },
            endAdornment: (
              <Icon
                className={classes.icon}
                height={24}
                icon={CalendarIcon}
                width={24}
              />
            ),
          }}
        />
      )}
    </FormControl>
  );
}
