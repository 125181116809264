import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    headline: {
      fontSize: theme.typography.pxToRem(72),
      lineHeight: theme.typography.pxToRem(72),
      margin: theme.spacing(4, 0, 6),
    },
  }),
);
