import { sanityClient, SanityDocumentType, SanityImage } from '../../shared';

export const api = {
  getImage: (country: GenericTypes.Country): Promise<SanityImage> =>
    sanityClient
      .fetch<{ image: SanityImage }>(
        `*[_type == $type && content.country == $country][0]{
        "image": content.ticketingWidget.image,
      }`,
        {
          country,
          type: SanityDocumentType.SETTINGS,
        },
      )
      .then((result) => result.image),
};
