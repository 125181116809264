import { Action, State } from './type';

export const initialState: State = {
  data: null,
  isDepositOwner: false,
  isResident: false,
  isTenant: false,
  notificationPermissions: null,
};

export const reducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'PROFILE_INIT': {
      return {
        ...state,
        data: action.payload,
      };
    }
    case 'PROFILE_UPDATE': {
      if (!state.data) {
        return state;
      }

      return {
        ...state,
        ...action.payload,
      };
    }
    case 'PERMISSIONS_INIT': {
      return {
        ...state,
        // eslint-disable-next-line camelcase
        notificationPermissions: action.payload.notification_permissions,
      };
    }
    default: {
      return { ...state };
    }
    case 'PERMISSIONS_UPDATE': {
      if (!state.notificationPermissions) {
        return state;
      }
      return {
        ...state,
        notificationPermissions: action.payload,
      };
    }
  }
};
