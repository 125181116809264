import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '../../consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    menuContainer: {
      position: 'relative',
      marginLeft: 'auto',
    },
    button: {
      backgroundColor: palette.white,
      borderRadius: theme.spacing(4),
      boxShadow: '0px 4px 24px rgba(245, 245, 247, 1)',
      height: 56,
      padding: theme.spacing(0, 3),
      position: 'relative',
      zIndex: 5,
      border: `1px solid ${palette.iron}`,
      [theme.breakpoints.down('sm')]: {
        height: 48,
        padding: theme.spacing(0, 2),
      },
    },
    text: {
      marginBottom: 0,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(16),
      },
    },
    menuIconWrapper: {
      position: 'relative',
      marginLeft: theme.spacing(2),
      width: 32,
      height: 32,
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(1),
        width: 24,
        height: 24,
      },
    },
    menuIcon: {
      position: 'absolute',
      top: 0,
      left: 0,
      opacity: 1,
      transition: theme.transitions.create('opacity'),
    },
    menuIconHidden: {
      opacity: 0,
    },
    menuListContainer: {
      backgroundColor: palette.white,
      borderRadius: theme.spacing(4),
      boxShadow: '4px 4px 16px rgba(201, 201, 204, 0.25)',
      padding: theme.spacing(9, 0, 3.5),
      position: 'absolute',
      right: 0,
      top: 0,
      width: 480,
      zIndex: 4,
    },
    contactElement: {
      margin: theme.spacing(3.5, 4, 0),
    },
    contactInfo: {
      borderRadius: theme.spacing(1),
      backgroundColor: palette.athensGray,
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      menuContainer: {
        marginLeft: 'auto',
        position: 'unset',
      },
      menuListContainer: {
        left: 0,
        right: 0,
        width: '100%',
        top: 170,
        padding: theme.spacing(3.5, 0),
        borderRadius: 0,
      },
    },
    [theme.breakpoints.down('xs')]: {
      menuListContainer: {
        top: 148,
      },
    },
  }),
);
