import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    table: {
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(3),
      width: '100%',
      border: '1px solid #C2C1C1',
      boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.12)',
      borderRadius: theme.spacing(1),
    },
    rowContainer: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    tableElement: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    tableHeader: {
      borderBottom: '2px solid #D6D6D6',
    },
    tableHeaderText: {
      color: '#706F6F',
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(20),
    },
    tableData: {
      borderBottom: '1px solid #F5F5F5',
    },
    tableDataText: {
      fontSize: theme.typography.pxToRem(14),
    },
    tableFooter: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      background: '#FFF6F2',
    },
    tableFooterText: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: theme.typography.pxToRem(20),
    },
  }),
);
