import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      marginBottom: theme.spacing(3),
    },
    issued: {
      textTransform: 'uppercase',
      marginBottom: theme.spacing(2),
    },
    headerContent: {
      width: '100%',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    title: {
      marginTop: theme.spacing(2),
      marginBottom: 0,
      fontFamily: 'Helvetica',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 40,
    },
    status: {
      border: `2px solid transparent`,
      borderRadius: theme.spacing(1),
      display: 'inline-block',
      fontWeight: theme.typography.fontWeightRegular,
      marginLeft: theme.spacing(2),
      padding: theme.spacing(1.5, 3),
    },
    details: {
      marginBottom: theme.spacing(4),
    },
    detailsCaption: {
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: '150%',
      color: '#706F6F',
    },
    detailsData: {
      fontSize: 18,
    },

    [theme.breakpoints.down('xs')]: {
      container: {
        padding: 0,
        marginTop: theme.spacing(2),
      },
      header: {
        alignItems: 'flex-start',
        flexDirection: 'column',
      },
      headerContent: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      },
      title: {
        display: 'inline-block',
      },
      status: {
        marginLeft: 'auto',
        marginTop: 0,
      },
      download: {
        marginTop: theme.spacing(2),
      },
    },
  }),
);
