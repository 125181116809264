import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      background: palette.athensGray,
      boxShadow: 'none',
      margin: theme.spacing(2, 0),
      '&:before': {
        display: 'none',
      },
      '&:first-child': {
        marginTop: theme.spacing(0),
      },
      '&:last-child': {
        marginBottom: theme.spacing(0),
      },
      '&$expanded:first-child': {
        marginTop: theme.spacing(0),
      },
      '&$expanded:last-child': {
        marginBottom: theme.spacing(0),
      },
    },
    rounded: {
      borderRadius: theme.spacing(0.5),
    },
    expanded: {},
  }),
);
