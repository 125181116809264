import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { palette } from '../../consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: '100%',
    },
    table: {
      borderCollapse: 'separate',
      borderSpacing: theme.spacing(0, 2),
      tableLayout: 'fixed',
    },
    headCell: {
      borderBottom: 'unset',
      paddingBottom: 0,
      paddingTop: 0,

      '& svg': {
        width: '1em',
        height: '1em',
        color: 'inherit',
      },
    },
    row: {
      boxShadow: '0px 4px 16px rgba(198, 198, 198, 0.25)',
      cursor: 'pointer',
      padding: theme.spacing(2),
    },
    cell: {
      borderBottom: `1px solid ${palette.athensGray}`,
      borderTop: `1px solid ${palette.athensGray}`,

      '&:first-child': {
        borderLeft: `1px solid ${palette.athensGray}`,
        borderTopLeftRadius: theme.spacing(1),
        borderBottomLeftRadius: theme.spacing(1),
      },

      '&:last-child': {
        borderRight: `1px solid ${palette.athensGray}`,
        borderTopRightRadius: theme.spacing(1),
        borderBottomRightRadius: theme.spacing(1),
      },
    },
    actionCell: {
      '& i': {
        display: 'block',
      },
    },
    emptyCell: {
      backgroundColor: palette.athensGray,
      borderBottom: 'unset',
      width: '100%',
    },
    pagination: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      marginTop: theme.spacing(2),
      padding: theme.spacing(1),
      width: '100%',
    },
    additionalFiler: {
      padding: theme.spacing(0, 1),
    },
    paginationActions: {
      '& svg': {
        color: 'inherit',
        height: '1em',
        width: '1em',
      },
    },

    [theme.breakpoints.down('xs')]: {
      row: {
        display: 'block',
        padding: 0,
        marginBottom: theme.spacing(2),
      },
      cellLabel: {
        display: 'inline-block',
        marginRight: theme.spacing(1),
      },
      cell: {
        alignItems: 'center',
        borderBottom: `1px solid ${palette.athensGray}`,
        borderLeft: `1px solid ${palette.athensGray}`,
        borderRight: `1px solid ${palette.athensGray}`,
        display: 'flex',
        flexDirection: 'row !important',
        textAlign: 'left !important',
        width: '100% !important',

        '&:first-child': {
          borderTop: `1px solid ${palette.athensGray}`,
          borderTopLeftRadius: theme.spacing(1),
          borderTopRightRadius: theme.spacing(1),
        },

        '&:last-child': {
          borderBottomLeftRadius: theme.spacing(1),
          borderBottomRightRadius: theme.spacing(1),
        },
      },
      pagination: {
        flexDirection: 'column-reverse',
      },
      paginationRoot: {
        width: '100%',
      },
      paginationToolbar: {
        '& > p:first-of-type': {
          display: 'none',
        },
      },
    },
  }),
);
