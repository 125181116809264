import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    leftColumn: {
      [theme.breakpoints.up('md')]: {
        maxWidth: '640px',
      },
    },
    createdLabel: {
      marginBottom: theme.spacing(1),
      textTransform: 'uppercase',
    },
    summaryText: {
      marginBottom: theme.spacing(2),
      fontSize: theme.typography.pxToRem(40),
      lineHeight: theme.typography.pxToRem(48),
      fontWeight: theme.typography.fontWeightBold,
    },
    descriptionText: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(24),
      whiteSpace: 'break-spaces',
    },
    commentsContainer: {
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: theme.spacing(5),
    },
  }),
);
