import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';
import {
  InputFieldV2,
  SelectFieldV2,
  DatePickerFieldV2,
  CheckboxesFieldV2,
  AttachmentFieldV2,
  RadioButtonFieldV2,
  SwitchFieldV2,
} from '../fields';
import { FieldConfig } from '../types';

type Props<TFormValues> = {
  fieldConfig: FieldConfig<TFormValues>;
  values: TFormValues;
};

export function FormField<TFormValues>({
  fieldConfig,
  values,
}: Props<TFormValues>): ReturnType<FC<FieldConfig<TFormValues> | null>> {
  if (fieldConfig.field.isHidden && fieldConfig.field.isHidden(values)) {
    return null;
  }
  return (
    <Field name={fieldConfig.field.name}>
      {(fieldProps: FieldProps) => {
        // !!! 'switch' necessary to narrow down types
        switch (fieldConfig.componentType) {
          case 'input': {
            const { props } = fieldConfig;
            return <InputFieldV2<TFormValues> {...fieldProps} props={props} />;
          }
          case 'select': {
            const { props } = fieldConfig;
            return <SelectFieldV2<TFormValues> {...fieldProps} props={props} />;
          }
          case 'date': {
            const { props } = fieldConfig;
            return (
              <DatePickerFieldV2<TFormValues> {...fieldProps} props={props} />
            );
          }
          case 'checkboxes': {
            const { props } = fieldConfig;
            return (
              <CheckboxesFieldV2<TFormValues> {...fieldProps} props={props} />
            );
          }
          case 'radio': {
            const { props } = fieldConfig;
            return (
              <RadioButtonFieldV2<TFormValues> {...fieldProps} props={props} />
            );
          }
          case 'switch': {
            const { props } = fieldConfig;
            return <SwitchFieldV2<TFormValues> {...fieldProps} props={props} />;
          }
          case 'attachment': {
            const { props } = fieldConfig;
            return (
              <AttachmentFieldV2<TFormValues> {...fieldProps} props={props} />
            );
          }
          default:
            return null;
        }
      }}
    </Field>
  );
}
