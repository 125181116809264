const descendingComparator = <T>(a: T, b: T, orderBy: string): number => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};
const getComparator = <T>(
  order: 'asc' | 'desc',
  orderBy: string,
): ((a: T, b: T) => number) =>
  order === 'desc'
    ? (a: T, b: T): number => descendingComparator(a, b, orderBy)
    : (a: T, b: T): number => -descendingComparator(a, b, orderBy);

export const applySort = <T>(
  elements: T[],
  orderBy: string,
  order: 'asc' | 'desc',
): T[] => {
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = elements.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) return order;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return a[1] - b[1];
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return stabilizedThis.map((el) => el[0]);
};
export const applyPagination = <T>(
  elements: T[],
  page: number,
  limit: number,
): T[] => elements.slice(page * limit, page * limit + limit);
