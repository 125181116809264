import Grid from '@material-ui/core/Grid';
import MaterialLink from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from '@shared/translations';
import { Block } from '../Footer/footer.type';
import { getFilteredOutBlocks } from './footer-links.helper';
import { useStyles } from './footer-links.styles';

type Props = {
  blocks: Block[];
};

export const FooterLinks: FC<Props> = ({ blocks }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const data = useMemo(() => getFilteredOutBlocks(blocks), [blocks]);

  return data.length > 0 ? (
    <Grid container spacing={4}>
      {data.map(({ key, titleKey, links }) => (
        <Grid item xs={12} sm={6} md={3} key={key}>
          <Typography variant="h2" className={classes.headline}>
            {t(titleKey)}
          </Typography>
          {links.length > 0 && (
            <ul className={classes.links}>
              {links.map(({ key, titleKey, route, url }) => (
                <li key={key}>
                  <MaterialLink
                    {...(url && {
                      target: '_blank',
                      rel: 'noopener noreferrer',
                      href: url,
                    })}
                    {...(route && { component: Link, to: route })}
                  >
                    {t(titleKey)}
                  </MaterialLink>
                </li>
              ))}
            </ul>
          )}
        </Grid>
      ))}
    </Grid>
  ) : null;
};
