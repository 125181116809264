import { sanityClient, SanityDocumentType } from '@shared/sanity';

export const api = {
  getRequestTypeId$: async (
    areaId: number,
    country: GenericTypes.Country,
  ): Promise<number | undefined> => {
    const query = `
      * [_type == $type && content.country == $country][0]{
        ...content.ticketing.repairMaintenanceRequest.areas{
          "requestTypeId": select(
            common.typeId == $areaId => common.typeId,
            outdoor.typeId == $areaId => outdoor.typeId,
            unit.typeId == $areaId => unit.typeId,
           )
        },
      }
    `;
    const response = await sanityClient.fetch<{
      requestTypeId: number | undefined;
    }>(query, {
      areaId,
      country,
      type: SanityDocumentType.SETTINGS,
    });

    return response.requestTypeId;
  },
};
