import { TranslationLabels } from '@generated/translation-labels';
import { Attachment } from '@shared/models';

export const attachmentSubTypeToTranslationLabelMap: Partial<
  Record<Attachment['attachmentSubType'], GenericTypes.TranslationLabel>
> = {
  MoveOutInfo: TranslationLabels.attachmentTitleMoveOutInfo,
  MoveOutDocument: TranslationLabels.attachmentTitleMoveOutDocument,
  MoveOutPriceList: TranslationLabels.attachmentTitleMoveOutPriceList,
};

export const getAttachmentTranslationLabelKey = (
  attachment: Attachment,
): GenericTypes.TranslationLabel =>
  attachmentSubTypeToTranslationLabelMap[attachment.attachmentSubType] || '';
