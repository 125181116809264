import Typography from '@material-ui/core/Typography';
import React, { FC, SVGProps } from 'react';
import { ConditionalWrapper, Icon } from '@shared/components';
import { ReactComponent as InformationCircleIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/alerts/information-circle.svg';
import { ReactComponent as AlertCircleIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/alerts/alert-circle.svg';
import { ReactComponent as AlertTriangleIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/alerts/alert-triangle.svg';
import { ReactComponent as CheckCircleIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/form-validation/check-circle.svg';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useTranslation } from '@shared/translations';
import { useStyles, Props as StyleProps } from './info-box.styles';

export type InfoBoxProps = {
  messageKey: GenericTypes.TranslationLabel;
  messageParams?: Record<string, string>;
  link?: string;
  type: StyleProps['type'];
  className?: string;
};

const getIcon = (type: InfoBoxProps['type']): FC<SVGProps<SVGSVGElement>> => {
  if (type === 'error') return AlertCircleIcon;
  if (type === 'warning') return AlertTriangleIcon;
  if (type === 'success') return CheckCircleIcon;
  return InformationCircleIcon;
};

export const InfoBox: FC<InfoBoxProps> = (props) => {
  const { messageKey, messageParams, link, type, className } = props;
  const classes = useStyles({ type });
  const { t } = useTranslation();

  return (
    <ConditionalWrapper
      showWrapper={Boolean(link)}
      wrapper={(children) => <Link to={link as string}>{children}</Link>}
    >
      <div className={clsx(classes.wrapper, className)}>
        <Icon
          className={classes.icon}
          width={32}
          height={32}
          icon={getIcon(type)}
        />
        <Typography className={classes.text}>
          {t(messageKey, messageParams)}
        </Typography>
      </div>
    </ConditionalWrapper>
  );
};
