import { TranslationLabels } from '@generated/translation-labels';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useApi, useCountry } from '@shared/hooks';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, InfoBox } from '@shared/components';
import { useTranslation } from '@shared/translations';
import { FormV2 } from '@shared/FormV2';
import { useSnackbar } from 'notistack';
import { useProfile } from '../../state';
import { useStyles } from './details.styles';
import { useApartment } from '../../../../+apartment';
import { ticketingRoutes, useTicketing } from '../../../../+ticketing';
import { ServiceRequestApi } from '../../../../+ticketing/ticketing.type';
import { getConfig, getFields, Values } from './details.model';

export const Details: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const classes = useStyles();
  const country = useCountry();
  const { data: apartment } = useApartment();
  const { data: profileData } = useProfile();
  const { getUpdateProfileTicketId$, tickets, setTicketList } = useTicketing();
  const [
    detailsUpdateOpenedTicketId,
    setDetailsUpdateOpenedTicketId,
  ] = useState<string | undefined>();
  const [isFormReadonly, setIsFormReadonly] = useState(true);
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const summaryLabel = useMemo(
    () =>
      t(
        TranslationLabels.invoiceAndCustomerRequestChangeOrUpdateCustomerDetailsJiraSummary,
      ),
    [t],
  );
  const getUpdateDetailsTicketId = useCallback(async () => {
    const ticketId = await getUpdateProfileTicketId$();

    setDetailsUpdateOpenedTicketId(ticketId);
    setIsFormDisabled(ticketId !== undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUpdateProfileTicketId$, tickets.length]);

  useApi(null, getUpdateDetailsTicketId);

  const updateDetailsTicketUrl = useMemo(() => {
    if (!detailsUpdateOpenedTicketId) return undefined;

    return `${ticketingRoutes.DETAILS}/${detailsUpdateOpenedTicketId}`;
  }, [detailsUpdateOpenedTicketId]);
  const formConfig = useMemo(
    () => getConfig(country, apartment, profileData, summaryLabel),
    [country, apartment, profileData, summaryLabel],
  );
  const fieldsConfig = useMemo(() => getFields(profileData, isFormReadonly), [
    profileData,
    isFormReadonly,
  ]);
  const handleFormButtonClick = (): void => {
    if (isFormDisabled) return;
    setIsFormReadonly((prevState) => !prevState);
  };

  const handleSuccess = (detailsUpdateTicketApi: ServiceRequestApi): void => {
    setIsFormDisabled(true);
    setTicketList([detailsUpdateTicketApi, ...tickets]);
    enqueueSnackbar(t(TranslationLabels.ticketCreated), {
      autoHideDuration: 3000,
      variant: 'success',
    });
  };

  useEffect(() => {
    if (isFormDisabled) setIsFormReadonly(true);
  }, [isFormDisabled]);

  return (
    <Box>
      {updateDetailsTicketUrl && (
        <InfoBox
          className={classes.detailsInfoBox}
          messageKey={TranslationLabels.ticketAssignmentInfo}
          messageParams={{
            ticketId: detailsUpdateOpenedTicketId ?? '',
          }}
          type="info"
          link={updateDetailsTicketUrl}
        />
      )}
      <Typography variant="h2">
        {isFormReadonly
          ? t(TranslationLabels.profileDetailsTitle)
          : t(
              TranslationLabels.invoiceAndCustomerRequestChangeOrUpdateCustomerDetailsTitle,
            )}
      </Typography>
      <Typography className={classes.text}>
        {isFormReadonly
          ? t(TranslationLabels.profileDetailsText)
          : t(
              TranslationLabels.invoiceAndCustomerRequestChangeOrUpdateCustomerDetailsDescription,
            )}
      </Typography>

      <FormV2<Values>
        config={formConfig}
        fieldsConfig={fieldsConfig}
        handleSuccess={handleSuccess}
        onCancel={handleFormButtonClick}
        showButtons={!isFormReadonly}
      />
      {isFormReadonly && (
        <div className={classes.buttonsRows}>
          <Button
            onClick={handleFormButtonClick}
            variant="contained"
            color="primary"
            disabled={isFormDisabled}
          >
            {t(TranslationLabels.profileDetailsButtonLabel)}
          </Button>
        </div>
      )}
    </Box>
  );
};
