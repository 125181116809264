import { BlockContentProps } from '@sanity/block-content-to-react';
import sanityClient from '@sanity/client';
import sanityBuilder from '@sanity/image-url';
import { Environment } from '@shared/enums';
import React from 'react';
import { BlockContent } from '../components/BlockContent/block-content.component';
import { getEnvValue, isEnv } from '../helpers';

const projectId = getEnvValue('SANITY_PROJECT_ID');
const dataset = getEnvValue('SANITY_DATASET');

export const client = sanityClient({
  apiVersion: 'v2021-03-25',
  dataset,
  projectId,
  useCdn: isEnv(Environment.PRODUCTION),
});
export const renderBlockContent = (
  props: Omit<BlockContentProps, 'dataset' | 'projectId' | 'serializers'>,
): JSX.Element => <BlockContent {...props} />;
export const imageBuilder = sanityBuilder(client);
