import { TranslationLabels } from '@generated/translation-labels';
import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { useTranslation } from '@shared/translations';
import { Icon } from '@shared/components';
import { ReactComponent as MessagesBubbleDoubleIcon } from '@heimstaden/icons-library/img/streamline-regular/messages-chat-smileys/messages-speech-bubbles/messages-bubble-double.svg';
import { Dialog } from '@shared/Dialog';
import { Comment as CommentType } from '../../../../+ticketing/ticketing.type';
import { useStyles } from './comments.styles';
import { Comment } from '../Comment/comment.component';
import { CommentForm } from '../CommentForm/comment-form.component';

type Props = {
  comments: CommentType[];
  handleSuccess: (comment: CommentType) => void;
  ticketId: string;
  titleComponent?: ReactNode;
  titleKey?: GenericTypes.TranslationLabel;
  isCommentingAllowed: boolean;
};

// TODO: New comments component should be used across the app https://fredensborg.atlassian.net/browse/MYH-703
//  during migration all Service Requests to new commenting, please remember to add check if commenting is allowed
export const Comments: FC<Props> = (props) => {
  const {
    comments,
    handleSuccess,
    ticketId,
    titleComponent,
    titleKey,
    isCommentingAllowed,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const commentsSectionEndRef = useRef<HTMLDivElement>(null);

  const scrollToLastComment = useCallback(() => {
    commentsSectionEndRef.current?.scrollIntoView();
  }, []);

  useEffect(() => {
    if (isOpen && comments.length > 0) {
      scrollToLastComment();
    }
  }, [comments.length, isOpen, scrollToLastComment]);

  return (
    <>
      <Button
        className={classes.button}
        color="primary"
        variant="outlined"
        onClick={() => setOpen(true)}
      >
        {t(TranslationLabels.ticketingDetailsCommentsOpenButtonLabel)}
        <Icon
          className={classes.closeIcon}
          icon={MessagesBubbleDoubleIcon}
          height={22}
          width={22}
        />
        <Badge badgeContent={comments.length} color="primary" />
      </Button>
      <Dialog
        fullWidth
        isOpen={isOpen}
        titleComponent={titleComponent}
        titleKey={titleKey}
        titleIcon={
          <Icon
            className={classes.titleIcon}
            icon={MessagesBubbleDoubleIcon}
            height={32}
            width={32}
          />
        }
        onClose={() => setOpen(false)}
        onEnter={scrollToLastComment}
        showCloseIcon
      >
        <DialogContent className={classes.content} dividers>
          {comments.map((comment) => (
            <Comment comment={comment} key={`comment-${comment.date}`} />
          ))}
          <div ref={commentsSectionEndRef} />
        </DialogContent>
        {isCommentingAllowed && (
          <DialogActions className={classes.actions} disableSpacing>
            <CommentForm ticketId={ticketId} handleSuccess={handleSuccess} />
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};
