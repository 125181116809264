import { TranslationLabels } from '@generated/translation-labels';
import {
  BookingMode,
  TerminationBookingFlowStep,
  TerminationFlowStep,
  TerminationFlowStepEventType,
  TerminationRequestFlowStep,
} from '../../types';
import { State } from '../state';
import { Comment } from '../../../+ticketing/ticketing.type';

export const eventTypeToTranslationLabelMap: Record<
  TerminationFlowStepEventType,
  string
> = {
  Termination: TranslationLabels.terminationFlowStepTerminationTitle,
  MoveOutInspection:
    TranslationLabels.terminationFlowStepMoveOutInspectionTitle,
  MoveOut: TranslationLabels.terminationFlowStepMoveOutTitle,
};

export const getEventTypeTranslationLabelKey = (
  eventType: TerminationFlowStepEventType,
): string => {
  return eventTypeToTranslationLabelMap[eventType] || '';
};

export const getTerminateAddress = (
  flowSteps: TerminationFlowStep[],
): {
  address: TerminationRequestFlowStep['address'];
  apartmentNo: TerminationRequestFlowStep['apartmentNo'];
} => {
  const requestFlowStep:
    | TerminationRequestFlowStep
    | undefined = flowSteps.find(
    (flowStep) => flowStep.eventType === 'Termination',
  );

  return {
    address: requestFlowStep?.address,
    apartmentNo: requestFlowStep?.apartmentNo,
  };
};

export const getTerminateRentalIds = (
  flowSteps: TerminationFlowStep[],
): {
  apartmentId: State['apartmentId'];
  rentalDealId: State['rentalDealId'];
} => {
  const requestFlowStep:
    | TerminationRequestFlowStep
    | undefined = flowSteps.find(
    (flowStep) => flowStep.eventType === 'Termination',
  );

  return {
    apartmentId: requestFlowStep?.apartmentId?.value ?? undefined,
    rentalDealId: requestFlowStep?.rentalDealId?.value ?? undefined,
  };
};

export const isFlowCompleted = (flowSteps: TerminationFlowStep[]): boolean => {
  return flowSteps.every((flowStep) => flowStep.status === 'resolved');
};

export const getTenantsResponses = (
  flowSteps: TerminationFlowStep[],
): TerminationRequestFlowStep['tenantsResponses'] => {
  const requestFlowStep:
    | TerminationRequestFlowStep
    | undefined = flowSteps.find(
    (flowStep) => flowStep.eventType === 'Termination',
  );

  return requestFlowStep?.tenantsResponses;
};

const isRequestFlowStep = (
  flowStep: TerminationFlowStep,
): flowStep is TerminationRequestFlowStep =>
  flowStep.eventType === 'Termination';

export const isBookingFlowStep = (
  flowStep: TerminationFlowStep,
): flowStep is TerminationBookingFlowStep =>
  flowStep.eventType === 'MoveOutInspection' ||
  flowStep.eventType === 'MoveOut';

export const isFlowStepResolved = (flowStep: TerminationFlowStep): boolean =>
  flowStep.status === 'resolved';

export const getBookedDates = (
  flowSteps: TerminationFlowStep[],
): State['bookedDates'] => {
  const moveOutInspectionStep:
    | TerminationBookingFlowStep
    | undefined = flowSteps.find(
    ({ eventType }) => eventType === 'MoveOutInspection',
  );
  const moveOutStep: TerminationBookingFlowStep | undefined = flowSteps.find(
    ({ eventType }) => eventType === 'MoveOut',
  );

  return {
    ...(moveOutInspectionStep && {
      [moveOutInspectionStep.eventType]:
        moveOutInspectionStep.bookingDate?.value,
    }),
    ...(moveOutStep && {
      [moveOutStep.eventType]: moveOutStep.bookingDate?.value,
    }),
  };
};

export const getComments = (flowSteps: TerminationFlowStep[]): Comment[] => {
  const requestFlowStep:
    | TerminationRequestFlowStep
    | undefined = flowSteps.find((step) => isRequestFlowStep(step));

  return requestFlowStep?.comments?.value || [];
};

export const parseFlowSteps = (
  flowSteps: TerminationFlowStep[],
): TerminationFlowStep[] => {
  return flowSteps.map((flowStep) => {
    if (isBookingFlowStep(flowStep)) {
      const isBooked = Boolean(flowStep.bookingDate?.value);

      return {
        ...flowStep,
        isCalendarOpened: false,
        mode: isBooked ? BookingMode.RESCHEDULING : BookingMode.BOOKING,
      };
    }

    return flowStep;
  });
};
