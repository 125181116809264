import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { FC, ReactNode } from 'react';
import { Container } from '@shared/components';
import { useStyles } from './container-fluid.styles';

type Props = {
  children: ReactNode;
  className?: string;
};

export const ContainerFluid: FC<Props> = ({ children, className }) => {
  const classes = useStyles();

  return (
    <Container className={clsx(classes.containerFluid, className)}>
      <Container>{children}</Container>
    </Container>
  );
};
ContainerFluid.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
ContainerFluid.defaultProps = {
  className: undefined,
};
