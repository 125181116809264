import React, { FC, ReactNode } from 'react';
import { useStyles } from './element.styles';

type Props = {
  attributes: unknown;
  element: {
    type: 'block-quote' | 'bulleted-list' | 'list-item' | 'numbered-list';
  };
  children: ReactNode;
};

export const Element: FC<Props> = ({ attributes, children, element }) => {
  const classes = useStyles();

  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote className={classes.quote} {...attributes}>
          {children}
        </blockquote>
      );
    case 'bulleted-list':
      return (
        <ul className={classes.listBullets} {...attributes}>
          {children}
        </ul>
      );
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    default:
      return <p {...attributes}>{children}</p>;
  }
};
