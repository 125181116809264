import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    footerLinks: {
      display: 'flex',
      margin: theme.spacing(0, -4),
    },
    links: {
      '& li + li': {
        marginTop: theme.spacing(1),
      },
    },
    headline: {
      marginBottom: theme.spacing(2),
    },
  }),
);
