import { TranslationLabels } from '@generated/translation-labels';
import { useApi, useCountry } from '@shared/hooks';
import React, { FC, useCallback } from 'react';
import { Content, Elements } from '../../shared';
import { api } from './types.repository';
import { ReactComponent as AreasImage } from '../../assets/areas.svg';
import { stepper } from '../stepper.config';

export const Types: FC = () => {
  const country = useCountry();
  const getTypes$ = useCallback(() => api.getTypes$(country), [country]);
  const [types, isFetching] = useApi(undefined, getTypes$);

  return (
    <Content
      introProps={{
        descriptionKey:
          TranslationLabels.ticketingCustomerAccountRequestItemsText,
        titleKey: TranslationLabels.ticketingCustomerAccountRequestItemsTitle,
        childrenRightColumn: <AreasImage />,
      }}
      isFetching={isFetching}
      type="columns"
      stepperConfig={stepper}
    >
      <Elements data={types} />
    </Content>
  );
};
