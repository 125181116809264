import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      height: '100%',
    },
    image: {
      backgroundPosition: 'center bottom',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
    container: {
      height: '100%',
      padding: theme.spacing(20),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(10),
      },
    },
    companyLogo: {
      position: 'absolute',
      height: 80,
      '& path.st0': {
        fill: `${palette.white}`,
      },
    },
  }),
);
