import Typography from '@material-ui/core/Typography';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@shared/components';
import { Translation, useTranslation } from '@shared/translations';
import { ArrowRightIcon } from '../../icons';
import { useStyles } from './link-box-widget.styles';

type Props = {
  text: Translation;
  title: Translation;
  to: GenericTypes.URL;
};

export const LinkBoxWidget: FC<Props> = ({ text, title, to }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box>
      <Link to={to} className={classes.link}>
        <div>
          <Typography variant="h2">{t(title.key, title.options)}</Typography>
          <Typography>{t(text.key, text.options)}</Typography>
        </div>
        <ArrowRightIcon height={32} width={32} />
      </Link>
    </Box>
  );
};
