import { Action, State } from './type';

export const initialState: State = {
  data: null,
  isImagesFetching: true,
  images: {},
  apartmentDocuments: {},
  residents: [],
  tenants: [],
};

export const reducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'APARTMENT_INIT': {
      return {
        ...state,
        data: action.payload,
      };
    }
    case 'APARTMENT_IMAGES_SET_FETCHING': {
      return {
        ...state,
        isImagesFetching: action.payload.isFetching,
      };
    }
    case 'APARTMENT_IMAGES_INIT': {
      return {
        ...state,
        images: {
          ...state.images,
          ...action.payload,
        },
        isImagesFetching: false,
      };
    }
    case 'APARTMENT_DOCUMENTS_INIT': {
      return {
        ...state,
        apartmentDocuments: {
          ...state.apartmentDocuments,
          ...action.payload,
        },
      };
    }
    case 'APARTMENT_INHABITANTS_INIT': {
      return {
        ...state,
        residents: action.payload.residents,
        tenants: action.payload.tenants,
      };
    }
    default: {
      return { ...state };
    }
  }
};
