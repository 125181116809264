import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    iconButton: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: theme.spacing(0.5),
      color: palette.white,
      padding: theme.spacing(1),
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  }),
);
