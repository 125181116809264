import React, { FC, useMemo } from 'react';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { Status } from '@shared/components';
import type { TenantResponse as TenantResponseType } from '../../../types';
import { useStyles } from './tenant-response.styles';
import { getTerminationTenantResponseStatusOptions } from '../../helpers';

type Props = {
  tenantResponse: TenantResponseType;
};

export const TenantResponse: FC<Props> = (props) => {
  const { tenantResponse } = props;
  const classes = useStyles();
  const status = useMemo(
    () => getTerminationTenantResponseStatusOptions(tenantResponse.status),
    [tenantResponse],
  );

  return (
    <ListItem className={classes.listItem}>
      <ListItemText className={classes.listItemText}>
        <Typography variant="body1">{tenantResponse.fullName}</Typography>
      </ListItemText>
      <ListItemIcon>
        <Status status={status} />
      </ListItemIcon>
    </ListItem>
  );
};

TenantResponse.displayName = 'TenantResponse';
