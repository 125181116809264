import { camelCase } from 'lodash-es';

// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export const camelizeKeys = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map((v) => camelizeKeys(v));
  }

  if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelizeKeys(obj[key]),
      }),
      {},
    );
  }

  return obj;
};

export const removePropertiesWithValues = <T>(
  o: T,
  valuesToRemove: unknown[],
): Partial<T> => {
  return Object.entries(o).reduce((acc, [key, value]) => {
    return valuesToRemove.includes(value)
      ? acc
      : {
          ...acc,
          [key]: value,
        };
  }, {});
};
