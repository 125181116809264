import { useTranslation } from '@shared/translations';
import React, { FC } from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useStyles } from './global-error.styles';

type Props = {
  errorMessage?: string;
};

export const GlobalError: FC<Props> = ({ errorMessage }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (!errorMessage) {
    return null;
  }

  return (
    <FormHelperText className={classes.globalError} error>
      {t(errorMessage)}
    </FormHelperText>
  );
};
