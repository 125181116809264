import { HeimAttributes } from '@shared/enums';
import { heimClient } from '@shared/http';
import { ICustomerMidleLayerApi2 } from '@shared/models';
import { sanityClient, SanityDocumentType } from '@shared/sanity';
import { InvoiceFilter } from './invoice.type';
import { InvoiceHead } from './shared';

export const api = {
  // Customer/Invoices/:dataSource/:partnerName/:invoiceFilter/:userAndSessionId/:customerId
  urlGetInvoices: (
    invoiceFilter: InvoiceFilter,
    customerId?: ICustomerMidleLayerApi2['customerIdApi'],
  ): string => {
    return [
      'Invoices',
      HeimAttributes.DATA_SOURCE,
      HeimAttributes.PARTNER_NAME,
      invoiceFilter,
      HeimAttributes.GUID,
      customerId,
    ].join('/');
  },
  getInvoices: async (
    invoiceFilter: InvoiceFilter,
    customerId?: ICustomerMidleLayerApi2['customerIdApi'],
  ): Promise<InvoiceHead> => {
    const url = api.urlGetInvoices(invoiceFilter, customerId);

    return heimClient.get<InvoiceHead>(url, {});
  },
  // sanity
  getContactNumber: (country: GenericTypes.Country): Promise<number> =>
    sanityClient
      .fetch<{ phone: number }>(
        `*[_type == $type && content.country == $country][0]{
      "phone": content.phone,
    }`,
        {
          type: SanityDocumentType.SETTINGS,
          country,
        },
      )
      .then((data) => data.phone),
};
