import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '../../..';

export const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      width: '100%',
      maxWidth: '610px',
      margin: '0 auto',
    },
    iconContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: theme.palette.primary.main,
      width: '64px',
      height: '64px',
      borderRadius: '50%',
      marginBottom: theme.spacing(3),
    },
    icon: {
      width: '32px',
      height: '32px',
      '& path': {
        stroke: palette.white,
      },
    },
    text: {
      whiteSpace: 'pre-line',
      margin: theme.spacing(3, 0, 2, 0),
    },
    buttons: {
      marginTop: theme.spacing(2),
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      '&:last-child': {
        marginRight: 0,
      },
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
      },
    },
  }),
);
