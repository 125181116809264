import React, { FC } from 'react';
import clsx from 'clsx';
import { Icon } from '@shared/components';
import { ReactComponent as PictureLandscape } from '@heimstaden/icons-library/img/streamline-regular/images-photography/pictures/picture-landscape.svg';
import { useStyles } from './image-placeholder.styles';

type Props = {
  className?: string;
};

export const ImagePlaceholder: FC<Props> = ({ className }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.wrapper, className)}>
      <div className={classes.circle}>
        <Icon className={classes.icon} icon={PictureLandscape} />
      </div>
    </div>
  );
};
