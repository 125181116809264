import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    menuContainer: {
      position: 'relative',
      marginLeft: 'auto',
    },
    menuHeading: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(1.5, 4),
    },
    viewAll: {
      cursor: 'pointer',
    },
    button: {
      backgroundColor: palette.white,
      borderRadius: theme.spacing(4),
      boxShadow: '0px 4px 24px rgba(245, 245, 247, 1)',
      height: 56,
      padding: theme.spacing(0, 2),
      position: 'relative',
      zIndex: 5,
      border: `1px solid ${palette.iron}`,
      [theme.breakpoints.down('sm')]: {
        height: 48,
        padding: theme.spacing(0, 1),
      },
    },
    text: {
      marginBottom: 0,
      marginRight: theme.spacing(2.5),
    },
    menuListContainer: {
      backgroundColor: palette.white,
      borderRadius: theme.spacing(4),
      boxShadow: '4px 4px 16px rgba(201, 201, 204, 0.25)',
      padding: theme.spacing(9, 0, 3.5),
      position: 'absolute',
      right: 0,
      top: 0,
      width: 480,
      zIndex: 4,
    },
    [theme.breakpoints.down('sm')]: {
      menuContainer: {
        marginLeft: 'auto',
        position: 'unset',
      },
      menuListContainer: {
        left: 0,
        right: 0,
        width: '100%',
        top: 170,
        padding: theme.spacing(1.5, 0, 3.5),
        borderRadius: 0,
      },
      menuHeading: {
        marginTop: theme.spacing(2),
      },
    },
    [theme.breakpoints.down('xs')]: {
      menuListContainer: {
        top: 148,
      },
    },
  }),
);
