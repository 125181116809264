import DialogActions from '@material-ui/core/DialogActions';
import { Dialog } from '@shared/Dialog';
import { TipDialogProps, CommonProps } from '@shared/Dialog/type';
import React, { FC } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from '@shared/translations';
import { TranslationLabels } from '@generated/translation-labels';
import { useStyles } from './tip-dialog.styles';

type Props = CommonProps & TipDialogProps;

export const TipDialog: FC<Props> = (props) => {
  const { isOpen, onClose, titleKey, descriptionKey } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      showCloseIcon
      titleComponent={
        <Typography variant="h3" align="center" className={classes.title}>
          {t(titleKey)}
        </Typography>
      }
    >
      <Typography align="center" className={classes.content}>
        {t(descriptionKey)}
      </Typography>
      <DialogActions classes={{ root: classes.dialogActions }}>
        <Button color="primary" variant="outlined" onClick={onClose}>
          {t(TranslationLabels.okButton)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
