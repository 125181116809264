import { TranslationLabels } from '@generated/translation-labels';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Box, formatPaymentDate, useTranslation } from '../../shared';
import { formatAmount, routes, useInvoice } from '../shared';
import { useStyles } from './widget.styles';

export const Widget: FC = () => {
  const { data } = useInvoice();
  const classes = useStyles();
  const { t, langCode } = useTranslation();

  if (!data) {
    return null;
  }

  const { currency, saldoAmount, oldestDueDate } = data;

  return (
    <Box className={classes.box}>
      <Typography variant="h2">
        {t(TranslationLabels.invoiceWidgetTitle)}
      </Typography>
      <Typography className={classes.description}>
        {t(TranslationLabels.invoiceWidgetText)}
      </Typography>
      <div className={classes.container}>
        <div className={classes.row}>
          <Typography>
            <strong>{t(TranslationLabels.invoiceWidgetUpcoming)}</strong>
          </Typography>
        </div>
        <div className={classes.amountBlock}>
          <Typography className={classes.text}>
            {t(TranslationLabels.invoiceWidgetTotal)}
          </Typography>
          <Typography className={classes.amount}>
            {formatAmount(currency, langCode, saldoAmount)}
          </Typography>
        </div>
        <div className={clsx(classes.row, classes.summary)}>
          {oldestDueDate && (
            <Typography className={classes.paymentDate}>
              {t(TranslationLabels.invoiceWidgetDueDate, {
                date: formatPaymentDate(oldestDueDate),
              })}
            </Typography>
          )}
        </div>
      </div>
      <div className={classes.buttonsRow}>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to={routes.LIST}
        >
          {t(TranslationLabels.invoiceWidgetLink)}
        </Button>
      </div>
    </Box>
  );
};
