import { sanityClient, SanityDocumentType } from '../../sanity';
import { Element } from './menu.type';

export const api = {
  getElements: (country: GenericTypes.Country): Promise<Element[]> =>
    sanityClient
      .fetch<{ elements: Element[] }>(
        `* [(_type == $type && content.country == $country)][0]{
        "elements": [
          ...content.header.menuElements[]->{
            "id": _id,
            "url": route,
            "titleKey": menuTitle->key,
            "shortDescriptionKey": menuText->key
          }
        ]
      }`,
        {
          country,
          type: SanityDocumentType.SETTINGS,
        },
      )
      .then((response) => response.elements),
};
