import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';
import { palette } from '@shared/consts';

export const useStyles = makeStyles((theme) =>
  createStyles({
    element: {
      backgroundColor: palette.athensGray,
      borderRadius: theme.spacing(1),
      fontWeight: theme.typography.fontWeightRegular,
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
      textAlign: 'center',
    },
    buttons: {
      display: 'inline-block',
      marginRight: theme.spacing(1),
      top: theme.spacing(1.5),
      position: 'relative',
    },
  }),
);
